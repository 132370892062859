import { gql } from "@apollo/client";

export const GET_PERIOD_BY_ID = gql`
	query GetPeriodById($id: ID!) {
		getPeriodById(id: $id) {
			name
			comment
			index
			id
			startDate
			endDate
			estimationFixedCost
			estimationProducts
			estimationVariableCosts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			confidenceIndexMyRevenue
			confidenceIndexMyCost
			nbPackagePricing
			nbDistributions
			workspaceId
			showTaxesMyRevenue
			products {
				id
				index
				name
				description
				imagePath
				price
				periodId
				tva
				deductibleTva
				volume
				volumeMetric
				frequency
				frequencyMetric
				discount
				createdBy
				updatedBy
				createdAt
				updatedAt
				variableCosts
				priceIsKnowingIn
				paymentFrequency
				timeMetric
				netPriceExclVAT
				netPriceInclVAT
				priceExclVAT
				priceInclVAT
				semiVariableCostParts {
					id
					value
					productId
					fixedCostId
				}
			}
			profitParameter {
				revenue {
					id
					realRevenue
					totalRevenueOneShot
					totalRevenueRecurring
					estimatedRevenue
					meanPrice
					totalVolume
					meanVariableCost
					totalVolumeOneShot
					totalVolumeRecurring
					totalVariableCostOneShot
					totalVariableCostRecurring
					grossRevenue
					totalDiscount
				}
				totalFixedCosts {
					id
					totalSemiVariableCostPart
					totalSemiVariableCostOneShot
					totalSemiVariableCostRecurring
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					id
					realProfit
					grossProfit
					pureGrossProfit
					estimatedProfit
					date
					user
				}
				variableCost {
					id
					variableCosts
					date
					user
				}
				potential {
					id
					nbSpectrum
					nbScenario
					nbFavorites
					date
					user
				}
				projection {
					id
					nbProjection
					nbFavorites
					date
					user
				}
				costBasedInformation {
					id
					nbCostBased
					nbFavorites
					date
					user
				}
			}
		}
	}
`;
