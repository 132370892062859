import styled from "styled-components";

export const Wrapper = styled.div`
	overflow: hidden;
	flex: 1;
	display: flex;
	flex-direction: column;

	& > * {
		:not(:last-child) {
			margin: 0 0 25px;
		}
	}
`;

export const Title = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.primaryColor};
	line-height: 35px;
`;

export const WrapperTitleAndResetBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const WrapperScroll = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: 1;

	::-webkit-scrollbar {
		width: 4px;
	}

	& > * {
		:not(:last-child) {
			margin: 0 0 25px;
		}
	}
`;
