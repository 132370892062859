import React, { useMemo } from "react";
import { Button, Header } from "priceit-ui";
import { CurrentPeriod } from "./periods/currentPeriod/CurrentPeriod";
import {
	ButtonContent,
	FolderIcon,
	Wrapper,
	WrapperCurrentPeriod,
	WrapperCustomFirstLine,
	WrapperCustomFirstLineRight,
	WrapperDynamic,
	WrapperFirstHalfFirstLine,
	WrapperFirstHalfFirstLine2,
	WrapperHeaderFirstLine,
	WrapperHeaderSecondLine,
	WrapperTitle1,
	WrapperTitle2,
	WrapperTitles,
} from "./HeaderCustom.style";
import { SelectProductsBar } from "./selectProductBar/SelectProductsBar";
import { ActionsBar } from "./actionsBar/ActionsBar";
import { useReactiveVar } from "@apollo/client";
import { menuCollapsedVar } from "@src/cache/cache";
import SimulationsSelect from "@components/headerCustom/components/simulationsSelect/SimulationsSelect";
import { useThemeContext } from "@theme/Theme";
import { useGlobalContext } from "@globalContext";

export const HeaderCustom = ({
	showHeader,
	showSecondLine,
	showCurrentPeriod,
	periodListHover,
	saveEnabled,
	onSave,
	onSaveAs,
	isModified,
	setCurrentSaveBar,
	selectDataSaveBar,
	currentSelectValue,
	selectTitleSaveBar,
	selectFooterSaveBar,
	customFirstLine,
	customFirstLineRight,
	onShowSimulations,
	productsSelected,
	onChangeProductsSelected,
	customHeaderInProductsBar,
	title,
	showTitle,
	saveAsDisabled,
	openPeriodDrawer,
	setOpenPeriodDrawer,
	noSelectFooter,
	shortcuts,
	selectButtonText,
	hideButton,
	style = {},
	selectButtonLayoutType = "normal",
	hideSimulationSelectTitle,
}) => {
	periodListHover = periodListHover ?? (() => {});
	const { mode } = useThemeContext();
	const { light } = useGlobalContext();
	const menuCollapsed = useReactiveVar(menuCollapsedVar);
	const width = useMemo(
		() => (light ? "100%" : "calc(100% - " + (menuCollapsed ? 65 : 300) + "px)"),
		[menuCollapsed]
	);

	const hideSimulationSelect =
		showHeader && showTitle && !hideButton && selectButtonLayoutType === "back";

	return (
		<div style={{ marginBottom: showSecondLine ? "140px" : "70px" }}>
			{showHeader ? (
				<Header backgroundColor="transparent">
					<Wrapper width={width}>
						<WrapperHeaderFirstLine style={{ ...style }}>
							<WrapperFirstHalfFirstLine>
								<WrapperCustomFirstLine>
									{showTitle ? (
										hideButton ? (
											<WrapperTitles>
												<WrapperTitle1>{title[0]}</WrapperTitle1>
												<WrapperTitle2>{title[1]}</WrapperTitle2>
											</WrapperTitles>
										) : selectButtonLayoutType === "back" ? (
											<WrapperTitles>
												<Button
													type="add"
													mode={mode}
													onClick={() => onShowSimulations()}
													icon={"upTriangleSharp"}
													styleLeftIcon={{
														transform: "rotate(-90deg) scaleX(1.5)",
													}}
													iconWidth={"9px"}
													iconHeight={"12px"}
													style={{ paddingLeft: 0 }}
													height={"32px"}
													underlineType={"dotted"}
												>
													<ButtonContent>
														<FolderIcon type={"open2"} />
														{selectButtonText}
													</ButtonContent>
												</Button>
												<SimulationsSelect
													setCurrent={setCurrentSaveBar}
													selectData={selectDataSaveBar}
													currentValue={currentSelectValue}
													noSelectFooter={noSelectFooter}
													onShowSimulations={onShowSimulations}
													selectFooter={selectFooterSaveBar}
												/>
											</WrapperTitles>
										) : (
											<WrapperTitles>
												<WrapperTitle2>{title[1]}</WrapperTitle2>
												<Button
													theme={{
														colorIcon: "#a5b9c8",
														colorText: "#a5b9c8",
														focusColorIcon: "#fff",
													}}
													type="select"
													onClick={() => onShowSimulations()}
													icon={"open2"}
													iconHeight={"24px"}
													iconWidth={"24px"}
													style={{ fontSize: "16px", paddingLeft: 0 }}
												>
													{selectButtonText}
												</Button>
											</WrapperTitles>
										)
									) : null}
								</WrapperCustomFirstLine>
								<WrapperDynamic>
									<ActionsBar
										saveEnabled={saveEnabled}
										onSave={onSave}
										onSaveAs={onSaveAs}
										isModified={isModified}
										setCurrent={setCurrentSaveBar}
										selectData={selectDataSaveBar}
										currentValue={currentSelectValue}
										selectTitle={selectTitleSaveBar}
										selectFooter={selectFooterSaveBar}
										onShowSimulations={onShowSimulations}
										saveAsDisabled={saveAsDisabled}
										noSelectFooter={noSelectFooter}
										shortcuts={shortcuts}
										hideSimulationSelect={hideSimulationSelect}
										hideSimulationSelectTitle={hideSimulationSelectTitle}
									/>
								</WrapperDynamic>
								{customFirstLine}
							</WrapperFirstHalfFirstLine>
							{showCurrentPeriod ? (
								<WrapperCurrentPeriod>
									<CurrentPeriod periodListHover={e => periodListHover(e)} />
								</WrapperCurrentPeriod>
							) : null}
							{customFirstLineRight && !showCurrentPeriod ? (
								<WrapperCustomFirstLineRight>
									{customFirstLineRight}
								</WrapperCustomFirstLineRight>
							) : null}
						</WrapperHeaderFirstLine>
						{showSecondLine ? (
							<WrapperHeaderSecondLine>
								<SelectProductsBar
									productsSelected={productsSelected}
									onChangeProductsSelected={onChangeProductsSelected}
									customHeaderInProductsBar={customHeaderInProductsBar}
									title={title}
								/>
							</WrapperHeaderSecondLine>
						) : null}
					</Wrapper>
				</Header>
			) : (
				<>
					<Header backgroundColor="transparent">
						<Wrapper width={width}>
							<WrapperHeaderFirstLine style={{ ...style }}>
								<WrapperFirstHalfFirstLine2>
									<WrapperCustomFirstLine>
										{showTitle ? (
											<WrapperTitles>
												<WrapperTitle1>{title[0]}</WrapperTitle1>
												<WrapperTitle2>{title[1]}</WrapperTitle2>
											</WrapperTitles>
										) : null}
										{customFirstLine}
									</WrapperCustomFirstLine>
									{customFirstLineRight ? (
										<WrapperCustomFirstLineRight>
											{customFirstLineRight}
										</WrapperCustomFirstLineRight>
									) : null}
								</WrapperFirstHalfFirstLine2>
								{showCurrentPeriod ? (
									<WrapperCurrentPeriod>
										{showCurrentPeriod ? (
											<CurrentPeriod
												periodListHover={e => {
													if (periodListHover) periodListHover(e);
												}}
												openPeriodDrawer={openPeriodDrawer}
												setOpenPeriodDrawer={setOpenPeriodDrawer}
											/>
										) : undefined}
									</WrapperCurrentPeriod>
								) : null}
							</WrapperHeaderFirstLine>
						</Wrapper>
					</Header>
				</>
			)}
		</div>
	);
};

export default HeaderCustom;
