import styled, { css } from "styled-components";
import { WrapperPeriod } from "@components/headerCustom/periods/periodComponent/PeriodComponent.style";

export const CPDates = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

export const CPDuration = styled.div`
	color: #cbcbcb;
	white-space: nowrap;
`;

export const CPType = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 8px;
	border: 4px solid #30363a;
	font-size: 14px;
	color: #fff;
`;

export const WhiteSpan = styled.span`
	color: #fff;
`;

export const ColorSpan = styled.span`
	color: ${props => props.color};
`;

export const WrapperHoverTableIcon = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid transparent;
	border-radius: 3px;
	padding: 0 0 0 20px;
`;

export const WrapperHoverRFIcon = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid transparent;
	border-radius: 3px;
`;

export const WrapperHead = styled.div`
	color: #ffcd38;
	font-size: 16px;
	display: flex;
	align-items: center;
	height: 70px;
	border-bottom: 1px solid #2b343b;
	cursor: pointer;
	&:hover {
		background-color: #4c575f;
	}
`;

export const Title = styled.div`
	margin-left: 20px;
	width: 100%;
`;

export const RightPart = styled.div`
	width: 24px;
	height: 70px;
	cursor: pointer;
`;

export const WrapperArrow = styled.div`
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: #fff;
	visibility: hidden;

	${WrapperHead}:hover & {
		visibility: visible;
	}
`;

export const WrapperList = styled.div`
	overflow-y: auto;
	height: calc(100vh - 70px - 70px);
`;
