export const sectionName = {
	allSection: "allSection",
	parametersSection: "parametersSection",
	sectionPdf: "sectionPdf",
	sectionOffer: "sectionOffer",
	historicOffer: "historicOffer",
	offerCreationStep: {
		templateSelection: "templateSelection",
		customization: "customization",
		preSelectionOfOptions: "preSelectionOfOptions",
	},
	refreshTemplate: "refreshTemplate",
	offerHistoric: {
		signed: "signedSection",
		generated: "generatedSection",
		consulted: "consultedSection",
		sent: "sentSection",
	},
};
