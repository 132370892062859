import React from "react";
import { SingleSelect } from "priceit-ui";
import { useTranslation } from "react-i18next";
import { LanguageSelectWrapper, LanguageSingleSelect } from "./Components.style";
import { useRecoilState } from "recoil";
import { clientOffersTemplateFiltersSelector } from "@recoil/clientOffers/clientsOffersSelector";
import { languages } from "@services/i18n";
import { useThemeContext } from "@theme/Theme";

export const LanguageSelect = React.memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode } = useThemeContext();
	const [filter, setFilter] = useRecoilState(clientOffersTemplateFiltersSelector);

	const availableLanguages = languages.map(language => ({
		value: language.value,
		option: (
			<SingleSelect.Option value={language.value}>{t(language.value)}</SingleSelect.Option>
		),
	}));

	return (
		<LanguageSelectWrapper>
			<LanguageSingleSelect
				type="basic"
				mode={mode}
				appendTo={document.body}
				onChange={e =>
					setFilter({ key: "language", newValue: e === filter?.language ? null : e })
				}
				value={filter?.language}
				placeHolder={t("All languages")}
				data={availableLanguages}
			/>
		</LanguageSelectWrapper>
	);
});
LanguageSelect.whyDidYouRender = true;
LanguageSelect.displayName = "LanguageSelect";
