import styled from "styled-components";
import { Icon } from "priceit-ui";

export const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	position: fixed;
	top: 0;
	width: ${props => props.width};
	z-index: 999;
`;

export const WrapperHeaderFirstLine = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: #2b343b;
	height: 70px;
	//overflow-y: hidden;
`;

export const WrapperHeaderSecondLine = styled.div`
	height: 70px;
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #1e272e;
`;

export const WrapperDynamic = styled.div`
	padding: 0 5px 0 10px;
	display: flex;
	align-items: center;
`;

export const WrapperCurrentPeriod = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperCustomFirstLine = styled.div`
	width: 100%;
	display: flex;
	align-items: stretch;
	padding-left: 25px;
`;

export const WrapperCustomFirstLineRight = styled.div`
	padding-right: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WrapperTitles = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WrapperFirstHalfFirstLine = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const WrapperFirstHalfFirstLine2 = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const WrapperTitle1 = styled.div`
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0;
	color: ${({ theme }) => theme.header.textColor};
	white-space: nowrap;
`;

export const WrapperTitle2 = styled.div`
	font: normal normal normal 16px/19px Lato;
	letter-spacing: 0;
	color: ${({ theme }) => theme.primaryColor};
	white-space: nowrap;
	margin-top: 5px;
`;

export const ButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FolderIcon = styled(Icon)`
	height: 20px;
	width: 20px;
	margin-right: 5px;
`;
