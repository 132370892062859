import styled from "styled-components";
import { Button } from "priceit-ui";

export const WrapperLoader = styled.div`
	background-color: ${({ theme }) => theme.page.backgroundColor};
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const ClientOfferCreatedWrapper = styled.div`
	background-color: ${({ theme }) => theme.page.backgroundColor};
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 36px 42px;
	overflow: hidden;
`;
export const ClientOfferCreatedPanel = styled.div`
	background-color: ${({ theme }) => theme.page.tertiaryBackgroundColor};
	width: 100%;
	height: 100%;
	box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding: 10px;
	text-align: center;
`;
export const ImageWrapper = styled.div`
	width: 40%;
	max-width: 575px;
	> img {
		width: 100%;
	}
`;
export const TitleText = styled.div`
	font-size: 24px;
	font-weight: 600;
	color: ${({ theme }) => theme.page.textColor};
	margin-top: 20px;
`;
export const SubText = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.page.textColor};
	margin-top: 20px;
	width: 75%;
	text-align: center;
	> a {
		margin-left: 7px;
	}
`;
export const ButtonWrapper = styled.div`
	margin-top: 40px;
`;
export const StyledButton = styled(Button)`
	min-width: 130px;
`;
