import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useRecoilState, useRecoilValue } from "recoil";

import {
	clientsOffersContactSelector,
	clientsOffersSelectedIdSelector,
} from "@recoil/clientOffers";

import { useThemeContext } from "@theme/Theme";

import { GET_OFFER_CONTACTS_BY_OFFER_ID } from "@graphQl/queries/offerContact";

import { LabelAndInputComponent } from "@components/labelAndInputComponent/LabelAndInputComponent";
import {
	ButtonAdd,
	DeleteEmailButton,
	WrapperAllEmail,
	WrapperButton,
	WrapperDeleteEmailButton,
	WrapperExtraEmail,
} from "./Contact.style";
import { clientsOffersMailQuoteGeneratorLinkAtom } from "@recoil/clientOffers/atom";

export const Contact = React.memo(() => {
	const { t } = useTranslation("clientsOffers/modalSendEmail");
	const { mode } = useThemeContext();
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const [clientsOffersContacts, setClientsOffersContacts] = useRecoilState(
		clientsOffersContactSelector
	);
	const quoteGeneratorEmail = useRecoilValue(clientsOffersMailQuoteGeneratorLinkAtom);

	const { data: dataOfferContact } = useQuery(GET_OFFER_CONTACTS_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const offerContacts = dataOfferContact?.getOfferContactsByOfferId || [];

	useEffect(() => {
		if (quoteGeneratorEmail) {
			setClientsOffersContacts({ newValue: [quoteGeneratorEmail] });
		} else if (offerContacts?.length) {
			setClientsOffersContacts({
				newValue: offerContacts.map(contact => contact.email),
			});
		}
	}, [dataOfferContact, quoteGeneratorEmail]);

	return (
		<WrapperAllEmail>
			{clientsOffersContacts?.map((value, index) => (
				<WrapperExtraEmail key={value[1]}>
					<LabelAndInputComponent
						hidden={index > 0}
						isMandatory={true}
						mode={mode}
						width={"100%"}
						height={"30px"}
						value={value[0]}
						label={index === 0 ? t("Contacts") : undefined}
						// error={state.isContactEmailNotValid[index] && isContactEmailInMissingData}
						// errorTooltipContent={
						// 	state.isContactEmailNotValid[index]
						// 		? t("The email address is not a valid one!")
						// 		: t("Email is required")
						// }
						setValue={inputValue =>
							setClientsOffersContacts({
								changeValue: inputValue,
								index: index,
							})
						}
					/>
					{clientsOffersContacts?.length > 1 && (
						<WrapperDeleteEmailButton firstItem={index === 0}>
							<DeleteEmailButton
								onClick={() =>
									setClientsOffersContacts({
										toDelete: true,
										index: index,
									})
								}
								type="delete"
								width="16px"
								height="16px"
							/>
						</WrapperDeleteEmailButton>
					)}
				</WrapperExtraEmail>
			))}

			<WrapperButton>
				<ButtonAdd
					onClick={() =>
						setClientsOffersContacts({
							newValue: "",
						})
					}
					mode={mode}
					type="add"
					iconWidth="15px"
					iconHeight="15px"
					icon="userAdd"
				>
					{t("Add a contact")}
				</ButtonAdd>
			</WrapperButton>
		</WrapperAllEmail>
	);
});
// export default ContactEmail;
Contact.whyDidYouRender = true;
Contact.displayName = "Contact";
