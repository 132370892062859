import { deleteOfferContactMutation } from "@graphQl/mutations/offerContact";
import { useRecoilValue } from "recoil";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";

export const useRemoveAllEmptyContactLines = offerContacts => {
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const [deleteOfferContact] = deleteOfferContactMutation(offerId);

	return () =>
		offerContacts?.forEach((contact, index) => {
			const errors = contact.errors;
			if (
				errors.length === 3 &&
				errors.filter(error => error.type.includes("empty")).length === 3 &&
				index !== 0
			) {
				deleteOfferContact({
					optimisticResponse: {
						__typename: "Mutation",
						deleteOfferContact: contact.id,
					},
					variables: {
						id: contact.id,
					},
				});
			}
		});
};
