import styled, { css } from "styled-components";
import { Icon, Tooltipv3 } from "priceit-ui";
import { Link } from "react-router-dom";

export const WrapperInputLabel = styled.div`
	${props =>
		!props.isDrawer &&
		css`
			margin-top: 5px;
		`};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const SubSectionTitle = styled.div`
	font-size: 12px;
	color: ${({ theme }) => theme.page.secondaryTextColor};
`;

export const WrapperTextArea = styled.div`
	position: relative;
`;

export const WrapperLabelAndTextArea = styled.div`
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	min-width: 250px;
	width: 100%;
`;

export const InfoIcon = styled(Icon)`
	fill: ${props =>
		props.checked ? props.theme.page.textColor : props.theme.page.secondaryTextColor};
	width: 16px;
	height: ${props => props.height ?? "24px"};
	margin: ${props => props.margin ?? 0};

	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
	}
`;

export const StyledTooltip = styled(Tooltipv3)`
	margin: 0 ${props => (props.isDrawer ? "15px" : 0)} 0 5px;
`;

export const WrapperInfoIcon = styled.div`
	padding: ${props => props.padding ?? 0};
	display: flex;
	align-items: center;
`;

export const TermsAndConditionsLink = styled.a`
	color: ${({ theme }) => theme.page.textColor};
	margin: 0 10px;
	word-break: break-all;
`;

export const NoTermsAndConditionsLink = styled.div`
	color: ${({ theme }) => theme.page.secondaryTextColor};
	margin-right: 10px;
`;

export const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.textColor.quarternary};
	text-decoration: underline;
`;

export const WrapperUploadedTC = styled.div`
	display: flex;
	align-items: center;
	${({ isDrawer, canEdit }) => {
		if (!isDrawer && canEdit) {
			return css`
				justify-content: space-between;
				margin-top: 5px;
			`;
		} else if (!isDrawer && !canEdit) {
			return css`
				justify-content: flex-end;
				width: 100%;
			`;
		}
	}}
`;

export const PDFOrLinkIcon = styled(Icon)`
	fill: ${({ theme }) => theme.page.textColor};
`;

export const WrapperTitleIconAndSwitchForTextArea = styled.div`
	align-self: start;
	display: flex;
	justify-content: space-between;
	${props =>
		!props.isDrawer &&
		css`
			width: 100%;
		`}
`;
