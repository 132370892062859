import { useSetRecoilState } from "recoil";
import {
	clientsOfferNewVersionIdSelector,
	clientsOffersIsNewOfferVersionSelector,
	sectionName,
} from "@recoil/clientOffers";
import {
	publicLinkCurrentPriceModelCombinationIdAtom,
	publicLinkSelectedPackIdAtom,
} from "@recoil/publicPriceModel/atom";
import {
	clientsOffersCreationStepAtom,
	clientsOffersIsNewOfferAtom,
	clientsOffersNewPriceModelCombinationAtom,
	clientsOffersSelectedTemplateAtom,
	clientsOffersShowCreationModalAtom,
	clientsOffersTemplateFiltersAtom,
} from "@recoil/clientOffers/atom";

export const useResetOfferCreationParameters = () => {
	const setCurrentPriceModelCombinationId = useSetRecoilState(
		publicLinkCurrentPriceModelCombinationIdAtom
	);
	const setIsNewOffer = useSetRecoilState(clientsOffersIsNewOfferAtom);
	const setIsNewOfferVersion = useSetRecoilState(clientsOffersIsNewOfferVersionSelector);
	const setNewOfferVersionId = useSetRecoilState(clientsOfferNewVersionIdSelector);

	const setSelectedTemplate = useSetRecoilState(clientsOffersSelectedTemplateAtom);
	const setNewPriceModelCombination = useSetRecoilState(
		clientsOffersNewPriceModelCombinationAtom
	);
	const setFilter = useSetRecoilState(clientsOffersTemplateFiltersAtom);
	const setShowCreationModal = useSetRecoilState(clientsOffersShowCreationModalAtom);
	const setSelectedPacksIds = useSetRecoilState(publicLinkSelectedPackIdAtom);
	const setCreationStep = useSetRecoilState(clientsOffersCreationStepAtom);
	return () => {
		setCurrentPriceModelCombinationId(null);
		setIsNewOffer(null);
		setIsNewOfferVersion(false);
		setSelectedTemplate(null);
		setFilter({});
		setNewOfferVersionId(null);
		setNewPriceModelCombination(null);
		setShowCreationModal(false);
		setSelectedPacksIds([]);
		setCreationStep(sectionName.offerCreationStep.templateSelection);
	};
};
