export const customSort = (a, b) => {
	if (Number.isInteger(a) && Number.isInteger(b)) {
		if (a > b) {
			return -1;
		}
		return 1;
	}
	if ([undefined, null, ""].includes(a)) {
		return 1;
	}
	if ([undefined, null, ""].includes(b)) {
		return -1;
	}
	if (typeof a === "string") {
		a = a.toUpperCase();
	}
	if (typeof a === "string") {
		b = b.toUpperCase();
	}
	if (a === "-" && b !== "-") {
		return 1;
	}
	if (b === "-" && a !== "-") {
		return -1;
	}
	if ((a || 0) > (b || 0)) {
		return 1;
	}
	if ((a || 0) < (b || 0)) {
		return -1;
	}
	return 0;
};
