import {
	additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector,
	commitmentDiscountStateByPriceModelIdSelector,
	paymentTermsDiscountStateByPriceModelIdSelector,
	selectedPackIdByPriceModelIdSelector,
	specialDiscountStateByPriceModelIdAndDiscountIdSelector,
} from "@src/recoil/publicPriceModel";
import { numericalDiscountStateByPriceModelIdAndUnitMetricSelector } from "@src/recoil/publicPriceModel/volumeDiscountSelector";

import { selectorFamily } from "recoil";
import {
	engagementDependenciesListAtom,
	engagementShowWarningAtom,
	extraAddonShowWarningAtom,
	paymentTermsDependenciesListAtom,
	paymentTermShowWarningAtom,
	specialDiscountDependenciesListAtom,
	specialDiscountDropdownShowWarningAtom,
	specialDiscountShowWarningAtom,
	volumeDependenciesListAtom,
	volumeShowWarningAtom,
} from "./atom";
import { retrieveLockedObjectForDependency } from "@src/services/retrieveLockedObjectForDependency/retrieveLockedObjectForDependency";

export const paymentTermsDependenciesListByPriceModelIdSelector = selectorFamily({
	key: "paymentTermsDependenciesListByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(paymentTermsDependenciesListAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(paymentTermsDependenciesListAtom, {
				...get(paymentTermsDependenciesListAtom),
				[priceModelId]: newValue || [],
			});
		},
});

export const engagementDependenciesListByPriceModelIdSelector = selectorFamily({
	key: "engagementDependenciesListByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(engagementDependenciesListAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(engagementDependenciesListAtom, {
				...get(engagementDependenciesListAtom),
				[priceModelId]: newValue || [],
			});
		},
});

export const specialDiscountDependenciesListByPriceModelIdSelector = selectorFamily({
	key: "specialDiscountDependenciesListByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(specialDiscountDependenciesListAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(specialDiscountDependenciesListAtom, {
				...get(specialDiscountDependenciesListAtom),
				[priceModelId]: newValue || [],
			});
		},
});

export const volumeDependenciesListByPriceModelIdSelector = selectorFamily({
	key: "volumeDependenciesListByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(volumeDependenciesListAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(volumeDependenciesListAtom, {
				...get(volumeDependenciesListAtom),
				[priceModelId]: newValue || [],
			});
		},
});

export const setDiscountRuleDependenciesByPriceModelIdSelector = selectorFamily({
	key: "setDiscountRuleDependenciesByPriceModelIdSelector",
	get: ({ get }) => 1,
	set:
		priceModelId =>
		({ get, set }, dataDiscountRule) => {
			const paymentTermsDependencies =
				dataDiscountRule?.find(rule => rule.paymentTermDiscount)?.dependenciesAsDesired ||
				[];

			const engagementDependencies =
				dataDiscountRule?.find(rule => rule.engagementDiscount)?.dependenciesAsDesired ||
				[];

			const specialDiscountDependencies = dataDiscountRule?.reduce((prev, current) => {
				if (current.specialDiscount && current?.dependenciesAsDesired) {
					prev.push(...current?.dependenciesAsDesired);
				}
				return prev;
			}, []);

			const volumeDependencies = dataDiscountRule?.reduce((prev, current) => {
				if (current.volumeDiscount && current?.dependenciesAsDesired) {
					prev.push(...current?.dependenciesAsDesired);
				}
				return prev;
			}, []);

			const getRangesDisable = dependencies =>
				dependencies?.reduce((prev, current) => {
					const discountRule = dataDiscountRule.find(
						discountRule => discountRule.id === current?.requiredDiscountRuleId
					);
					const specialDiscountId =
						current?.requiredDiscountRule?.type === "specialDiscount" ||
						current?.requiredDiscountRule?.type === "specialCondition" ||
						current?.requiredDiscountRule?.type === "extra" ||
						current?.requiredDiscountRule?.type === "addon"
							? discountRule?.specialDiscount?.id
							: null;

					const unitMetric = [
						"rangeNumericalDiscount",
						"rangeNumericalSupplement",
						"tieredNumericalDiscount",
						"tieredNumericalSupplement",
						"linearNumericalDiscount",
						"linearNumericalSupplement",
					].includes(current?.requiredDiscountRule?.type)
						? discountRule?.volumeDiscount?.unitMetric
						: null;
					const isSwitch =
						current?.requiredDiscountRule?.type === "specialDiscount" ||
						current?.requiredDiscountRule?.type === "specialCondition" ||
						current?.requiredDiscountRule?.type === "extra" ||
						current?.requiredDiscountRule?.type === "addon"
							? discountRule?.specialDiscount?.specialRange?.length <= 2
							: false;
					current?.rangeDependencies?.forEach(range => {
						const { isLocked, lockedExtraAddon } =
							retrieveLockedObjectForDependency(discountRule);
						const isNoneRange =
							(current?.desiredDiscountRule?.specialDiscount?.id &&
								range?.desiredRange?.index === 0) ||
							(current?.requiredDiscountRule?.specialDiscount?.id &&
								range?.requiredRange?.index === 0);

						if (!isNoneRange) {
							const currentRange = prev.find(
								value => value?.id === range?.desiredRange.id
							);
							if (!currentRange) {
								prev.push({
									id: range?.desiredRange.id,
									type: current?.desiredDiscountRule?.type,
									discountRuleId: current?.desiredDiscountRule?.id,
									index: range?.desiredRange?.index,
									requiredDiscountRuleList: [
										{
											id: current?.requiredDiscountRule?.id,
											specialDiscountId,
											type: current?.requiredDiscountRule?.type,
											unitMetric,
											name: current?.requiredDiscountRule?.name,
											rangeList: [range?.requiredRange?.id],
											isSwitch,
											isLocked,
											lockedExtraAddon,
										},
									],
								});
							} else {
								const currentRequiredDiscountRule =
									currentRange.requiredDiscountRuleList?.find(
										value => value?.id === current?.requiredDiscountRule?.id
									);
								if (!currentRequiredDiscountRule) {
									currentRange?.requiredDiscountRuleList.push({
										id: current?.requiredDiscountRule?.id,
										specialDiscountId,
										type: current?.requiredDiscountRule?.type,
										unitMetric,
										name: current?.requiredDiscountRule?.name,
										rangeList: [range?.requiredRange?.id],
										isSwitch,
										isLocked,
										lockedExtraAddon,
									});
								} else {
									currentRequiredDiscountRule?.rangeList.push(
										range?.requiredRange?.id
									);
								}
							}
						}
					});
					return prev;
				}, []);

			const paymentTermsRangesDisable = getRangesDisable(paymentTermsDependencies);
			set(
				paymentTermsDependenciesListByPriceModelIdSelector(priceModelId),
				paymentTermsRangesDisable
			);

			const engagementRangesDisable = getRangesDisable(engagementDependencies);
			set(
				engagementDependenciesListByPriceModelIdSelector(priceModelId),
				engagementRangesDisable
			);

			const specialRangesDisable = getRangesDisable(specialDiscountDependencies);
			set(
				specialDiscountDependenciesListByPriceModelIdSelector(priceModelId),
				specialRangesDisable
			);

			const volumeRangesDisable = getRangesDisable(volumeDependencies);
			set(volumeDependenciesListByPriceModelIdSelector(priceModelId), volumeRangesDisable);
		},
});

export const specialDiscountDropdownShowWarningByPriceModelIdSelector = selectorFamily({
	key: "specialDiscountDropdownShowWarningByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(specialDiscountDropdownShowWarningAtom)[priceModelId],
	set:
		priceModelId =>
		({ set, get }, disableRange) => {
			const allRanges = get(specialDiscountDropdownShowWarningAtom)[priceModelId];
			if (disableRange) {
				if (!allRanges) {
					set(specialDiscountDropdownShowWarningAtom, {
						...get(specialDiscountDropdownShowWarningAtom),
						[priceModelId]: [disableRange],
					});
				} else {
					set(specialDiscountDropdownShowWarningAtom, {
						...get(specialDiscountDropdownShowWarningAtom),
						[priceModelId]: [...allRanges, disableRange],
					});
				}
			} else {
				set(specialDiscountDropdownShowWarningAtom, {
					...get(specialDiscountDropdownShowWarningAtom),
					[priceModelId]: [],
				});
			}
		},
});

export const paymentTermShowWarningSelector = selectorFamily({
	key: "paymentTermShowWarningSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(paymentTermShowWarningAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(paymentTermShowWarningAtom, {
				...get(paymentTermShowWarningAtom),
				[priceModelId]: newValue,
			});
		},
});

export const engagementShowWarningSelector = selectorFamily({
	key: "engagementShowWarningSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(engagementShowWarningAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(engagementShowWarningAtom, {
				...get(engagementShowWarningAtom),
				[priceModelId]: newValue,
			});
		},
});

export const specialDiscountShowWarningSelector = selectorFamily({
	key: "specialDiscountShowWarningSelector",
	get:
		({ priceModelId, discountRuleId }) =>
		({ get }) => {
			const specialDiscountWarningRanges = get(specialDiscountShowWarningAtom)[priceModelId];
			const range = specialDiscountWarningRanges?.find(
				warningRange => warningRange.discountRuleId === discountRuleId
			)?.range;
			return range;
		},
	set:
		({ priceModelId, discountRuleId }) =>
		({ get, set }, newValue) => {
			const allValues = get(specialDiscountShowWarningAtom)[priceModelId];
			if (newValue) {
				if (!allValues) {
					set(specialDiscountShowWarningAtom, {
						...get(specialDiscountShowWarningAtom),
						[priceModelId]: [{ range: newValue, discountRuleId }],
					});
				} else {
					set(specialDiscountShowWarningAtom, {
						...get(specialDiscountShowWarningAtom),
						[priceModelId]: [...allValues, { range: newValue, discountRuleId }],
					});
				}
			} else {
				set(specialDiscountShowWarningAtom, {
					...get(specialDiscountShowWarningAtom),
					[priceModelId]: [],
				});
			}
		},
});

export const extraAddonShowWarningSelector = selectorFamily({
	key: "extraAddonShowWarningSelector",
	get:
		({ priceModelId, packId, ruleId }) =>
		({ get }) => {
			const extraAddonWarningRanges = get(extraAddonShowWarningAtom)[priceModelId];
			const selectedPackId = get(selectedPackIdByPriceModelIdSelector(priceModelId));
			const range = extraAddonWarningRanges?.find(
				warningRange => selectedPackId === packId && warningRange.ruleId === ruleId
			)?.range;
			return range;
		},
	set:
		({ priceModelId, packId, ruleId }) =>
		({ get, set }, newValue) => {
			const allValues = get(extraAddonShowWarningAtom)[priceModelId];
			if (newValue) {
				if (!allValues) {
					set(extraAddonShowWarningAtom, {
						...get(extraAddonShowWarningAtom),
						[priceModelId]: [{ range: newValue, packId, ruleId }],
					});
				} else {
					set(extraAddonShowWarningAtom, {
						...get(extraAddonShowWarningAtom),
						[priceModelId]: [...allValues, { range: newValue, packId, ruleId }],
					});
				}
			} else {
				set(extraAddonShowWarningAtom, {
					...get(extraAddonShowWarningAtom),
					[priceModelId]: [],
				});
			}
		},
});

export const volumeShowWarningSelector = selectorFamily({
	key: "volumeShowWarningSelector",
	get:
		({ priceModelId, discountId }) =>
		({ get }) => {
			const volumeWarningRanges = get(volumeShowWarningAtom)[priceModelId];
			const range = volumeWarningRanges?.find(
				warningRange => warningRange.discountId === discountId
			)?.range;
			return range;
		},
	set:
		({ priceModelId, discountId }) =>
		({ get, set }, newValue) => {
			const allValues = get(volumeShowWarningAtom)[priceModelId];
			if (newValue) {
				if (!allValues) {
					set(volumeShowWarningAtom, {
						...get(volumeShowWarningAtom),
						[priceModelId]: [{ range: newValue, discountId }],
					});
				} else {
					set(volumeShowWarningAtom, {
						...get(volumeShowWarningAtom),
						[priceModelId]: [...allValues, { range: newValue, discountId }],
					});
				}
			} else {
				set(volumeShowWarningAtom, {
					...get(volumeShowWarningAtom),
					[priceModelId]: [],
				});
			}
		},
});

export const setPaymentTermWarningSelector = selectorFamily({
	key: "setPaymentTermWarningSelector",
	get: ({ get }) => 1,
	set:
		priceModelId =>
		({ get, set }, { disableRanges, paymentTermRange, discountRule, timeMetric }) => {
			const paymentTermsDiscountState = get(
				paymentTermsDiscountStateByPriceModelIdSelector(priceModelId)
			);

			disableRanges?.forEach(range => {
				if (range?.id === paymentTermsDiscountState) {
					const newPaymentTermState = paymentTermRange?.find(
						paymentRange =>
							!disableRanges?.find(disRange => paymentRange.id === disRange.id)
					)?.id;
					if (newPaymentTermState) {
						set(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId), {
							newValue: newPaymentTermState,
							discountRule,
							timeMetric,
						});
					} else {
						set(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId), {
							newValue: newPaymentTermState,
							discountRule,
							timeMetric,
						});
					}
					set(paymentTermShowWarningSelector(priceModelId), range);
				}
			});
		},
});

export const setEngagementWarningSelector = selectorFamily({
	key: "setEngagementWarningSelector",
	get: ({ get }) => 1,
	set:
		priceModelId =>
		({ get, set }, { disableRanges, engagementRange, discountRule, timeMetric }) => {
			const commitmentDiscountState = get(
				commitmentDiscountStateByPriceModelIdSelector(priceModelId)
			);

			disableRanges?.forEach(range => {
				if (range?.id === commitmentDiscountState) {
					const newCommitmentState = engagementRange?.find(
						commitmentRange =>
							!disableRanges?.find(disRange => commitmentRange.id === disRange.id)
					)?.id;
					if (newCommitmentState) {
						set(commitmentDiscountStateByPriceModelIdSelector(priceModelId), {
							newValue: newCommitmentState,
							discountRule,
							timeMetric,
						});
					} else {
						set(commitmentDiscountStateByPriceModelIdSelector(priceModelId), {
							newValue: null,
							discountRule,
							timeMetric,
						});
					}
					set(engagementShowWarningSelector(priceModelId), range);
				}
			});
		},
});

export const setNumericalSpecialDiscountWarningSelector = selectorFamily({
	key: "setNumericalSpecialDiscountWarningSelector",
	get: ({ get }) => 1,
	set:
		({ priceModelId, uuid, discountRuleId }) =>
		({ get, set }, { disableRanges, isDropdown, numericalAvailableRanges }) => {
			const numericalDiscountState = get(
				numericalDiscountStateByPriceModelIdAndUnitMetricSelector({
					priceModelId,
					uuid,
				})
			);

			disableRanges?.forEach(range => {
				if (range?.id === numericalDiscountState.value) {
					const newNumericalDiscountState = numericalAvailableRanges?.find(
						numericalDiscountRange =>
							!disableRanges?.find(
								disRange => numericalDiscountRange.id === disRange.id
							)
					)?.id;
					if (newNumericalDiscountState) {
						set(
							numericalDiscountStateByPriceModelIdAndUnitMetricSelector({
								priceModelId,
								uuid,
							}),
							{ volumeRangeId: newNumericalDiscountState }
						);
						if (isDropdown) {
							set(
								specialDiscountDropdownShowWarningByPriceModelIdSelector(
									priceModelId
								),
								range
							);
						} else {
							set(
								specialDiscountShowWarningSelector({
									priceModelId,
									discountRuleId,
								}),
								range
							);
						}
					}
				}
			});
		},
});

export const setSpecialDiscountSelectWarningSelector = selectorFamily({
	key: "setSpecialDiscountSelectWarningSelector",
	get: ({ get }) => 1,
	set:
		({ priceModelId, discountId, discountRuleId }) =>
		({ get, set }, { disableRanges, specialRanges, isDropdown }) => {
			const selectedDiscount = get(
				specialDiscountStateByPriceModelIdAndDiscountIdSelector({
					priceModelId,
					discountId,
				})
			);

			disableRanges?.forEach(range => {
				if (range?.id === selectedDiscount) {
					const newSpecialDiscountState = specialRanges?.find(
						specRange => !disableRanges?.find(disRange => specRange.id === disRange.id)
					)?.id;
					if (newSpecialDiscountState) {
						set(
							specialDiscountStateByPriceModelIdAndDiscountIdSelector({
								priceModelId,
								discountId,
							}),
							newSpecialDiscountState
						);
						if (isDropdown) {
							set(
								specialDiscountDropdownShowWarningByPriceModelIdSelector(
									priceModelId
								),
								range
							);
						} else {
							set(
								specialDiscountShowWarningSelector({
									priceModelId,
									discountRuleId,
								}),
								range
							);
						}
					} else {
						set(
							specialDiscountStateByPriceModelIdAndDiscountIdSelector({
								priceModelId,
								discountId,
								discountRuleId,
							}),
							null
						);
					}
				}
			});
		},
});

export const setSpecialDiscountSwitchWarningSelector = selectorFamily({
	key: "setSpecialDiscountSwitchWarningSelector",
	get: ({ get }) => 1,
	set:
		({ priceModelId, discountId, discountRuleId }) =>
		({ get, set }, { disableRange, isDropdown }) => {
			const selectedDiscount = get(
				specialDiscountStateByPriceModelIdAndDiscountIdSelector({
					priceModelId,
					discountId,
				})
			);
			disableRange?.forEach(range => {
				if (range?.id === selectedDiscount) {
					set(
						specialDiscountStateByPriceModelIdAndDiscountIdSelector({
							priceModelId,
							discountId,
						}),
						null
					);
					if (isDropdown) {
						set(
							specialDiscountDropdownShowWarningByPriceModelIdSelector(priceModelId),
							range
						);
					} else {
						set(
							specialDiscountShowWarningSelector({
								priceModelId,
								discountRuleId,
							}),
							range
						);
					}
				}
			});
		},
});

export const setExtraAddonSelectWarningSelector = selectorFamily({
	key: "setExtraAddonSelectWarningSelector",
	get: ({ get }) => 1,
	set:
		({ priceModelId, discountId, packId, ruleId, isAddon }) =>
		({ get, set }, { disableRanges, specialRanges }) => {
			const selectedAdditionalValues = get(
				additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
					priceModelId,
					discountId,
					packId,
					ruleId,
					isAddon,
				})
			);
			const selectedRangeId = selectedAdditionalValues?.rangeId || null;

			disableRanges?.forEach(range => {
				if (range?.id === selectedRangeId) {
					const newSpecialDiscountState = specialRanges?.find(
						specRange => !disableRanges?.find(disRange => specRange.id === disRange.id)
					)?.id;
					if (newSpecialDiscountState) {
						set(
							additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
								priceModelId,
								discountId,
								packId,
								ruleId,
								isAddon,
							}),
							newSpecialDiscountState
						);
					} else {
						set(
							additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
								priceModelId,
								discountId,
								packId,
								ruleId,
								isAddon,
							}),
							null
						);
					}
					set(extraAddonShowWarningSelector({ priceModelId, packId, ruleId }), range);
				}
			});
		},
});

export const setExtraAddonSwitchWarningSelector = selectorFamily({
	key: "setExtraAddonSwitchWarningSelector",
	get: ({ get }) => 1,
	set:
		({ priceModelId, discountId, packId, ruleId, isAddon }) =>
		({ get, set }, disableRange) => {
			const selectedAdditionalValues = get(
				additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
					priceModelId,
					discountId,
					packId,
					ruleId,
					isAddon,
				})
			);
			const selectedRangeId = selectedAdditionalValues?.rangeId || null;

			disableRange?.forEach(range => {
				if (range?.id === selectedRangeId) {
					set(
						additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
							priceModelId,
							discountId,
							packId,
							ruleId,
							isAddon,
						}),
						null
					);
					set(extraAddonShowWarningSelector({ priceModelId, packId, ruleId }), range);
				}
			});
		},
});
