import { gql } from "@apollo/client";

export const FILTER_FRAGMENT = gql`
	fragment FilterFragment on OldFilter {
		id
		type
		operator
		selected
		beforeDate
		afterDate
		foreignKey
	}
`;

export const COLUMN_NAME_AND_FILTER_FRAGMENT = gql`
	${FILTER_FRAGMENT}
	fragment ColumnNameAndFilterFragment on OldColumn {
		id
		name
		filter {
			...FilterFragment
		}
	}
`;

export const COLUMN_FILTER_FRAGMENT = gql`
	${FILTER_FRAGMENT}
	fragment ColumnFilterFragment on OldColumn {
		id
		filter {
			...FilterFragment
		}
	}
`;
