import React, { memo } from "react";
import * as S from "./IncludedMention.style";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { Checkbox, Tooltipv3 } from "priceit-ui";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import { updateQuoteConfigurationShowIncludedMentionMutation } from "@graphQl/mutations/quoteConfiguration";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/QuoteLayout.style";
import { useRecoilValue } from "recoil";
import { priceModelPageRefElAtom } from "@src/recoil/priceModel/atom";

const IncludedMention = memo(({ offerId, priceModelCombinationId }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode } = useThemeContext();
	const priceModelPageEl = useRecoilValue(priceModelPageRefElAtom);

	const { quoteConfigurationId, showIncludedMention } = useQuoteConfigurationData({
		offerId,
		priceModelCombinationId,
	});

	const [updateQuoteConfigurationShowIncludedMention] =
		updateQuoteConfigurationShowIncludedMentionMutation();

	return (
		<S.Wrapper>
			<StyledCheckboxGroup
				checkIcon
				focusColor="#FFCD38"
				boxLabelDistance="0px"
				interBoxDistance="8px"
				style={{ marginLeft: "-7.5px" }}
				selected={showIncludedMention ? ["showIncludedMention"] : []}
				onChange={() => {
					updateQuoteConfigurationShowIncludedMention({
						variables: {
							updateQuoteConfiguration: {
								id: quoteConfigurationId,
								showIncludedMention: !showIncludedMention,
							},
						},
						optimisticResponse: {
							updateQuoteConfiguration: {
								__typename: "QuoteConfiguration",
								id: quoteConfigurationId,
								showIncludedMention: !showIncludedMention,
							},
						},
						update(cache) {
							priceModelCombinationId !== null &&
								updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
						},
					});
				}}
			>
				<Tooltipv3
					key={"includedMention"}
					placement="top"
					mode={mode}
					type="primary"
					maxWidth="350px"
					content={t("The term will be displayed if the attribute is free")}
					interactive={false}
					appendTo={priceModelPageEl || document.body}
				>
					<Checkbox.Button
						mode={mode}
						value={"showIncludedMention"}
						width="15px"
						height="15px"
					>
						<StyledTextCheckbox>{t('Displays the term "Included"')}</StyledTextCheckbox>
					</Checkbox.Button>
				</Tooltipv3>
			</StyledCheckboxGroup>
		</S.Wrapper>
	);
});

IncludedMention.displayName = "IncludedMention";
export default IncludedMention;
