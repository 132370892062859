import { computePriceHT } from "@services/computation";
import { useTranslation, Trans } from "react-i18next";
import { Icon } from "priceit-ui";
import React from "react";
import { PercentChartSubTitle, PercentChartTitle } from "../ProductsDrawer.style";
import { ProgressBar } from "@components/progressBar/ProgressBar";

export const searchProducts = (searchInput, products) => {
	let newProducts = products ? [...products] : [];
	if (searchInput !== "") {
		newProducts = newProducts?.filter(product => {
			const lowerCaseName = [product?.name?.toLowerCase()];
			product?.categories?.map(category => {
				lowerCaseName.push(category?.name?.toLowerCase());
			});
			let include = false;
			lowerCaseName?.map(lowerCase => {
				if (lowerCase?.includes(searchInput.toLowerCase())) {
					include = true;
				}
			});
			return include;
		});
	}
	return newProducts;
};

export const computeUnitNetPrice = product => {
	const price = computePriceHT(product);
	return price * (1 - product.discount / 100);
};

export const computeRevenue = product => {
	const price = computeUnitNetPrice(product);
	return price * product.volume;
};

export const computeGrossProfit = product => {
	const semiVariableCost = product?.semiVariableCostParts?.reduce((accumulator, currentValue) => {
		return accumulator + currentValue.value;
	}, 0);
	const price = computeUnitNetPrice(product);

	return price - product.variableCosts - semiVariableCost;
};

export const computeUnitFixedCosts = (product, period) => {
	const price = computeUnitNetPrice(product);
	return (
		(price / period?.profitParameter?.revenue?.realRevenue) *
		period?.profitParameter?.totalFixedCosts?.realTotalFixedCosts
	);
};

export const computeUnitNetProfit = (product, period) => {
	const price = computeGrossProfit(product);
	const fixedCost = computeUnitFixedCosts(product, period);
	return price - fixedCost;
};

export const percentChartsRender = ({ t, data, period, coeff }) => {
	if (period.profitParameter) {
		const obj = {
			revenue: {
				color: "#FFCD38",
				maxVal: period.profitParameter.revenue.realRevenue,
				val: data.revenue,
				title: t("revenue"),
			},
			variablesCosts: {
				color: "#A082D2",
				maxVal:
					period.profitParameter.variableCost.variableCosts +
					period.profitParameter.totalFixedCosts.totalSemiVariableCostPart,
				val:
					data.totalVariableCostOneShot +
					data.totalVariableCostRecurring +
					data.totalSemiVariableCostOneShot +
					data.totalSemiVariableCostRecurring,
				title: t("total variable costs"),
			},
			grossProfit: {
				color: "#A5B9C8",
				maxVal:
					period.profitParameter.revenue.realRevenue -
					(period.profitParameter.variableCost.variableCosts +
						period.profitParameter.totalFixedCosts.totalSemiVariableCostPart),
				val:
					data.revenue -
					(data.totalVariableCostOneShot +
						data.totalVariableCostRecurring +
						data.totalSemiVariableCostOneShot +
						data.totalSemiVariableCostRecurring),
				title: {
					profit: t("gross profit"),
					loss: t("gross loss"),
				},
			},
			fixedCosts: {
				color: "#85E6D6",
				maxVal: period.profitParameter.totalFixedCosts.realTotalFixedCosts,
				val: data.totalFixedCost,
				title: t("fixed costs"),
			},
			netProfit: {
				color: "#78CE5E",
				maxVal:
					period.profitParameter.revenue.realRevenue -
					(period.profitParameter.variableCost.variableCosts +
						period.profitParameter.totalFixedCosts.totalSemiVariableCostPart) -
					period.profitParameter.totalFixedCosts.realTotalFixedCosts,
				val:
					data.revenue -
					(data.totalVariableCostOneShot +
						data.totalVariableCostRecurring +
						data.totalSemiVariableCostOneShot +
						data.totalSemiVariableCostRecurring) -
					data.totalFixedCost,
				title: {
					profit: t("net profit"),
					loss: t("net loss"),
				},
			},
		};
		return Object.entries(obj).map(([key, val]) => {
			if (val.maxVal) {
				let percent = (val.val / val.maxVal) * 100;
				percent = [NaN, undefined].includes(percent) ? 0 : percent;
				let chartData = {
					value: percent,
					color: val.color,
					textColor: val.color,
				};
				let title = val.title;
				let negative = false;
				if (key === "grossProfit" || key === "netProfit") {
					negative = true;
					if (val.maxVal >= 0) {
						title = title.profit;
						if (val.val < 0) {
							chartData.color = "#ff5a5a";
						}
					} else {
						title = title.loss;
						if (val.val < 0) {
							chartData.color = "#ff5a5a";
							chartData.textColor = "#ff5a5a";
						} else {
							chartData.textColor = "#ff5a5a";
						}
					}
				}

				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							padding: "0 10px 0 0",
						}}
						key={"initial-situation-donut-chart-" + key}
					>
						<ProgressBar
							percent={chartData.value}
							fillColor={chartData.color}
							labelValue={val.val * coeff}
							barWidth={"120px"}
							negative={negative}
							hidePercentInsideBar
						/>
						<PercentChartTitle>
							<Icon
								type="upTriangleSharp"
								fill={chartData.textColor}
								style={{ transform: "rotate(90deg) scaleX(1.5)" }}
								width={"8px"}
							/>
							&nbsp;
							<Trans
								t={t}
								i18nKey="% of the modeled"
								defaults="<PercentChartSubTitle> {{pourcent}} </PercentChartSubTitle/>% of the modeled <PercentChartSubTitle> {{title}} </PercentChartSubTitle>"
								values={{
									pourcent: chartData.value.fnb({
										stringText: true,
										decimal: Math.abs(percent) < 1 ? 1 : 0,
									}),
									title: title,
								}}
								components={{
									PercentChartSubTitle: (
										<PercentChartSubTitle color={chartData.textColor} />
									),
								}}
							>
								% of the modeled
							</Trans>
						</PercentChartTitle>
					</div>
				);
			}
		});
	}
	return null;
};
