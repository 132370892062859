import styled, { css } from "styled-components";
import { Icon, Input } from "priceit-ui";

export const Wrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	${props =>
		props.justify &&
		css`
			justify-content: space-between;
		`};
`;

export const WrapperBackButtonAndText = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Title = styled.div`
	color: ${({ theme }) => theme.textColor.primary};
	font-size: 22px;
	line-height: 20px;
	margin: 2px 0 0 0;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	& > button {
		margin: 0 10px;
	}
`;

export const LoadingIcon = styled(Icon)`
	width: 20px;
	height: 20px;
	fill: ${({ theme }) => theme.page.textColor};
`;

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoadingLabel = styled.div`
	color: ${({ theme }) => theme.page.textColor};
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 0 10px;
`;

export const TooltipWrapper = styled.div`
	display: flex;
	height: 60px;
	justify-content: center;
	align-items: center;
	& > * {
		margin: 0 5px;
	}
`;

export const InputLinkOffer = styled(Input)`
	width: 300px;
	height: 40px;
`;

export const WrapperButtons = styled.div`
	display: flex;
	& > * {
		margin-right: 10px;
	}
`;
export const ButtonText = styled.div`
	white-space: pre-wrap;
	line-height: 15px;
`;
