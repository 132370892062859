import { useWorkspace } from "@hooks/useWorkspace/UseWorkspace";
import { useRecoilValue } from "recoil";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import { useQuery } from "@apollo/client";
import { GET_OFFER_DATA_FOR_SUBJECT_DEFAULT_VALUE } from "@graphQl/queries/offer";
import { useTranslation } from "react-i18next";

export const useDefaultMailSubject = ({ isSignatureLink = false }) => {
	const { t } = useTranslation("clientsOffers/modalSendEmail");
	const { workspace } = useWorkspace();

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const { data: dataOffer } = useQuery(GET_OFFER_DATA_FOR_SUBJECT_DEFAULT_VALUE, {
		variables: { id: offerId },
		skip: !offerId,
	});
	const offerName = dataOffer?.getOfferById?.name || null;
	const workspaceName = workspace?.name || null;
	const companyName = dataOffer?.getOfferById?.companyName || null;

	const defaultSubject = `${workspaceName} x ${companyName} - ${offerName}`;
	const signatureLinkSubject = `${defaultSubject} - ${t("Signature link")}`;

	return isSignatureLink ? signatureLinkSubject : defaultSubject;
};
