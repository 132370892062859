import { periodMultiplier, periodTypesEnum } from "./periodMultiplier";
import moment from "moment";
import { computeVariableCostPart } from "@pages/myBusinessModel/fixedCosts/service/FixedCosts.service";

const coefficient = (current, monthDifference) =>
	periodMultiplier(current.timeMetric) !== 0
		? monthDifference / periodMultiplier(current.timeMetric)
		: 1;

export const computePriceHT = current =>
	current.priceIsKnowingIn === "TVAC" ? current.price / (1 + current.tva / 100.0) : current.price;

export const productsComputation = (products, monthDifference, ht = true) => {
	const totalVolume = products.reduce((prev, current) => {
		return prev + current.volume;
	}, 0);

	const totalVolumeOneShot = products
		.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => prev + current.volume, 0);

	const totalVolumeRecurring = products
		.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => prev + current.volume, 0);

	const totalRevenue = products.reduce((prev, current) => {
		const coeffRevenue = coefficient(current, monthDifference);
		const price = ht
			? computePriceHT(current)
			: isNaN(current.priceInclVAT)
			? 0
			: current.priceInclVAT;
		return prev + current.volume * price * (1 - current.discount / 100.0) * coeffRevenue;
	}, 0);

	const totalRevenueRecurring = products
		.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const coeffRevenue = periodMultiplier("month") / periodMultiplier(current.timeMetric);
			const price = ht
				? computePriceHT(current)
				: isNaN(current.priceInclVAT)
				? 0
				: current.priceInclVAT;
			return prev + current.volume * price * (1 - current.discount / 100.0) * coeffRevenue;
		}, 0);

	const totalRevenueOneShot = products
		.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const price = ht
				? computePriceHT(current)
				: isNaN(current.priceInclVAT)
				? 0
				: current.priceInclVAT;
			return prev + current.volume * price * (1 - current.discount / 100.0);
		}, 0);

	const totalGrossRevenue = products.reduce((prev, current) => {
		const coeffTotalGrossRevenue = coefficient(current, monthDifference);
		const price = ht
			? computePriceHT(current)
			: isNaN(current.priceInclVAT)
			? 0
			: current.priceInclVAT;
		return prev + current.volume * price * coeffTotalGrossRevenue;
	}, 0);

	const totalDiscount = products.reduce((prev, current) => {
		const coeffDiscount = coefficient(current, monthDifference);
		const price = ht
			? computePriceHT(current)
			: isNaN(current.priceInclVAT)
			? 0
			: current.priceInclVAT;
		return prev + current.volume * price * (current.discount / 100.0) * coeffDiscount;
	}, 0);

	const meanVariableCost =
		products.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			return prev + current.variableCosts * current.volume * coeffVariableCost;
		}, 0) / totalVolume;

	const meanSemiVariableCost =
		products.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			const semiVariableCost = current?.semiVariableCostParts?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.value,
				0
			);
			return prev + semiVariableCost * current.volume * coeffVariableCost;
		}, 0) / totalVolume;

	const totalVariableCostOneShot = products
		.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			return prev + current.variableCosts * current.volume * coeffVariableCost;
		}, 0);

	const totalSemiVariableCostOneShot = products
		.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			const semiVariableCost = current?.semiVariableCostParts?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.value,
				0
			);
			return prev + semiVariableCost * current.volume * coeffVariableCost;
		}, 0);

	const meanVariableCostOneShot = totalVolumeOneShot
		? totalVariableCostOneShot / totalVolumeOneShot
		: 0;

	const meanSemiVariableCostOneShot = totalVolumeOneShot
		? totalSemiVariableCostOneShot / totalVolumeOneShot
		: 0;

	const totalVariableCostRecurring = products
		.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			return prev + current.variableCosts * current.volume * coeffVariableCost;
		}, 0);

	const totalSemiVariableCostRecurring = products
		.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
		.reduce((prev, current) => {
			const coeffVariableCost = coefficient(current, monthDifference);
			const semiVariableCost = current?.semiVariableCostParts?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.value,
				0
			);
			return prev + semiVariableCost * current.volume * coeffVariableCost;
		}, 0);
	const meanVariableCostRecurring = totalVolumeRecurring
		? totalVariableCostRecurring / totalVolumeRecurring
		: 0;

	const meanSemiVariableCostRecurring = totalVolumeRecurring
		? totalSemiVariableCostRecurring / totalVolumeRecurring
		: 0;

	const meanPrice =
		products.reduce((prev, current) => {
			const coeffPrice = coefficient(current, monthDifference);
			const price = ht
				? computePriceHT(current)
				: isNaN(current.priceInclVAT)
				? 0
				: current.priceInclVAT;
			return prev + price * (1 - current.discount / 100.0) * current.volume * coeffPrice;
		}, 0) / totalVolume;

	const meanPriceOneShot =
		products
			.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return prev + price * (1 - current.discount / 100.0) * current.volume;
			}, 0) / totalVolumeOneShot;

	const meanListPriceOneShot =
		products
			.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return prev + price * current.volume;
			}, 0) / totalVolumeOneShot;

	const meanPriceRecurring =
		products
			.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const coeffPrice = periodMultiplier("month") / periodMultiplier(current.timeMetric);
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return prev + price * (1 - current.discount / 100.0) * current.volume * coeffPrice;
			}, 0) / totalVolumeRecurring;

	const meanListPriceRecurring =
		products
			.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const coeffPrice = periodMultiplier("month") / periodMultiplier(current.timeMetric);
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return prev + price * current.volume * coeffPrice;
			}, 0) / totalVolumeRecurring;

	const discount =
		products.reduce((prev, current) => {
			const coeffDiscount = coefficient(current, monthDifference);
			const price = ht
				? computePriceHT(current)
				: isNaN(current.priceInclVAT)
				? 0
				: current.priceInclVAT;
			return (
				prev + ((price * (current.discount ?? 0)) / 100.0) * current.volume * coeffDiscount
			);
		}, 0) / totalVolume;

	const discountOneShot =
		products
			.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return prev + ((price * (current.discount ?? 0)) / 100.0) * current.volume;
			}, 0) / totalVolume;

	const discountRecurring =
		products
			.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
			.reduce((prev, current) => {
				const coeffDiscount =
					periodMultiplier("month") / periodMultiplier(current.timeMetric);
				const price = ht
					? computePriceHT(current)
					: isNaN(current.priceInclVAT)
					? 0
					: current.priceInclVAT;
				return (
					prev +
					((price * (current.discount ?? 0)) / 100.0) * current.volume * coeffDiscount
				);
			}, 0) / totalVolume;

	const price =
		products.reduce((prev, current) => {
			const coeffPrice = coefficient(current, monthDifference);
			const price = ht
				? computePriceHT(current)
				: isNaN(current.priceInclVAT)
				? 0
				: current.priceInclVAT;
			return prev + price * current.volume * coeffPrice;
		}, 0) / totalVolume;

	const weightedTva =
		products.reduce((prev, current) => {
			return prev + current.tva * current.volume;
		}, 0) / totalVolume;

	return {
		volume: totalVolume || 0,
		totalVolumeOneShot: totalVolumeOneShot || 0,
		totalVolumeRecurring: totalVolumeRecurring || 0,
		meanPriceRecurring: meanPriceRecurring || 0,
		meanPriceOneShot: meanPriceOneShot || 0,
		revenue: totalRevenue || 0,
		totalRevenueOneShot: totalRevenueOneShot || 0,
		totalRevenueRecurring: totalRevenueRecurring || 0,
		grossRevenue: totalGrossRevenue || 0,
		totalDiscount: totalDiscount || 0,
		meanVariableCost: meanVariableCost || 0,
		meanSemiVariableCost: meanSemiVariableCost || 0,
		meanVariableCostOneShot: meanVariableCostOneShot || 0,
		meanSemiVariableCostOneShot: meanSemiVariableCostOneShot || 0,
		meanVariableCostRecurring: meanVariableCostRecurring || 0,
		meanSemiVariableCostRecurring: meanSemiVariableCostRecurring || 0,
		totalVariableCostOneShot: totalVariableCostOneShot || 0,
		totalSemiVariableCostOneShot: totalSemiVariableCostOneShot || 0,
		totalVariableCostRecurring: totalVariableCostRecurring || 0,
		totalSemiVariableCostRecurring: totalSemiVariableCostRecurring || 0,
		meanPrice: meanPrice || 0,
		meanListPrice: price || 0,
		meanDiscountPercent: (100 * (price - meanPrice)) / price || 0,
		meanDiscount: discount || 0,
		meanDiscountOneShot: discountOneShot || 0,
		meanDiscountRecurring: discountRecurring || 0,
		meanWeightedTva: weightedTva || 0,
		meanListPriceOneShot: meanListPriceOneShot || 0,
		meanListPriceRecurring: meanListPriceRecurring || 0,
	};
};

export const fixedCostComputation = (fixedCosts, monthDifference) => {
	const totalFixedCost = fixedCosts.reduce((prev, current) => {
		const coeffFixedCost = monthDifference / periodMultiplier(current.timePeriod);
		const varCost = computeVariableCostPart(
			current?.variableCostPart,
			current?.variableCostPartType,
			current?.cost * coeffFixedCost
		).amount;
		return prev + (current.cost * coeffFixedCost - varCost);
	}, 0);
	const semiVariableCost = fixedCosts.reduce((prev, current) => {
		const coeffFixedCost = monthDifference / periodMultiplier(current.timePeriod);
		const varCost = computeVariableCostPart(
			current?.variableCostPart,
			current?.variableCostPartType,
			current?.cost * coeffFixedCost
		).amount;
		return prev + varCost;
	}, 0);

	return {
		fixedCost: totalFixedCost,
		semiVariableCost: semiVariableCost,
	};
};

export const variableCostComputation = (listOfProducts, monthDifference) => {
	const totalVarCost = listOfProducts.reduce((prev, current) => {
		const coeffVarCost = coefficient(current, monthDifference);
		return prev + current.variableCosts * current.volume * coeffVarCost;
	}, 0);

	return {
		totalVariableCosts: totalVarCost,
	};
};

export const averageFixedCostComputation = (
	products,
	monthDifference,
	totalRevenue,
	totalFixedCosts
) => {
	const meanFixedCosts = products.reduce((prev, current) => {
		const coeffProduct =
			periodMultiplier(current.timeMetric) !== 0
				? monthDifference / periodMultiplier(current.timeMetric)
				: 1;
		const priceHT = computePriceHT(current);
		return (
			prev +
			((priceHT * (1 - current.discount / 100.0) * current.volume * coeffProduct) /
				totalRevenue) *
				totalFixedCosts
		);
	}, 0);

	return {
		meanFixedCosts: meanFixedCosts,
	};
};

export const percentModeledRevenue = (period, products) => {
	const endDate = moment(period?.endDate);
	const startDate = moment(period?.startDate);
	const monthDifference = endDate.diff(startDate, "months") + 1;

	const averageData = productsComputation(products || [], monthDifference);
	const currentRevenue = averageData.revenue;
	const maxRevenue = period?.profitParameter.revenue.realRevenue;

	let percent = (Math.abs(currentRevenue) / maxRevenue) * 100;
	percent = isNaN(percent) ? 0 : percent;
	return percent;
};

export const getPriceVirtualUnit = ({
	products,
	monthDifference,
	periodVisualized = 1,
	ht = true,
	totalRevenue = 1,
	totalFixedCosts = 1,
}) => {
	const priceMetricOneShotList = [];
	const priceMetricRecurringList = [];
	let timeMetric = null;

	const virtualVariableCostUnitOneShot = {};
	const virtualVariableCostUnitRecurring = {};

	const virtualPriceUnitOneShot = { listPrice: {}, netPrice: {} };
	const virtualPriceUnitRecurring = { listPrice: {}, netPrice: {} };

	const virtualVolumeUnitOneShot = {};
	const virtualVolumeUnitRecurring = {};

	const virtualDiscountUnitOneShot = {};
	const virtualDiscountUnitRecurring = {};

	const virtualRelativeFixedCostUnitOneShot = {};
	const virtualRelativeFixedCostUnitRecurring = {};

	const virtualSemiVariableCostUnitOneShot = {};
	const virtualSemiVariableCostUnitRecurring = {};

	const virtualPureGrossProfitUnitOneShot = {};
	const virtualPureGrossProfitUnitRecurring = {};

	const virtualGrossProfitUnitOneShot = {};
	const virtualGrossProfitUnitRecurring = {};

	const virtualNetProfitUnitOneShot = {};
	const virtualNetProfitUnitRecurring = {};

	products.forEach(product => {
		if (product.timeMetric === periodTypesEnum.ONLY_ONCE) {
			if (priceMetricOneShotList.includes(product.volumeMetric)) {
				return;
			}
			priceMetricOneShotList.push(product.volumeMetric);

			const productFilter = products
				.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE)
				.filter(e => e.volumeMetric === product.volumeMetric);

			const totalVolume = productFilter.reduce((prev, current) => {
				const coeffVolume = periodVisualized / monthDifference;
				return prev + current.volume * coeffVolume;
			}, 0);

			const meanPrice = productFilter.reduce(
				(prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const coeffVolume = periodVisualized / monthDifference;
					return {
						listPrice: prev.listPrice + price * current.volume * coeffVolume,
						netPrice:
							prev.netPrice +
							price * (1 - current.discount / 100.0) * current.volume * coeffVolume,
					};
				},
				{ listPrice: 0, netPrice: 0 }
			);

			meanPrice.listPrice /= totalVolume;
			meanPrice.netPrice /= totalVolume;

			const discount =
				productFilter.reduce((prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const coeffVolume = periodVisualized / monthDifference;
					return (
						prev +
						((price * (current.discount ?? 0)) / 100.0) * current.volume * coeffVolume
					);
				}, 0) / totalVolume;

			const meanVariableCost =
				productFilter.reduce((prev, current) => {
					const coeffVolume = periodVisualized / monthDifference;
					return prev + current.variableCosts * current.volume * coeffVolume;
				}, 0) / totalVolume;

			const meanSemiVariableCost =
				productFilter.reduce((prev, current) => {
					const semiVariableCost = current?.semiVariableCostParts?.reduce(
						(accumulator, currentValue) => accumulator + currentValue.value,
						0
					);

					const coeffVolume = periodVisualized / monthDifference;
					return prev + semiVariableCost * current.volume * coeffVolume;
				}, 0) / totalVolume;

			const meanFixedCosts =
				productFilter.reduce((prev, current) => {
					const coeffVolume = periodVisualized / monthDifference;
					const priceHT = computePriceHT(current);
					return (
						prev +
						((priceHT * (1 - current.discount / 100.0) * current.volume * coeffVolume) /
							totalRevenue) *
							totalFixedCosts
					);
				}, 0) / totalVolume;

			const meanPureGrossProfit =
				productFilter.reduce((prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const coeffVolume = periodVisualized / monthDifference;
					return (
						prev +
						(price * (1 - current.discount / 100.0) - current.variableCosts) *
							current.volume *
							coeffVolume
					);
				}, 0) / totalVolume;

			const meanGrossProfit =
				productFilter.reduce((prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const semiVariableCost = current?.semiVariableCostParts?.reduce(
						(accumulator, currentValue) => accumulator + currentValue.value,
						0
					);
					const coeffVolume = periodVisualized / monthDifference;
					return (
						prev +
						(price * (1 - current.discount / 100.0) -
							current.variableCosts -
							semiVariableCost) *
							current.volume *
							coeffVolume
					);
				}, 0) / totalVolume;

			const meanNetProfit =
				productFilter.reduce((prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const semiVariableCost = current?.semiVariableCostParts?.reduce(
						(accumulator, currentValue) => accumulator + currentValue.value,
						0
					);
					const coeffVolume = periodVisualized / monthDifference;
					return (
						prev +
						(price * (1 - current.discount / 100.0) -
							current.variableCosts -
							semiVariableCost -
							meanFixedCosts) *
							current.volume *
							coeffVolume
					);
				}, 0) / totalVolume;

			virtualVolumeUnitOneShot[product.volumeMetric] = totalVolume || 0;
			virtualPriceUnitOneShot.listPrice[product.volumeMetric] = meanPrice.listPrice || 0;
			virtualPriceUnitOneShot.netPrice[product.volumeMetric] = meanPrice.netPrice || 0;
			virtualDiscountUnitOneShot[product.volumeMetric] = discount || 0;
			virtualVariableCostUnitOneShot[product.volumeMetric] = meanVariableCost || 0;
			virtualRelativeFixedCostUnitOneShot[product.volumeMetric] = meanFixedCosts || 0;
			virtualSemiVariableCostUnitOneShot[product.volumeMetric] = meanSemiVariableCost || 0;
			virtualPureGrossProfitUnitOneShot[product.volumeMetric] = meanPureGrossProfit || 0;
			virtualGrossProfitUnitOneShot[product.volumeMetric] = meanGrossProfit || 0;
			virtualNetProfitUnitOneShot[product.volumeMetric] = meanNetProfit || 0;
		} else {
			if (priceMetricRecurringList.includes(product.volumeMetric)) {
				return;
			}
			priceMetricRecurringList.push(product.volumeMetric);

			const productFilter = products
				.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE)
				.filter(e => e.volumeMetric === product.volumeMetric);

			const totalVolume = productFilter.reduce((prev, current) => {
				if (timeMetric === null) {
					timeMetric = current.timeMetric;
				} else if (timeMetric !== current.timeMetric) {
					timeMetric = "multipleTimeMetric";
				}
				return prev + current.volume;
			}, 0);

			const meanPrice = productFilter.reduce(
				(prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;
					const coeffPrice = periodVisualized / periodMultiplier(current.timeMetric);

					return {
						listPrice: prev.listPrice + price * coeffPrice * current.volume,
						netPrice:
							prev.netPrice +
							price * (1 - current.discount / 100.0) * current.volume * coeffPrice,
					};
				},
				{ listPrice: 0, netPrice: 0 }
			);

			meanPrice.listPrice /= totalVolume;
			meanPrice.netPrice /= totalVolume;

			const discount =
				productFilter.reduce((prev, current) => {
					const price = ht
						? computePriceHT(current)
						: isNaN(current.priceInclVAT)
						? 0
						: current.priceInclVAT;

					const coeffPrice = periodVisualized / periodMultiplier(current.timeMetric);
					return (
						prev +
						((price * (current.discount ?? 0)) / 100.0) * current.volume * coeffPrice
					);
				}, 0) / totalVolume;

			const meanVariableCost =
				productFilter.reduce((prev, current) => {
					const coeffPrice = periodVisualized / periodMultiplier(current.timeMetric);
					return prev + current.variableCosts * coeffPrice * current.volume;
				}, 0) / totalVolume;

			const meanSemiVariableCost =
				productFilter.reduce((prev, current) => {
					const semiVariableCost = current?.semiVariableCostParts?.reduce(
						(accumulator, currentValue) => accumulator + currentValue.value,
						0
					);

					const coeffPrice = periodVisualized / periodMultiplier(current.timeMetric);
					return prev + semiVariableCost * current.volume * coeffPrice;
				}, 0) / totalVolume;

			const meanFixedCosts =
				productFilter.reduce((prev, current) => {
					const coeffPrice = periodVisualized / periodMultiplier(current.timeMetric);
					const priceHT = computePriceHT(current);
					return (
						prev +
						((priceHT * (1 - current.discount / 100.0) * current.volume * coeffPrice) /
							totalRevenue) *
							totalFixedCosts
					);
				}, 0) / totalVolume;

			const meanPureGrossProfit = meanPrice.netPrice - meanVariableCost;
			const meanGrossProfit = meanPureGrossProfit - meanSemiVariableCost;
			const meanNetProfit = meanGrossProfit - meanFixedCosts;

			virtualVolumeUnitRecurring[product.volumeMetric] = totalVolume || 0;
			virtualPriceUnitRecurring.listPrice[product.volumeMetric] = meanPrice.listPrice || 0;
			virtualPriceUnitRecurring.netPrice[product.volumeMetric] = meanPrice.netPrice || 0;
			virtualDiscountUnitRecurring[product.volumeMetric] = discount || 0;
			virtualVariableCostUnitRecurring[product.volumeMetric] = meanVariableCost || 0;
			virtualPureGrossProfitUnitRecurring[product.volumeMetric] = meanPureGrossProfit || 0;
			virtualSemiVariableCostUnitRecurring[product.volumeMetric] = meanSemiVariableCost || 0;
			virtualGrossProfitUnitRecurring[product.volumeMetric] = meanGrossProfit || 0;
			virtualRelativeFixedCostUnitRecurring[product.volumeMetric] = meanFixedCosts || 0;
			virtualNetProfitUnitRecurring[product.volumeMetric] = meanNetProfit || 0;
		}
	});

	const virtualUnit = {
		virtualVariableCostUnitOneShot: virtualVariableCostUnitOneShot,
		virtualVariableCostUnitRecurring: virtualVariableCostUnitRecurring,

		virtualPriceUnitOneShot: virtualPriceUnitOneShot,
		virtualPriceUnitRecurring: virtualPriceUnitRecurring,

		virtualVolumeUnitOneShot: virtualVolumeUnitOneShot,
		virtualVolumeUnitRecurring: virtualVolumeUnitRecurring,

		virtualDiscountUnitOneShot: virtualDiscountUnitOneShot,
		virtualDiscountUnitRecurring: virtualDiscountUnitRecurring,

		virtualRelativeFixedCostUnitOneShot: virtualRelativeFixedCostUnitOneShot,
		virtualRelativeFixedCostUnitRecurring: virtualRelativeFixedCostUnitRecurring,

		virtualSemiVariableCostUnitOneShot: virtualSemiVariableCostUnitOneShot,
		virtualSemiVariableCostUnitRecurring: virtualSemiVariableCostUnitRecurring,

		virtualPureGrossProfitUnitOneShot: virtualPureGrossProfitUnitOneShot,
		virtualPureGrossProfitUnitRecurring: virtualPureGrossProfitUnitRecurring,

		virtualGrossProfitUnitOneShot: virtualGrossProfitUnitOneShot,
		virtualGrossProfitUnitRecurring: virtualGrossProfitUnitRecurring,

		virtualNetProfitUnitOneShot: virtualNetProfitUnitOneShot,
		virtualNetProfitUnitRecurring: virtualNetProfitUnitRecurring,

		timeMetric,
	};
	return virtualUnit;
};

export const getVirtualUnit = (products, monthDifference) => {
	const virtualUnitGlobal = {};
	const virtualUnitOneShot = {};
	const virtualUnitRecurring = {};
	const setVirtualUnit = (products, obj, recurring) => {
		products.map(product => {
			//FIXME: no value returned for the map?
			if (product.volumeMetric in obj) {
				recurring
					? (obj[product.volumeMetric] +=
							product.volume *
							(periodMultiplier("month") / periodMultiplier(product.timeMetric)))
					: (obj[product.volumeMetric] +=
							product.volume *
							(monthDifference /
								(periodMultiplier(product.timeMetric) || monthDifference)));
			} else {
				recurring
					? (obj[product.volumeMetric] =
							product.volume *
							(periodMultiplier("month") / periodMultiplier(product.timeMetric)))
					: (obj[product.volumeMetric] =
							product.volume *
							(monthDifference /
								(periodMultiplier(product.timeMetric) || monthDifference)));
			}
		});
	};
	setVirtualUnit(products, virtualUnitGlobal);
	setVirtualUnit(
		products.filter(e => e.timeMetric === periodTypesEnum.ONLY_ONCE),
		virtualUnitOneShot
	);
	setVirtualUnit(
		products.filter(e => e.timeMetric !== periodTypesEnum.ONLY_ONCE),
		virtualUnitRecurring,
		true
	);

	const virtualUnit = {
		virtualUnitGlobal: virtualUnitGlobal,
		virtualUnitOneShot: virtualUnitOneShot,
		virtualUnitRecurring: virtualUnitRecurring,
	};
	return virtualUnit;
};
