import { selector, selectorFamily } from "recoil";
import moment from "moment";
import { getUID } from "@services/text";

import {
	clientOffersGroupsStateAtom,
	clientOffersIsValidDateAtom,
	clientOfferVisibleEmailAtom,
	clientsOfferNewVersionIdAtom,
	clientsOffersBodyAtom,
	clientsOffersContactAtom,
	clientsOffersContactCcAtom,
	clientsOffersContactCciAtom,
	clientsOffersCreationStepAtom,
	clientsOffersIsCollapsedSectionAtom,
	clientsOffersIsNameEditingAtom,
	clientsOffersIsNewOfferAtom,
	clientsOffersIsNewOfferVersionAtom,
	clientsOffersIsRefreshTemplateAtom,
	clientsOffersNewPriceModelCombinationAtom,
	clientsOffersNextButtonDisabledAtom,
	clientsOffersSelectedIdAtom,
	clientsOffersSelectedTemplateAtom,
	clientsOffersShowCreationModalAtom,
	clientsOffersSubjectAtom,
	clientsOffersTemplateFiltersAtom,
	clientsOffersTemplateSearchValueAtom,
} from "@recoil/clientOffers/atom";
import { sectionName } from "@recoil/clientOffers/actions";

export const clientsOffersSelectedIdSelector = selector({
	key: "clientsOffersSelectedIdSelector",
	get: ({ get }) => get(clientsOffersSelectedIdAtom),
	set: ({ get, set }, newValue) => {
		set(clientsOffersSelectedIdAtom, newValue);
		set(clientsOffersIsNameEditingAtom, false);
	},
});

export const clientsOffersIsNewOfferVersionSelector = selector({
	key: "clientsOffersIsNewOfferVersionSelector",
	get: ({ get }) => get(clientsOffersIsNewOfferVersionAtom),
	set: ({ get, set }, newValue) => {
		set(clientsOffersIsNewOfferVersionAtom, newValue);
		set(clientsOffersShowCreationModalAtom, newValue);
	},
});

export const clientsOffersIsRefreshTemplateSelector = selector({
	key: "clientsOffersIsRefreshTemplateSelector",
	get: ({ get }) => get(clientsOffersIsRefreshTemplateAtom),
	set: ({ get, set }, newValue) => {
		set(clientsOffersIsRefreshTemplateAtom, newValue);
		set(clientsOffersShowCreationModalAtom, newValue);
	},
});

export const clientOffersIsValidDateSelector = selector({
	key: "clientOffersIsValidDateSelecter",
	get: ({ get }) => get(clientOffersIsValidDateAtom),
	set: ({ get, set }, newValue) => {
		const isValid = moment(newValue, "DD-MM-YYYY", true).isValid();
		set(clientOffersIsValidDateAtom, isValid);
	},
});

export const clientsOffersIsCollapsedSectionBySectionIdAndVersionISelector = selectorFamily({
	key: "clientsOffersIsCollapsedSectionBySectionIdAndVersionISelector",
	get:
		({ sectionId, offerId }) =>
		({ get }) => {
			switch (sectionId) {
				case sectionName.historicOffer:
				case sectionName.offerHistoric.signed:
				case sectionName.offerHistoric.consulted:
				case sectionName.offerHistoric.generated:
				case sectionName.offerHistoric.sent:
					return (
						get(clientsOffersIsCollapsedSectionAtom)?.[offerId]?.[sectionId] ?? false
					);
				default:
					return get(clientsOffersIsCollapsedSectionAtom)?.[offerId]?.[sectionId] ?? true;
			}
		},

	set:
		({ sectionId, offerId }) =>
		({ get, set }, newValue) => {
			const atom = get(clientsOffersIsCollapsedSectionAtom);

			if (sectionId === sectionName.allSection) {
				const newAtom = {};
				Object.keys(atom?.[offerId]).map(key => {
					newAtom[key] = newValue;
				});

				set(clientsOffersIsCollapsedSectionAtom, {
					...atom,
					[offerId]: newAtom,
				});
			} else {
				const newAtom = { ...atom?.[offerId] };
				newAtom[sectionId] = newValue;

				const { [sectionName.allSection]: removedElement, ...subSectionAtom } =
					newAtom || {};

				const areAllSame = new Set(Object.values(subSectionAtom)).size === 1;

				set(clientsOffersIsCollapsedSectionAtom, {
					...atom,
					[offerId]: {
						...atom?.[offerId],
						[sectionId]: newValue,
						[sectionName.allSection]: areAllSame
							? newValue
							: atom?.[offerId]?.[sectionName.allSection],
					},
				});
			}
		},
});

export const clientOffersGroupsStateByColumnIdSelector = selectorFamily({
	key: "clientOffersGroupsStateByColumnIdSelector",
	get:
		columnId =>
		({ get }) =>
			get(clientOffersGroupsStateAtom)[columnId],
	set:
		columnId =>
		({ get, set }, groupsState) =>
			set(clientOffersGroupsStateAtom, {
				...get(clientOffersGroupsStateAtom),
				[columnId]: groupsState,
			}),
});

export const fetchClientsOffersSelector = selector({
	key: "clientsOffersIsCollapsedSectionBySectionIdAndVersionISelector",
	get: ({ get }) => 1,
	set: ({ get, set }, offerVersion) => {
		const isCollapsedSections = {};
		offerVersion.forEach((version, index) => {
			isCollapsedSections[version.id] = {
				[sectionName.allSection]: !!index,
			};

			if (version.offerVersionQuotes?.length) {
				isCollapsedSections[version.id][sectionName.sectionPdf] = !!index;
			}
			if (version.offerVersionAccess?.length) {
				isCollapsedSections[version.id][sectionName.sectionOffer] = !!index;
			}
			if (index === 0) {
				isCollapsedSections[version.id][sectionName.parametersSection] = !!index;
			}
		});
		set(clientsOffersIsCollapsedSectionAtom, {
			...get(clientsOffersIsCollapsedSectionAtom),
			...isCollapsedSections,
		});
	},
});

//Creation Offer
export const clientOffersTemplateFiltersSelector = selector({
	key: "clientOffersTemplateFiltersSelector",
	get: ({ get }) => get(clientsOffersTemplateFiltersAtom),
	set: ({ get, set }, { key, newValue }) => {
		set(clientsOffersTemplateFiltersAtom, {
			...get(clientsOffersTemplateFiltersAtom),
			[key]: newValue,
		});
	},
});

export const clientOffersResetFiltersSelector = selector({
	key: "clientOffersResetFiltersSelector",
	get: ({ get }) => 1,
	set: ({ set }) => {
		set(clientsOffersTemplateFiltersAtom, {});
	},
});

export const clientsOfferNewVersionIdSelector = selector({
	key: "clientsOfferNewVersionIdSelector",
	get: ({ get }) => get(clientsOfferNewVersionIdAtom),
	set: ({ get, set }, newValue) => {
		if (newValue === null) {
			set(clientsOffersNewPriceModelCombinationAtom, null);
		}
		set(clientsOfferNewVersionIdAtom, newValue);
	},
});

export const clientsOffersResetSelectedOfferSelector = selector({
	key: "clientsOffersResetSelectedOfferSelector",
	get: ({ get }) => 1,
	set: ({ set }) => {
		set(clientsOffersSelectedIdAtom, null);
		set(clientsOffersIsNewOfferAtom, false);
		set(clientsOffersSelectedTemplateAtom, null);
		set(clientsOfferNewVersionIdAtom, null);
		set(clientsOffersTemplateFiltersAtom, {});
		set(clientsOffersIsNewOfferVersionAtom, false);
		set(clientsOffersShowCreationModalAtom, false);
		set(clientsOffersCreationStepAtom, sectionName.offerCreationStep.templateSelection);
		set(clientsOffersNextButtonDisabledAtom, false);
		set(clientsOffersTemplateSearchValueAtom, "");
		set(clientsOffersNewPriceModelCombinationAtom, null);
	},
});

export const clientOfferVisibleEmailSelector = selector({
	key: "clientOfferVisibleEmailSelector",
	get: ({ get }) => get(clientOfferVisibleEmailAtom),
	set: ({ get, set, reset }, newValue) => {
		set(clientOfferVisibleEmailAtom, newValue);
		if (newValue === false) {
			reset(clientsOffersBodyAtom);
			reset(clientsOffersContactAtom);
			reset(clientsOffersSubjectAtom);
		}
	},
});

export const clientsOffersContactSelector = selector({
	key: "clientsOffersContactSelector",
	get: ({ get }) => get(clientsOffersContactAtom),
	set: ({ set, get }, { index, changeValue, newValue, toDelete }) => {
		const currentValues = [...get(clientsOffersContactAtom)];
		if (index !== undefined && changeValue !== undefined) {
			currentValues[index] = [changeValue, currentValues[index][1]];
		} else if (newValue !== undefined) {
			if (Array.isArray(newValue)) {
				newValue.forEach(value => currentValues.push([value, getUID("xxxxxxxxy")]));
			} else {
				currentValues.push([newValue, getUID("xxxxxxxxy")]);
			}
		} else if (![null, undefined].includes(index) && toDelete) {
			currentValues.splice(index, 1);
		}
		set(clientsOffersContactAtom, currentValues);
	},
});

export const clientsOffersContactCcSelector = selector({
	key: "clientsOffersContactCcSelector",
	get: ({ get }) => get(clientsOffersContactCcAtom),
	set: ({ set, get }, { index, changeValue, newValue, toDelete }) => {
		const currentValues = [...get(clientsOffersContactCcAtom)];
		if (index !== undefined && changeValue !== undefined) {
			currentValues[index] = changeValue;
		} else if (newValue !== undefined) {
			if (Array.isArray(newValue)) {
				currentValues.push(...newValue);
			} else {
				currentValues.push(newValue);
			}
		} else if (![null, undefined].includes(index) && toDelete) {
			currentValues.splice(index, 1);
		}
		set(clientsOffersContactCcAtom, currentValues);
	},
});

export const clientsOffersContactCciSelector = selector({
	key: "clientsOffersContactCciSelector",
	get: ({ get }) => get(clientsOffersContactCciAtom),
	set: ({ set, get }, { index, changeValue, newValue, toDelete }) => {
		const currentValues = [...get(clientsOffersContactCciAtom)];
		if (index !== undefined && changeValue !== undefined) {
			currentValues[index] = changeValue;
		} else if (newValue !== undefined) {
			if (Array.isArray(newValue)) {
				currentValues.push(...newValue);
			} else {
				currentValues.push(newValue);
			}
		} else if (![null, undefined].includes(index) && toDelete) {
			currentValues.splice(index, 1);
		}
		set(clientsOffersContactCciAtom, currentValues);
	},
});
