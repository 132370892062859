import styled from "styled-components";

export const WrapperTooltipContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const VolumeMetricList = styled.ul`
	margin: 5px 0 5px 0;
`;

export const VolumeMetricItem = styled.li``;

export const WrapperWarningIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 2px;
`;
