import styled from "styled-components";

export const CenteredSpan = styled.span`
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${props => props.color};
`;

export const CenteredDiv = styled.div`
	width: 100%;
	display: flex;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	flex-direction: ${props => (props.row ? "row" : "column")};
	align-items: center;
	justify-content: center;
`;

export const ContainerFixedText = styled.div`
	width: 100%;
	font-style: italic;
	color: #a5b9c8;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
export const ContainerTitleFixedText = styled.div`
	font-style: italic;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const EllipsisDiv = styled.div`
	display: block;
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	font-style: italic;
	color: #a5b9c8;
	padding-right: 2px;
`;

export const WrapperLoader = styled.div`
	height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WrapperTable = styled.div``;

export const CategoryBubble = styled.div`
	background-color: ${props => props.backgroundColor};
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: 0 5px;
`;

export const WrapperErrorIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
