import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Tooltipv3 } from "priceit-ui";
import {
	SaveAsIcon,
	SaveButtons,
	SaveIcon,
	Wrapper,
	WrapperSave,
	WrapperSelectAndTitle,
	WrapperSelectAndIcons,
	SelectTitle,
	WrapperShortcuts,
} from "./ActionsBar.style";
import { ExtraSaveIcon } from "@components/headerCustom/actionsBar/ActionsBar.style";
import { Shortcut } from "@components/headerCustom/actionsBar/components/shortcut/Shortcut";
import SimulationsSelect from "@components/headerCustom/components/simulationsSelect/SimulationsSelect";

const ActionsBarPage = ({
	saveEnabled,
	onSave,
	onSaveAs,
	setCurrent,
	selectData,
	currentValue,
	selectTitle,
	selectFooter = "Open simulations table",
	onShowSimulations,
	isModified,
	saveAsDisabled,
	noSelectFooter,
	shortcuts,
	hideSimulationSelect,
	hideSimulationSelectTitle,
}) => {
	const { t } = useTranslation("shared/header/saveBar");

	const [saved, setSaved] = useState(false);
	const [loading, setLoading] = useState(false);

	const savePointer =
		saveEnabled && !loading && selectData && selectData.length > 0 ? "pointer" : "not-allowed";
	const saveAsPointer = saveEnabled && !loading ? "pointer" : "not-allowed";
	const saveAsColor = saveEnabled ? "#a5b9c8" : "#38444D";
	const saveColor = saveEnabled && selectData && selectData.length > 0 ? "#a5b9c8" : "#38444D";

	const save = async () => {
		if (selectData && selectData.length > 0) {
			setLoading(true);
			const saving = await onSave();
			setSaved(saving);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (saved && isModified) {
			setSaved(false);
		}
	}, [isModified]);

	return (
		<Wrapper>
			<>
				<WrapperSelectAndTitle>
					{hideSimulationSelect || hideSimulationSelectTitle ? null : (
						<SelectTitle>{selectTitle}</SelectTitle>
					)}
					<WrapperSelectAndIcons hideSimulationSelect={hideSimulationSelect}>
						{hideSimulationSelect ? null : (
							<SimulationsSelect
								currentValue={currentValue}
								setCurrent={setCurrent}
								selectData={selectData}
								noSelectFooter={noSelectFooter}
								onShowSimulations={onShowSimulations}
								selectFooter={selectFooter}
							/>
						)}
						{saveEnabled ? (
							<SaveButtons
								align={(isModified || saved) && !loading ? "start" : "center"}
							>
								<Tooltipv3
									placement="bottom"
									mode="dark"
									type="primary"
									maxWidth="350px"
									content={
										isModified &&
										!saved &&
										!loading &&
										selectData &&
										selectData.length > 0
											? t(
													"Some modifications have not been saved. Click here to save them."
												)
											: t("Save")
									}
								>
									{isModified &&
									!saved &&
									!loading &&
									selectData &&
									selectData.length > 0 ? (
										<WrapperSave>
											<ExtraSaveIcon
												type={"notSaved"}
												multipleStyle={{
													std0: { fill: saveColor },
													std1: { fill: "#E65757" },
												}}
												onClick={save}
												savePointer={savePointer}
												saveColor={saveColor}
											/>
										</WrapperSave>
									) : saved && !loading && selectData && selectData.length > 0 ? (
										<WrapperSave>
											<ExtraSaveIcon
												type={"wellSaved"}
												onClick={save}
												multipleStyle={{
													std0: { fill: saveColor },
													std1: { fill: "#78CE5E" },
													std2: { stroke: "#1e272e" },
												}}
												savePointer={savePointer}
											/>
										</WrapperSave>
									) : (
										<WrapperSave>
											<SaveIcon
												type={loading ? "loading" : "save"}
												onClick={save}
												savePointer={savePointer}
												saveColor={saveColor}
											/>
										</WrapperSave>
									)}
								</Tooltipv3>
								{saveAsDisabled ? null : (
									<Tooltipv3
										placement="bottom"
										mode="dark"
										type="primary"
										maxWidth="350px"
										content={t("Save as")}
									>
										<WrapperSave>
											<SaveAsIcon
												type={"saveAs"}
												onClick={onSaveAs}
												saveAsPointer={saveAsPointer}
												saveAsColor={saveAsColor}
											/>
										</WrapperSave>
									</Tooltipv3>
								)}
							</SaveButtons>
						) : (
							""
						)}
						{shortcuts?.length > 0 ? (
							<WrapperShortcuts>
								{shortcuts
									.filter(shortcut => !shortcut.hide)
									.map(shortcut => (
										<Shortcut
											key={shortcut.icon}
											icon={shortcut.icon}
											iconWidth={shortcut.iconWidth}
											iconHeight={shortcut.iconHeight}
											showPointer={!!shortcut.onClick}
											tooltipContent={shortcut.tooltipContent}
											tooltipInteractive={shortcut.tooltipInteractive}
											tooltipDelay={shortcut.tooltipDelay}
											onClick={shortcut.onClick ? shortcut.onClick : () => {}}
											iconFill={shortcut.iconFill}
											iconHoverFill={shortcut.iconHoverFill}
											disabled={shortcut.disabled}
											type={shortcut.type}
											title={shortcut.title}
										/>
									))}
							</WrapperShortcuts>
						) : null}
					</WrapperSelectAndIcons>
				</WrapperSelectAndTitle>
			</>
		</Wrapper>
	);
};
export const ActionsBar = ActionsBarPage;
