import { periodMultiplier } from "@services/periodMultiplier";

export const computeVariableCostPart = (value, type, totalFixedCosts) => {
	let amount = 0;
	let percent = 0;
	if (totalFixedCosts === 0 || !totalFixedCosts) return { amount, percent };
	switch (type) {
		case "percentage":
			percent = value || 0;
			amount = totalFixedCosts * (percent / 100) || 0;
			break;
		case "amount":
		default:
			amount = value || 0;
			percent = (amount / totalFixedCosts) * 100 || 0;
			break;
	}
	return {
		amount,
		percent,
	};
};

export const computeVariableCostPartAmount = (fixedCost, periodType, monthDifference) => {
	const coefficient =
		(periodMultiplier(periodType) || monthDifference) /
		(periodMultiplier(fixedCost?.timePeriod) || periodMultiplier(fixedCost?.timePeriod));
	return computeVariableCostPart(
		fixedCost?.variableCostPart,
		fixedCost?.variableCostPartType,
		fixedCost?.cost * coefficient
	).amount;
};

export const allocatedVariableCost = (
	fixedCost,
	periodType,
	monthDifference,
	product,
	kpiProductsSelected
) => {
	const variableCostPartAmount = computeVariableCostPartAmount(
		fixedCost,
		periodType,
		monthDifference
	);

	const coeffProductPeriod =
		(periodMultiplier(periodType) || monthDifference) /
		(periodMultiplier(product.timeMetric) || monthDifference);

	const coeffPeriod = (periodMultiplier(periodType) || monthDifference) / monthDifference;

	const revenue = product?.netPriceExclVAT * product?.volume;

	const percentOfTotalRevenue =
		((revenue * coeffProductPeriod) / (kpiProductsSelected.revenue * coeffPeriod)) * 100 || 0;
	const percentOfTotalVolume =
		(product.timeMetric === "onlyOnce"
			? ((product?.volume * coeffProductPeriod) /
					(kpiProductsSelected.volume * coeffPeriod)) *
			  100
			: (product?.volume / kpiProductsSelected.volume) * 100) || 0;

	const revenueAllocation = fixedCost?.revenueAllocation
	const volumeAllocation = fixedCost?.volumeAllocation

	const allocatedSemiVariableCost =
		variableCostPartAmount *
		((percentOfTotalRevenue / 100) * (revenueAllocation / 100) +
			(percentOfTotalVolume / 100) * (volumeAllocation / 100));

	return { allocatedSemiVariableCost, variableCostPartAmount };
};

export const percentCoefficients = (productsSelected, monthDifference, totalComputationalData) => {
	let newVolumePercentCoefficient = 0;
	let newRevenuePercentCoefficient = 0;
	productsSelected.forEach(product => {
		const coefficient =
			monthDifference / (periodMultiplier(product.timeMetric) || monthDifference);
		const revenue = product.price * product.volume * coefficient;
		const percentOfTotalRevenue = (revenue / totalComputationalData.revenue) * 100;
		const percentOfTotalVolume = (product.volume / totalComputationalData.volume) * 100;
		newVolumePercentCoefficient += percentOfTotalVolume;
		newRevenuePercentCoefficient += percentOfTotalRevenue;
	});
	if (newVolumePercentCoefficient === 0) {
		newVolumePercentCoefficient = 100;
	}
	if (newRevenuePercentCoefficient === 0) {
		newRevenuePercentCoefficient = 100;
	}

	return {
		volumePercentCoefficient: 100 / newVolumePercentCoefficient,
		revenuePercentCoefficient: 100 / newRevenuePercentCoefficient,
	};
};
