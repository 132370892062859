export const periodTypesEnum = {
	ONLY_ONCE: "onlyOnce",
	NOT_APPLICABLE: "notApplicable",
	PERIOD: "period",
	HOUR: "hour",
	DAY: "day",
	WEEK: "week",
	MONTH: "month", 
	QUARTER: "quarter",
	SEMESTER: "semester",
	YEAR: "year",
};

export const periodMultiplier = (period) => {
			switch (period) {
				case periodTypesEnum.ONLY_ONCE:
					return 0;
				case periodTypesEnum.NOT_APPLICABLE:
					return 0;
				case periodTypesEnum.PERIOD:
					return 0;
				case periodTypesEnum.HOUR:
					return 0.00136986;
				case periodTypesEnum.DAY:
					return 0.0328767;
				case periodTypesEnum.WEEK:
					return 0.230137;
				case periodTypesEnum.MONTH:
					return 1;
				case periodTypesEnum.QUARTER:
					return 3;
				case periodTypesEnum.SEMESTER:
					return 6;
				case periodTypesEnum.YEAR:
					return 12;
			};
	
};
