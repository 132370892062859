import styled, { css } from "styled-components";
import { Icon } from "priceit-ui";

export const WrapperDataBox = styled.div`
	position: relative;
	width: ${props => props.width ?? "250px"};
	${props =>
		props.minWidth &&
		css`
			min-width: ${props.minWidth};
		`};
	height: ${props => (props.height ? props.height : props.splitOnTwoLines ? "75px" : "60px")};
	display: flex;
	background-color: ${props => props.backgroundColor ?? "#495965"};

	border-radius: ${props => (props.withoutRadiusTop ? "" : "5px")};

	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	${props =>
		props.borderColor &&
		css`
			border: 1px solid ${props.borderColor};
			outline: 1px solid rgba(0, 0, 0, 0);
			border-radius: 7px;
		`};
`;

export const ContentContainer = styled.div`
	height: 100%;
	width: calc(100% - 8px);
	padding: 8px;
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.centerContent ? "center" : "normal")};
	border-top-right-radius: ${props => (props.addRadius ? "5px" : "")};
	border-bottom-right-radius: ${props => (props.addRadius ? "5px" : "")};
	border-top: ${props => (props.addBorderTopContent ? props.addBorderTopContent : "")};
	border-bottom: ${props => (props.addBorderBottomContent ? props.addBorderBottomContent : "")};
	border-right: ${props => (props.addBorderRightContent ? props.addBorderRightContent : "")};
`;

export const DataBoxTitle = styled.div`
	color: ${props => props.color};
	font-size: 12px;
`;

export const DataBoxValue = styled.div`
	font-size: ${props =>
		props.isValueBoxWiderThanWrapper || props.splitOnTwoLines ? "16px" : "18px"};
	margin-right: 10px;
	color: ${props => props.color};
	display: flex;
	${props =>
		props.splitOnTwoLines &&
		css`
			flex-direction: column;
		`};
	${props =>
		!props.splitOnTwoLines &&
		css`
			align-items: baseline;
			height: ${props.centerContent ? "" :"100%"};
		`};
`;

export const BorderColorDiv = styled.div`
	flex-basis: 7px;
	min-width: 7px;
	width: 7px;
	height: 100%;
	background-color: ${props => props.color};
	border-top-left-radius: ${props => (props.withoutRadiusTop ? "" : "5px")};
	border-bottom-left-radius: ${props => (props.withoutRadiusBottom ? "" : "5px")};
`;

export const WrapperValue = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	${props =>
		props.splitOnTwoLines &&
		css`
			overflow: hidden;
		`}
	${props =>
		!props.splitOnTwoLines &&
		!props.suffixStartsWithSlash &&
		css`
			margin-right: 3px;
		`}
`;

export const WrapperUnits = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	${props =>
		props.splitOnTwoLines &&
		css`
			overflow: hidden;
		`}
`;

export const Triangle = styled(Icon)`
	position: absolute;
	width: 10px;
	height: 10px;
	top: 50%;
	transform: rotate(90deg) scaleX(1.5) translate(-35%, 0);
	left: ${props => props.distance || "-20px"};
	fill: ${props => props.color || "#A5B9C8"};
`;
