import React, { useCallback, useState } from "react";

import { Tooltipv3 } from "priceit-ui";
import { TextDiv } from "./Respondent.style";
import { useThemeContext } from "@theme/Theme";

export const CellText = ({ answer }) => {
	const { mode } = useThemeContext();
	const [isTextOverflowing, setIsTextOverflowing] = useState(false);
	const [checkOverflow, setCheckOverflow] = useState(false);

	const staticTextRef = useCallback(
		node => {
			if (node !== null) {
				if (node.clientWidth < node.scrollWidth || node.clientHeight < node.scrollHeight) {
					setIsTextOverflowing(true);
				} else {
					setIsTextOverflowing(false);
				}
			}
		},
		[checkOverflow]
	);

	return (
		<Tooltipv3
			placement={"top"}
			mode={mode}
			type="primary"
			content={answer}
			disabled={!isTextOverflowing}
			appendTo={document.body}
			style={{
				width: "100%",
			}}
			maxWidth={"250px"}
		>
			<TextDiv
				ref={staticTextRef}
				onMouseEnter={() => {
					setCheckOverflow(!checkOverflow);
				}}
			>
				{answer}
			</TextDiv>
		</Tooltipv3>
	);
};
