import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { Checkbox, Tooltipv3 } from "priceit-ui";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import { updateQuoteConfigurationShowVatDetailMutation } from "@graphQl/mutations/quoteConfiguration";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/QuoteLayout.style";
import { useRecoilValue } from "recoil";
import { priceModelPageRefElAtom } from "@src/recoil/priceModel/atom";

const VatDetail = memo(({ offerId, priceModelCombinationId }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode } = useThemeContext();
	const priceModelPageEl = useRecoilValue(priceModelPageRefElAtom);
	const { quoteConfigurationId, showVatDetail, showTotalBoxes } = useQuoteConfigurationData({
		offerId,
		priceModelCombinationId,
	});

	const [updateQuoteConfigurationShowVatDetail] = updateQuoteConfigurationShowVatDetailMutation();

	if (!showTotalBoxes) return null;

	return (
		<StyledCheckboxGroup
			checkIcon
			focusColor="#FFCD38"
			boxLabelDistance="0px"
			interBoxDistance="8px"
			style={{ marginLeft: "-7.5px" }}
			selected={showVatDetail ? ["showVatDetail"] : []}
			onChange={() => {
				updateQuoteConfigurationShowVatDetail({
					variables: {
						updateQuoteConfiguration: {
							id: quoteConfigurationId,
							showVatDetail: !showVatDetail,
						},
					},
					optimisticResponse: {
						updateQuoteConfiguration: {
							__typename: "QuoteConfiguration",
							id: quoteConfigurationId,
							showVatDetail: !showVatDetail,
						},
					},
					update(cache) {
						priceModelCombinationId !== null &&
							updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
					},
				});
			}}
		>
			<Tooltipv3
				key={"vatDetail"}
				placement="top"
				mode={mode}
				type="primary"
				maxWidth="350px"
				content={t("Hides VAT details in the totals insert on the first PDF page")}
				interactive={false}
				appendTo={priceModelPageEl || document.body}
			>
				<Checkbox.Button mode={mode} value={"showVatDetail"} width="15px" height="15px">
					<StyledTextCheckbox>{t("Vat details")}</StyledTextCheckbox>
				</Checkbox.Button>
			</Tooltipv3>
		</StyledCheckboxGroup>
	);
});
VatDetail.displayName = "VatDetail";
export default VatDetail;
