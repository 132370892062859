import React, { useCallback, useRef, useState } from "react";
import {
	Container,
	FullRow,
	HoverActionIcon,
	ProductName,
	WrapperCross,
} from "@components/headerCustom/selectProductBar/SelectProductsBar.style";
import { ProductBubbleWrapper } from "@components/headerCustom/selectProductBar/components/productBubble/ProductBubble.style";
import { Tooltipv3 } from "priceit-ui";

export const ProductBubble = ({ product, removeProduct }) => {
	const [isTextOverflowing, setIsTextOverflowing] = useState(false);
	const [checkOverflow, setCheckOverflow] = useState(false);

	const staticTextRef = useCallback(
		node => {
			if (node !== null) {
				if (node.clientWidth < node.scrollWidth || node.clientHeight < node.scrollHeight) {
					setIsTextOverflowing(true);
				} else {
					setIsTextOverflowing(false);
				}
			}
		},
		[checkOverflow]
	);
	return (
		<Container>
			<Tooltipv3
				placement="top"
				content={product.name}
				interactive={false}
				disabled={!isTextOverflowing}
				style={{ height: "100%" }}
				mode="dark"
				type="primary"
				maxWidth="350px"
			>
				<ProductBubbleWrapper>
					<FullRow>
						<ProductName
							ref={staticTextRef}
							onMouseEnter={() => {
								setCheckOverflow(!checkOverflow);
							}}
						>
							{product.name}
						</ProductName>
						<WrapperCross onClick={removeProduct} hoverColor="#ffffff1A">
							<HoverActionIcon iconColorFill="#CBCBCB" type="cross" />
						</WrapperCross>
					</FullRow>
				</ProductBubbleWrapper>
			</Tooltipv3>
		</Container>
	);
};
