import { UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT } from "@graphQl/fragments/priceModelCombinationFragment";

export const updateUpdateAtInCacheForLinks = (cache, id) =>
	cache.writeFragment({
		id: `PriceModelCombination:${id}`,
		fragment: UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT,
		fragmentName: "UpdatedAtPriceModelCombinationFragment",
		data: {
			id,
			updatedAt: Date.now(),
		},
	});
