import { atom } from "recoil";

export const isCreatingDependencyAtom = atom({
	key: "isCreatingDependencyAtom",
	default: false,
});

export const volumeDiscountLinkIdAtom = atom({
	key: "volumeDiscountLinkIdAtom",
	default: "",
});

export const paymentTermsDependenciesListAtom = atom({
	key: "paymentTermsDependenciesListAtom",
	default: {},
});

export const engagementDependenciesListAtom = atom({
	key: "engagementDependenciesListAtom",
	default: {},
});

export const specialDiscountDependenciesListAtom = atom({
	key: "specialDiscountDependenciesListAtom",
	default: {},
});

export const volumeDependenciesListAtom = atom({
	key: "volumeDependenciesListAtom",
	default: {},
});

export const specialDiscountDropdownShowWarningAtom = atom({
	key: "specialDiscountDropdownShowWarningAtom",
	default: {},
});

export const paymentTermShowWarningAtom = atom({
	key: "paymentTermShowWarningAtom",
	default: {},
});

export const engagementShowWarningAtom = atom({
	key: "engagementShowWarningAtom",
	default: {},
});

export const specialDiscountShowWarningAtom = atom({
	key: "specialDiscountShowWarningAtom",
	default: {},
});

export const extraAddonShowWarningAtom = atom({
	key: "extraAddonShowWarningAtom",
	default: {},
});

export const volumeShowWarningAtom = atom({
	key: "volumeShowWarningAtom",
	default: {},
});
