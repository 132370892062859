import { gql, useMutation } from "@apollo/client";
import {
	OFFER_COMPANY_NAME_FRAGMENT,
	OFFER_CONFIRMATION_FRAGMENT,
	OFFER_CRM_DEAL_FRAGMENT,
	OFFER_EMAIL_REQUIRED_FRAGMENT,
	OFFER_FRAGMENT,
	OFFER_ID_FRAGMENT,
	OFFER_LICENSE_START_DATE_FRAGMENT,
	OFFER_NAME_FRAGMENT,
	OFFER_OFFER_STATUS_FRAGMENT,
	OFFER_OPEN_OFFER_EMAILS_FRAGMENT,
	OFFER_OWNER_FRAGMENT,
	OFFER_OWNER_ID_FRAGMENT,
	OFFER_QUOTE_LAYOUT_FRAGMENT,
	OFFER_QUOTE_OFFER_EMAILS_FRAGMENT,
	OFFER_SEND_DATE_FRAGMENT,
	OFFER_CLOSING_DATE_FRAGMENT,
	OFFER_SIGNATURE_CONNECTION_FRAGMENT,
	OFFER_PAYMENT_CONNECTION_FRAGMENT,
	OFFER_TOKEN_FRAGMENT,
	OFFER_VAT_NUMBER_FRAGMENT,
} from "@graphQl/fragments/offer";
import { OFFER_REQUEST_RESPONSE_FRAGMENT } from "@graphQl/fragments/offerRequestResponse";

const CREATE_OFFER = gql`
	${OFFER_FRAGMENT}
	mutation CreateOffer($createOffer: CreateOffer) {
		createOffer(createOffer: $createOffer) {
			...OfferFragment
		}
	}
`;

export const createOfferMutation = () => {
	const [createOffer, { loading }] = useMutation(CREATE_OFFER);
	return [createOffer, { loading }];
};

const UPDATE_OFFER_NAME = gql`
	${OFFER_NAME_FRAGMENT}
	mutation updateOfferName($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferNameFragment
		}
	}
`;

export const updateOfferNameMutation = () => {
	const [updateOfferName, { loading }] = useMutation(UPDATE_OFFER_NAME);
	return [updateOfferName, { loading }];
};

const UPDATE_OFFER_COMPANY_NAME = gql`
	${OFFER_COMPANY_NAME_FRAGMENT}
	mutation updateOfferCompanyName($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferCompanyNameFragment
		}
	}
`;

export const updateOfferCompanyNameMutation = () => {
	const [updateOfferCompanyName, { loading }] = useMutation(UPDATE_OFFER_COMPANY_NAME);
	return [updateOfferCompanyName, { loading }];
};

const UPDATE_OFFER_OWNER = gql`
	${OFFER_OWNER_ID_FRAGMENT}
	${OFFER_OWNER_FRAGMENT}
	mutation updateOfferOwner($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferOwnerIdFragment
			...OfferOwnerFragment
		}
	}
`;

export const updateOfferOwnerMutation = () => {
	const [updateOfferOwner, { loading }] = useMutation(UPDATE_OFFER_OWNER);
	return [updateOfferOwner, { loading }];
};

const UPDATE_OFFER_QUOTE_LAYOUT = gql`
	${OFFER_QUOTE_LAYOUT_FRAGMENT}
	mutation updateOfferQuoteLayout($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferQuoteLayoutFragment
		}
	}
`;

export const updateOfferQuoteLayoutMutation = () => {
	const [updateOfferQuoteLayout, { loading }] = useMutation(UPDATE_OFFER_QUOTE_LAYOUT);
	return [updateOfferQuoteLayout, { loading }];
};

const UPDATE_OFFER_EMAIL_REQUIRED = gql`
	${OFFER_EMAIL_REQUIRED_FRAGMENT}
	mutation updateOfferEmailRequired($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferEmailRequiredFragment
		}
	}
`;

export const updateOfferEmailRequiredMutation = () => {
	const [updateOfferEmailRequired, { loading }] = useMutation(UPDATE_OFFER_EMAIL_REQUIRED);
	return [updateOfferEmailRequired, { loading }];
};

const UPDATE_OFFER_EMAILS = gql`
	${OFFER_QUOTE_OFFER_EMAILS_FRAGMENT}
	${OFFER_OPEN_OFFER_EMAILS_FRAGMENT}
	mutation updateOfferEmails($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferQuoteOfferEmailsFragment
			...OfferOpenOfferEmailsFragment
		}
	}
`;

export const updateOfferEmailsMutation = () => {
	const [updateOfferEmails, { loading }] = useMutation(UPDATE_OFFER_EMAILS);
	return [updateOfferEmails, { loading }];
};

const UPDATE_OFFER_QUOTE_OFFER_EMAILS = gql`
	${OFFER_QUOTE_OFFER_EMAILS_FRAGMENT}
	mutation updateOfferQuoteOfferEmails($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferQuoteOfferEmailsFragment
		}
	}
`;

export const updateOfferQuoteOfferEmailsMutation = () => {
	const [updateOfferQuoteOfferEmails, { loading }] = useMutation(UPDATE_OFFER_QUOTE_OFFER_EMAILS);
	return [updateOfferQuoteOfferEmails, { loading }];
};

const UPDATE_OFFER_OPEN_OFFER_EMAILS = gql`
	${OFFER_OPEN_OFFER_EMAILS_FRAGMENT}
	mutation updateOfferOpenOfferEmails($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferOpenOfferEmailsFragment
		}
	}
`;

export const updateOfferOpenOfferEmailsMutation = () => {
	const [updateOfferOpenOfferEmails, { loading }] = useMutation(UPDATE_OFFER_OPEN_OFFER_EMAILS);
	return [updateOfferOpenOfferEmails, { loading }];
};

export const SET_DEFAULT_OFFER_PARAMETER = gql`
	${OFFER_QUOTE_OFFER_EMAILS_FRAGMENT}
	${OFFER_OPEN_OFFER_EMAILS_FRAGMENT}
	mutation SetDefaultOfferParameter($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferQuoteOfferEmailsFragment
			...OfferOpenOfferEmailsFragment
		}
	}
`;

export const setDefaultOfferParameterMutation = () => {
	const [setDefaultOfferParameter, { loading, error }] = useMutation(SET_DEFAULT_OFFER_PARAMETER);
	return [setDefaultOfferParameter, { loading, error }];
};

export const UPDATE_OFFER_VAT_NUMBER = gql`
	${OFFER_VAT_NUMBER_FRAGMENT}
	mutation UpdateOfferVatNumber($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferVATNumberFragment
		}
	}
`;
export const updateOfferVATNumberMutation = () => {
	const [updateOfferVATNumber, { loading, error }] = useMutation(UPDATE_OFFER_VAT_NUMBER);
	return [updateOfferVATNumber, { loading, error }];
};

export const UPDATE_OFFER_LICENSE_START_DATE = gql`
	${OFFER_LICENSE_START_DATE_FRAGMENT}
	mutation UpdateOfferLicenseStartDate($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferLicenseStartDateFragment
		}
	}
`;

export const updateOfferLicenseStartDateMutation = () => {
	const [updateOfferLicenseStartDate, { loading, error }] = useMutation(
		UPDATE_OFFER_LICENSE_START_DATE
	);
	return [updateOfferLicenseStartDate, { loading, error }];
};

export const UPDATE_OFFER_SEND_DATE = gql`
	${OFFER_SEND_DATE_FRAGMENT}
	mutation UpdateOfferSendDate($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferSendDateFragment
		}
	}
`;
export const updateOfferSendDateMutation = () => {
	const [updateOfferSendDate, { loading, error }] = useMutation(UPDATE_OFFER_SEND_DATE);
	return [updateOfferSendDate, { loading, error }];
};

export const UPDATE_OFFER_CLOSING_DATE = gql`
	${OFFER_CLOSING_DATE_FRAGMENT}
	mutation UpdateOfferClosingDate($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferClosingDateFragment
		}
	}
`;
export const updateOfferClosingDateMutation = () => {
	const [updateOfferClosingDate, { loading, error }] = useMutation(UPDATE_OFFER_CLOSING_DATE);
	return [updateOfferClosingDate, { loading, error }];
};

const DELETE_OFFER = gql`
	${OFFER_ID_FRAGMENT}
	mutation DeleteOffer($id: ID!, $deleteAssociatedDeal: Boolean) {
		deleteOffer(id: $id, deleteAssociatedDeal: $deleteAssociatedDeal) {
			...OfferIdFragment
		}
	}
`;

export const deleteOfferMutation = () => {
	const [deleteOffer, { loading, error }] = useMutation(DELETE_OFFER, {
		update(cache, { data }) {
			if (!data) return;
			const { deleteOffer } = data;
			const normalizedId = cache.identify({
				id: deleteOffer.id,
				__typename: "Offer",
			});
			cache.evict({ id: normalizedId });
			cache.gc();
		},
	});
	return [deleteOffer, { loading, error }];
};

const DELETE_OFFERS = gql`
	${OFFER_ID_FRAGMENT}
	mutation DeleteOffers($ids: [ID]!, $deleteAssociatedDeals: Boolean) {
		deleteOffers(ids: $ids, deleteAssociatedDeals: $deleteAssociatedDeals) {
			...OfferIdFragment
		}
	}
`;

export const deleteOffersMutation = () => {
	const [deleteOffers, { loading, error }] = useMutation(DELETE_OFFERS, {
		update(cache, { data: { deleteOffers } }) {
			deleteOffers.forEach(offer => {
				const normalizedId = cache.identify({
					id: offer.id,
					__typename: "Offer",
				});
				cache.evict({ id: normalizedId });
				cache.gc();
			});
		},
	});
	return [deleteOffers, { loading, error }];
};

const REQUEST_OFFER_ACCESS = gql`
	mutation RequestOfferAccess($offerRequestInput: OfferRequestInput!) {
		requestOfferAccess(offerRequestInput: $offerRequestInput)
	}
`;

export const requestOfferAccessMutation = sessionId => {
	const [requestOfferAccess, { loading }] = useMutation(REQUEST_OFFER_ACCESS, {
		context: {
			headers: {
				sessionId,
			},
		},
	});
	return [requestOfferAccess, { loading }];
};

export const RETRIEVE_OFFER_ACCESS = gql`
	mutation RetrieveOfferAccess($token: String!, $email: String, $password: String, $userId: ID) {
		retrieveOfferAccess(token: $token, email: $email, password: $password, userId: $userId) {
			sessionId
			userId
		}
	}
`;

export const retrieveOfferAccessMutation = () => useMutation(RETRIEVE_OFFER_ACCESS);

const SET_OFFER_CRM_DEAL = gql`
	${OFFER_CRM_DEAL_FRAGMENT}
	mutation SetOfferCrmDeal($offerId: ID!, $connectionId: String, $dealId: String) {
		setOfferCrmDeal(id: $offerId, connectionId: $connectionId, dealId: $dealId) {
			...OfferCrmDealFragment
		}
	}
`;

export const setOfferCrmDealMutation = () => {
	const [setOfferCrmDeal, { loading }] = useMutation(SET_OFFER_CRM_DEAL);
	const setDealId = (offerId, connection, dealId, previousDealId, onError) =>
		setOfferCrmDeal({
			variables: {
				offerId,
				connectionId: connection.id,
				dealId,
			},
			optimisticResponse: {
				setOfferCrmDeal: {
					__typename: "Offer",
					id: offerId,
					crmDealId: dealId,
					name: "",
					crmDealConnection: {
						id: connection.id,
						name: connection.name,
						service: {
							id: connection.service?.id,
							name: connection.service?.name,
						},
					},
				},
			},
			update(cache, { data: { setOfferCrmDeal: setOfferCrmDealData } }) {
				cache.modify({
					fields: {
						getAlreadyAssociatedDealIds(existing) {
							const newId = setOfferCrmDealData.crmDealId;
							let newList = [...existing];
							if (previousDealId) {
								newList = newList.filter(element => previousDealId !== element);
							}
							newList.push(newId);
							return newList;
						},
					},
				});
			},
			errorPolicy: "none",
			onError,
		});
	const removeConnection = (offerId, previousDealId) =>
		setOfferCrmDeal({
			variables: {
				offerId,
				connectionId: null,
				dealId: null,
			},
			optimisticResponse: {
				setOfferCrmDeal: {
					__typename: "Offer",
					id: offerId,
					crmDealId: null,
					crmDealConnection: null,
				},
			},
			update(cache /*, { data: { setOfferCrmDeal } }*/) {
				cache.modify({
					fields: {
						getAlreadyAssociatedDealIds(existing) {
							let newList = [...existing];
							newList = newList.filter(element => previousDealId !== element);
							return newList;
						},
					},
				});
			},
			errorPolicy: "none",
		});
	return { setDealId, removeConnection, loading };
};

const CONFIRM_OFFER_CREATION = gql`
	${OFFER_CONFIRMATION_FRAGMENT}
	mutation ConfirmOfferCreation($id: ID!) {
		confirmOfferCreation(id: $id) {
			...OfferConfirmationFragment
		}
	}
`;

const CONFIRM_OFFER_CREATION_WITH_TOKEN = gql`
	${OFFER_CONFIRMATION_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	mutation ConfirmOfferCreationWithToken($id: ID!) {
		confirmOfferCreation(id: $id) {
			...OfferConfirmationFragment
			...OfferTokenFragment
		}
	}
`;

export const confirmOfferCreationMutation = (withToken, isNewOffer) => {
	const [confirmOfferCreation, { loading }] = useMutation(
		withToken ? CONFIRM_OFFER_CREATION_WITH_TOKEN : CONFIRM_OFFER_CREATION,
		{
			update(cache, { data: { confirmOfferCreation } }) {
				if (isNewOffer) {
					cache.modify({
						fields: {
							getOffers(existingOffersRef, { storeFieldName }) {
								const newOfferRef = cache.writeFragment({
									data: confirmOfferCreation,
									fragment: OFFER_ID_FRAGMENT,
								});
								if (storeFieldName === `getOffers`) {
									return [...existingOffersRef, newOfferRef];
								}
								return existingOffersRef;
							},
						},
					});
				}
			},
		}
	);
	return [confirmOfferCreation, { loading }];
};

const SET_OFFER_SIGNATURE_CONNECTION = gql`
	${OFFER_SIGNATURE_CONNECTION_FRAGMENT}
	mutation SetOfferSignatureConnection($offerId: ID!, $connectionId: String) {
		setOfferSignatureConnection(id: $offerId, connectionId: $connectionId) {
			...OfferSignatureConnectionFragment
		}
	}
`;

export const setOfferSignatureConnectionMutation = () => {
	const [setOfferSignatureConnection, { loading }] = useMutation(SET_OFFER_SIGNATURE_CONNECTION);
	return [setOfferSignatureConnection, { loading }];
};

const SET_OFFER_PAYMENT_CONNECTION = gql`
	${OFFER_PAYMENT_CONNECTION_FRAGMENT}
	mutation SetOfferPaymentConnection($offerId: ID!, $connectionId: String) {
		setOfferPaymentConnection(id: $offerId, connectionId: $connectionId) {
			...OfferPaymentConnectionFragment
		}
	}
`;

export const setOfferPaymentConnectionMutation = () => {
	const [setOfferPaymentConnection, { loading }] = useMutation(SET_OFFER_PAYMENT_CONNECTION);
	return [setOfferPaymentConnection, { loading }];
};

export const REQUIRE_OFFER_FROM_TEMPLATE_TOKEN = gql`
	${OFFER_REQUEST_RESPONSE_FRAGMENT}
	mutation RequireOfferFromTemplateToken(
		$templateToken: String!
		$userToken: String
		$firstName: String
		$lastName: String
		$email: String!
		$companyName: String!
	) {
		requireOfferFromTemplateToken(
			templateToken: $templateToken
			userToken: $userToken
			firstName: $firstName
			lastName: $lastName
			email: $email
			companyName: $companyName
		) {
			...OfferRequestResponseFragment
		}
	}
`;

export const requireOfferFromTemplateTokenMutation = () => {
	const [requireOfferFromTemplate, { loading }] = useMutation(REQUIRE_OFFER_FROM_TEMPLATE_TOKEN);
	return [requireOfferFromTemplate, { loading }];
};

export const UPDATE_OFFER_STATUS = gql`
	${OFFER_OFFER_STATUS_FRAGMENT}
	${OFFER_SEND_DATE_FRAGMENT}
	${OFFER_CLOSING_DATE_FRAGMENT}
	mutation UpdateOfferStatus($updateOffer: UpdateOffer!) {
		updateOffer(updateOffer: $updateOffer) {
			...OfferOfferStatusFragment
			...OfferSendDateFragment
			...OfferClosingDateFragment
		}
	}
`;

export const updateOfferStatusMutation = () => {
	const [updateOfferStatus, { loading }] = useMutation(UPDATE_OFFER_STATUS);
	return [updateOfferStatus, { loading }];
};
