import { useEffect } from "react";
import { clientsOffersSelectedIdSelector, sectionName } from "@recoil/clientOffers";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	clientsOffersCreationStepAtom,
	clientsOffersEnableCrmDeal,
	clientsOffersNextButtonDisabledAtom,
	clientsOffersSelectedTemplateAtom,
} from "@recoil/clientOffers/atom";
import { useQuery } from "@apollo/client";
import { GET_OFFER_CRM_DEAL_BY_OFFER_ID, GET_OFFER_MANDATORY_BY_ID } from "@graphQl/queries/offer";
import { isErrorAddress } from "./isErrorAddress/isErrorAddress";
import { useGetDisplayedAndMandatoryQuoteField } from "./useGetDisplayedAndMandatoryQuoteField/useGetDisplayedAndMandatoryQuoteField";
import { isErrorOfferContact } from "./isErrorOfferContact/isErrorOfferContact";
import { quoteFieldsKeys } from "@services/constants";

const isValidURL = stringURL => {
	let url;

	try {
		url = new URL(stringURL);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
};

export const useCanNextCustomization = () => {
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useRecoilState(
		clientsOffersNextButtonDisabledAtom
	);
	const creationStep = useRecoilValue(clientsOffersCreationStepAtom);
	const selectedTemplate = useRecoilValue(clientsOffersSelectedTemplateAtom);
	const enabled = useRecoilValue(clientsOffersEnableCrmDeal);

	const { data: dataOffer } = useQuery(GET_OFFER_MANDATORY_BY_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});

	const { display: isPostalAddressDisplayed, mandatory: isPostalAddressMandatory } =
		useGetDisplayedAndMandatoryQuoteField({
			priceModelCombinationId: selectedTemplate,
			quoteFieldName: quoteFieldsKeys.POSTAL_ADDRESS,
		});

	const { offerContacts, name, companyName, ownerId, offerProperties, address } =
		dataOffer?.getOfferById || {};

	const {
		enableAttachedPresentation,
		attachedPresentation,
		attachedLink,
		enableAttachedLink,
		enableClientsLogo,
		clientsLogo,
	} = offerProperties || {};

	const { data: dataCrmDeal } = useQuery(GET_OFFER_CRM_DEAL_BY_OFFER_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});
	const crmDeal = dataCrmDeal?.getOfferById || {};

	const conditionAttachedPresentationEnableNexButton =
		(enableAttachedPresentation && attachedPresentation) ||
		!attachedPresentation ||
		!enableAttachedPresentation;

	const errorOfferContact = isErrorOfferContact({ offerContacts });
	const errorAddress = isErrorAddress({
		address: address,
		isMandatory: isPostalAddressDisplayed && isPostalAddressMandatory,
	});

	useEffect(() => {
		if (isNextButtonDisabled) {
			if (
				((creationStep === sectionName.offerCreationStep.customization &&
					!enabled &&
					name &&
					companyName &&
					ownerId &&
					conditionAttachedPresentationEnableNexButton &&
					!errorOfferContact) ||
					(enabled && crmDeal.crmDealId && name)) &&
				!errorAddress &&
				(!enableAttachedLink || isValidURL(attachedLink)) &&
				((enableClientsLogo && !!clientsLogo) || !enableClientsLogo)
			) {
				setIsNextButtonDisabled(false);
			}
		}
	}, [
		creationStep,
		enabled,
		crmDeal,
		name,
		enableClientsLogo,
		clientsLogo,
		companyName,
		ownerId,
		conditionAttachedPresentationEnableNexButton,
		errorOfferContact,
		errorAddress,
		enableAttachedLink,
		attachedLink,
		isNextButtonDisabled,
		setIsNextButtonDisabled,
	]);
};
