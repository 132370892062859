import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Dialog } from "priceit-ui";
import analytics from "../../../config/analytics/analytics";

import { useSize } from "@hooks/customHooks";

import { useWorkspace } from "@hooks/useWorkspace/UseWorkspace";

import {
	clientsOffersSelectedIdSelector,
	clientsOffersIsNewOfferVersionSelector,
} from "@recoil/clientOffers/clientsOffersSelector";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { createOfferMutation, setOfferCrmDealMutation } from "@graphQl/mutations/offer";
import CreateNewOfferPage from "@pages/clientsOffers/createNewOfferPage/CreateNewOfferPage";
import { useIframeActions } from "@hooks/UseIframeActions";
import { WrapperIframe, WrapperZoom } from "./createOfferWrapper.style";
import { clientsOffersIsNewOfferAtom } from "@recoil/clientOffers/atom";
import { useLazyQuery } from "@apollo/client";
import { GET_OFFER_BY_ID_FOR_HUBSPOT_VERSION_CREATION } from "@src/graphQl/queries/offer";

export const CreateOfferWrapper = () => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { search, pathname } = useLocation();

	const { auth, loadingAuth } = useAuth();
	const { workspace, loadingWorkspace } = useWorkspace();

	const [cancelCreateOffer] = useIframeActions();
	const [creationError, setCreationError] = useState(false);
	const [selectedOfferId, setSelectedOfferId] = useRecoilState(clientsOffersSelectedIdSelector);
	const setIsNewOffer = useSetRecoilState(clientsOffersIsNewOfferAtom);
	const setIsNewOfferVersion = useSetRecoilState(clientsOffersIsNewOfferVersionSelector);
	const [createOffer] = createOfferMutation();
	const { setDealId } = setOfferCrmDealMutation();
	const { ErrorModal } = Dialog;

	const containerRef = useRef(null);
	const containerSize = useSize(containerRef);
	const isIframe = window !== window.parent;
	const [wrapperNode, setWrapperNode] = useState(null);
	const widthDifference = containerSize?.width - (containerSize?.width || 0) * 0.7;
	const heightDifference = containerSize?.height - (containerSize?.height || 0) * 0.7;

	const [negativeMargins, setNegativeMargins] = useState({
		topBottomMargin: -200,
		leftRightMargin: -400,
	});

	useEffect(() => {
		if (auth?.uuid) {
			analytics.identify(auth?.uuid, auth);
		}
	}, [auth]);

	const [getOfferByIdForHubspotVersionCreation] = useLazyQuery(
		GET_OFFER_BY_ID_FOR_HUBSPOT_VERSION_CREATION
	);

	useEffect(() => {
		if (!pathname.includes("offercreated") && !loadingAuth) {
			const path = pathname.split("/").slice(2).join("/");
			if (!loadingWorkspace) {
				analytics.page(
					{
						path: path,
						workspaceName: workspace?.name,
						workspaceActive: workspace?.active,
						workspaceActivationDate: workspace?.createdAt,
						workspaceActiveToDate: workspace?.activeToDate,
					},
					{}
				);
				const searchParams = new URLSearchParams(search);
				const service = searchParams.get("crmservice");
				const alreadyExistingOffer = searchParams.get("offerid");
				analytics.track(`Create a new offer/version - ${service} button`, {
					type: alreadyExistingOffer ? "new version" : "new offer",
					workspaceName: workspace?.name,
					workspaceActive: workspace?.active,
					workspaceActivationDate: workspace?.createdAt,
					workspaceActiveToDate: workspace?.activeToDate,
				});
			}
		}
	}, [pathname, loadingWorkspace, loadingAuth]);

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		const alreadyExistingOffer = searchParams.get("offerid");
		const crmConnectionId = searchParams.get("crmconnectionid");
		const dealId = searchParams.get("dealid");
		if (alreadyExistingOffer) {
			getOfferByIdForHubspotVersionCreation({
				variables: {
					id: alreadyExistingOffer,
				},
			})
				.then(() => {
					setSelectedOfferId(alreadyExistingOffer);
					setIsNewOfferVersion(true);
				})
				.catch(e => {
					console.log(e);
					setCreationError(true);
				});
		} else {
			setIsNewOffer(true);
			createOffer()
				.then(newOffer => {
					const offerId = newOffer?.data?.createOffer?.id;
					setSelectedOfferId(offerId);
					if (crmConnectionId && dealId && offerId) {
						setDealId(
							offerId,
							{ id: crmConnectionId },
							dealId,
							null,
							e => console.log(e) // fail silently on this, it's possible to select a deal later
						);
					}
				})
				.catch(e => {
					console.log(e);
					setCreationError(true);
				});
		}
	}, []);

	const wrapperRef = useCallback(
		node => {
			if (node !== null) {
				if (!wrapperNode) {
					setWrapperNode(node);
				}
				setNegativeMargins({
					topBottomMargin: -heightDifference * 0.71,
					leftRightMargin: -widthDifference * 0.71,
				});
			}
		},
		[containerSize, widthDifference, heightDifference]
	);

	return (
		<>
			{selectedOfferId ? (
				<WrapperIframe ref={containerRef}>
					{isIframe ? (
						<WrapperZoom
							ref={wrapperRef}
							topBottomMargin={negativeMargins?.topBottomMargin}
							leftRightMargin={negativeMargins?.leftRightMargin}
						>
							<CreateNewOfferPage />
						</WrapperZoom>
					) : (
						<CreateNewOfferPage />
					)}
				</WrapperIframe>
			) : null}
			<ErrorModal
				noFixPlaceHolder
				reverseButtonHighlight
				footerType="1button"
				okText={t("Close")}
				onOk={() => {
					cancelCreateOffer();
				}}
				visible={creationError}
				title={t("There was an error creating the offer.")}
			/>
		</>
	);
};

CreateOfferWrapper.displayName = "CreateOfferWrapper";
export default CreateOfferWrapper;
