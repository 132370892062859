const minWidth = {
	dropdown: 120,
	inputCurrency: 160,
	input: 160,
	textArea: 160,
	inputPercentage: 90,
	result: 90,
	progressBar: 100,
	switch: 160,
	rating: 200,
	picture: 90,
	published: 200,
	favorite: 90,
	ARR: 150,
	singleFeeValue: 150,
	sendDate: 150,
	closingDate: 150,
	lastConsultation: 150,
	lastConsultedVersion: 150,
	heatScore: 150,
};

export const getMinWidthColumn = type => {
	if (Object.keys(minWidth).includes(type)) {
		return minWidth[type];
	}
	return 90;
};
