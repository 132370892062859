import { selector, selectorFamily } from "recoil";
import {
	contactEmailsAtom,
	discountPricePointAtom,
	packPriceLoadingAtom,
	primaryMultiplierAtom,
	primaryPriceMetricsAtom,
	primaryPriceTimeMetricAtom,
	publicAppliedGlobalRulesAtom,
	publicLinkHighlightDifferencesAtom,
	publicLinkIsSideBarExpandedAtom,
	publicLinkOpenedSectionAtom,
	publicLinkPriceModelTypeAtom,
	publicLinkSelectedPackIdAtom,
	publicLinkShowPackSelectionInfoAtom,
	publicLinkShowSavingsAtom,
	publicLinkTvaDisplayAtom,
	publicNumericalInputErrorAtom,
	publicLinkHidePricesAtom,
} from "./atom";
import { commitmentDiscountValueByPriceModelIdSelector } from "./commitmentDiscountSelector";
import { paymentTermsDiscountValueByPriceModelIdSelector } from "./paymentTermsDiscountSelector";
import defaultText from "@services/defaultText";
import { convertMetric } from "@services/metrics";
import { priceModelType } from "@recoil/publicPriceModel";

// FIXME: Les selectors qui ne modifient pas l'atom peuvent être supprimés, et on utilise simplement l'atom à la place

export const isSideBarExpandedByPriceModelIdSelector = selectorFamily({
	key: "isSideBarExpandedByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkIsSideBarExpandedAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkIsSideBarExpandedAtom, {
				...get(publicLinkIsSideBarExpandedAtom),
				[priceModelId]: newValue,
			});
		},
});

export const selectedPackIdByPriceModelIdSelector = selectorFamily({
	key: "selectedPackIdByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkSelectedPackIdAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			const selectedPackIdAtom = get(publicLinkSelectedPackIdAtom);

			if (selectedPackIdAtom?.[priceModelId] !== newValue) {
				set(publicLinkSelectedPackIdAtom, {
					...selectedPackIdAtom,
					[priceModelId]: newValue,
				});
			} else {
				const { [priceModelId]: removedProperty, ...newAtom } = selectedPackIdAtom || {};
				set(publicLinkSelectedPackIdAtom, {
					...newAtom,
				});
			}
		},
});

export const defaultSelectedPackIdByPriceModelIdSelector = selectorFamily({
	key: "selectedPackIdByPriceModelIdSelector",
	get: () => 1,
	set:
		priceModelId =>
		({ get, set }, defaultValue) => {
			const selectedPackIdAtom = get(publicLinkSelectedPackIdAtom);
			set(publicLinkSelectedPackIdAtom, {
				...selectedPackIdAtom,
				[priceModelId]: defaultValue,
			});
		},
});

export const discountPricePointByPackIdAndTypeSelector = selectorFamily({
	key: "discountPricePointByPackIdAndTypeSelector",
	get:
		({ packId, type }) =>
		({ get }) =>
			get(discountPricePointAtom)?.[packId]?.[type],
});

export const discountPricePointByPriceModelIdSelector = selectorFamily({
	key: "discountPricePointByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			Object.values(get(discountPricePointAtom)).filter(
				x => x?.priceModelId === priceModelId
			),
});

export const showPackInfoSelectionByPriceModelIdSelector = selectorFamily({
	key: "showPackInfoSelectionByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkShowPackSelectionInfoAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkShowPackSelectionInfoAtom, {
				...get(publicLinkShowPackSelectionInfoAtom),
				[priceModelId]: newValue,
			});
		},
});

export const openedAllSectionSelector = selectorFamily({
	key: "opennedAllSectionSelector",
	get:
		({ priceModelId }) =>
		({ get }) => {
			return get(publicLinkOpenedSectionAtom)?.[priceModelId];
		},
	set:
		({ priceModelId }) =>
		({ get, set }, newValue) => {
			const publicLinkOpenedSection = JSON.parse(
				JSON.stringify(get(publicLinkOpenedSectionAtom)) //I am sorry, do not find better way to copy nested object
			);
			const allKeysOfPriceModelId =
				publicLinkOpenedSection[priceModelId] !== undefined
					? Object.keys(publicLinkOpenedSection[priceModelId])
					: [];
			if (priceModelId && allKeysOfPriceModelId.length === 0) {
				publicLinkOpenedSection[priceModelId] = newValue;
			} else if (priceModelId && allKeysOfPriceModelId.length > 0) {
				allKeysOfPriceModelId.forEach(
					key => (publicLinkOpenedSection[priceModelId][key] = newValue)
				);
			}
			set(publicLinkOpenedSectionAtom, publicLinkOpenedSection);
		},
});

export const openedSectionSelector = selectorFamily({
	key: "openedSectionSelector",
	get:
		({ priceModelId, sectionId }) =>
		({ get }) => {
			return get(publicLinkOpenedSectionAtom)?.[priceModelId]?.[sectionId];
		},
	set:
		({ sectionId, priceModelId }) =>
		({ get, set }, newValue) => {
			const publicLinkOpenedSection = JSON.parse(
				JSON.stringify(get(publicLinkOpenedSectionAtom)) //I am sorry, do not find better way to copy nested object
			);
			if (priceModelId && !sectionId) {
				publicLinkOpenedSection[priceModelId] = newValue;
			} else if (priceModelId && sectionId) {
				publicLinkOpenedSection[priceModelId][sectionId] = newValue;
			}
			set(publicLinkOpenedSectionAtom, publicLinkOpenedSection);
		},
});

export const highlightDifferencesByPriceModelIdSelector = selectorFamily({
	key: "highlightDifferencesByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkHighlightDifferencesAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkHighlightDifferencesAtom, {
				...get(publicLinkHighlightDifferencesAtom),
				[priceModelId]: newValue,
			});
		},
});

export const hidePricesByPriceModelIdSelector = selectorFamily({
	key: "hidePricesByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkHidePricesAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkHidePricesAtom, {
				...get(publicLinkHidePricesAtom),
				[priceModelId]: newValue,
			});
		},
});

export const priceModelTypeByPriceModelIdSelector = selectorFamily({
	key: "priceModelTypeByPriceModelIdSelector",
	set:
		priceModelId =>
		({ get, set }, { timeMetric, newPaymentTerm, newCommitment, discountRule }) => {
			const commitmentSelected =
				newCommitment ??
				get(commitmentDiscountValueByPriceModelIdSelector({ priceModelId, discountRule }));
			const paymentTermSelected =
				newPaymentTerm ??
				get(
					paymentTermsDiscountValueByPriceModelIdSelector({ priceModelId, discountRule })
				);

			const convertedMetric = convertMetric(commitmentSelected, timeMetric);
			const newValue =
				timeMetric === defaultText.onlyOnce
					? priceModelType.nonRecurring
					: convertedMetric[paymentTermSelected] === 1 ||
						  paymentTermSelected === defaultText.oneShot
						? priceModelType.oneShotRecurring
						: priceModelType.recurring;

			set(publicLinkPriceModelTypeAtom, {
				...get(publicLinkPriceModelTypeAtom),
				[priceModelId]: newValue,
			});
		},
});

export const tvaDisplayByPriceModelIdSelector = selectorFamily({
	key: "tvaDisplayByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkTvaDisplayAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkTvaDisplayAtom, {
				...get(publicLinkTvaDisplayAtom),
				[priceModelId]: newValue,
			});
		},
});

export const showSavingsByPriceModelIdSelector = selectorFamily({
	key: "showSavingsByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(publicLinkShowSavingsAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(publicLinkShowSavingsAtom, {
				...get(publicLinkShowSavingsAtom),
				[priceModelId]: newValue,
			});
		},
});

export const primaryPriceMetricsByPriceModelIdSelector = selectorFamily({
	key: "primaryPriceMetricsByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(primaryPriceMetricsAtom)[priceModelId] || [],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(primaryPriceMetricsAtom, {
				...get(primaryPriceMetricsAtom),
				[priceModelId]: newValue,
			});
		},
});

export const primaryMultiplierByPriceModelIdSelector = selectorFamily({
	key: "primaryMultiplierByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(primaryMultiplierAtom)[priceModelId] || [],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(primaryMultiplierAtom, {
				...get(primaryMultiplierAtom),
				[priceModelId]: newValue,
			});
		},
});

export const primaryPriceTimeMetricByPriceModelIdSelector = selectorFamily({
	key: "primaryPriceTimeMetricByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(primaryPriceTimeMetricAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(primaryPriceTimeMetricAtom, {
				...get(primaryPriceTimeMetricAtom),
				[priceModelId]: newValue,
			});
		},
});

export const fetchPriceModelsSelector = selector({
	key: "fetchPriceModelsSelector",
	get: ({ get }) => 1,
	set: ({ get, set }, priceModel) => {
		const priceModelId = priceModel.id;

		const priceModelDesignAndSetting = priceModel.designAndSettings || {};

		set(
			highlightDifferencesByPriceModelIdSelector(priceModelId),
			priceModelDesignAndSetting.highlightedDifferences
		);

		set(hidePricesByPriceModelIdSelector(priceModelId), priceModelDesignAndSetting.hidePrices);

		set(
			tvaDisplayByPriceModelIdSelector(priceModelId),
			priceModelDesignAndSetting?.displayedVatDefault === defaultText.vatIncluded
				? defaultText.vatIncluded
				: defaultText.vatExcluded
		);

		set(showSavingsByPriceModelIdSelector(priceModelId), priceModel.showSavings);
		set(
			defaultSelectedPackIdByPriceModelIdSelector(priceModelId),
			priceModel.defaultSelectedPack
		);
		const primaryMetrics = priceModel.primaryMetrics?.map(metric => metric.id);
		set(primaryPriceMetricsByPriceModelIdSelector(priceModelId), primaryMetrics);
		set(
			primaryPriceTimeMetricByPriceModelIdSelector(priceModelId),
			priceModel.primaryTimeMetric?.term === null
				? "totalCommitment"
				: priceModel.primaryTimeMetric?.term
		);

		const sectionsOpened = {
			addonBefore: priceModelDesignAndSetting.addOnCollapsedByDefaultBefore,
			extraBefore: priceModelDesignAndSetting.extraCollapsedByDefaultBefore,
			addonAfter: priceModelDesignAndSetting.addOnCollapsedByDefaultAfter,
			extraAfter: priceModelDesignAndSetting.extraCollapsedByDefaultAfter,
		};

		priceModel?.categories?.map(category => {
			sectionsOpened[category.id] = category.openedByDefault;
		});
		set(openedSectionSelector({ priceModelId }), sectionsOpened);
	},
});

export const loadingPackPricesSelector = selectorFamily({
	key: "loadingPackPricesSelector",
	get:
		packId =>
		({ get }) =>
			get(packPriceLoadingAtom)[packId],
	set:
		packId =>
		({ get, set }, loading) => {
			set(packPriceLoadingAtom, {
				...get(packPriceLoadingAtom),
				[packId]: loading,
			});
		},
});

export const contactEmailsSelector = selector({
	key: "contactEmailsSelector",
	get: ({ get }) => get(contactEmailsAtom),
	set: ({ get, set }, { index, type, value }) => {
		const currentContacts = [...get(contactEmailsAtom)];
		if (type === "SET_CONTACT_MAIL") {
			currentContacts.push("");
			set(contactEmailsAtom, currentContacts);
		} else if (type === "SET_CONTACTS") {
			set(contactEmailsAtom, value);
		} else if (type === "DELETE_CONTACT") {
			currentContacts.splice(index, 1);
			set(contactEmailsAtom, currentContacts);
		} else {
			currentContacts[index] = value;
			set(contactEmailsAtom, currentContacts);
		}
	},
});

export const publicAppliedGlobalRulesSelector = selectorFamily({
	key: "publicAppliedGlobalRulesSelector",
	get:
		tabId =>
		({ get }) =>
			get(publicAppliedGlobalRulesAtom)[tabId],
	set:
		tabId =>
		({ get, set }, rules) => {
			set(publicAppliedGlobalRulesAtom, {
				...get(publicAppliedGlobalRulesAtom),
				[tabId]: rules || [],
			});
		},
});

export const publicNumericalInputErrorSelector = selector({
	key: "publicNumericalInputErrorSelector",
	get: ({ get }) => get(publicNumericalInputErrorAtom),
	set: ({ get, set }, { packId, featureId, newValue }) => {
		const publicNumericalInputError = JSON.parse(
			JSON.stringify(get(publicNumericalInputErrorAtom))
		);
		if (!publicNumericalInputError[packId]) {
			publicNumericalInputError[packId] = {};
		}
		publicNumericalInputError[packId][featureId] = newValue;
		set(publicNumericalInputErrorAtom, publicNumericalInputError);
	},
});
