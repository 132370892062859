import { computeTieredNumericalPrice } from "../computeTieredNumericalPrice/computeTieredNumericalPrice";

export const computeFeaturePriceService = ({
	priceModelFeature,
	level,
	boostLevel,
	volume = null,
	forExtraFee,
}) => {
	const packId = level.packId;
	const feeBasedAttribute = priceModelFeature.feeBased;
	const levelDataList = priceModelFeature?.levelData;
	const enableExtraFees = priceModelFeature.enableExtraFees;
	const { value: featureValue } = priceModelFeature || {};

	const { type: numericalType } = featureValue || {};

	if (!numericalType || numericalType === "range") {
		const defaultLevel = levelDataList.find(lvlData => {
			return level?.levelDataId === lvlData.id;
		});
		const defaultLevelDataPackBooster = defaultLevel?.levelDataPacks?.find(
			lvl => lvl.packId === packId
		);

		const levelDataPack = boostLevel?.levelDataPacks.find(lvl => lvl.packId === packId);

		const { price, extraFee } = levelDataPack || {};
		if (forExtraFee) {
			return enableExtraFees
				? extraFee - (feeBasedAttribute ? 0 : defaultLevelDataPackBooster?.extraFee || 0)
				: 0;
		}

		return price - (feeBasedAttribute ? 0 : defaultLevelDataPackBooster?.price || 0);
	}

	if (numericalType === "tiered") {
		const numericalRanges = featureValue?.ranges;

		const { defaultBoosterValue, levelDataValue } = level;

		const levelDataPacks = levelDataList.map(levelData =>
			levelData.levelDataPacks.find(levelDataPack => levelDataPack.packId === packId)
		);

		const levelLevelDataPack = levelDataPacks.find(
			levelDataPack => levelDataPack.levelDataId === level.levelDataId
		);

		const levelLevelData = levelDataList.find(l => l.id === level.levelDataId);

		if (forExtraFee && priceModelFeature.extraFeeMetric !== "numericalUnitMetric") {
			const levelDataPack = boostLevel?.levelDataPacks.find(lvl => lvl.packId === packId);

			const { extraFee } = levelDataPack || {};

			return volume > levelDataValue ? extraFee : 0;
		}

		const boostPrice = computeTieredNumericalPrice({
			numericalRanges,
			levelDatas: levelDataList,
			levelDataPacks,
			volume: volume || defaultBoosterValue,
			forExtraFee,
		});

		const levelVolume = levelLevelDataPack.isBoosterAllowed
			? levelDataValue
			: levelLevelData.value?.from;

		const levelPrice = !feeBasedAttribute
			? computeTieredNumericalPrice({
					numericalRanges,
					levelDatas: levelDataList,
					levelDataPacks,
					volume: levelVolume,
					forExtraFee,
				})
			: 0;
		return boostPrice - levelPrice;
	}

	if (numericalType === "linear") {
		const { defaultBoosterValue, levelDataValue } = level;

		const levelDataPack = boostLevel?.levelDataPacks.find(lvl => lvl.packId === packId);

		const { price, extraFee } = levelDataPack || {};

		if (forExtraFee) {
			if (priceModelFeature.extraFeeMetric !== "numericalUnitMetric") {
				return volume > levelDataValue ? extraFee : 0;
			}
			return extraFee * (volume - (!priceModelFeature?.feeBased ? levelDataValue : 0));
		}

		return (
			price * ((volume || defaultBoosterValue) - (!feeBasedAttribute ? levelDataValue : 0))
		);
	}

	return 0;
};
