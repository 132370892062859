import { computeFeaturePriceService } from "@src/services/priceModel/computeFeaturePriceService/computeFeaturePriceService";
import { selector, selectorFamily } from "recoil";
import { boosterStateAtom, numericalValueFeatureAtom } from "./atom";
import { LEVEL_IS_LINKED_FRAGMENT } from "@src/graphQl/fragments/level";
import { PRICE_MODEL_FOR_PACK_UPDATE_FRAGMENT } from "@src/graphQl/fragments/priceModel";

export const boosterStatesByPriceModelIdSelector = selectorFamily({
	key: "boosterStatesByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(boosterStateAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(boosterStateAtom, {
				...get(boosterStateAtom),
				[priceModelId]: newValue,
			});
		},
});

export const numericalValueFeatureByPriceModelIdSelector = selectorFamily({
	key: "numericalValueFeatureByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(numericalValueFeatureAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(numericalValueFeatureAtom, {
				...get(numericalValueFeatureAtom),
				[priceModelId]: newValue,
			});
		},
});

export const numericalValueFeatureSelector = selectorFamily({
	key: "numericalValueFeatureSelector",
	get:
		({ priceModelId, featureId, packId }) =>
		({ get }) => {
			if (priceModelId && packId && featureId) {
				return get(numericalValueFeatureAtom)?.[priceModelId]?.[packId]?.[featureId];
			} else return null;
		},
	set:
		({ priceModelId, featureId, packId }) =>
		({ get, set }, { value, cache }) => {
			const atom = JSON.parse(JSON.stringify(get(numericalValueFeatureAtom)));
			if (!!cache) {
				const levelWithLink = cache.readFragment({
					id: cache.identify({
						packId,
						featureId,
						__typename: "Level",
					}),
					fragment: LEVEL_IS_LINKED_FRAGMENT,
					fragmentName: "LevelIsLinkedFragment",
				});

				if (levelWithLink.isLinked) {
					const priceModelWithPacks = cache.readFragment({
						id: cache.identify({
							__typename: "PriceModel",
							id: priceModelId,
						}),
						fragment: PRICE_MODEL_FOR_PACK_UPDATE_FRAGMENT,
						fragmentName: "PriceModelForPackUpdate",
					});

					const packs = priceModelWithPacks.packs;
					packs
						.filter(pack => pack.id !== packId)
						.forEach(pack => {
							const levelIdentify = cache.identify({
								packId: pack.id,
								featureId,
								__typename: "Level",
							});
							const levelWithLink = cache.readFragment({
								id: levelIdentify,
								fragment: LEVEL_IS_LINKED_FRAGMENT,
								fragmentName: "LevelIsLinkedFragment",
							});
							if (levelWithLink.isLinked) {
								if (!atom?.[priceModelId]) {
									atom[priceModelId] = {};
								}
								if (!atom[priceModelId]?.[pack.id]) {
									atom[priceModelId][pack.id] = {};
								}
								atom[priceModelId][pack.id][featureId] = value;
							}
						});
				}
			}

			if (!atom?.[priceModelId]) {
				atom[priceModelId] = {};
			}
			if (!atom[priceModelId]?.[packId]) {
				atom[priceModelId][packId] = {};
			}
			atom[priceModelId][packId][featureId] = value;

			set(numericalValueFeatureAtom, atom);
		},
});

export const boosterStateByPMIdAndFeatureIdAndPackIdAndInputTypeSelector = selectorFamily({
	key: "boosterStateByPriceModelIdAndDiscountIdSelector",
	get:
		({ priceModelId, featureId, packId, inputType }) =>
		({ get }) => {
			if (priceModelId && packId && featureId) {
				return get(boosterStateAtom)?.[priceModelId]?.[packId]?.[featureId];
			} else return null;
		},
	set:
		({ priceModelId, featureId, packId, inputType }) =>
		({ get, set }, { booster, numerical, cache }) => {
			const atom = JSON.parse(JSON.stringify(get(boosterStateAtom)));

			const {
				price,
				score,
				value,
				from,
				to,
				isUnlimited,
				valueId,
				switchId,
				description,
				defaultBoosterId,
				numericalInputValue,
				levelDataId,
			} = booster || {};

			if (booster) {
				if (!!cache) {
					const levelWithLink = cache.readFragment({
						id: cache.identify({
							packId,
							featureId,
							__typename: "Level",
						}),
						fragment: LEVEL_IS_LINKED_FRAGMENT,
						fragmentName: "LevelIsLinkedFragment",
					});

					if (levelWithLink.isLinked) {
						const priceModelWithPacks = cache.readFragment({
							id: cache.identify({
								__typename: "PriceModel",
								id: priceModelId,
							}),
							fragment: PRICE_MODEL_FOR_PACK_UPDATE_FRAGMENT,
							fragmentName: "PriceModelForPackUpdate",
						});

						const packs = priceModelWithPacks.packs;
						packs
							.filter(pack => pack.id !== packId)
							.forEach(pack => {
								const levelIdentify = cache.identify({
									packId: pack.id,
									featureId,
									__typename: "Level",
								});
								const levelWithLink = cache.readFragment({
									id: levelIdentify,
									fragment: LEVEL_IS_LINKED_FRAGMENT,
									fragmentName: "LevelIsLinkedFragment",
								});
								if (levelWithLink.isLinked) {
									if (!atom?.[priceModelId]) {
										atom[priceModelId] = {};
									}
									if (!atom[priceModelId]?.[pack.id]) {
										atom[priceModelId][pack.id] = {};
									}
									atom[priceModelId][pack.id][featureId] = booster;
								}
							});
					}

					if (!atom?.[priceModelId]) {
						atom[priceModelId] = {};
					}
					if (!atom[priceModelId]?.[packId]) {
						atom[priceModelId][packId] = {};
					}
					atom[priceModelId][packId][featureId] = booster;

					set(boosterStateAtom, atom);
				} else if (inputType === "2") {
					set(boosterStateAtom, {
						...(atom || {}),
						[`feature:${featureId}`]: {
							...(atom?.[`feature:${featureId}`] || {}),
							[`pack:${packId}`]: {
								inputType,
								price,
								score,
								value,
								valueId,
								switchId,
								description,
								defaultBoosterId,
							},
						},
						[priceModelId]: {
							...(atom?.[priceModelId] || {}),
							[packId]: {
								...(atom?.[priceModelId]?.[packId] || {}),
								[featureId]: {
									inputType,
									price,
									score,
									value,
									valueId,
									switchId,
									description,
									defaultBoosterId,
								},
							},
						},
					});
				} else if (inputType === "3") {
					set(boosterStateAtom, {
						...(atom || {}),
						[`feature:${featureId}`]: {
							...(atom?.[`feature:${featureId}`] || {}),
							[`pack:${packId}`]: {
								inputType,
								price,
								score,
								value,
								valueId,
								description,
								defaultBoosterId,
							},
						},
						[priceModelId]: {
							...(atom?.[priceModelId] || {}),
							[packId]: {
								...(atom?.[priceModelId]?.[packId] || {}),
								[featureId]: {
									inputType,
									price,
									score,
									value,
									valueId,
									description,
									defaultBoosterId,
								},
							},
						},
					});
				} else if (inputType === "4") {
					const { isRange, pluralMetric, timeMetric, unitMetric, displayedAs } =
						numerical || {};
					set(boosterStateAtom, {
						...(atom || {}),
						[`feature:${featureId}`]: {
							...(atom?.[`feature:${featureId}`] || {}),
							[`pack:${packId}`]: {
								inputType,
								price,
								score,
								valueId,
								from,
								to,
								isUnlimited,
								isRange,
								pluralMetric,
								timeMetric,
								unitMetric,
								description,
								defaultBoosterId,
								numericalInputValue,
								displayedAs,
								featureId,
								levelDataId,
								value,
							},
						},
						[priceModelId]: {
							...(atom?.[priceModelId] || {}),
							[packId]: {
								...(atom?.[priceModelId]?.[packId] || {}),
								[featureId]: {
									inputType,
									price,
									score,
									valueId,
									from,
									to,
									isUnlimited,
									isRange,
									pluralMetric,
									timeMetric,
									unitMetric,
									description,
									defaultBoosterId,
									numericalInputValue,
									displayedAs,
									featureId,
									levelDataId,
									value,
								},
							},
						},
					});
				}
			}
		},
});

export const removeBoosterByPMIdAndFeatureIdAndPackIdAndInputTypeSelector = selectorFamily({
	key: "removeBoosterByPriceModelIdAndDiscountIdSelector",
	set:
		({ priceModelId, featureId, packId }) =>
		({ get, set }) => {
			const atom = get(boosterStateAtom);

			const { [featureId]: removedProperty, ...newAtom } =
				atom?.[priceModelId]?.[packId] || {};

			set(boosterStateAtom, {
				...(atom || {}),
				[priceModelId]: {
					...(atom?.[priceModelId] || {}),
					[packId]: newAtom,
				},
				[`feature:${featureId}`]: {
					...(atom?.[`feature:${featureId}`] || {}),
					[`pack:${packId}`]: newAtom[featureId],
				},
			});
		},
});

export const fetchBoosterSelector = selector({
	key: "fetchBoosterSelector",
	get: ({ get }) => 1,
	set: ({ get, set }, priceModel) => {
		const priceModelId = priceModel.id;

		const priceModelFeatureList = priceModel?.priceModelFeatures;

		const defaultBoosterValuesState = {};
		const defaultNumericalValueState = {};

		priceModelFeatureList?.forEach(priceModelFeature => {
			const feature = priceModelFeature?.feature;
			const feeBasedAttribute = priceModelFeature.feeBased;
			const priceModelFeatureId = priceModelFeature.id;

			const levelList = priceModelFeature?.level;
			const levelDataList = priceModelFeature?.levelData;

			levelList?.forEach(level => {
				const packId = level.packId;
				const defaultOption = levelDataList?.find(x => x.id === level?.levelDataId);

				const isThereAnyBoosterAvailable = levelDataList?.some(lvl => {
					if (defaultOption?.value?.index >= lvl.value.index) {
						if (!["tiered", "linear"].includes(feature?.value?.type)) {
							return false;
						} else if (feature?.value?.displayedAs !== "input") {
							return false;
						}
					}
					const currentLevelDataPack = lvl.levelDataPacks.find(
						levelDataPack => levelDataPack.packId === packId
					);
					return currentLevelDataPack?.isBoosterAllowed;
				});
				if (isThereAnyBoosterAvailable) {
					const { defaultBoosterId, defaultBoosterValue, levelDataId } = level || {};
					const boostLevel = levelDataList.find(lvlData => {
						return defaultBoosterId === lvlData.id;
					});

					const { score } = boostLevel || {};

					const value = boostLevel?.value;

					const { description, from, to, isUnlimited } = value || {};

					const levelDataPack = boostLevel?.levelDataPacks.find(
						lvl => lvl.packId === packId
					);

					const { isBoosterAllowed } = levelDataPack || {};
					const price = computeFeaturePriceService({
						priceModelFeature,
						level,
						boostLevel,
					});
					const booster = {
						price,
						score,
						isBoosterAllowed,
						value,
						description,
						from,
						to,
						isUnlimited,
						defaultBoosterId,
						inputType: feature?.inputTypeId,
						pluralMetric: feature?.value?.pluralMetric,
						unitMetric: feature?.value?.unitMetric,
						timeMetric: feature?.value?.timeMetric,
						isRange: feature?.value?.isRange,
						feeBased: feeBasedAttribute,
						defaultBoosterValue,
						levelDataId,
						featureId: priceModelFeature?.id,
						numericalInputValue: defaultBoosterValue,
						displayedAs: feature?.value?.displayedAs,
					};
					if (packId && priceModelFeatureId && level?.defaultBoosterId) {
						if (!defaultBoosterValuesState[packId]) {
							defaultBoosterValuesState[packId] = {};
						}
						defaultBoosterValuesState[packId][priceModelFeatureId] = {
							...booster,
							valueId: level?.defaultBoosterId
								? level?.defaultBoosterId
								: level?.levelDataId,
						};
					}
				}
				if (feature?.value?.displayedAs === "input") {
					if (packId && priceModelFeatureId) {
						if (!defaultNumericalValueState[packId]) {
							defaultNumericalValueState[packId] = {};
						}
						defaultNumericalValueState[packId][priceModelFeatureId] =
							level?.defaultBoosterValue || level?.levelDataValue || 0;
					}
				}
			});
		});
		set(boosterStatesByPriceModelIdSelector(priceModelId), defaultBoosterValuesState);
		set(numericalValueFeatureByPriceModelIdSelector(priceModelId), defaultNumericalValueState);
	},
});
