import { css } from "styled-components";

export const customGap = space => css`
	& > * {
		margin-right: ${space + (isNaN(space) ? "" : "px")};

		&:last-child {
			margin-right: 0;
		}
	}
`;

export const verticalCustomGap = space => css`
	& > * {
		margin-bottom: ${space + (isNaN(space) ? "" : "px")};

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const verticalCustomPaddingGap = space => css`
	& > * {
		padding-bottom: ${space + (isNaN(space) ? "" : "px")};

		&:last-child {
			padding-bottom: 0;
		}
	}
`;
