import styled, {css} from "styled-components";

export const CategorizationIntro = styled.div`
	display: flex;
	flex-flow: wrap;
    font-size: 12px;
`;

export const CategorizationTitle = styled.div`
    
`;

export const WrapperButtonAndProgressBar = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin-top: 7px;
`;

export const ColoredSpan = styled.div`
	color: ${props => props.color};
	${props => props.rightSpace && css`
		margin-right: 3px;
	`};${props => props.leftSpace && css`
		margin-left: 3px;
	`};
`;

export const WrapperProgressBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
