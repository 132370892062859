import { gql, useMutation } from "@apollo/client";
import { GET_PERIOD_BY_ID } from "@hooks/usePeriod/graphQl/query";

const UPDATE_FIXED_COST = gql`
	mutation UpdateFixedCost($updateFixedCostInput: UpdateFixedCostInput!) {
		updateFixedCost(updateFixedCostInput: $updateFixedCostInput) {
			id
			periodId
			index
			name
			description
			cost
			vat
			vatDeductible
			variableCostPart
			variableCostPartType
			volumeAllocation
			revenueAllocation
			manualDispatching
			timePeriod
			createdBy
			updatedBy
			createdAt
			updatedAt
			categories {
				id
				name
				categorizationId
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
			products {
				id
				semiVariableCostParts {
					id
					value
					productId
					fixedCostId
				}
			}
		}
	}
`;

export const updateFixedCostMutation = () => {
	const [updateFixedCost] = useMutation(UPDATE_FIXED_COST, {
		refetchQueries: result => {
			return [
				{
					query: GET_PERIOD_BY_ID,
					variables: { id: result.data.updateFixedCost.periodId },
				},
			];
		},
	});
	return updateFixedCost;
};

const UPDATE_FIXED_COSTS = gql`
	mutation updateBulkFixedCosts($updateFixedCostsInput: [UpdateFixedCostInput]!) {
		updateBulkFixedCosts(updateFixedCostsInput: $updateFixedCostsInput) {
			id
			periodId
			index
			name
			description
			cost
			vat
			vatDeductible
			variableCostPart
			variableCostPartType
			volumeAllocation
			revenueAllocation
			manualDispatching
			timePeriod
			createdBy
			updatedBy
			createdAt
			updatedAt
			categories {
				id
				name
				categorizationId
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
			products {
				id
				semiVariableCostParts {
					id
					value
					productId
					fixedCostId
				}
			}
		}
	}
`;

export const updateFixedCostsMutation = () => {
	const [updateFixedCosts] = useMutation(UPDATE_FIXED_COSTS, {
		refetchQueries: result => {
			return [
				{
					query: GET_PERIOD_BY_ID,
					variables: { id: result.data.updateBulkFixedCosts[0].periodId },
				},
			];
		},
	});
	return updateFixedCosts;
};

const DELETE_FIXED_COSTS = gql`
	mutation DeleteFixedCosts($ids: [ID]!) {
		deleteFixedCosts(ids: $ids) {
			id
			periodId
		}
	}
`;

export const deleteFixedCostsMutation = () => {
	const [deleteFixedCosts] = useMutation(DELETE_FIXED_COSTS, {
		update(cache, { data: { deleteFixedCosts } }) {
			cache.modify({
				fields: {
					getFixedCostsByPeriodId(existingFixedCosts = [], { readField }) {
						return [...existingFixedCosts].filter(
							element =>
								deleteFixedCosts.findIndex(
									fixedCost => fixedCost === readField("id", element)
								) === -1
						);
					},
					getPeriodById(existingPeriod) {
						// Find a better solution to update the cache
					},
				},
			});
		},
	});
	return deleteFixedCosts;
};
