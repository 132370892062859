import styled, { css } from "styled-components";
import { Icon, Button } from "priceit-ui";

export const Wrapper = styled.div`
	padding-left: 25px;
	display: flex;
	align-items: center;
	width: 100%;
`;

export const WrapperCross = styled.div`
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 1px;
	height: 100%;
	width: 20%;
	border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;
	z-index: 1;
	&:hover {
		border: 1px solid ${props => props.hoverColor};
		background-color: ${props => props.hoverColor};
	}
`;

export const Cross = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;
	opacity: 0.1;
	border: 1px solid ${props => props.hoverColor};
	background-color: ${props => props.hoverColor};
	cursor: pointer;
	z-index: 3;
`;

export const HoverActionIcon = styled(Icon)`
	margin: auto;
	fill: ${props => props.iconColorFill};
	height: 8px;
	transition: 0.2s all ease-out;
	${WrapperCross}:hover & {
		transform: translate(1px, 1px);
	}
`;

export const ProductName = styled.div`
	padding-left: 20px;
	//width: 80%;
	margin: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
`;

export const CategoryName = styled.div`
	padding-left: 20px;
	width: 80%;
	margin: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
	color: #fff;
`;

export const FullRow = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 100%;
`;

export const Container = styled.div`
	height: 30px;
	width: 165px;
	margin-left: 10px;
`;

export const OptionsSeparator = styled.div`
	min-width: 1px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #495965;
`;

export const CategoryBubble = styled.div`
	margin-left: 10px;
	height: 100%;
	border-radius: 20px;
	font-size: 14px;
	font-style: italic;
	padding-left: 5px;
	display: flex;
	justify-content: space-between;
	color: #fff;
	background-color: ${props => props.categoryColor};
`;

export const ProductBubble = styled.div`
	margin-left: 10px;
	height: 100%;
	border: 0.5px solid #cbcbcb;
	border-radius: 20px;
	font-size: 14px;
	padding-left: 5px;
	display: flex;
	justify-content: space-between;
	color: #cbcbcb;
`;

export const WrapperProductsBarCustomHeader = styled.div``;
