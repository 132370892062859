import styled from "styled-components";
import { Button, SingleSelect } from "priceit-ui";

export const LanguageSelectWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const LanguageSingleSelect = styled(SingleSelect.Group)`
	flex: 1;
`;

export const SearchTemplateLabel = styled.div`
	color: ${({ theme }) => theme.primaryColor};
`;

export const CategoriesSelectWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > div {
		:not(:last-child) {
			margin: 0 0 25px;
		}
	}
`;

export const CategorySelectWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const SelectHeader = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.secondaryTextColor};
`;

export const StyledButton = styled(Button)`
	padding: 0 0;
`;
