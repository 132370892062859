import React from "react";

import { useTranslation } from "react-i18next";

import {
	Counter,
	Label,
	StyledInput,
	WrapperLabelAndCounter,
	WrapperLabelAndInput,
} from "./LabelAndInputComponent.style";

export const LabelAndInputComponent = React.memo(
	({
		width,
		label,
		maxLength,
		hideMaxLength,
		value,
		setValue,
		error,
		errorTooltipContent,
		onFocus,
		onBlur,
		disabled,
		autoFocus,
		mode,
		isMandatory,
		placeholder,
		hidden = false,
		fontWeight = 400,
		type = "text",
	}) => {
		const { t } = useTranslation("generic");
		return (
			<WrapperLabelAndInput width={width}>
				<WrapperLabelAndCounter disabled={disabled} hidden={hidden}>
					<Label>
						{isMandatory ? "*" : null}
						{label}
						{!isMandatory ? " " + t("(optional)") : null}
					</Label>
					{maxLength && !hideMaxLength ? (
						<Counter>
							{value?.length ?? 0}/{maxLength}
						</Counter>
					) : null}
				</WrapperLabelAndCounter>
				<StyledInput
					type={type}
					error={error}
					errorTooltipContent={errorTooltipContent}
					maxLength={maxLength ?? undefined}
					mode={mode}
					width={"100%"}
					height="30px"
					value={value}
					onChange={e => setValue(e?.target?.value)}
					tooltipContentShowPasswordEye={null}
					tooltipContentHidePasswordEye={null}
					onFocus={onFocus}
					onBlur={onBlur}
					autoShowTooltip
					disabled={disabled}
					autoFocus={autoFocus}
					fontWeight={fontWeight}
					placeholder={placeholder}
				/>
			</WrapperLabelAndInput>
		);
	}
);
LabelAndInputComponent.whyDidYouRender = true;
LabelAndInputComponent.displayName = "LabelAndInputComponent";
