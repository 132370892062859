import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Tablev3, Tooltipv3, Icon } from "priceit-ui";
import { useTranslation, Trans } from "react-i18next";
import {
	computeUnitFixedCosts,
	computeUnitNetPrice,
	computeUnitNetProfit,
	searchProducts,
	computeGrossProfit,
} from "@components/productsDrawer/services/ProductsDrawer.service";
import { ProgressBar } from "@components/progressBar/ProgressBar";

import { currentPeriodIdVar } from "@src/cache/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { suffixMetrics } from "@services/text";
import { CenteredSpan, CenteredDiv, EllipsisDiv, WrapperTable } from "../TableView.style";
import { periodMultiplier } from "@services/periodMultiplier";
import moment from "moment";
import { productsComputation } from "@services/computation";
import { StyledInputNumber } from "../../secondLineHeader/SecondLineHeader.style";
import {
	WrapperRuleBasedAllocation,
	WrapperInputs,
	LabelInput,
	LinkIcon,
	InfoIcon,
	WrapperVariableCostColumnHeader,
	HeaderCategorization,
	TooltipContent,
	WrapperInfoIcon,
	WrapperCategoryName,
	TitleCategoryText,
	WrapperHeaderCategorization,
	MissingDataTooltip,
} from "./FixedCostView.style";
import {
	allocatedVariableCost,
	computeVariableCostPartAmount,
} from "@pages/myBusinessModel/fixedCosts/service/FixedCosts.service";
import { useFixedCosts } from "@hooks/useFixedCosts/UseFixedCosts";
import { useSaveTable } from "@hooks/useSaveTable/UseSaveTable";
import { textColor } from "@src/services/textColor";
import RelatedVolumeHeader from "@components/productsDrawer/components/TableViews/fixedCostView/headers/RelatedVolumeHeader";
import { priceMetricsObj, priceMetricTrad } from "@services/i18n";
import { getMinWidthColumn } from "@services/minWidthColumn";
import { GET_CATEGORIZATION_BY_PERIOD_ID } from "@src/graphQl/queries/categorizationNewQuery";

export const FixedCostView = React.memo(
	({
		data,
		searchInput,
		period,
		productsSelected,
		onChangeProductsSelected,
		loadingProducts,
		periodType,
		products,
	}) => {
		const { t } = useTranslation("drawer/page");
		const { updateFixedCost } = useFixedCosts();
		const currentPeriodId = useReactiveVar(currentPeriodIdVar);
		const priceMetrics = priceMetricsObj();

		const {
			loading: loadingCategorization,
			error: errorCategorization,
			data: dataCategorization,
		} = useQuery(GET_CATEGORIZATION_BY_PERIOD_ID, {
			skip: currentPeriodId === "0",
			variables: {
				id: currentPeriodId,
			},
		});

		const { saveTable, initialTableState, loadingTableProps } = useSaveTable({
			tableName: "fixedCostView",
		});

		const [sortByState, setSortByState] = useState([]);
		const [hiddenColumnsState, setHiddenColumnsState] = useState([]);
		const [resizeState, setResizeState] = useState({ columnWidths: {} });

		const sortByCallBack = useCallback(sortBy => setSortByState(sortBy), []);
		const hiddenCallBack = useCallback(
			hiddenColumns => setHiddenColumnsState(hiddenColumns),
			[]
		);
		const resizeCallBack = useCallback(
			resize => setResizeState(resize.columnWidths),
			[resizeState]
		);

		useEffect(() => {
			if (initialTableState.sortBy) {
				setSortByState(initialTableState.sortBy);
			}
		}, [initialTableState.sortBy]);

		useEffect(() => {
			if (initialTableState.hiddenColumn) {
				setHiddenColumnsState(initialTableState.hiddenColumn);
			}
		}, [initialTableState.hiddenColumn]);

		useEffect(() => {
			if (initialTableState.resize.columnWidths) {
				setResizeState(initialTableState.resize.columnWidths);
			}
		}, [initialTableState.resize]);

		useEffect(() => {
			setSortByState([]);
			setHiddenColumnsState([]);
			setResizeState({ columnWidths: {} });
		}, [currentPeriodId]);

		// const { volumeAllocation, revenueAllocation } = cache.readFragment({
		// 	id: `FixedCost:${data.currentFixedCostRow?.row?.state?.id}`,
		// 	fragment: gql`
		// 		fragment MyFixedCostAllocation on FixedCost {
		// 			volumeAllocation
		// 			revenueAllocation
		// 		}
		// 	`,
		// });
		const { volumeAllocation, revenueAllocation } = data.fixedCost;

		useEffect(() => {
			if (
				[undefined, null, NaN].includes(volumeAllocation) &&
				[undefined, null, NaN].includes(revenueAllocation)
			) {
				updateFixedCost({
					variables: {
						updateFixedCostInput: {
							id: data?.currentFixedCostRow?.row?.state?.id,
							volumeAllocation: 50,
							revenueAllocation: 50,
						},
					},
				});
			}
		}, [volumeAllocation, revenueAllocation]);

		const isSelectedProduct = product => !!productsSelected.find(prd => prd.id === product.id);

		const endDate = moment(period?.endDate);
		const startDate = moment(period?.startDate);
		const monthDifference = endDate.diff(startDate, "months") + 1;

		const listOfProducts = useMemo(() => {
			if (products) {
				return searchProducts(searchInput, [...products]);
			}
			return [];
		}, [products, searchInput]);

		const completeListOfProducts = useMemo(() => {
			if (products) {
				return [...products];
			}
			return [];
		}, [products]);

		const kpiProductsSelected = useMemo(() => {
			return productsComputation(productsSelected || [], monthDifference);
		}, [productsSelected, monthDifference]);

		const totalComputationalData = productsComputation(
			completeListOfProducts || [],
			monthDifference
		);

		const customSort = useMemo((a, b) => {
			if (Math.abs(a) > Math.abs(b)) {
				return 1;
			}
			if (Math.abs(a) < Math.abs(b)) {
				return -1;
			}
			return 0;
		}, []);

		const columns = useMemo(
			() => [
				{
					Header: t("PRICE DESCRIPTION"),
					sticky: "left",
					columns: [
						{
							Header: t("ID"),
							accessor: "id",
							width:
								resizeState.columnWidths?.id ||
								initialTableState?.resize?.columnWidths?.id ||
								60,
							Cell: item => item.row.index + 1,
						},
						{
							Header: t("Price point name"),
							accessor: "name",
							width:
								resizeState.columnWidths?.name ||
								initialTableState?.resize?.columnWidths?.name ||
								150,
							minWidth: getMinWidthColumn("result"),
							Cell: element => <EllipsisDiv>{element.value}</EllipsisDiv>,
						},
						{
							Header: t("Description"),
							accessor: "description",
							width:
								resizeState.columnWidths?.description ||
								initialTableState?.resize?.columnWidths?.description ||
								150,
							minWidth: getMinWidthColumn("result"),
							Cell: element => <EllipsisDiv>{element.value}</EllipsisDiv>,
						},
					],
				},
				{
					Header: () => (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div>{t("CATEGORIES")}</div>
						</div>
					),
					accessor: "productsAndServices",
					columns: [
						...[...(dataCategorization?.getCategorizationByPeriodId || [])]
							.sort((a, b) => a.id - b.id)
							.map(cat => {
								return {
									Header: () => {
										return (
											<WrapperHeaderCategorization>
												<HeaderCategorization
													primary={cat.description ? 0 : 1}
												>
													{cat.name}
												</HeaderCategorization>
												<HeaderCategorization
													primary={cat.description ? 1 : 0}
												>
													{cat.description}
												</HeaderCategorization>
											</WrapperHeaderCategorization>
										);
									},
									id: "categorization-" + cat.name,
									minWidth: getMinWidthColumn("dropdown"),
									width:
										resizeState.columnWidths?.[`categorization-${cat.name}`] ||
										initialTableState?.resize?.columnWidths?.[
											`categorization-${cat.name}`
										] ||
										200,
									accessor: originalRow => {
										const selected = cat.category?.find(x =>
											originalRow?.categories?.map(y => y.id)?.includes(x.id)
										);
										return selected?.name;
									},
									hiddenName:
										cat.name + (cat.description ? " - " + cat.description : ""),
									centerHeader: true,
									centerBody: true,
									paddingBodyCell: "0px",
									Cell: item => {
										const selected = cat.category?.find(x =>
											item?.row.state?.categories
												?.map(y => y.id)
												?.includes(x.id)
										);
										return (
											<WrapperCategoryName
												color={textColor(selected?.color)}
												backgroundColor={selected?.color}
											>
												<TitleCategoryText>
													{selected?.name}
												</TitleCategoryText>
											</WrapperCategoryName>
										);
									},
								};
							})
							.flat(2),
					],
				},
				{
					Header: () => {
						const [volumeAllocationState, setVolumeAllocation] = useState(50);
						const [revenueAllocationState, setRevenueAllocation] = useState(50);

						useEffect(() => {
							if (
								![undefined, null, NaN].includes(volumeAllocation) &&
								![undefined, null, NaN].includes(revenueAllocation)
							) {
								setVolumeAllocation(volumeAllocation);
								setRevenueAllocation(revenueAllocation);
							}
						}, [volumeAllocation, revenueAllocation]);

						const volumeMetricsForSelectedProducts = productsSelected?.map(
							product => product.volumeMetric
						);
						const uniqueVolumeMetrics = volumeMetricsForSelectedProducts
							?.filter((volumeMetric, i, self) => self.indexOf(volumeMetric) === i)
							?.filter(volumeMetric => !!volumeMetric);
						const showWarning =
							uniqueVolumeMetrics?.length > 1 && volumeAllocationState !== 0;

						return (
							<WrapperRuleBasedAllocation>
								<div style={{ marginRight: "5px" }}>
									{t("RULE BASED ALLOCATION:")}
								</div>
								<Tooltipv3
									placement="top"
									mode="dark"
									type="primary"
									maxWidth="350px"
									appendTo={document.body}
									content={
										<TooltipContent>
											{t(
												"Semi-variable costs will be allocated to products based on a mix of the volume and revenue corresponding to those products."
											)}
										</TooltipContent>
									}
									interactive={false}
								>
									<WrapperInfoIcon>
										<InfoIcon type="info" marginRight={0} />
									</WrapperInfoIcon>
								</Tooltipv3>
								<WrapperInputs>
									<LabelInput>
										{t("volume")}
										{":"}
									</LabelInput>
									<StyledInputNumber
										mode="dark"
										theme={{
											textAlign: "center",
											inputtextcolor: "white",
										}}
										margin={"0 10px 0 5px"}
										width="75px"
										height="30px"
										suffix="%"
										value={volumeAllocationState}
										warning={showWarning}
										errorTooltipContent={
											<TooltipContent>
												{
													"Please note that you are reallocating costs based on a mix of revenue generated and volume. Because your products don't have the same metric, comparing their volume might not make sense."
												}
											</TooltipContent>
										}
										isAllowed={e =>
											(e.floatValue >= 0 && e.floatValue <= 100) ||
											e.formattedValue === ""
										}
										onChange={e => {
											setVolumeAllocation(e.floatValue);
											setRevenueAllocation(100 - e.floatValue);

											updateFixedCost({
												variables: {
													updateFixedCostInput: {
														id: data?.currentFixedCostRow?.row?.state
															?.id,
														volumeAllocation: e.floatValue,
														revenueAllocation: 100 - e.floatValue,
													},
												},
											});
										}}
										enableDelay
										delay={300}
									/>
									<LinkIcon type="link3" />
									<LabelInput color="#FFCD38">
										{t("revenue")}
										{":"}
									</LabelInput>
									<StyledInputNumber
										mode="dark"
										theme={{
											textAlign: "center",
											inputtextcolor: "white",
										}}
										margin={"0 0 0 5px"}
										width="75px"
										height="30px"
										suffix="%"
										value={revenueAllocationState}
										isAllowed={e =>
											(e.floatValue >= 0 && e.floatValue <= 100) ||
											e.formattedValue === ""
										}
										onChange={e => {
											setVolumeAllocation(100 - e.floatValue);
											setRevenueAllocation(e.floatValue);

											updateFixedCost({
												variables: {
													updateFixedCostInput: {
														id: data?.currentFixedCostRow?.row?.state
															?.id,
														volumeAllocation: 100 - e.floatValue,
														revenueAllocation: e.floatValue,
													},
												},
											});
										}}
										enableDelay
										delay={500}
									/>
								</WrapperInputs>
							</WrapperRuleBasedAllocation>
						);
					},
					headerDependencies: [productsSelected],
					accessor: "ruleBasedAllocation",
					columns: [
						{
							Header: t("Allocated semi-variable"),
							accessor: "allocatedSemiVariableColumn",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.allocatedSemiVariableColumn ||
								initialTableState?.resize?.columnWidths
									?.allocatedSemiVariableColumn ||
								220,
							minWidth: getMinWidthColumn("result"),
							dependencies: [
								productsSelected?.length,
								revenueAllocation,
								volumeAllocation,
								periodType,
								data?.fixedCost.variableCostPart,
							],
							Cell: item => {
								const coefficient =
									(periodMultiplier(periodType) || monthDifference) /
									(periodMultiplier(item.row.state.timeMetric) ||
										monthDifference);

								const semiVariableCost =
									item.row.state?.semiVariableCostParts?.find(
										element =>
											element.fixedCostId ===
											data?.currentFixedCostRow?.row?.state?.id
									);

								return (
									<CenteredSpan
										color={
											!semiVariableCost?.value ||
											semiVariableCost?.value === 0 ||
											!isSelectedProduct(item?.row.state)
												? "#A5B9C8"
												: "#00CBFF"
										}
									>
										{(isSelectedProduct(item?.row.state)
											? (semiVariableCost?.value || 0) *
												item.row.state.volume *
												coefficient
											: 0
										).fnb({
											stringText: true,
											zeroIfNan: true,
											withCurrency: true,
											decimal: 2,
										})}
									</CenteredSpan>
								);
							},
						},
						{
							Header: t("% of the total variable part"),
							accessor: "percentTotalVariablePartColumn",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.percentTotalVariablePartColumn ||
								initialTableState?.resize?.columnWidths
									?.percentTotalVariablePartColumn ||
								175,
							minWidth: getMinWidthColumn("progressBar"),
							dependencies: [
								productsSelected?.length,
								revenueAllocation,
								volumeAllocation,
								periodType,
								data?.fixedCost.variableCostPart,
							],
							Cell: item => {
								const variableCostPartAmount = computeVariableCostPartAmount(
									data?.fixedCost,
									periodType,
									monthDifference
								);
								const coefficient =
									(periodMultiplier(periodType) || monthDifference) /
									(periodMultiplier(item.row.state.timeMetric) ||
										monthDifference);

								const semiVariableCost =
									item.row.state?.semiVariableCostParts?.find(
										element =>
											element.fixedCostId ===
											data?.currentFixedCostRow?.row?.state?.id
									);

								const percentSemiVarTotal =
									(((semiVariableCost?.value || 0) *
										item.row.state.volume *
										coefficient) /
										(variableCostPartAmount || 1)) *
									100;

								return (
									<CenteredDiv>
										<ProgressBar
											percent={
												isSelectedProduct(item?.row.state)
													? percentSemiVarTotal
													: 0
											}
											fillColor={
												!isSelectedProduct(item?.row.state) ||
												percentSemiVarTotal === 0
													? "#A5B9C8"
													: "#00CBFF"
											}
										/>
									</CenteredDiv>
								);
							},
						},
						{
							Header: t("Unit allocated variable part"),
							accessor: originalRow => {
								const coefficient =
									(periodMultiplier(periodType) || monthDifference) /
									(periodMultiplier(originalRow?.timeMetric) || monthDifference);
								return (
									computeUnitFixedCosts(originalRow, period) *
									originalRow.volume *
									coefficient
								);
							},
							id: "unitAllocatedVariablePartColumn",
							width:
								resizeState.columnWidths?.unitAllocatedVariablePartColumn ||
								initialTableState?.resize?.columnWidths
									?.unitAllocatedVariablePartColumn ||
								175,
							centerHeader: true,
							centerCell: true,
							dependencies: [
								productsSelected?.length,
								revenueAllocation,
								volumeAllocation,
								periodType,
								data?.fixedCost.variableCostPart,
								kpiProductsSelected,
							],
							minWidth: getMinWidthColumn("result"),
							Cell: item => {
								useEffect(() => {
									if (item?.row?.state?.id) {
										const { allocatedSemiVariableCost } = allocatedVariableCost(
											data?.fixedCost,
											periodType,
											monthDifference,
											item?.row?.state,
											kpiProductsSelected
										);

										const coefficient =
											(periodMultiplier(periodType) || monthDifference) /
											(periodMultiplier(item?.row.state?.timeMetric) ||
												monthDifference);

										const allocatedUnitSemiVariableCost =
											allocatedSemiVariableCost /
											(item?.row.state?.volume * coefficient);

										let semiVariableCostsState =
											item.row.state?.semiVariableCostParts?.map(element => {
												return {
													...element,
												};
											});
										const semiVariableCostFromCacheIndex =
											semiVariableCostsState?.findIndex(
												element =>
													element.fixedCostId ===
													data?.currentFixedCostRow?.row?.state?.id
											);

										if (
											semiVariableCostFromCacheIndex !== -1 &&
											allocatedUnitSemiVariableCost !==
												semiVariableCostsState[
													semiVariableCostFromCacheIndex
												].value
										) {
											if (!isSelectedProduct(item?.row.state)) {
												semiVariableCostsState.splice(
													semiVariableCostFromCacheIndex,
													1
												);
												item.row.setState(oldState => {
													return {
														...oldState,
														semiVariableCostParts:
															semiVariableCostsState,
													};
												});
											} else {
												semiVariableCostsState[
													semiVariableCostFromCacheIndex
												].value = allocatedUnitSemiVariableCost;
												item.row.setState(oldState => {
													return {
														...oldState,
														semiVariableCostParts:
															semiVariableCostsState,
													};
												});
											}
										} else if (
											semiVariableCostFromCacheIndex === -1 &&
											isSelectedProduct(item?.row.state)
										) {
											semiVariableCostsState.push({
												productId: item.row.state.id,
												fixedCostId:
													data?.currentFixedCostRow?.row?.state?.id,
												value: allocatedUnitSemiVariableCost,
											});
											item.row.setState(oldState => {
												return {
													...oldState,
													semiVariableCostParts: semiVariableCostsState,
												};
											});
										}
									}
								}, [
									productsSelected?.length,
									revenueAllocation,
									volumeAllocation,
									periodType,
									item?.row?.state?.id,
									kpiProductsSelected,
								]);

								const semiVariableCost =
									item.row.state?.semiVariableCostParts?.find(
										element =>
											element.fixedCostId ===
											data?.currentFixedCostRow?.row?.state?.id
									);

								return (
									<CenteredSpan
										color={
											!semiVariableCost?.value ||
											semiVariableCost?.value === 0 ||
											!isSelectedProduct(item?.row.state)
												? "#A5B9C8"
												: "#00CBFF"
										}
									>
										{(isSelectedProduct(item?.row.state)
											? semiVariableCost?.value || 0
											: 0
										).fnb({
											stringText: true,
											zeroIfNan: true,
											withCurrency: true,
											decimal: 2,
										}) +
											suffixMetrics({
												...item.row.original,
											})}
									</CenteredSpan>
								);
							},
						},
					],
				},
				{
					Header: t("VOLUME"),
					columns: [
						{
							Header: () => (
								<RelatedVolumeHeader
									productsSelected={productsSelected}
									volumeAllocation={volumeAllocation}
								/>
							),
							hiddenName: t("Related volume"),
							accessor: "volume",
							width:
								resizeState.columnWidths?.volume ||
								initialTableState?.resize?.columnWidths?.volume ||
								180,
							centerHeader: true,
							centerCell: true,
							headerDependencies: [productsSelected, volumeAllocation],
							dependencies: [periodType],
							minWidth: getMinWidthColumn("result"),
							Cell: element => {
								const item = element.row.original;
								return (
									<CenteredSpan>
										{item.volume
											? `${item.volume.fnb({
													stringText: true,
													zeroIfNan: true,
													withCurrency: false,
													decimal: 2,
												})} ${priceMetricTrad({
													priceMetricList: priceMetrics,
													priceMetric: item.volumeMetric,
													count: item.volume || 1,
												})}`
											: "-"}
									</CenteredSpan>
								);
							},
						},
						{
							Header: () => <CenteredDiv>{t("% of the total volume")}</CenteredDiv>,
							hiddenName: t("% of the total volume"),
							accessor: "percentageOfTotalVolume",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.percentageOfTotalVolume ||
								initialTableState?.resize?.columnWidths?.percentOfTotalVolume ||
								220,
							minWidth: getMinWidthColumn("progressBar"),
							Cell: element => {
								const item = element.row.original;
								const percentOfTotalVolume =
									(item.volume / totalComputationalData.volume) * 100;
								return (
									<CenteredDiv>
										<ProgressBar
											percent={percentOfTotalVolume}
											fillColor={"#A5B9C8"}
										/>
									</CenteredDiv>
								);
							},
						},
					],
				},
				{
					Header: t("REVENUE"),
					columns: [
						{
							Header: t("Related revenue"),
							accessor: originalRow => {
								const coefficient =
									(periodMultiplier(periodType) || monthDifference) /
									(periodMultiplier(originalRow.timeMetric) || monthDifference);
								const revenue =
									originalRow.netPriceExclVAT * originalRow.volume * coefficient;
								return revenue;
							},
							id: "revenue",
							width:
								resizeState.columnWidths?.revenue ||
								initialTableState?.resize?.columnWidths?.revenue ||
								150,
							centerHeader: true,
							centerCell: true,
							minWidth: getMinWidthColumn("result"),
							Cell: element => (
								<CenteredDiv>
									{element.value
										? element.value.fnb({
												stringText: true,
												zeroIfNan: true,
												withCurrency: true,
												decimal: 2,
											})
										: "-"}
								</CenteredDiv>
							),
						},
						{
							Header: t("% of the total revenue"),
							accessor: originalRow => {
								const coefficient =
									monthDifference /
									(periodMultiplier(originalRow.timeMetric) || monthDifference);
								const revenue =
									originalRow.netPriceExclVAT * originalRow.volume * coefficient;
								const percentOfTotalRevenue =
									(revenue / totalComputationalData.revenue) * 100;
								return percentOfTotalRevenue;
							},
							id: "percentageOfTotalRevenue",
							width:
								resizeState.columnWidths?.percentageOfTotalRevenue ||
								initialTableState?.resize?.columnWidths?.percentageOfTotalRevenue ||
								220,
							centerHeader: true,
							centerCell: true,
							minWidth: getMinWidthColumn("progressBar"),
							Cell: element => {
								const coefficient =
									monthDifference /
									(periodMultiplier(element?.row.state?.timeMetric) ||
										monthDifference);
								const revenue =
									element?.row.state?.netPriceExclVAT *
									element?.row.state?.volume *
									coefficient;
								const percentOfTotalRevenue =
									(revenue / totalComputationalData.revenue) * 100;
								return (
									<CenteredDiv>
										<ProgressBar
											percent={percentOfTotalRevenue}
											fillColor={"#FDB432"}
										/>
									</CenteredDiv>
								);
							},
						},
					],
				},
				{
					Header: () => (
						<WrapperVariableCostColumnHeader>
							<Tooltipv3
								placement="top"
								mode="dark"
								type="primary"
								maxWidth="350px"
								content={
									<TooltipContent>
										{t(
											"The purely variable costs, as defined in the variable costs table."
										)}
									</TooltipContent>
								}
								interactive={false}
							>
								<InfoIcon type="info" marginRight={"5px"} />
							</Tooltipv3>
							<div>{t("VARIABLE COSTS")}</div>
						</WrapperVariableCostColumnHeader>
					),
					id: "variableCostColumnHeader",
					columns: [
						{
							Header: t("Unit variable costs"),
							accessor: "variableCosts",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.variableCosts ||
								initialTableState?.resize?.columnWidths?.variableCosts ||
								200,
							minWidth: getMinWidthColumn("result"),
							Cell: element => {
								const item = element.row.original;
								return (
									<CenteredSpan>
										{item.variableCosts
											? item.variableCosts.fnb({
													stringText: true,
													zeroIfNan: true,
													withCurrency: true,
													decimal: 2,
												}) +
												suffixMetrics({
													...item,
												})
											: "-"}
									</CenteredSpan>
								);
							},
						},
					],
				},
				{
					Header: t("GROSS PROFIT"),
					columns: [
						{
							Header: t("Gross unit profit"),
							accessor: "grossUnitProfit",
							width:
								resizeState.columnWidths?.grossUnitProfit ||
								initialTableState?.resize?.columnWidths?.grossUnitProfit ||
								220,
							centerHeader: true,
							centerCell: true,
							minWidth: getMinWidthColumn("result"),
							Cell: element => {
								const item = element.row.original;

								const grossProfit = computeGrossProfit(element.row.state);
								return (
									<CenteredSpan>
										{grossProfit.fnb({
											stringText: true,
											zeroIfNan: true,
											withCurrency: true,
											decimal: 2,
										}) +
											suffixMetrics({
												...item,
											})}
									</CenteredSpan>
								);
							},
						},
						{
							Header: t("Gross unit profit in % of the net price"),
							accessor: originalRow =>
								originalRow.grossUnitProfit / originalRow.netPriceExclVAT,
							id: "percentGrossProfit",
							width:
								resizeState.columnWidths?.percentGrossProfit ||
								initialTableState?.resize?.columnWidths?.percentGrossProfit ||
								220,
							centerHeader: true,
							centerCell: true,
							minWidth: getMinWidthColumn("progressBar"),
							Cell: element => {
								const grossProfit = computeGrossProfit(element.row.state);
								return (
									<CenteredDiv>
										<ProgressBar
											percent={
												(grossProfit / element.row.state.netPriceExclVAT) *
												100
											}
											fillColor={"#a5b9c8"}
											negative
										/>
									</CenteredDiv>
								);
							},
						},
					],
				},
				{
					Header: t("NET PROFIT"),
					columns: [
						{
							Header: t("Unit net profit"),
							accessor: "unitNetProfit",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.unitNetProfit ||
								initialTableState?.resize?.columnWidths?.unitNetProfit ||
								220,
							dependencies: [periodType],
							sortType: (rowA, rowB) =>
								customSort(
									computeUnitNetProfit(rowA.original, period),
									computeUnitNetProfit(rowB.original, period)
								),
							minWidth: getMinWidthColumn("result"),
							Cell: element => {
								const item = element.row.original;
								return (
									<CenteredSpan style={{ margin: "0 20px" }}>
										{computeUnitNetProfit(item, period).fnb({
											stringText: true,
											zeroIfNan: true,
											withCurrency: true,
											decimal: 2,
										}) +
											suffixMetrics({
												...item,
											})}
									</CenteredSpan>
								);
							},
						},
						{
							Header: t("Unit net profit in % of the net price"),
							accessor: "percentNetProfitPrice",
							centerHeader: true,
							centerCell: true,
							width:
								resizeState.columnWidths?.percentNetProfitPrice ||
								initialTableState?.resize?.columnWidths?.percentNetProfitPrice ||
								220,
							minWidth: getMinWidthColumn("progressBar"),
							sortType: (rowA, rowB) =>
								customSort(
									computeUnitNetProfit(rowA.original, period) /
										computeUnitNetPrice(rowA.original),
									computeUnitNetProfit(rowB.original, period) /
										computeUnitNetPrice(rowB.original)
								),
							Cell: element => {
								const item = element.row.original;
								return (
									<CenteredDiv>
										<ProgressBar
											percent={
												(computeUnitNetProfit(item, period) /
													computeUnitNetPrice(item)) *
												100
											}
											fillColor={"#85E6D6"}
											negative
										/>
									</CenteredDiv>
								);
							},
						},
					],
				},
			],
			[
				productsSelected?.length,
				listOfProducts?.length,
				periodType,
				revenueAllocation,
				volumeAllocation,
				data?.fixedCost.variableCostPart,
				loadingTableProps,
				kpiProductsSelected,
			]
		);

		const rowSelection = React.useMemo(
			() => ({
				onChange: value => {
					onChangeProductsSelected(
						listOfProducts.filter(product => value.includes(product.id))
					);
				},
				selectedRowIds: productsSelected.map(x => x.id),
			}),
			[productsSelected]
		);

		return (
			<WrapperTable>
				<Tablev3
					columns={columns}
					mode={"dark"}
					data={listOfProducts}
					rowSelection={rowSelection}
					maxHeight={"calc(100vh - 290px)"}
					showHidingBox
					onUnload={currentState => saveTable({ currentState, columns })}
					initialState={{
						sortBy: sortByState,
						hiddenColumns: hiddenColumnsState || [
							"description",
							...[...(dataCategorization?.getCategorizationByPeriodId || [])]
								.sort((a, b) => a.id - b.id)
								.map((x, idx) => (idx !== 0 ? "categorization-" + x.name : null))
								.filter(x => x),
						],
						columnResizing: { columnWidths: resizeState },
					}}
					isLoading={!products || loadingProducts || loadingTableProps}
					callBackSortBy={sortByCallBack}
					callBackHiddenColumns={hiddenCallBack}
					callBackResize={resizeCallBack}
					editText={t("Edit")}
					sortText={t("Sort")}
					collapseText={t("Collapse")}
					hideText={t("Hide")}
					selectedRowsWording={t("selected line", {
						count: rowSelection?.selectedRowIds?.length,
					})}
					topErrorTooltipText={_ => (
						<Trans
							t={t}
							i18nKey="<MissingDataTooltip><h5>Some required data are missing.</h5><div><Icon type='lightedBulb' />Tip:</div>If you sort the data in this column, rows with missing data will appear at the bottom of the table.</MissingDataTooltip>"
							defaults="<MissingDataTooltip><h5>Some required data are missing.</h5><div><Icon type='lightedBulb' />Tip:</div>If you sort the data in this column, rows with missing data will appear at the bottom of the table.</MissingDataTooltip>"
							components={{
								br: <br />,
								MissingDataTooltip: <MissingDataTooltip />,
								Icon: <Icon fill="#404d57" />,
								h5: <h5 />,
								div: <div />,
							}}
						>
							<MissingDataTooltip>
								<h5>Some required data are missing.</h5>
								<div>
									<Icon type="lightedBulb" />
									Tip:
								</div>
								If you sort the data in this column, rows with missing data will
								appear at the bottom of the table.
							</MissingDataTooltip>
						</Trans>
					)}
				/>
			</WrapperTable>
		);
	},
	(prevProps, nextProps) => prevProps.gmfequals(nextProps)
);
FixedCostView.whyDidYouRender = true;
FixedCostView.displayName = "FixedCostView";
