import React, { useContext } from "react";
import { SingleSelect } from "priceit-ui";
import { SelectLinesTitle } from "@components/productsDrawer/components/LeftPanels/LeftPanel.style";
import { useTranslation } from "react-i18next";
import DispatchProductDrawerContext from "@components/productsDrawer/context/DispatchProductDrawerContext";
import { actions } from "@components/productsDrawer/reducer/reducer";

const RecurringSelection = ({ listOfProducts }) => {
	const { t } = useTranslation("productsDrawer/recurringSelection");
	const { state, dispatch } = useContext(DispatchProductDrawerContext);

	return (
		<>
			<SelectLinesTitle>{t("Select lines")}:</SelectLinesTitle>
			<SingleSelect.Group
				type="basic"
				mode="dark"
				center
				onChange={e => {
					dispatch({
						type: actions.UPDATE_RECURRING_SELECTION,
						value: e,
						listOfProducts,
					});
				}}
				value={state.recurringSelection}
				appendTo={document.body}
				width={"235px"}
				data={[
					{
						value: "recurring",
						option: (
							<SingleSelect.Option>{t("with recurring metric")}</SingleSelect.Option>
						),
					},
					{
						value: "nonRecurring",
						option: (
							<SingleSelect.Option>
								{t("with non-recurring metric")}
							</SingleSelect.Option>
						),
					},
					{
						value: "recurringAndNonRecurring",
						option: (
							<SingleSelect.Option>
								{t("with or without recurring metric")}
							</SingleSelect.Option>
						),
					},
				]}
			/>
		</>
	);
};

export default RecurringSelection;
