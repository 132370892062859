import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Loader, Style as PStyle } from "priceit-ui";
import { WrapperLoader } from "./Routes.style";
import "@src/services/NumberFormatter";
import { RecoilRoot } from "recoil";
import { Style } from "@light/index.style";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@components/errorBoundary/ErrorBoundary";
import CreateOfferWrapper from "@light/createOffer/createOfferWrapper";
import ClientOfferCreated from "@pages/clientsOffers/clientOfferCreated/ClientOfferCreated";
import { AuthenticatedRoute } from "@pages/workspaces/authenticatedRoute/AuthenticatedRoute";
import analytics from "../../../config/analytics/analytics";
import openReplayTracker from "../../../config/openReplay";

const Login = lazy(() => import("@pages/authentication/login/Login"));
const NotFoundPage = lazy(() => import("@src/components/404/NotFoundPage"));

const GlobalRoutes = () => {
	useEffect(() => {
		if (process.env.OPEN_REPLAY_PROJECT_KEY) {
			openReplayTracker.start();
		}
	}, []);

	const fontFamily = "Poppins";
	return (
		<RecoilRoot>
			<Suspense
				fallback={
					<WrapperLoader>
						<Loader />
					</WrapperLoader>
				}
			>
				<Sentry.ErrorBoundary
					fallback={({ componentStack, error, resetError }) => (
						<ErrorBoundary
							componentStack={componentStack}
							error={error}
							resetError={resetError}
						/>
					)}
				>
					<>
						<PStyle fontFamily={fontFamily} />
						<Style fontFamily={fontFamily} />
						<Routes>
							<Route path="/login" element={<Login />} />
							<Route
								path="/offercreated"
								element={
									<Sentry.ErrorBoundary
										fallback={({ componentStack, error, resetError }) => (
											<ErrorBoundary
												componentStack={componentStack}
												error={error}
												resetError={resetError}
											/>
										)}
									>
										<ClientOfferCreated />
									</Sentry.ErrorBoundary>
								}
							/>
							<Route
								path="/:workspaceId/createOffer"
								element={
									<AuthenticatedRoute>
										<CreateOfferWrapper />
									</AuthenticatedRoute>
								}
							/>
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</>
				</Sentry.ErrorBoundary>
			</Suspense>
		</RecoilRoot>
	);
};

export default GlobalRoutes;
