import { gql, useMutation } from "@apollo/client";
import { GET_PERIOD_BY_ID } from "./query";

const DELETE_PERIOD = gql`
	mutation DeletePeriods($ids: [ID]!) {
		deletePeriods(ids: $ids)
	}
`;

export const deletePeriodsMutation = () => {
	const [deletePeriods] = useMutation(DELETE_PERIOD, {
		update(cache, { data: { deletePeriods } }) {
			cache.modify({
				fields: {
					getPeriodsByWorkspaceId(existingPeriods = []) {
						return [...existingPeriods].filter(
							e =>
								deletePeriods.findIndex(
									period => `Period:${period}` === e.__ref
								) === -1
						);
					},
				},
			});
		},
	});
	return deletePeriods;
};

const UPDATE_PERIOD = gql`
	mutation UpdatePeriod($updatePeriodInput: UpdatePeriodInput!) {
		updatePeriod(updatePeriodInput: $updatePeriodInput) {
			name
			comment
			index
			id
			startDate
			endDate
			estimationFixedCost
			estimationProducts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			nbDistributions
			profitParameter {
				revenue {
					id
					realRevenue
					totalRevenueOneShot
					totalRevenueRecurring
					estimatedRevenue
					meanPrice
					totalVolume
					meanVariableCost
					totalVolumeOneShot
					totalVolumeRecurring
					totalVariableCostOneShot
					totalVariableCostRecurring
				}
				totalFixedCosts {
					id
					totalSemiVariableCostPart
					totalSemiVariableCostOneShot
					totalSemiVariableCostRecurring
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					id
					nbProfitability
					realProfit
					grossProfit
					estimatedProfit
					date
					user
				}
				variableCost {
					id
					variableCosts
					date
					user
				}
				potential {
					id
					nbSpectrum
					nbScenario
					nbFavorites
					date
					user
				}
				projection {
					id
					nbProjection
					nbFavorites
					date
					user
				}
				costBasedInformation {
					id
					nbCostBased
					nbFavorites
					date
					user
				}
			}
		}
	}
`;

export const updatePeriodMutation = () => {
	const [updatePeriod] = useMutation(UPDATE_PERIOD);
	return updatePeriod;
};

const CREATE_BULK_FIXED_COST = gql`
	mutation CreateBulkFixedCosts($periodId: ID!, $fixedCosts: [CreateFixedCostsInput]!) {
		createBulkFixedCosts(periodId: $periodId, fixedCosts: $fixedCosts) {
			id
			periodId
			index
			name
			description
			cost
			vat
			vatDeductible
			timePeriod
			createdBy
			updatedBy
			createdAt
			updatedAt
		}
	}
`;

export const createBulkFixedCostMutation = () => {
	const [createBulkFixedCost] = useMutation(CREATE_BULK_FIXED_COST, {
		refetchQueries: result => {
			return [
				{
					query: GET_PERIOD_BY_ID,
					variables: { id: result.data.createBulkFixedCosts[0].periodId },
				},
			];
		},
	});
	return createBulkFixedCost;
};

const DUPLICATE_PERIOD = gql`
	mutation ($duplicatePeriodInput: DuplicatePeriodInput!) {
		duplicatePeriod(duplicatePeriodInput: $duplicatePeriodInput) {
			name
			comment
			id
			startDate
			endDate
			estimationFixedCost
			estimationVariableCosts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			nbDistributions
			products {
				id
			}
			fixedCosts {
				id
			}
			profitParameter {
				revenue {
					realRevenue
					estimatedRevenue
					meanPrice
					totalVolume
				}
				totalFixedCosts {
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					realProfit
					estimatedProfit
					date
					user
				}
				variableCost {
					variableCosts
					date
					user
				}
				potential {
					nbSpectrum
					nbScenario
					date
					user
				}
				projection {
					nbProjection
					date
					user
				}
				costBasedInformation {
					nbCostBased
					nbFavorites
				}
			}
		}
	}
`;

export const duplicatePeriodMutation = () => {
	const [duplicatePeriod] = useMutation(DUPLICATE_PERIOD, {
		update(cache, { data: { duplicatePeriod } }) {
			cache.modify({
				fields: {
					getPeriodsByWorkspaceId(existingPeriods = []) {
						return [...existingPeriods, duplicatePeriod];
					},
				},
			});
		},
	});
	return duplicatePeriod;
};

const DUPLICATE_PERIODS = gql`
	mutation ($ids: [ID]!, $workspaceId: ID!) {
		duplicateBulkPeriods(ids: $ids, workspaceId: $workspaceId) {
			name
			comment
			id
			startDate
			endDate
			estimationFixedCost
			estimationVariableCosts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			nbDistributions
			products {
				id
			}
			fixedCosts {
				id
			}
			profitParameter {
				revenue {
					realRevenue
					estimatedRevenue
					meanPrice
					totalVolume
				}
				totalFixedCosts {
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					realProfit
					nbProfitability
					estimatedProfit
					date
					user
				}
				variableCost {
					variableCosts
					date
					user
				}
				potential {
					nbSpectrum
					nbScenario
					date
					user
				}
				projection {
					nbProjection
					date
					user
				}
				costBasedInformation {
					nbCostBased
					nbFavorites
					date
					user
				}
			}
		}
	}
`;

export const duplicateBulkPeriodsMutation = () => {
	const [duplicateBulkPeriods] = useMutation(DUPLICATE_PERIODS, {
		update(cache, { data: { duplicateBulkPeriods } }) {
			cache.modify({
				fields: {
					getPeriodsByWorkspaceId(existingPeriods = []) {
						return [...existingPeriods, ...duplicateBulkPeriods];
					},
				},
			});
		},
	});
	return duplicateBulkPeriods;
};

const UPDATE_USER_WORKSPACE_DEFAULT = gql`
	mutation UpdateUserWorkspaceDefault($updateUserWorkspaceDefault: UpdateUserWorkspaceDefault!) {
		updateUserWorkspaceDefault(updateUserWorkspaceDefault: $updateUserWorkspaceDefault) {
			id
			periodId
		}
	}
`;

export const updateUserWorkspaceDefaultMutation = () => {
	const [updateUserWorkspaceDefault] = useMutation(UPDATE_USER_WORKSPACE_DEFAULT);
	return updateUserWorkspaceDefault;
};

const CREATE_PRODUCT = gql`
	mutation CreateProduct($createProductInput: CreateProductInput!) {
		createProduct(createProductInput: $createProductInput) {
			id
		}
	}
`;

export const createProductMutation = () => {
	const [createProduct] = useMutation(CREATE_PRODUCT);
	return createProduct;
};

const CREATE_PROFITABILITY = gql`
	mutation CreateProfitability($createProfitabilityInput: CreateProfitabilityInput!) {
		createProfitability(createProfitabilityInput: $createProfitabilityInput) {
			id
		}
	}
`;

export const createProfitabilityMutation = () => {
	const [createProfitability] = useMutation(CREATE_PROFITABILITY);
	return createProfitability;
};

const CREATE_PROJECTION = gql`
	mutation CreateProjection($createProjectionInput: CreateProjectionInput!) {
		createProjection(createProjectionInput: $createProjectionInput) {
			id
		}
	}
`;

export const createProjectionMutation = () => {
	const [createProjection] = useMutation(CREATE_PROJECTION);
	return createProjection;
};

const CREATE_POTENTIAL = gql`
	mutation CreateMyPotential($createMyPotentialInput: CreateMyPotentialInput!) {
		createMyPotential(createMyPotentialInput: $createMyPotentialInput) {
			id
		}
	}
`;

export const createMyPotentialMutation = () => {
	const [createMyPotential] = useMutation(CREATE_POTENTIAL);
	return createMyPotential;
};

const CREATE_DISTRIBUTION = gql`
	mutation CreateDistribution($createDistributionInput: CreateDistributionInput!) {
		createDistribution(createDistributionInput: $createDistributionInput) {
			id
		}
	}
`;

export const createDistributionMutation = () => {
	const [createDistribution] = useMutation(CREATE_DISTRIBUTION);
	return createDistribution;
};
