import styled, { css } from "styled-components";
import { Icon, Tab } from "priceit-ui";
import { colors } from "@theme/userThemes/userColors";

export const WrapperProductsDrawer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #2b343b;
`;

export const WrapperColumns = styled.div`
	display: flex;
	width: 100%;
	height: calc(100vh - 235px);
`;

export const WrapperFirstLine = styled.div`
	height: 70px;
	display: flex;
	justify-content: space-between;
`;

export const WrapperSecondLine = styled.div`
	height: 90px;
	display: flex;
	background-color: #404d57;
	border-top: 1px solid #1e272e;
	border-bottom: 1px solid #1e272e;
`;

export const WrapperFirstColumn = styled.div`
	display: flex;
	flex-direction: column;
	border-right: 1px solid #1e272e;
	width: 250px;
	min-width: 250px;
`;

export const WrapperSearch = styled.div`
	margin-right: 24px;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const SearchDiv = styled.div`
	margin: 10px 0;
`;

export const WrapperQuickSelectionAndCategories = styled.div`
	flex: 1;
	background-color: #404d57;
	overflow-y: auto;
`;

export const WrapperCategories = styled.div`
	padding: 7px;
	background-color: #404d57;
	overflow-y: auto;
	flex: 1;
	::-webkit-scrollbar {
		width: 4px;
	}
`;

export const WrapperTitle = styled.div`
	color: #a5b9c8;
	font-size: 12px;
	padding: 5px 0;
`;

export const StyledTabPane = styled(Tab.TabPane)`
	height: 50px;
	align-self: flex-end;
	font-size: 14px;

	color: ${props => (props.disabled ? colors.grey5 : colors.white1)};
	fill: ${props => (props.disabled ? colors.grey5 : colors.white1)};
`;

export const TitleTab = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperPercentCharts = styled.div`
	display: flex;
	padding: 0 5px 0 0px;
`;

export const StyledTabContent = styled(Tab.TabContent)`
	//height: calc(100vh - 70px);
	height: 100%;
	background-color: #404d57;
	overflow: auto;
`;

export const WrapperTitles = styled.div`
	padding-left: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WrapperTitle1 = styled.div`
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0;
	color: #a5b9c8;
	opacity: 1;
	white-space: nowrap;
`;

export const WrapperTitle2 = styled.div`
	font: normal normal normal 16px/19px Lato;
	letter-spacing: 0;
	color: #ffcd38;
	opacity: 1;
	white-space: nowrap;
`;

export const SearchAndCurrentPeriod = styled.div`
	display: flex;
	align-items: center;
`;

export const ArrowNbProducts = styled(Icon)`
	${props =>
		!props.noMargin &&
		css`
			margin: 0 8px;
		`}
	width: 8px;
	transform: rotate(90deg) scaleX(1.5);
`;

export const WrapperFooter = styled.div`
	height: 75px;
	box-shadow: 0 9px 10px -8px rgb(30 39 46 / 50%) inset;
	background-color: #404d57;
	display: flex;
	align-items: center;
`;

export const PercentChartTitle = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 12px;
	white-space: nowrap;
`;

export const PercentChartSubTitle = styled.div`
	font-size: 12px;
	color: ${props => props.color};
	text-transform: lowercase;
`;
