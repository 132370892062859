import styled from "styled-components";
import { Dialog } from "priceit-ui";
import { verticalCustomGap } from "@services/Utils.style";

const { Modal, SaveModal } = Dialog;

export const ModalSendEmailStyled = styled(Modal)`
	max-height: calc(100vh - 100px);
	background-color: ${({ theme }) => theme.backgroundColor.tertiary};
	overflow: hidden;
`;

export const WrapperBodyModal = styled.div`
	padding-right: 5px;
	max-height: calc(100vh - 100px - 80px - 80px - 40px);
	overflow: auto;
	${verticalCustomGap("10px")};
`;

export const WarningModal = styled(SaveModal)``;
