import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "priceit-ui";

import {
	ButtonWrapper,
	ClientOfferCreatedPanel,
	ClientOfferCreatedWrapper,
	ImageWrapper,
	StyledButton,
	SubText,
	TitleText,
	WrapperLoader,
} from "./ClientOfferCreated.style";

import { useThemeContext } from "@theme/Theme";
import { useIframeActions } from "@hooks/UseIframeActions";
import { useOfferLink } from "@pages/clientsOffers/hooks/useOfferLink";

const ClientOfferCreated = () => {
	const { t } = useTranslation("clientsOffers/created");
	const { mode } = useThemeContext();
	const [confirmCreateOffer] = useIframeActions();
	const { loading, offerLink } = useOfferLink();
	return (
		<ClientOfferCreatedWrapper mode={mode}>
			<ClientOfferCreatedPanel mode={mode}>
				{loading ? (
					<WrapperLoader>
						<Loader />
					</WrapperLoader>
				) : (
					<>
						<ImageWrapper>
							<img
								src={`https://${process.env.IMAGE_PROVIDER}/images/iframe_offer_confirmation.svg`}
								alt={"iframe_offer_confirmation"}
							/>
						</ImageWrapper>
						<TitleText>{t("Your offer has been created successfully")}</TitleText>
						{offerLink ? (
							<SubText>
								{t("Offer link :")}
								<br />
								<a href={offerLink} target="_blank" rel="noreferrer">
									{offerLink}
								</a>
							</SubText>
						) : null}
						<ButtonWrapper>
							<StyledButton
								mode={mode}
								type={mode === "dark" ? "secondary" : "primary"}
								onClick={() => {
									confirmCreateOffer();
								}}
							>
								{t("Close")}
							</StyledButton>
						</ButtonWrapper>
					</>
				)}
			</ClientOfferCreatedPanel>
		</ClientOfferCreatedWrapper>
	);
};

export default ClientOfferCreated;
