import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { GET_MAIL_CONNECTIONS } from "@graphQl/queries/mail";

import { useThemeContext } from "@theme/Theme";

import { LabelAndDropdownComponent } from "@components/labelAndDropdownComponent/LabelAndDropdownComponent";
import { clientsOffersEmailSelectedAtom } from "@recoil/clientOffers/atom";

export const SelectMail = React.memo(() => {
	const { t } = useTranslation("clientsOffers/modalSendEmail");
	const { mode } = useThemeContext();

	const { loading: loadingConnections, data: dataConnections } = useQuery(GET_MAIL_CONNECTIONS);
	const connections = dataConnections?.getUserMailConnections;

	const [clientsOffersEmailSelectedValue, clientsOffersEmailSelectedState] = useRecoilState(
		clientsOffersEmailSelectedAtom
	);

	const data = useMemo(() => {
		if (connections?.length) {
			return connections.map(element => {
				return {
					id: element.id,
					value: element.user.email,
				};
			});
		} else return [];
	}, [dataConnections]);

	useEffect(() => {
		if (connections?.length) {
			clientsOffersEmailSelectedState(connections[0].id);
		}
	}, [dataConnections]);

	if (loadingConnections) return null;

	return (
		<LabelAndDropdownComponent
			mode={mode}
			width={"100%"}
			label={t("Your email")}
			isMandatory={true}
			value={clientsOffersEmailSelectedValue}
			setValue={value => clientsOffersEmailSelectedState(value)}
			data={data}
		/>
	);
});
SelectMail.whyDidYouRender = true;
SelectMail.displayName = "SelectMail";
