export const isErrorOfferContact = ({ offerContacts }) => {
	let lineError = false;
	offerContacts?.forEach((contact, index) => {
		const errors = contact.errors;
		if (
			!(
				(errors.length === 3 &&
					errors.filter(error => error.type.includes("empty")).length === 3) ||
				errors.length === 0
			) ||
			(index === 0 && errors.length > 0)
		) {
			lineError = true;
		}
	});
	return lineError;
};
