import React, { lazy, memo, Suspense } from "react";
import { Loader } from "priceit-ui";
import CSSMotion from "rc-motion";
import { useRecoilValue } from "recoil";
import { sectionName } from "@recoil/clientOffers";
import { clientsOffersCreationStepAtom } from "@recoil/clientOffers/atom";
import {
	WrapperOfferDefinition,
	WrapperOfferDefinitionTemplateSelection,
	WrapperPreselectionOfOptions,
} from "./CenterPanel.style";

const TemplateSelection = lazy(() => import("./templateSelection/TemplateSelection"));
const OfferDefinition = lazy(() => import("./offerDefinition/OfferDefinition"));
const PreSelectionOfOptions = lazy(() => import("./preSelectionOfOptions/PreSelectionOfOptions"));

export const CenterPanel = memo(({ hasOnlyOnePriceModelCombination }) => {
	const creationStep = useRecoilValue(clientsOffersCreationStepAtom);

	return (
		<>
			{!hasOnlyOnePriceModelCombination ? (
				<CSSMotion
					key={"templateSelection"}
					visible={creationStep === sectionName.offerCreationStep.templateSelection}
					removeOnLeave={true}
				>
					{() => {
						return (
							<WrapperOfferDefinitionTemplateSelection
								visible={
									creationStep === sectionName.offerCreationStep.templateSelection
								}
							>
								<Suspense fallback={<Loader />}>
									<TemplateSelection />
								</Suspense>
							</WrapperOfferDefinitionTemplateSelection>
						);
					}}
				</CSSMotion>
			) : null}

			<CSSMotion
				key={"customization"}
				visible={creationStep === sectionName.offerCreationStep.customization}
				removeOnLeave={true}
			>
				{() => {
					return (
						<WrapperOfferDefinition
							visible={creationStep === sectionName.offerCreationStep.customization}
						>
							<Suspense fallback={<Loader />}>
								<OfferDefinition />
							</Suspense>
						</WrapperOfferDefinition>
					);
				}}
			</CSSMotion>
			<CSSMotion
				key={"preSelectionOfOptions"}
				visible={[
					sectionName.offerCreationStep.preSelectionOfOptions,
					sectionName.refreshTemplate,
				].includes(creationStep)}
			>
				{() => {
					return (
						<WrapperPreselectionOfOptions
							visible={[
								sectionName.offerCreationStep.preSelectionOfOptions,
								sectionName.refreshTemplate,
							].includes(creationStep)}
						>
							<Suspense fallback={<Loader />}>
								<PreSelectionOfOptions />
							</Suspense>
						</WrapperPreselectionOfOptions>
					);
				}}
			</CSSMotion>
		</>
	);
});
CenterPanel.whyDidYouRender = true;
CenterPanel.displayName = "CenterPanel";
export default CenterPanel;
