import styled, { css } from "styled-components";
import { Button, Icon } from "priceit-ui";
import { verticalCustomGap } from "@services/Utils.style";

export const ButtonAdd = styled(Button)`
	font-size: 12px;
	margin-top: -8px;
	margin-right: -7px;
`;

export const WrapperButton = styled.div`
	display: flex;
	fill: #2b343b;
	margin-top: 5px;
`;

export const DeleteEmailButton = styled(Icon)`
	margin: 0 0 0 5px;
	cursor: pointer;
	fill: ${({ theme }) => theme.page.secondaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
	}
`;

export const WrapperDeleteEmailButton = styled.div`
	${({ firstItem }) =>
		firstItem &&
		css`
			margin-top: 14px;
		`}
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const WrapperExtraEmail = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperAllEmail = styled.div`
	display: flex;
	flex-direction: column;
	${verticalCustomGap("10px")};
`;
