import { gql } from "@apollo/client";
import { OFFER_CONTACT_EMAIL_FRAGMENT, OFFER_CONTACT_FRAGMENT } from "../fragments/offerContact";

export const GET_OFFER_CONTACT_EMAIL_BY_OFFER_ID = gql`
	${OFFER_CONTACT_EMAIL_FRAGMENT}
	query GetOfferContactEmailByOfferId($id: ID!) {
		getOfferContactsByOfferId(id: $id) {
			...OfferContactEmailFragment
		}
	}
`;

export const GET_OFFER_CONTACT_BY_ID = gql`
	${OFFER_CONTACT_FRAGMENT}
	query GetOfferContactById($id: ID!) {
		getOfferById(id: $id) {
			...OfferContactFragment
		}
	}
`;

export const GET_OFFER_CONTACTS_BY_OFFER_ID = gql`
	${OFFER_CONTACT_FRAGMENT}
	query GetOfferContactsByOfferId($id: ID!) {
		getOfferContactsByOfferId(id: $id) {
			...OfferContactFragment
		}
	}
`;
