import { gql } from "@apollo/client";

export const OFFER_REQUEST_RESPONSE_TOKEN_FRAGMENT = gql`
	fragment OfferRequestResponseTokenFragment on OfferRequestResponse {
		token
	}
`;

export const OFFER_REQUEST_RESPONSE_MAIL_FRAGMENT = gql`
	fragment OfferRequestResponseMailFragment on OfferRequestResponse {
		mail
	}
`;

export const OFFER_REQUEST_RESPONSE_FRAGMENT = gql`
	${OFFER_REQUEST_RESPONSE_TOKEN_FRAGMENT}
	${OFFER_REQUEST_RESPONSE_MAIL_FRAGMENT}
	fragment OfferRequestResponseFragment on OfferRequestResponse {
		...OfferRequestResponseTokenFragment
		...OfferRequestResponseMailFragment
	}
`;
