import { gql } from "@apollo/client";

export const GET_FIXED_COST_BY_PERIOD_ID = gql`
	query getFixedCostsByPeriodId($periodId: ID!) {
		getFixedCostsByPeriodId(periodId: $periodId) {
			id
			periodId
			index
			name
			description
			cost
			vat
			vatDeductible
			variableCostPart
			variableCostPartType
			volumeAllocation
			revenueAllocation
			manualDispatching
			timePeriod
			createdBy
			updatedBy
			createdAt
			updatedAt
			categories {
				id
				name
				categorizationId
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
			products {
				id
				periodId
				index
				name
				description
				imagePath
				price
				tva
				deductibleTva
				volume
				volumeMetric
				frequency
				frequencyMetric
				discount
				createdBy
				updatedBy
				createdAt
				updatedAt
				variableCosts
				priceIsKnowingIn
				paymentFrequency
				timeMetric
				priceInclVAT
				priceExclVAT
				netPriceExclVAT
				netPriceInclVAT
				revenue
				grossUnitProfit
				categories {
					id
					name
					categorizationId
					color
					index
					createdBy
					updatedBy
					createdAt
					updatedAt
				}
				semiVariableCostParts {
					id
					value
					productId
					fixedCostId
				}
			}
		}
	}
`;
