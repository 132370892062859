import styled from "styled-components";

export const HeaderCategorization = styled.div`
	font-size: ${props => (props.primary ? "14px" : "12px")};
	color: ${props => (props.primary ? "#FFF" : "#71889A")};
`;
export const WrapperHeaderCategorization = styled.div`
	&:first-child {
		margin-bottom: 4px;
	}
`;

export const WrapperCategoryName = styled.div`
	display: flex;
	background-color: ${props => props.backgroundColor};
	width: calc(100% - 20px);
	justify-content: center;
	height: 30px;
	border-radius: 3px;
	align-items: center;
	margin: auto;
	padding: 0 10px;
`;

export const WrapperTooltipContent = styled.div`
	overflow-wrap: break-word;
`;

export const TitleCategory = styled.div`
	color: white;
	display: -webkit-box;
	width: 90px;
	font-size: 14px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-align: center;
`;

export const TitleCategoryText = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${props => props.color};
`;

export const WrapperTitleCategory = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
