import { colors } from "@src/theme/userThemes/userColors";
import { Icon } from "priceit-ui";
import styled, { css } from "styled-components";

export const RowWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`;

export const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-left: 1px solid ${({ theme }) => theme.borderColor.box.primary};
	padding: 0 0 0 40px;
	&:first-child {
		border-left: unset;
		padding: 0 45px 0 0;
	}
`;

export const ImageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	&:last-child {
		padding: 0 0 0 50px;
	}
`;

export const ImagesWrapper = styled.div`
	display: flex;
	margin-bottom: 15px;
`;

export const WrapperNameAndBranding = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
`;

export const PaletteTitle = styled.div`
	font-size: 16px;
	color: ${({ theme }) => theme.textColor.primary};
	margin-top: 15px;
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: #5c6f7e;
	margin-top: 15px;
`;

export const WrapperColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 15px;
`;

export const LogoLinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
	gap: 3px;
	width: 50%;
`;

export const LabelInput = styled.label`
	color: ${({ theme }) => theme.page.secondaryTextColor};
	font-size: 12px;
`;

export const WrapperLabelInput = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`;

export const WrapperInfoIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InfoIcon = styled(Icon)`
	fill: ${colors.black11};
	margin-right: ${props => props.marginRight ?? "10px"};
	width: 14px;
	height: 14px;
`;

export const WrapperInput = styled.div`
	position: relative;
`;

export const WrapperLinkIcon = styled.div`
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const LinkIcon = styled(Icon)`
	fill: ${colors.black11};
	cursor: pointer;
	&:hover {
		fill: ${({ theme }) => theme.primaryColor};
	}
`;
