import { gql } from "@apollo/client";
import {
	ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT,
	BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT,
	COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT,
	CRM_PIPELINES_PRICE_MODEL_COMBINATION_FRAGMENT,
	CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT,
	DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT,
	DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT,
	ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT,
	ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT,
	EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT,
	FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT,
	FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	HAS_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT,
	INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT,
	IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT,
	LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT,
	LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT,
	LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT,
	MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT,
	OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT,
	OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT,
	OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT,
	ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT,
	OPEN_COMBINATION_LINK_USERS_FRAGMENT,
	PRICE_MODEL_COMBINATION_ALL_FRAGMENT,
	PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_AND_QUOTE_LAYOUT_FRAGMENT,
	PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_COMBINATION_PUBLIC_LINK_TC_DATA_FRAGMENT,
	PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT,
	PRICE_MODEL_LINK_COMBINATION_FOR_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_PACK_ID_FRAGMENT,
	PROTECTED_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT,
	PROTECTED_PRICE_MODEL_COMBINATION_FRAGMENT,
	PUBLIC_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT,
	PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT,
	QUOTE_COMBINATION_LINK_USERS_FRAGMENT,
	QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT,
	SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT,
	SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT,
	SMALL_PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT,
	SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT,
	UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT,
	UPDATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT,
} from "@graphQl/fragments/priceModelCombinationFragment";
import { PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT } from "@graphQl/fragments/priceModelCombinationProperties";
import { PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT } from "@graphQl/fragments/priceModelCombinationQuoteFieldFragment";
import {
	PRICE_MODEL_LINKED_LANGUAGE_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINKED_METRICS_COMBINATION_FRAGMENT,
	PRICE_MODEL_LINKED_PRICE_MODELS_COMBINATION_FRAGMENT,
	PRICE_MODEL_VOLUMES_INFORMATION_COMBINATION_FRAGMENT,
} from "@graphQl/fragments/priceModelLinkCombinationFragment";
import { WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT } from "@graphQl/fragments/workspace";

import { EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT } from "../fragments/expiredOrUnpublishedPriceModelCombination";
import { AVAILABILITY_DATA_TOKEN_FRAGMENT, TOKEN_FRAGMENT } from "../fragments/token";
import { OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT } from "../fragments/offerProperties";

export const GET_PRICE_MODEL_COMBINATIONS_FOR_CHANGE_REFERENCE = gql`
	query GetPriceModelCombinationsForChangeReference(
		$filters: [PriceModelCombinationsTemplateFiltersEnum]
	) {
		getPriceModelCombinations(filters: $filters) {
			id
			isReference
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_FOR_DELETE = gql`
	query GetPriceModelCombinationsForDelete(
		$filters: [PriceModelCombinationsTemplateFiltersEnum]
	) {
		getPriceModelCombinations(filters: $filters) {
			id
			name
			isReference
			priceModelCombinationProperties {
				id
				name
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS = gql`
	query GetPriceModelCombinations($filters: [PriceModelCombinationsTemplateFiltersEnum]) {
		getPriceModelCombinations(filters: $filters) {
			priceModelLinkCombination {
				id
				index
				name
				priceModel {
					id
					language
					name
				}
			}
			selectedCategories {
				id
				categorizationId
				categoryId
				priceModelCombinationId
				priceModelCombinationPropertiesId
				category {
					id
					name
					color
				}
			}
			priceModelCombinationProperties {
				id
				name
			}
			introPageSections
			createdById
			updatedById
			tokenObj {
				id
				expirationDate
				active
				token
				hasPassword
				password
				isExpired
				type
				enablePassword
				enableExpirationDate
			}
			offerProperties {
				id
				priceModelCombinationId
				enableTc
				enableFooter
				footerContent
				name
				enableDealOwnerContact
				enableIntroductionTextForProspect
				introductionTextForProspect
			}
			id
			priceModelCombinationPropertiesId
			name
			askEmailWhenLinkIsOpened
			summaryPageBackgroundColor
			offerAccessPageTheme
			companyName
			description
			displayedTheme
			enablePassword
			expirationDate
			favicon
			summaryPageFontColor
			isFavorite
			isQuoteGeneratorActive
			language
			currency
			shouldLinkVolumes
			offerImage
			selectedTemplate
			showPriceitBranding
			syncDisplayedTheme
			createdAt
			updatedAt
			quoteGeneratorIsSales
			maxGlobalDiscount
			enableMaxGlobalDiscount
			offerLogoLink
			mainShadeTypographyColor
			complementaryShadeTypographyColor
			accentShadeTypographyColor
			mainBackgroundColor
			alternativeBackgroundColor
			inactiveShadeBackgroundColor
			token
			workspaceId
			active
			tokenId
			loginLink
			isReference
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_BY_ID = gql`
	query GetPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			priceModelLinkCombination {
				id
				index
				name
				priceModel {
					id
					language
					name
				}
			}
			selectedCategories {
				id
				categorizationId
				categoryId
				priceModelCombinationId
				priceModelCombinationPropertiesId
				category {
					id
					name
					color
				}
			}
			priceModelCombinationProperties {
				id
				name
			}
			introPageSections
			createdById
			updatedById
			tokenObj {
				id
				expirationDate
				active
				token
				hasPassword
				password
				isExpired
				type
				enablePassword
				enableExpirationDate
			}
			offerProperties {
				id
				priceModelCombinationId
				enableTc
				enableFooter
				footerContent
				name
				enableDealOwnerContact
				enableIntroductionTextForProspect
				introductionTextForProspect
			}
			id
			priceModelCombinationPropertiesId
			name
			askEmailWhenLinkIsOpened
			summaryPageBackgroundColor
			offerAccessPageTheme
			companyName
			description
			displayedTheme
			enablePassword
			expirationDate
			favicon
			summaryPageFontColor
			isFavorite
			isQuoteGeneratorActive
			language
			currency
			shouldLinkVolumes
			offerImage
			selectedTemplate
			showPriceitBranding
			syncDisplayedTheme
			createdAt
			updatedAt
			quoteGeneratorIsSales
			maxGlobalDiscount
			enableMaxGlobalDiscount
			offerLogoLink
			mainShadeTypographyColor
			complementaryShadeTypographyColor
			accentShadeTypographyColor
			mainBackgroundColor
			alternativeBackgroundColor
			inactiveShadeBackgroundColor
			token
			workspaceId
			active
			tokenId
			loginLink
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_FOR_OFFER_CREATION = gql`
	${PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_FRAGMENT}
	query GetPriceModelCombinationsForOfferCreation(
		$offset: Int
		$limit: Int
		$filters: PriceModelCombinationFiltersInput
	) {
		getPaginatedPriceModelCombinations(offset: $offset, limit: $limit, filters: $filters) {
			...PriceModelCombinationForOfferCreationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_CHECK_READ_PERMISSION = gql`
	query GetPriceModelCombinationForCheckReadPermission($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			id
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_BY_ID = gql`
	${PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_FRAGMENT}
	query GetPriceModelCombinationForOfferCreationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationForOfferCreationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_BY_ID = gql`
	${PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_AND_QUOTE_LAYOUT_FRAGMENT}
	query GetPriceModelCombinationForOfferCreationMailById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationForOfferCreationMailAndQuoteLayoutFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_UPDATE_INFO = gql`
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${UPDATED_AT_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsUpdateInfo {
		getPriceModelCombinations {
			...OnlyNamePriceModelCombinationFragment
			...UpdatedAtPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_CREATORS = gql`
	${CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsCreators {
		getPriceModelCombinations {
			...CreatedByPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_UPDATERS = gql`
	${UPDATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsUpdaters {
		getPriceModelCombinations {
			...UpdatedByPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_ALL_BY_ID = gql`
	${PRICE_MODEL_COMBINATION_ALL_FRAGMENT}
	query GetPriceModelCombinationAllById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationAllFragment
		}
	}
`;

export const GET_CREATED_BY_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetCreatedByPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...CreatedByPriceModelCombinationFragment
		}
	}
`;

export const GET_DESCRIPTION_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetDescriptionPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...DescriptionPriceModelCombinationFragment
		}
	}
`;

export const GET_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetDisplayedThemePriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...DisplayedThemePriceModelCombinationFragment
		}
	}
`;

export const GET_IS_QUOTE_GENERATOR_ACTIVE_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetIsQuoteGeneratorActiveByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...IsQuoteGeneratorActivePriceModelCombinationFragment
		}
	}
`;

export const GET_LANGUAGE_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetLanguageByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...LanguagePriceModelCombinationFragment
		}
	}
`;

export const GET_PUBLIC_LINK_TC_DATA_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${PRICE_MODEL_COMBINATION_PUBLIC_LINK_TC_DATA_FRAGMENT}
	query GetPublicLinkTcDataByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationPublicLinkTcDataFragment
		}
	}
`;

export const GET_QUOTE_GENERATOR_INFO_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	query GetQuoteGeneratorInfoByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...QuoteGeneratorIsSalesPriceModelCombinationFragment
			quoteFields {
				...PriceModelCombinationQuoteFieldFragment
			}
		}
	}
`;

export const GET_QUOTE_GENERATOR_INFO_AND_ACTIVE_PROFITABILITY_THRESHOLD_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${QUOTE_GENERATOR_IS_SALES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	query GetQuoteGeneratorInfoAndActiveProfitabilityThresholdByPriceModelCombinationById(
		$id: ID!
	) {
		getPriceModelCombinationById(id: $id) {
			...QuoteGeneratorIsSalesPriceModelCombinationFragment
			quoteFields {
				...PriceModelCombinationQuoteFieldFragment
			}
		}
	}
`;

export const GET_QUOTE_FIELDS_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	query GetQuoteGeneratorInfoByPriceModelCombinationFragment($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			quoteFields {
				...PriceModelCombinationQuoteFieldFragment
			}
		}
	}
`;

export const GET_OFFER_IMAGE_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetOfferImagePriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...OfferImagePriceModelCombinationFragment
		}
	}
`;

export const GET_SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_ID = gql`
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetShowPriceitBrandingPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...ShowPriceitBrandingPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_LINKS_LANGUAGE_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${PRICE_MODEL_LINKED_LANGUAGE_COMBINATION_FRAGMENT}
	query GetPriceModelLinksLanguageByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			id
			priceModelLinkCombination {
				...PriceModelLinkedLanguageCombinationFragment
			}
		}
	}
`;

export const GET_SYNC_DISPLAYED_THEME_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetSyncDisplayedThemeByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...SyncDisplayedThemePriceModelCombinationFragment
		}
	}
`;

export const GET_SUMMARY_PAGE_COLORS_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetSummaryPageColorsByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...SummaryPageFontColorPriceModelCombinationFragment
			...SummaryPageBackgroundColorPriceModelCombinationFragment
		}
	}
`;

export const GET_ASK_EMAIL_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetAskEmailByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...AskEmailPriceModelCombinationFragment
		}
	}
`;

export const GET_ENABLE_MAX_GLOBAL_DISCOUNT_AND_MAX_GLOBAL_DISCOUNT_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${ENABLE_MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAX_GLOBAL_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetEnableMaxGlobalDiscountAndMaxDiscountByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...EnableMaxGlobalDiscountPriceModelCombinationFragment
			...MaxGlobalDiscountPriceModelCombinationFragment
		}
	}
`;

export const GET_PASSWORD_INFO_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${HAS_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetHasPasswordByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...HasPasswordPriceModelCombinationFragment
			...EnablePasswordPriceModelCombinationFragment
		}
	}
`;

export const GET_BASIC_INFORMATION_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FAVICON_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DESCRIPTION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SELECTED_TEMPLATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CREATED_BY_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPLEMENTARY_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ACCENT_SHADE_TYPOGRAPHY_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${MAIN_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ALTERNATIVE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${INACTIVE_SHADE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetBasicInformationPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...OfferImagePriceModelCombinationFragment
			...FaviconPriceModelCombinationFragment
			...DisplayedThemePriceModelCombinationFragment
			...DescriptionPriceModelCombinationFragment
			...ShowPriceitBrandingPriceModelCombinationFragment
			...CompanyNamePriceModelCombinationFragment
			...SelectedTemplatePriceModelCombinationFragment
			...FontColorPriceModelCombinationFragment
			...BackgroundColorPriceModelCombinationFragment
			...OfferAccessPageThemePriceModelCombinationFragment
			...OnlyNamePriceModelCombinationFragment
			...OfferLogoLinkPriceModelCombinationFragment
			...CreatedByPriceModelCombinationFragment
			...MainShadeTypographyColorPriceModelCombinationFragment
			...ComplementaryShadeTypographyColorPriceModelCombinationFragment
			...AccentShadeTypographyColorPriceModelCombinationFragment
			...MainBackgroundColorPriceModelCombinationFragment
			...AlternativeBackgroundColorPriceModelCombinationFragment
			...InactiveShadeBackgroundColorPriceModelCombinationFragment
			...CurrencyPriceModelCombinationFragment
		}
	}
`;

export const GET_LINK_VOLUMES_INFORMATION_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_LINKED_METRICS_COMBINATION_FRAGMENT}
	query GetLinkVolumesInformationPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...LinkVolumesPriceModelCombinationFragment
			priceModelLinkCombination {
				...PriceModelLinkedMetricsCombinationFragment
			}
		}
	}
`;

export const GET_LINK_PRICE_MODELS_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_LINKED_PRICE_MODELS_COMBINATION_FRAGMENT}
	query GetLinkPriceModelsPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...LinkVolumesPriceModelCombinationFragment
			priceModelLinkCombination {
				...PriceModelLinkedPriceModelsCombinationFragment
			}
		}
	}
`;

export const GET_VOLUMES_INFORMATION_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${PRICE_MODEL_LINKED_METRICS_COMBINATION_FRAGMENT}
	${PRICE_MODEL_VOLUMES_INFORMATION_COMBINATION_FRAGMENT}
	query GetVolumesInformationPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			priceModelLinkCombination {
				...PriceModelVolumeInformationsCombinationFragment
			}
		}
	}
`;

export const GET_VAT_LINK_PRICE_MODEL_COMBINATION_BY_ID = gql`
	query GetVatLinkPriceModelCombinationByToken($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			priceModelLinkCombination {
				id
				priceModelId
				vat {
					rate
				}
			}
		}
	}
`;

export const GET_VAT_LINK_PRICE_MODEL_COMBINATION_BY_TOKEN = gql`
	query GetVatLinkPriceModelCombinationByToken($token: String!) {
		getPriceModelCombinationByToken(token: $token) {
			... on PriceModelCombination {
				priceModelLinkCombination {
					vat {
						rate
					}
					priceModelId
				}
			}
		}
	}
`;

export const GET_ACCESS_RIGHTS_PRICE_MODEL_COMBINATION_BY_ID = gql`
	${TOKEN_FRAGMENT}
	${ASK_EMAIL_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ENABLE_PASSWORD_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetAccessRightsPriceModelCombinationById($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...AskEmailPriceModelCombinationFragment
			...EnablePasswordPriceModelCombinationFragment
			tokenObj {
				...TokenFragment
			}
		}
	}
`;

export const GET_OPEN_COMBINATION_LINK_USERS_BY_COMBINATION_ID = gql`
	${OPEN_COMBINATION_LINK_USERS_FRAGMENT}
	query GetOpenCombinationLinkUsersByCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...OpenCombinationLinkUsersFragment
		}
	}
`;

export const GET_QUOTE_COMBINATION_LINK_USERS_BY_COMBINATION_ID = gql`
	${QUOTE_COMBINATION_LINK_USERS_FRAGMENT}
	query GetQuoteCombinationLinkUsersByCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...QuoteCombinationLinkUsersFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_BY_TOKEN = gql`
	${PUBLIC_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PROTECTED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationByToken(
		$token: String!
		$password: String
		$email: String
		$userId: ID
	) {
		getPriceModelCombinationByToken(
			token: $token
			password: $password
			email: $email
			userId: $userId
		) {
			... on PriceModelCombination {
				...PublicPriceModelCombinationFragment
			}
			... on ProtectedPriceModelCombination {
				...ProtectedPriceModelCombinationFragment
			}
			... on ExpiredOrUnpublishedPriceModelCombination {
				...ExpiredOrUnpublishedPriceModelCombinationFragment
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_BY_TOKEN = gql`
	${PUBLIC_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT}
	${EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PROTECTED_PRICE_MODEL_COMBINATION_FOR_DISCOVER_STEP_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationForDiscoverStepByToken(
		$token: String!
		$password: String
		$email: String
		$userId: ID
	) {
		getPriceModelCombinationByToken(
			token: $token
			password: $password
			email: $email
			userId: $userId
		) {
			... on PriceModelCombination {
				...PublicPriceModelCombinationForDiscoverStepFragment
			}
			... on ExpiredOrUnpublishedPriceModelCombination {
				...ExpiredOrUnpublishedPriceModelCombinationFragment
			}
			... on ProtectedPriceModelCombination {
				...ProtectedPriceModelCombinationForDiscoverStepFragment
			}
			...ShowPriceitBrandingPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_PASSWORD_CARD_BY_TOKEN = gql`
	query getPriceModelCombinationForPasswordCardByToken(
		$token: String!
		$password: String
		$email: String
		$userId: ID
	) {
		getPriceModelCombinationByToken(
			token: $token
			password: $password
			email: $email
			userId: $userId
		) {
			... on PriceModelCombination {
				id
			}
			... on ProtectedPriceModelCombination {
				wrongCredentials
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_FOR_DISCOUNT = gql`
	${PRICE_MODEL_LINK_COMBINATION_FOR_DISCOUNT_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsForDiscount($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationForDiscountPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_APPROVAL_BUTTON = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsDataForApprovalButton($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelCombinationFragment
			...OfferAccessPageThemePriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_GENERATE_QUOTE_BUTTON_MODAL = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_QUOTE_FIELD_FRAGMENT}
	query GetPriceModelCombinationsDataForGenerateQuoteButtonModal($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelCombinationFragment
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
			quoteFields {
				...PriceModelCombinationQuoteFieldFragment
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_APPROVAL_BUTTON_MODAL = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsDataForApprovalButtonModal($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelCombinationFragment
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_DATA_FOR_SEND_MAIL_MODAL = gql`
	${LANGUAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LOGIN_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ONLY_NAME_FRAGMENT}
	query GetPriceModelCombinationsDataForSendMailModal($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...LanguagePriceModelCombinationFragment
			...LoginLinkPriceModelCombinationFragment
			priceModelCombinationProperties {
				...PriceModelCombinationPropertiesOnlyNameFragment
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_PRICE_MODEL_COMBINATION_CONTENT = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SYNC_DISPLAYED_THEME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${COMPANY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LINK_VOLUMES_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SHOW_PRICEIT_BRANDING_PRICE_MODEL_COMBINATION_FRAGMENT}
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	${AVAILABILITY_DATA_TOKEN_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsDataForPriceModelCombinationContent($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelCombinationFragment
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
			...DisplayedThemePriceModelCombinationFragment
			...SyncDisplayedThemePriceModelCombinationFragment
			...CompanyNamePriceModelCombinationFragment
			...ExpirationDatePriceModelCombinationFragment
			...LinkVolumesPriceModelCombinationFragment
			...OfferImagePriceModelCombinationFragment
			...ShowPriceitBrandingPriceModelCombinationFragment
			...SummaryPageFontColorPriceModelCombinationFragment
			...SummaryPageBackgroundColorPriceModelCombinationFragment
			...CurrencyPriceModelCombinationFragment
			workspace {
				...WorkspaceForPublicPriceModelFragment
			}
			tokenObj {
				...AvailabilityDataTokenFragment
			}

			offerProperties {
				...OfferPropertiesForPriceModelCombinationFragment
			}
		}
	}
`;

export const GET_NUMBER_OF_PRICE_MODEL_LINK_COMBINATIONS = gql`
	${SMALL_PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetNumberOfPriceModelLinkCombinations($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...SmallPriceModelLinkCombinationPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_PACK_SELECTION_INFO = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${IS_QUOTE_GENERATOR_ACTIVE_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsDataForPackSelectionInfo($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelCombinationFragment
			...SummaryPageFontColorPriceModelCombinationFragment
			...SummaryPageBackgroundColorPriceModelCombinationFragment
			...IsQuoteGeneratorActivePriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_DATA_FOR_OFFER_HEADER = gql`
	${ONLY_NAME_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	${AVAILABILITY_DATA_TOKEN_FRAGMENT}
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_LOGO_LINK_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationsDataForOfferHeader($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...OnlyNamePriceModelCombinationFragment
			...OfferImagePriceModelCombinationFragment
			...OfferLogoLinkPriceModelCombinationFragment
			...SummaryPageFontColorPriceModelCombinationFragment
			...SummaryPageBackgroundColorPriceModelCombinationFragment
			workspace {
				...WorkspaceForPublicPriceModelFragment
			}
			tokenObj {
				...AvailabilityDataTokenFragment
			}
			...PriceModelLinkCombinationPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATIONS_WORKSPACE = gql`
	${WORKSPACE_FOR_PUBLIC_PRICE_MODEL_FRAGMENT}
	query GetPriceModelCombinationsDataForOfferHeader($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			id
			workspace {
				...WorkspaceForPublicPriceModelFragment
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_DATA_FOR_LINK_NAME_INPUT = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationDataForLinkNameInput($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_DATA_FOR_LINKS_DRAWER_HEADER = gql`
	${PRICE_MODEL_COMBINATION_PROPERTIES_ID_PRICE_MODEL_COMBINATION_FRAGMENT}
	${TOKEN_FRAGMENT}
	query GetPriceModelCombinationDataForLinksDrawerHeader($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationPropertiesIdPriceModelCombinationFragment
			tokenObj {
				...TokenFragment
			}
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_FOR_USE_DISCOUNT_RULES = gql`
	${PRICE_MODEL_LINK_COMBINATION_PRICE_MODEL_PACK_ID_FRAGMENT}
	query GetPriceModelCombinationForUseDiscountRules($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelLinkCombinationPriceModelPackIdFragment
		}
	}
`;

export const GET_PRICE_MODEL_COMBINATION_OFFER_STATUS_MAPPING = gql`
	${CRM_PIPELINES_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationOfferStatusMappings($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...CrmPipelinesPriceModelCombinationFragment
		}
	}
`;

export const GET_CURRENCY_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetCurrencyByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...CurrencyPriceModelCombinationFragment
		}
	}
`;

export const GET_CURRENCY_BY_PRICE_MODEL_COMBINATION_TOKEN = gql`
	${CURRENCY_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetPriceModelCombinationCurrencyByToken($token: String!) {
		getPriceModelCombinationByToken(token: $token) {
			...CurrencyPriceModelCombinationFragment
		}
	}
`;

export const GET_INTRO_PAGE_SECTIONS_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${INTRO_PAGE_SECTIONS_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetIntroPageSectionsByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...IntroPageSectionsPriceModelCombinationFragment
		}
	}
`;

export const GET_SELECTION_SUMMARY_PRICE_MODEL_COMBINATION_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${PRICE_MODEL_COMBINATION_SELECTION_SUMMARY_FRAGMENT}
	query GetSummaryPriceModelCombinationByPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			...PriceModelCombinationSelectionSummaryFragment
		}
	}
`;

export const GET_ISREFERENCE_PRICE_MODEL_COMBINATION = gql`
	query GetIsReferencePriceModelCombination($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			id
			isReference
		}
	}
`;
