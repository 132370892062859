export const computeTieredNumericalPrice = ({
	numericalRanges,
	levelDatas,
	levelDataPacks,
	volume,
	forExtraFee = false,
}) =>
	numericalRanges.reduce((prev, current, index) => {
		const currentLevelData = levelDatas.find(x => x.numericalRangeId === current.id);
		const currentLevelDataPack = levelDataPacks.find(
			x => x.levelDataId === currentLevelData?.id
		);

		if (index > 0) {
			const prevNumericalRange = numericalRanges?.[index - 1];
			if (current.from < volume && !current.isUnlimited) {
				if (forExtraFee) {
					return (
						prev +
						(currentLevelDataPack?.extraFee || 0) *
							(current.from - prevNumericalRange.from)
					);
				}

				return (
					prev +
					(currentLevelDataPack?.price || 0) * (current.from - prevNumericalRange.from)
				);
			}
			if (
				(volume > prevNumericalRange.from && volume <= current.from) ||
				(current.isUnlimited && volume > current.from)
			) {
				if (forExtraFee) {
					return (
						prev +
						(currentLevelDataPack?.extraFee || 0) * (volume - prevNumericalRange.from)
					);
				}

				return (
					prev + (currentLevelDataPack?.price || 0) * (volume - prevNumericalRange.from)
				);
			}
		}
		return prev;
	}, 0);
