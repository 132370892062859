import { useRecoilCallback } from "recoil";
// adapted from https://github.com/luisanton-io/recoil-nexus
// Goal: make recoil state available outside of React components

const nexus = {};

const RecoilOutsideTheBox = () => {
	nexus.get = useRecoilCallback(
		({ snapshot }) =>
			atom =>
				snapshot.getLoadable(atom).contents,
		[]
	);

	nexus.getPromise = useRecoilCallback(
		({ snapshot }) =>
			atom =>
				snapshot.getPromise(atom),
		[]
	);

	nexus.set = useRecoilCallback(({ snapshot, gotoSnapshot }) => {
		return (atom, valOrUpdater) => {
			const newSnapshot = snapshot.map(mutable => {
				mutable.set(atom, valOrUpdater);
			});

			gotoSnapshot(newSnapshot);
		};
	}, []);

	nexus.reset = useRecoilCallback(({ reset }) => reset, []);

	return null;
};

export function getRecoil(atom) {
	return nexus.get(atom);
}

export function getRecoilPromise(atom) {
	return nexus.getPromise(atom);
}

export function setRecoil(atom, valOrUpdater) {
	nexus.set(atom, valOrUpdater);
}

export function resetRecoil(atom) {
	nexus.reset(atom);
}

export default RecoilOutsideTheBox;
