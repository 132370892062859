import { gql } from "@apollo/client";
import { CONNECTION_OWNER_FRAGMENT } from "@graphQl/fragments/user";

export const MAIL_SERVICE_NAME_FRAGMENT = gql`
	fragment MailServiceNameFragment on MailService {
		id
		name
	}
`;

export const MAIL_SERVICE_DESCRIPTION_FRAGMENT = gql`
	fragment MailServiceDescriptionFragment on MailService {
		id
		description
	}
`;

export const MAIL_CONNECTION_USER_FRAGMENT = gql`
	fragment MailConnectionUserFragment on MailConnectionUser {
		id
		email
	}
`;

export const MAIL_CONNECTION_FRAGMENT = gql`
	${CONNECTION_OWNER_FRAGMENT}
	${MAIL_SERVICE_NAME_FRAGMENT}
	${MAIL_SERVICE_DESCRIPTION_FRAGMENT}
	${MAIL_CONNECTION_USER_FRAGMENT}
	fragment MailConnectionFragment on MailConnection {
		id
		name
		owner {
			...ConnectionOwnerFragment
		}
		service {
			...MailServiceNameFragment
			...MailServiceDescriptionFragment
		}
		user {
			...MailConnectionUserFragment
		}
		active
	}
`;
