import { selector } from "recoil";
import { volumeDiscountStateByPriceModelIdSelector } from "./volumeDiscountSelector";
import {
	commitmentDiscountStateByPriceModelIdSelector,
	commitmentDiscountConditionShowSelectByPriceModelIdSelector,
} from "./commitmentDiscountSelector";
import { paymentTermsDiscountStateByPriceModelIdSelector } from "./paymentTermsDiscountSelector";
import {
	additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector,
	additionalValuesStatesByPriceModelIdSelector,
} from "./additionalValuesSelector";
import {
	specialDiscountStateByPriceModelIdAndDiscountIdSelector,
	specialDiscountStatesByPriceModelIdSelector,
} from "./specialDiscountSelector";
import { priceModelTypeByPriceModelIdSelector } from "./publicLinkSelector";
import { volumeDiscountStateAtom } from "./atom";
import defaultText from "@services/defaultText";
import { setDiscountRuleDependenciesByPriceModelIdSelector } from "../priceModel/dependencies/dependencySelector";
import { convertMetric } from "@src/services/metrics";

export const fetchDiscountRulesSelector = selector({
	key: "fetchDiscountRulesSelector",
	get: ({ get }) => 1,
	set: ({ get, set }, { priceModel, linkedVolumePriceMetricsAndVolumeReference }) => {
		const priceModelId = priceModel.id;
		const discountRulesList = priceModel?.discountRule;
		const volumeDiscountData = discountRulesList?.find(
			rule => rule.volumeDiscount && !rule.volumeDiscount.unitMetric
		)?.volumeDiscount;
		const volumeRangeData = volumeDiscountData?.volumeRange || [];
		if (volumeRangeData?.length) {
			if (
				linkedVolumePriceMetricsAndVolumeReference.shouldLinkVolumes &&
				!get(volumeDiscountStateByPriceModelIdSelector(priceModelId))
			) {
				let val = priceModel?.displayedVolumeInputDefault || 1;
				Object.keys(get(volumeDiscountStateAtom) || {}).forEach(pmId => {
					if (
						priceModelId !== pmId &&
						linkedVolumePriceMetricsAndVolumeReference[pmId]?.priceMetric ===
							priceModel.priceMetric &&
						linkedVolumePriceMetricsAndVolumeReference[pmId]?.volumeReference ===
							priceModel.volumeReference
					) {
						val = get(volumeDiscountStateByPriceModelIdSelector(pmId));
					}
				});

				set(volumeDiscountStateByPriceModelIdSelector(priceModelId), {
					newValue: val,
					discountRangeData: volumeRangeData,
					priceModelMetric: priceModel?.priceMetric,
					basedOn: volumeDiscountData?.basedOn,
				});
			} else {
				set(volumeDiscountStateByPriceModelIdSelector(priceModelId), {
					newValue:
						priceModel?.displayedVolumeInputDefault || priceModel?.volumeReference || 1,
					discountRangeData: volumeRangeData,
					priceModelMetric: priceModel?.priceMetric,
					basedOn: volumeDiscountData?.basedOn,
				});
			}
		} else {
			set(volumeDiscountStateByPriceModelIdSelector(priceModelId), {
				newValue: priceModel?.displayedVolumeInputDefault || 1,
				priceModelMetric: priceModel?.priceMetric,
				basedOn: volumeDiscountData?.basedOn,
			});
		}

		const commitmentRangeData =
			discountRulesList?.find(rule => rule.engagementDiscount)?.engagementDiscount
				?.engagementRange || [];

		const defaultCommitmentDiscount =
			commitmentRangeData?.find(element => {
				return element.isDefaultSelected;
			}) || commitmentRangeData?.[0];

		const paymentTermDiscount = discountRulesList?.find(rule => rule.paymentTermDiscount);
		const paymentTermDiscountRangeData =
			paymentTermDiscount?.paymentTermDiscount?.paymentTermRange || [];

		const defaultPaymentTermDiscount =
			paymentTermDiscountRangeData?.find(element => {
				return element.isDefaultSelected;
			}) || paymentTermDiscountRangeData?.[0];

		set(commitmentDiscountStateByPriceModelIdSelector(priceModelId), {
			newValue: commitmentRangeData.length
				? defaultCommitmentDiscount?.id
				: priceModel?.displayedCommitmentInputDefault || priceModel?.minEngagement || 1,
			discountRule: discountRulesList,
			timeMetric: priceModel?.timeMetric,
		});
		set(
			commitmentDiscountConditionShowSelectByPriceModelIdSelector(priceModelId),
			!!commitmentRangeData.length
		);
		const duration = commitmentRangeData.length
			? defaultCommitmentDiscount?.duration
			: priceModel?.displayedCommitmentInputDefault || priceModel?.minEngagement || 1;

		const convertedCommitment = convertMetric(duration, priceModel?.timeMetric)[
			defaultPaymentTermDiscount?.term
		];

		if (convertedCommitment && (convertedCommitment < 1 || convertedCommitment % 1 !== 0)) {
			set(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId), {
				newValue: paymentTermDiscount?.paymentTermDiscount?.paymentTermRange[0]?.id,
				discountRule: discountRulesList,
				timeMetric: priceModel?.timeMetric,
			});
		} else {
			set(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId), {
				newValue: defaultPaymentTermDiscount?.id,
				discountRule: discountRulesList,
				timeMetric: priceModel?.timeMetric,
			});
		}

		set(setDiscountRuleDependenciesByPriceModelIdSelector(priceModelId), discountRulesList);

		const additionalValuesList =
			discountRulesList?.filter(
				x =>
					x.specialDiscount &&
					x.active &&
					!x.specialDiscount.isDiscount &&
					!x.specialDiscount.isSpecialCondition
			) || [];

		const additionalValuesState = {};

		additionalValuesList.forEach(discount => {
			const discountId = discount.specialDiscount.id;
			const ruleId = discount.id;
			const isAddon = discount?.specialDiscount.timeMetric !== defaultText.onlyOnce;
			const isBefore = discount?.specialDiscount.position === "before";
			priceModel?.packs?.forEach(pack => {
				const packId = pack?.id;
				const rangeId = discount.specialDiscount.defaultPositions.find(
					p => p.packId === packId
				)?.specialRangeId;
				if (
					rangeId &&
					discount?.specialDiscount?.specialRange
						?.find(range => range.id === rangeId)
						?.specialDiscountRange?.find(
							discountRange => discountRange.packId === packId
						)?.enableDiscount
				) {
					const newValue = get(
						additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
							priceModelId,
							discountId,
							packId,
							ruleId,
							isAddon,
							isBefore,
						})
					) || {
						ruleId,
						discountId,
						rangeId,
						isAddon,
						isBefore,
					};

					additionalValuesState[packId] = [
						...(additionalValuesState[packId] || []),
						newValue,
					];
				}
			});
		});

		set(additionalValuesStatesByPriceModelIdSelector(priceModelId), additionalValuesState);

		const specialDiscountList =
			priceModel.discountRule?.filter(
				x =>
					x.specialDiscount &&
					x.active &&
					(x.specialDiscount.isDiscount || x.specialDiscount.isSpecialCondition)
			) || [];

		const discountState = {};

		specialDiscountList.map(discount => {
			discountState[discount.specialDiscount.id] =
				get(
					specialDiscountStateByPriceModelIdAndDiscountIdSelector({
						priceModelId,
						discountId: discount.specialDiscount.id,
					})
				) ||
				discount.specialDiscount.specialRange.find(range => range.isDefaultSelected)?.id;
			return discountState;
		});

		set(specialDiscountStatesByPriceModelIdSelector(priceModelId), discountState);

		const newPaymentTerm = defaultPaymentTermDiscount?.term;

		const commitmentConditionShowSelect = commitmentRangeData.length;

		const newCommitment = commitmentConditionShowSelect
			? defaultCommitmentDiscount?.duration
			: priceModel?.displayedCommitmentInputDefault;

		set(priceModelTypeByPriceModelIdSelector(priceModelId), {
			timeMetric: priceModel.timeMetric,
			newPaymentTerm,
			newCommitment,
			discountRule: discountRulesList,
		});
	},
});
