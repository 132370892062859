import { Icon } from "priceit-ui";
import styled, { css } from "styled-components";

export const Round = styled.div`
	z-index: ${props => props.zIndex ?? 2};
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 36px;
	min-height: 36px;
	height: 36px;
	width: 36px;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
	background-color: ${({ roundBackgroundColor, theme }) =>
		roundBackgroundColor ?? theme.crossButton.backgroundColor.primary};
	border-radius: 50%;
	margin: ${props => props.margin ?? "0 20px 0 15px"};
	box-shadow: ${props => (props.withShadow ? "0 5px 10px -3px rgba(0,0,0,0.16)" : "none")};
	transition: background-color 0.25s;
	&:hover {
		background-color: ${({ hoverRoundBackgroundColor, theme }) =>
			hoverRoundBackgroundColor ?? theme.crossButton.backgroundColor.hover};
	}
	${props =>
		props.headerType === "tabs" &&
		css`
			position: absolute;
			top: 15px;
			right: 15px;
			margin: 0;
		`}
`;

export const Cross = styled(Icon)`
	height: 15px;
	width: 15px;
	transition: transform 0.25s, fill 0.25s;
	fill: ${({ colorCrossButton, theme }) =>
		colorCrossButton ?? theme.crossButton.crossColor.primary};
	${Round}:hover & {
		transform: rotate(90deg);
		fill: ${({ hoverColorCrossButton, theme }) =>
			hoverColorCrossButton ?? theme.crossButton.crossColor.hover};
	}
`;
