import { useQuery } from "@apollo/client";
import { GET_OFFER_NAME_AND_COMPANY_NAME_BY_OFFER_ID } from "@src/graphQl/queries/offer";
import {
	GET_OFFER_PROPERTIES_BY_ID,
	GET_OFFER_PROPERTIES_BY_OFFER_ID,
} from "@src/graphQl/queries/offerProperties";
import { lastOfferPropertiesDraftIdAtom, offerNameEditedAtom } from "@src/recoil/clientOffers/atom";
import { useRecoilValue } from "recoil";

export const useComputeOfferName = ({ offerId, isDraft }) => {
	const lastOfferPropertiesDraftId = useRecoilValue(lastOfferPropertiesDraftIdAtom);
	const isOfferNameEditable = useRecoilValue(offerNameEditedAtom);
	const { data: dataOffer } = useQuery(GET_OFFER_NAME_AND_COMPANY_NAME_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const { name: offerName, companyName } = dataOffer?.getOfferById || {};

	const { data: dataOfferProperties } = useQuery(GET_OFFER_PROPERTIES_BY_OFFER_ID, {
		variables: {
			offerId,
		},
		skip: !offerId || isDraft,
	});

	const { data: dataDraftOfferProperties } = useQuery(GET_OFFER_PROPERTIES_BY_ID, {
		variables: {
			id: lastOfferPropertiesDraftId,
		},
		skip: !lastOfferPropertiesDraftId || !isDraft,
	});

	const { name: offerPropertiesName } =
		(isDraft
			? dataDraftOfferProperties?.getOfferPropertiesById
			: dataOfferProperties?.getOfferPropertiesByOfferId) || {};

	const availableConstants = {
		"{{CompanyName}}": companyName ?? "",
		// do not use translation
		// "{{NomEntreprise}}": companyName ?? "",
		// "{{Bedrijfsnaam}}": companyName ?? "",
	};

	const computedOfferName = Object.keys(availableConstants).reduce(
		(prev, current) => prev.replace(current, availableConstants[current]),
		offerPropertiesName ?? ""
	);

	if (!isOfferNameEditable && computedOfferName) {
		return computedOfferName;
	}
	return offerName;
};
