import React, { useMemo } from "react";
import {
	ContainerStyle,
	FillerStyle,
	LabelStyle,
	WrapperProgressBar,
	ValueInside,
} from "./ProgressBar.style";

export const ProgressBar = ({
	fillColor,
	percent,
	negative,
	labelValue,
	opacity,
	rectangle,
	reverseColor,
	colorPositiveProp,
	colorNegativeProp,
	hidePercentInsideBar,
	barWidth,
	noLabelValue
}) => {
	const barPercent = useMemo(() => {
		let pct = percent;
		if (negative) {
			pct = Math.abs(percent);
		}
		if (pct > 100) {
			pct = 100;
		} else if ((pct < 0 && !negative) || pct < 0.01) {
			pct = 0;
		}
		return (negative ? pct / 2 : pct) || 0;
	}, [percent]);

	const colorPositive = colorPositiveProp || (reverseColor ? "#78ce5e" : "#e65757");
	const colorNegative = colorNegativeProp || (reverseColor ? "#e65757" : "#78ce5e");

	const position = useMemo(() => {
		if (negative) {
			return "calc(" + (50 + (percent < 0 ? -barPercent : barPercent)) + "% - 14px)";
		}
		return (
			"calc(" +
			barPercent +
			"% - " +
			(barPercent > 50
				? barPercent.fnb({
						zeroIfNan: true,
						stringText: true,
						decimal: 0,
				  }).length *
						14 +
				  "px"
				: "0px") +
			")"
		);
	}, [percent]);

	return (
		<WrapperProgressBar opacity={opacity} barWidth={barWidth}>
			{noLabelValue ? null : (
				<LabelStyle
					percent={barPercent || 0}
					color={
						negative && !fillColor
							? Math.round(percent * 100) < 0
								? colorPositive
								: Math.round(percent * 100) > 0
								? colorNegative
								: "#ffffff"
							: fillColor
					}
					position={!labelValue ? position : "0%"}
				>
					{!labelValue
						? `${(percent !== null && Math.round(percent * 100) !== 0
								? percent
								: 0
						  ).fnb({
								zeroIfNan: true,
								stringText: true,
								decimal: percent < 0.1 ? 2 : 1,
						  })}%`
						: labelValue?.fnb({
								stringText: true,
								zeroIfNan: true,
								withCurrency: true,
						  })}
				</LabelStyle>
			)}
			<ContainerStyle
				labelValue={labelValue}
				hidePercentInsideBar={hidePercentInsideBar}
				rectangle={rectangle}
			>
				{labelValue && !hidePercentInsideBar ? (
					<ValueInside isEqualTo0={percent === 0}>
						{percent?.fnb({
							stringText: true,
							zeroIfNan: true,
							decimal: 1,
						})}
						%
					</ValueInside>
				) : null}
				<FillerStyle
					percent={barPercent || 0}
					fillColor={
						negative && !fillColor
							? percent < 0
								? colorPositive
								: colorNegative
							: fillColor
					}
					negative={negative}
					rotate={percent < 0 ? 1 : 0}
				/>
			</ContainerStyle>
		</WrapperProgressBar>
	);
};
