import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { LeftPanel, Wrapper, WrapperContent } from "./CreateNewOfferPage.style";
import { Dialog, Layout, Loader } from "priceit-ui";

import { useGlobalContext } from "@globalContext";

import { HeaderCustom } from "@components/headerCustom/HeaderCustom";
import CreateNewOfferHeader from "./createNewOfferHeader/CreateNewOfferHeader";
import RightPanel from "./rightPanel/RightPanel";

import { useRecoilState } from "recoil";
import { clientsOffersSelectedIdSelector, sectionName } from "@recoil/clientOffers";

import { GET_OFFER_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_BY_OFFER_ID } from "@graphQl/queries/offer";
import FlowPanel from "./flowPanel/FlowPanel";
import CenterPanel from "./centerPanel/CenterPanel";
import { useThemeContext } from "@theme/Theme";
import RecoilOutsideTheBox from "@recoil/component/RecoilOutsideTheBox";
import { useIframeActions } from "@hooks/UseIframeActions";
import {
	clientOffersIsEditingPreselectionAtom,
	clientsOffersCreationStepAtom,
	clientsOffersSelectedTemplateAtom,
} from "@recoil/clientOffers/atom";
import { GET_PRICE_MODEL_COMBINATIONS_FOR_OFFER_CREATION } from "@graphQl/queries/priceModelCombinationQuery";
import { useHandleOkActionCreationOfferTemplateStep } from "@hooks/useOffer/useHandleOkActionCreationOffer";

export const CreateNewOfferPage = () => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { header: headerColors } = useThemeContext();
	const [cancelCreateOffer] = useIframeActions();
	const { light } = useGlobalContext();

	const { ErrorModal } = Dialog;
	const [offerId, setSelectedOfferId] = useRecoilState(clientsOffersSelectedIdSelector);
	const [creationStep, setCreationStep] = useRecoilState(clientsOffersCreationStepAtom);
	const [templateId, setSelectedTemplate] = useRecoilState(clientsOffersSelectedTemplateAtom);

	const { handleOkAction } = useHandleOkActionCreationOfferTemplateStep();

	const [isEditingPreselection, setIsEditingPreselection] = useRecoilState(
		clientOffersIsEditingPreselectionAtom
	);

	const { data: offerData, loading: loadingOffer } = useQuery(
		GET_OFFER_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_BY_OFFER_ID,
		{
			variables: {
				id: offerId,
			},
			skip: !offerId,
		}
	);

	const offerDoesntExist = offerId && !loadingOffer && !offerData?.getOfferById?.id;

	const { loading: loadingPriceModelCombinations, data: dataPriceModelCombinations } = useQuery(
		GET_PRICE_MODEL_COMBINATIONS_FOR_OFFER_CREATION,
		{
			variables: {
				offset: 0,
				limit: 3,
				filters: {
					search: "",
					languages: [],
					categories: [],
				},
			},
			errorPolicy: "all",
		}
	);

	const hasOnlyOnePriceModelCombination =
		dataPriceModelCombinations?.getPaginatedPriceModelCombinations?.length === 1;

	useEffect(() => {
		if (
			hasOnlyOnePriceModelCombination &&
			offerId &&
			![sectionName.offerCreationStep.preSelectionOfOptions, sectionName.refreshTemplate].includes(creationStep)
		) {
			const templateId = dataPriceModelCombinations?.getPaginatedPriceModelCombinations[0].id;

			handleOkAction({
				selectedTemplateId: templateId,
			});
			setSelectedTemplate(templateId);
			setCreationStep(sectionName.offerCreationStep.customization);
		}
	}, [hasOnlyOnePriceModelCombination, offerId]);

	if (loadingPriceModelCombinations || (!templateId && hasOnlyOnePriceModelCombination && !sectionName.refreshTemplate)) {
		return <Loader />;
	}

	return (
		<Layout style={light ? { flex: "1", overflow: "hidden" } : { height: "100vh" }}>
			<HeaderCustom
				style={{
					backgroundColor: headerColors.backgroundColor,
					borderBottom: `1px solid ${headerColors.borderColor}`,
					height: "80px",
					padding: "0 25px 0 0",
				}}
				customFirstLine={
					<CreateNewOfferHeader
						hasOnlyOnePriceModelCombination={hasOnlyOnePriceModelCombination}
					/>
				}
			/>
			<WrapperContent>
				<Wrapper
					allowOverflow={
						creationStep === sectionName.offerCreationStep.preSelectionOfOptions ||
						creationStep === sectionName.refreshTemplate
					}
				>
					<LeftPanel>
						<FlowPanel
							hasOnlyOnePriceModelCombination={hasOnlyOnePriceModelCombination}
						/>
						<RecoilOutsideTheBox />
						<CenterPanel
							hasOnlyOnePriceModelCombination={hasOnlyOnePriceModelCombination}
						/>
					</LeftPanel>
					<RightPanel />
				</Wrapper>
				<ErrorModal
					noFixPlaceHolder
					reverseButtonHighlight
					footerType="1button"
					width={"40vw"}
					okText={t("Close")}
					onOk={() => {
						cancelCreateOffer();
						setSelectedOfferId(null);
						isEditingPreselection && setIsEditingPreselection(false);
					}}
					visible={offerDoesntExist}
					title={t("The offer doesn't exist.")}
				/>
			</WrapperContent>
		</Layout>
	);
};
CreateNewOfferPage.whyDidYouRender = true;
CreateNewOfferPage.displayName = "CreateNewOfferPage";
export default CreateNewOfferPage;
