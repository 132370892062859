import React from "react";
import { Title } from "@pages/clientsOffers/createNewOfferPage/createNewOfferHeader/CreateNewOfferHeader.style";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import { useQuery } from "@apollo/client";
import { GET_OFFER_NAME } from "@graphQl/queries/offer";

export const OfferNameEditingPreselection = React.memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const { data: dataOffer } = useQuery(GET_OFFER_NAME, {
		variables: { id: offerId },
		skip: !offerId,
	});

	const { name: offerName } = dataOffer?.getOfferById || {};

	return <Title>{t('Overview of "{{offerName}}"', { offerName })}</Title>;
});
OfferNameEditingPreselection.whyDidYouRender = true;
OfferNameEditingPreselection.displayName = "OfferNameEditingPreselection";
