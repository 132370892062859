import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./RightPanel.style";
import { useRecoilValue } from "recoil";
import { sectionName } from "@recoil/clientOffers";
import SearchFilter from "./searchFilter/SearchFilter";
import OfferParameters from "./offerParameters/OfferParameters";
import { clientsOffersCreationStepAtom } from "@recoil/clientOffers/atom";

export const RightPanel = () => {
	const { t } = useTranslation(["specific/clientsOffers", "table/general"]);
	const creationStep = useRecoilValue(clientsOffersCreationStepAtom);

	return (
		<Wrapper>
			{creationStep === sectionName.offerCreationStep.templateSelection ? (
				<SearchFilter />
			) : creationStep === sectionName.offerCreationStep.customization ? (
				<OfferParameters />
			) : null}
		</Wrapper>
	);
};
RightPanel.whyDidYouRender = true;
RightPanel.displayName = "RightPanel";
export default RightPanel;
