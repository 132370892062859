import { useSetRecoilState } from "recoil";
import {
	clientOffersIsEditingPreselectionAtom,
	clientsOffersCreationStepAtom,
	clientsOffersIsNewOfferAtom,
	clientsOffersNewPriceModelCombinationAtom,
	clientsOffersSelectedTemplateAtom,
	clientsOffersShowCreationModalAtom,
	clientsOffersTemplateFiltersAtom,
	lastOfferPropertiesDraftIdAtom,
	offerNameEditedAtom,
} from "@recoil/clientOffers/atom";
import {
	clientsOffersResetSelectedOfferSelector,
	clientsOffersIsNewOfferVersionSelector,
	clientsOfferNewVersionIdSelector,
} from "@recoil/clientOffers";
import { sectionName } from "@recoil/clientOffers";
import {
	publicLinkSelectedPackIdAtom,
	publicLinkSelectedTabAtom,
} from "@src/recoil/publicPriceModel";

export const useCloseEditingPreselection = () => {
	const setIsEditingPreselection = useSetRecoilState(clientOffersIsEditingPreselectionAtom);
	const setCreationStep = useSetRecoilState(clientsOffersCreationStepAtom);
	const setCurrentPriceModelCombinationId = useSetRecoilState(
		clientsOffersNewPriceModelCombinationAtom
	);
	const reset = useSetRecoilState(clientsOffersResetSelectedOfferSelector);

	const setSelectedPacksIds = useSetRecoilState(publicLinkSelectedPackIdAtom);
	const setSelectedTab = useSetRecoilState(publicLinkSelectedTabAtom);
	const setIsNewOffer = useSetRecoilState(clientsOffersIsNewOfferAtom);
	const setLastOfferPropertiesDraftId = useSetRecoilState(lastOfferPropertiesDraftIdAtom);
	const setIsEditable = useSetRecoilState(offerNameEditedAtom);
	const setSelectedTemplate = useSetRecoilState(clientsOffersSelectedTemplateAtom);
	const setFilter = useSetRecoilState(clientsOffersTemplateFiltersAtom);
	const setShowCreationModal = useSetRecoilState(clientsOffersShowCreationModalAtom);
	const setIsNewOfferVersion = useSetRecoilState(clientsOffersIsNewOfferVersionSelector);
	const setNewOfferVersionId = useSetRecoilState(clientsOfferNewVersionIdSelector);

	return () => {
		setIsEditingPreselection(false);
		setCreationStep(sectionName.offerCreationStep.templateSelection);
		setCurrentPriceModelCombinationId(null);
		reset(null);

		setLastOfferPropertiesDraftId(null);
		setIsEditable(true);
		setIsNewOffer(null);
		setIsNewOfferVersion(false);
		setSelectedTemplate(null);
		setFilter({});
		setNewOfferVersionId(null);
		setShowCreationModal(false);
		setSelectedPacksIds([]);
		setSelectedTab(null);
	};
};
