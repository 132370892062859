import styled, { css } from "styled-components";
import { Select, Icon } from "priceit-ui";

export const Wrapper = styled.div`
	width: fit-content;
	display: flex;
	align-items: flex-end;
`;

export const Title = styled.div`
	margin-left: 10px;
	font-size: 11px;
	color: #cbcbcb;
`;

export const SBSelect = styled(Select.Group)`
	background-color: #1e272e;
	color: #cbcbcb;
	width: ${props => (props.saveEnabled ? "80%" : "100%")};
	max-width: 270px;
	height: 30px;
	padding-top: 0;
	border: 0;
	&:hover {
		color: #e7e9eb;
	}
`;

export const SaveButtons = styled.div`
	display: flex;
	flex-flow: row;
	align-items: stretch;
`;

export const SaveIcon = styled(Icon)`
	width: 35px;
	height: 22px;
	cursor: ${props => props.savePointer};
	fill: ${props => props.saveColor};
	${props =>
		props.savePointer === "pointer" &&
		css`
			&:hover {
				fill: #fff;
			}
		`};
`;

export const ExtraSaveIcon = styled(Icon)`
	transform: translate(4px, 2px);
	width: 35px;
	height: 29px;
	cursor: ${props => props.savePointer};
	fill: ${props => props.saveColor};
	&:hover {
		fill: #e7e9eb;
	}
`;

export const SaveAsIcon = styled(Icon)`
	width: 35px;
	height: 24px;
	cursor: ${props => props.saveAsPointer};
	fill: ${props => props.saveAsColor};
	&:hover {
		${props =>
			props.saveAsPointer === "pointer" &&
			css`
				fill: #fff;
			`}
	}
`;
export const WrapperSave = styled.div`
	border-radius: 3px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin-left: 5px;
`;

export const SelectTitle = styled.div`
	font-size: 12px;
	color: #a5b9c8;
`;

export const WrapperSelectAndTitle = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperSelectAndIcons = styled.div`
	${props =>
		props.hideSimulationSelect &&
		css`
			margin-top: 32px;
		`};
	display: flex;
	& > * {
		&:last-child {
			padding-right: 10px;
		}
	}
`;

export const WrapperShortcuts = styled.div`
	display: flex;
	align-items: center;
`;
