import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
	WrapperCategorization,
	WrapperCategory,
	WrapperSelectAndTitle,
	WrapperSelect,
	CategorizationDescription,
	CategorizationTitle,
	WrapperCategoryTitleAndDescription,
} from "./Categorization.style";
import { SelectLinesTitle } from "@components/productsDrawer/components/LeftPanels/LeftPanel.style";
import { SingleSelect, MultiSelect } from "priceit-ui";
import DispatchProductDrawerContext from "@components/productsDrawer/context/DispatchProductDrawerContext";
import { actions } from "@components/productsDrawer/reducer/reducer";
import { CellText } from "@src/pages/myPricingTools/valueBasedPricing/priceSensitivityMeter/respondents/CellText";

export const Categorization = ({ listOfCategorization, listOfProducts }) => {
	const { t } = useTranslation("drawer/page");
	const { state, dispatch } = useContext(DispatchProductDrawerContext);

	return (
		<WrapperCategorization>
			<SelectLinesTitle>{t("Select lines that belong to")}</SelectLinesTitle>
			<div>
				{[...listOfCategorization]
					.sort((a, b) => a.id - b.id)
					.map((categorization, index) => {
						return (
							<WrapperCategory key={"PD-listOfCategories-" + categorization.id}>
								<WrapperSelectAndTitle>
									<WrapperSelect>
										<SingleSelect.Group
											type="primary"
											mode="dark"
											width="75px"
											onChange={e => {
												dispatch({
													type: actions.UPDATE_AND_OR_SELECT,
													value: e,
													id: index - 1,
													listOfProducts,
												});
											}}
											value={state.orAnd[index - 1]}
											appendTo={document.body}
											data={[
												{
													value: "and",
													color: "#FFCD38",
													option: (
														<SingleSelect.Option center>
															{t("and")}
														</SingleSelect.Option>
													),
												},
												{
													value: "or",
													color: "#FFCD38",
													option: (
														<SingleSelect.Option center>
															{t("or")}
														</SingleSelect.Option>
													),
												},
											]}
										/>
									</WrapperSelect>
									<WrapperCategoryTitleAndDescription>
										<CategorizationTitle>
											{categorization.name}
										</CategorizationTitle>
   
										<CategorizationDescription>
											<CellText answer={categorization.description} />
										</CategorizationDescription>
									</WrapperCategoryTitleAndDescription>
								</WrapperSelectAndTitle>

								<MultiSelect.Group
									mode="dark"
									type={"secondary"}
									placeHolder={t("Select categories")}
									onChange={e => {
										dispatch({
											type: actions.UPDATE_CATEGORIES_SELECTION,
											value: e,
											id: index,
											listOfProducts,
										});
									}}
									appendTo={document.body}
									value={state.categorizationsSelected[index]}
									data={[...categorization?.category]
										.sort((a, b) => a.index - b.index)
										.map(category => ({
											value: category?.id || `${index}-${category?.name}`,
											color: category?.color || "#a5b9c8",
											option: (
												<MultiSelect.Option>
													{category?.name}
												</MultiSelect.Option>
											),
										}))}
								/>
							</WrapperCategory>
						);
					})}
			</div>
		</WrapperCategorization>
	);
};
