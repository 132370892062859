import { selectorFamily } from "recoil";
import { packDisabledDueToSpecialDiscountDependencyAtom, specialDiscountStateAtom } from "./atom";

export const specialDiscountStatesByPriceModelIdSelector = selectorFamily({
	key: "specialDiscountStatesByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(specialDiscountStateAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(specialDiscountStateAtom, {
				...get(specialDiscountStateAtom),
				[priceModelId]: newValue,
			});
		},
});

export const specialDiscountStateByPriceModelIdAndDiscountIdSelector = selectorFamily({
	key: "specialDiscountStateByPriceModelIdAndDiscountIdSelector",
	get:
		({ priceModelId, discountId }) =>
		({ get }) =>
			get(specialDiscountStateAtom)?.[priceModelId]?.[discountId],
	set:
		({ priceModelId, discountId }) =>
		({ get, set }, newValue) => {
			const atom = get(specialDiscountStateAtom);

			set(specialDiscountStateAtom, {
				...atom,
				[priceModelId]: {
					...atom?.[priceModelId],
					[discountId]: newValue,
				},
			});
		},
});

export const packDisabledDueToSpecialDiscountDependencyByPriceModelIdSelector = selectorFamily({
	key: "packDisabledDueToSpecialDiscountDependencyByPriceModelIdSelector",
	get:
		({ priceModelId }) =>
		({ get }) =>
			get(packDisabledDueToSpecialDiscountDependencyAtom)?.[priceModelId],
	set:
		({ priceModelId }) =>
		({ get, set }, { newValue, discountId }) => {
			const atom = get(packDisabledDueToSpecialDiscountDependencyAtom);

			set(packDisabledDueToSpecialDiscountDependencyAtom, {
				...atom,
				[priceModelId]: {
					...atom?.[priceModelId],
					[discountId]: newValue,
				},
			});
		},
});
