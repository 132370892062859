import { gql } from "@apollo/client";
import {
	OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT,
	OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_TC_FRAGMENT,
	OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT,
	OFFER_PROPERTIES_FOR_OFFER_FRAGMENT,
	OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT,
	OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT,
	OFFER_PROPERTIES_NAME_FRAGMENT,
	OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_INTRODUCTION_PAGE_FRAGMENT,
} from "../fragments/offerProperties";

export const GET_OFFER_PROPERTIES_BY_ID = gql`
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	query GetOfferPropertiesById($id: ID!) {
		getOfferPropertiesById(id: $id) {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	query GetOfferPropertiesByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT}
	query GetOfferPropertiesEnableDealOwnerContactByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesEnableDealOwnerContactFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	${OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	query GetOfferPropertiesEnableIntroductionTextForProspectByOfferId(
		$offerId: ID!
		$isDraft: Boolean
	) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesEnableIntroductionTextForProspectFragment
			...OfferPropertiesIntroductionTextForProspectFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT}
	query GetOfferPropertiesEnableAttachedPresentationByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesEnableAttachedPresentationFragment
			...OfferPropertiesAttachedPresentationFragment
			...OfferPropertiesAttachedPresentationTitleFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT}
	query GetOfferPropertiesEnableAttachedLinkByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesEnableAttachedLinkFragment
			...OfferPropertiesAttachedLinkFragment
			...OfferPropertiesAttachedLinkTitleFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT}
	${OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT}
	query GetOfferPropertiesEnableClientsLogoByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesEnableClientsLogoFragment
			...OfferPropertiesClientsLogoFragment
		}
	}
`;
export const GET_OFFER_PROPERTIES_NAME_BY_PRICE_MODEL_BY_OFFER_ID = gql`
	${OFFER_PROPERTIES_NAME_FRAGMENT}
	query GetOfferPropertiesNameByOfferId($offerId: ID!, $isDraft: Boolean) {
		getOfferPropertiesByOfferId(offerId: $offerId, isDraft: $isDraft) {
			...OfferPropertiesNameFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_TC_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_TC_FRAGMENT}
	query GetOfferPropertiesEnableTCByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableTcFragment
		}
	}
`;

export const GET_FOOTER_INFO_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT}
	query GetFooterInfoByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableFooterFragment
			...OfferPropertiesFooterContentFragment
			...OfferPropertiesFooterTitleFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_FOR_PRICE_MODEL_COMBINATION_FRAGMENT}
	query GetOfferPropertiesByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesForPriceModelCombinationFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT}
	query GetOfferPropertiesEnableDealOwnerContactByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableDealOwnerContactFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_INTRODUCTION_PAGE_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_PAGE_FRAGMENT}
	query GetOfferPropertiesEnableIntroductionPageByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableIntroductionPageFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	${OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	query GetOfferPropertiesEnableIntroductionTextForProspectByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableIntroductionTextForProspectFragment
			...OfferPropertiesIntroductionTextForProspectFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT}
	query GetOfferPropertiesEnableAttachedPresentationByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableAttachedPresentationFragment
			...OfferPropertiesAttachedPresentationFragment
			...OfferPropertiesAttachedPresentationTitleFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT}
	${OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT}
	query GetOfferPropertiesEnableAttachedLinkByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableAttachedLinkFragment
			...OfferPropertiesAttachedLinkFragment
			...OfferPropertiesAttachedLinkTitleFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT}
	query GetOfferPropertiesEnableClientsLogoByPriceModelCombinationId(
		$priceModelCombinationId: ID!
	) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesEnableClientsLogoFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_NAME_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${OFFER_PROPERTIES_NAME_FRAGMENT}
	query GetOfferPropertiesNameByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getOfferPropertiesByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...OfferPropertiesNameFragment
		}
	}
`;
