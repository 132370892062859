export const computeDifferenceInMonths = (startDate, endDate) => {
	return (
		1 +
		endDate?.getFullYear() * 12 +
		endDate?.getMonth() -
		(startDate?.getFullYear() * 12 + startDate?.getMonth())
	);
};

export const periodTypeToDivider = periodType => {
	let divider;
	switch (periodType) {
		case "month":
			divider = 12;
			break;
		case "quarter":
			divider = 4;
			break;
		case "semester":
			divider = 2;
			break;
		case "year":
			divider = 1;
			break;
		default:
			divider = 12;
	}
	return divider;
};

export const dateToString = rawDate => {
	if (!rawDate) {
		return "-";
	}
	const date = new Date(rawDate);
	let day = date.getDate();
	if (day < 10) {
		day = `0${day}`;
	}
	let month = date.getMonth() + 1;
	if (month < 10) {
		month = `0${month}`;
	}
	const year = date.getFullYear();
	return `${day}-${month}-${year}`;
};
