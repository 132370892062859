const defaultText = {
	totalAmount: "totalAmount",
	totalPrice: "totalPrice",
	unitPrice: "unitPrice",
	highlightTotal: "highlightTotal",
	highlightUnit: "highlightUnit",
	onlyOnce: "onlyOnce",
	range: "range",
	tiered: "tiered",
	vatIncluded: "vatIncluded",
	vatExcluded: "vatExcluded",
	times: "times",
	divide: "divide",
	oneShot: "oneShot",
	outsideTheSidebar: "outsideTheSidebar",
	inTheSidebar: "inTheSidebar",
	month: "month",
	totalCommitment: "totalCommitment",
	price: "price",
	currency: "currency",
	percentage: "percentage",
	hidden: "hidden",
	free: "free",
	plain: "plain",
	tooltip: "tooltip",
	dark: "dark",
	light: "light",
	owner: "owner",
	admin: "admin",
};

export default defaultText;
