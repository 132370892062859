import React from "react";
import {
	VolumeMetricList,
	VolumeMetricItem,
	WrapperTooltipContent,
	WrapperWarningIcon,
} from "./RelatedVolumeHeader.style";
import { CenteredDiv } from "@components/productsDrawer/components/TableViews/TableView.style";
import { useTranslation } from "react-i18next";
import { Icon, Tooltipv3 } from "priceit-ui";

class UniqueNameSet extends Set {
	constructor(values) {
		super(values);
		const volumeMetrics = [];
		for (let value of this) {
			if (volumeMetrics.includes(value.volumeMetric)) {
				this.delete(value);
			} else {
				volumeMetrics.push(value.volumeMetric);
			}
		}
	}
}

const RelatedVolumeHeader = ({ productsSelected, volumeAllocation }) => {
	const { t } = useTranslation("fixedCosts/allocationDrawer");

	const uniqueVolumeMetrics = [...new UniqueNameSet(productsSelected)];
	const volumeMetricsObj = uniqueVolumeMetrics.reduce(
		(o, key) => ({ ...o, [key.volumeMetric]: 0 }),
		{}
	);
	Object.keys(volumeMetricsObj).forEach(key => {
		volumeMetricsObj[key] =
			productsSelected?.filter(product => product.volumeMetric === key)?.length ?? 0;
	});

	const tooltipContent = (
		<WrapperTooltipContent>
			{t("Selected products have different unit metrics") + ": "}
			<VolumeMetricList>
				{Object.keys(volumeMetricsObj).map(key => (
					<VolumeMetricItem key={key}>{`${key}: ${volumeMetricsObj[key]} ${
						volumeMetricsObj[key] > 1 ? t("occurrences") : t("occurrence")
					}`}</VolumeMetricItem>
				))}
			</VolumeMetricList>
		</WrapperTooltipContent>
	);

	return (
		<CenteredDiv row>
			{t("Related volume")}
			{volumeAllocation !== 0 && uniqueVolumeMetrics?.length > 1 ? (
				<Tooltipv3
					placement={"top"}
					type={"warning"}
					mode={"dark"}
					interactive={false}
					content={tooltipContent}
					style={{ marginLeft: "2px", display: "flex", alignItems: "center" }}
					appendTo={document.body}
				>
					<WrapperWarningIcon>
						<Icon type="warning2" fill={"#E68357"} height="14px" width="14px" />
					</WrapperWarningIcon>
				</Tooltipv3>
			) : null}
		</CenteredDiv>
	);
};

export default RelatedVolumeHeader;
