import React, { memo } from "react";
import { Button } from "priceit-ui";

import { useTranslation } from "react-i18next";
import {
	Title,
	Wrapper,
	WrapperBackButtonAndText,
	WrapperButtons,
} from "./CreateNewOfferHeader.style";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
	clientsOfferNewVersionIdSelector,
	clientsOffersIsNewOfferVersionSelector,
	clientsOffersResetSelectedOfferSelector,
	clientsOffersSelectedIdSelector,
	sectionName,
} from "@recoil/clientOffers";

import { deleteOfferMutation } from "@graphQl/mutations/offer";
import { deleteOfferVersionMutation } from "@graphQl/mutations/offerVersion";
import NextButton from "./components/NextButton";
import CloseButton from "./components/CloseButton";
import { useThemeContext } from "@theme/Theme";
import { useGlobalContext } from "@globalContext";
import { useIframeActions } from "@hooks/UseIframeActions";
import { clientOffersIsEditingPreselectionAtom } from "@recoil/clientOffers/atom";
import { OfferNameEditingPreselection } from "@pages/clientsOffers/createNewOfferPage/createNewOfferHeader/components/OfferNameEditingPreselection";
import {
	publicLinkCurrentPriceModelCombinationIdAtom,
	publicLinkSelectedPackIdAtom,
	publicLinkSelectedTabAtom,
} from "@src/recoil/publicPriceModel";
import {
	clientsOffersCreationStepAtom,
	clientsOffersIsNewOfferAtom,
	clientsOffersNewPriceModelCombinationAtom,
	clientsOffersSelectedTemplateAtom,
	clientsOffersShowCreationModalAtom,
	clientsOffersTemplateFiltersAtom,
	lastOfferPropertiesDraftIdAtom,
	offerNameEditedAtom,
} from "@src/recoil/clientOffers/atom";
import SkipButton from "@pages/clientsOffers/createNewOfferPage/createNewOfferHeader/components/SkipButton";

export const CreateNewOfferHeader = memo(({ hasOnlyOnePriceModelCombination }) => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode, page: pageColors } = useThemeContext();
	const { light } = useGlobalContext();
	const [cancelCreateOffer] = useIframeActions();
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const [isNewOfferVersion, setIsNewOfferVersion] = useRecoilState(
		clientsOffersIsNewOfferVersionSelector
	);
	const [newOfferVersionId, setNewOfferVersionId] = useRecoilState(
		clientsOfferNewVersionIdSelector
	);
	const [creationStep, setCreationStep] = useRecoilState(clientsOffersCreationStepAtom);
	const resetSelectedOffer = useSetRecoilState(clientsOffersResetSelectedOfferSelector);
	const isEditingPreselection = useRecoilValue(clientOffersIsEditingPreselectionAtom);

	const setSelectedPacksIds = useSetRecoilState(publicLinkSelectedPackIdAtom);
	const setSelectedTab = useSetRecoilState(publicLinkSelectedTabAtom);

	const setIsNewOffer = useSetRecoilState(clientsOffersIsNewOfferAtom);
	const setLastOfferPropertiesDraftId = useSetRecoilState(lastOfferPropertiesDraftIdAtom);
	const setIsEditable = useSetRecoilState(offerNameEditedAtom);

	const setCurrentPriceModelCombinationId = useSetRecoilState(
		publicLinkCurrentPriceModelCombinationIdAtom
	);
	const setPriceModelCombination = useSetRecoilState(
		publicLinkCurrentPriceModelCombinationIdAtom
	);

	const setSelectedTemplate = useSetRecoilState(clientsOffersSelectedTemplateAtom);
	const setNewPriceModelCombination = useSetRecoilState(
		clientsOffersNewPriceModelCombinationAtom
	);

	const setFilter = useSetRecoilState(clientsOffersTemplateFiltersAtom);
	const setShowCreationModal = useSetRecoilState(clientsOffersShowCreationModalAtom);

	const [deleteOffer] = deleteOfferMutation();
	const [deleteOfferVersion] = deleteOfferVersionMutation({ offerId });
	const deleteOfferVersionFunction = () =>
		deleteOfferVersion({
			variables: {
				id: newOfferVersionId,
			},
			optimisticResponse: {
				deleteOfferVersion: {
					__typename: "OfferVersion",
					id: newOfferVersionId,
				},
			},
		});

	const resetOfferCreationParameter = () => {
		setLastOfferPropertiesDraftId(null);
		setIsEditable(true);
		setCurrentPriceModelCombinationId(null);
		setIsNewOffer(null);
		setIsNewOfferVersion(false);
		setSelectedTemplate(null);
		setFilter({});
		setNewOfferVersionId(null);
		setNewPriceModelCombination(null);
		setShowCreationModal(false);
		setSelectedPacksIds([]);
		setSelectedTab(null);
		setCreationStep(sectionName.offerCreationStep.templateSelection);
	};
	const deleteOfferFunction = () =>
		deleteOffer({
			variables: {
				id: offerId,
			},
			optimisticResponse: {
				deleteOffer: {
					__typename: "Offer",
					id: offerId,
				},
			},
		});

	const displayCancelButton = () => {
		switch (creationStep) {
			case sectionName.offerCreationStep.templateSelection:
				if (light) {
					return t("Cancel");
				}
				return t("Back to Offers pipeline");

			case sectionName.offerCreationStep.customization:
				if (hasOnlyOnePriceModelCombination) {
					if (light) {
						return t("Cancel");
					}
					return t("Back to Offers pipeline");
				}
				return t("Back to template selection");

			case sectionName.offerCreationStep.preSelectionOfOptions:
				return t("Back to customization");

			default:
				return null;
		}
	};

	const deleteOfferAndCancel = () => {
		if (!isNewOfferVersion) {
			deleteOfferFunction().then(() => cancelCreateOffer());
		} else if (newOfferVersionId) {
			deleteOfferVersionFunction().then(() => cancelCreateOffer());
		} else {
			cancelCreateOffer();
		}
		resetSelectedOffer(null);
	};

	const handleCancelAction = () => {
		switch (creationStep) {
			case sectionName.offerCreationStep.templateSelection:
				deleteOfferAndCancel();
				break;
			case sectionName.offerCreationStep.customization:
				if (hasOnlyOnePriceModelCombination) {
					deleteOfferAndCancel();
					break;
				}
				setSelectedTemplate(null);
				setNewOfferVersionId(null);
				setPriceModelCombination(null);
				if (newOfferVersionId) {
					deleteOfferVersionFunction();
				}
				setCreationStep(sectionName.offerCreationStep.templateSelection);
				break;
			case sectionName.offerCreationStep.preSelectionOfOptions:
				setCreationStep(sectionName.offerCreationStep.customization);
				break;
			case sectionName.refreshTemplate:
				if (newOfferVersionId) {
					deleteOfferVersionFunction().then(() => cancelCreateOffer());
				}
				resetSelectedOffer(null);
				resetOfferCreationParameter();
				break;
			default:
				break;
		}
	};

	return (
		<Wrapper justify={isEditingPreselection}>
			{isEditingPreselection ? (
				<OfferNameEditingPreselection />
			) : (
				<WrapperBackButtonAndText>
					{creationStep !== sectionName.refreshTemplate && (
						<Button
							mode={mode}
							type="tertiary"
							onClick={handleCancelAction}
							icon={"left"}
							iconHeight={"8px"}
							iconWidth={"8px"}
							theme={{
								colorText: pageColors.secondaryTextColor,
								colorIcon: pageColors.secondaryTextColor,
								border: "unset",
							}}
							padding="0 0 2px"
							height="20px"
							withShadow={false}
						>
							{displayCancelButton()}
						</Button>
					)}
					<Title>
						{creationStep === sectionName.refreshTemplate
							? t("Update of the offer")
							: t("New offer")}
					</Title>
				</WrapperBackButtonAndText>
			)}
			{isEditingPreselection ? (
				<CloseButton />
			) : (
				<WrapperButtons>
					{creationStep === sectionName.refreshTemplate && (
						<Button
							mode={mode}
							type="cancel"
							width={"160px"}
							onClick={handleCancelAction}
						>
							{t("Cancel")}
						</Button>
					)}
					{creationStep === sectionName.offerCreationStep.customization && <SkipButton />}
					{creationStep !== sectionName.offerCreationStep.templateSelection && (
						<NextButton />
					)}
				</WrapperButtons>
			)}
		</Wrapper>
	);
});
CreateNewOfferHeader.whyDidYouRender = true;
CreateNewOfferHeader.displayName = "CreateNewOfferHeader";
export default CreateNewOfferHeader;
