import styled from "styled-components";

export const ProductBubbleWrapper = styled.div`
	height: 100%;
	border: 0.5px solid #cbcbcb;
	border-radius: 20px;
	font-size: 14px;
	padding-left: 5px;
	display: flex;
	justify-content: space-between;
	color: #cbcbcb;
`;
