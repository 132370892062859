export const fallbackCopyTextToClipboard = text => {
	const textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		const successful = document.execCommand("copy");
		document.body.removeChild(textArea);
		return successful;
	} catch (err) {
		document.body.removeChild(textArea);
		return false;
	}
};
export const copyTextToClipboard = async text => {
	if (!navigator.clipboard) {
		try {
			fallbackCopyTextToClipboard(text);
			return true;
		} catch {
			return false;
		}
	}
	try {
		await navigator.clipboard.writeText(text);
		return true;
	} catch {
		try {
			fallbackCopyTextToClipboard(text);
			return true;
		} catch {
			return false;
		}
	}
};
