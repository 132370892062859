import { gql } from "@apollo/client";

export const CATEGORY_ID_FRAGMENT = gql`
	fragment CategoryIdFragment on CategoryNew {
		id
	}
`;

export const CATEGORY_CATEGORIZATIONID_FRAGMENT = gql`
	fragment CategoryCategorizationIdFragment on CategoryNew {
		id
		categorizationId
	}
`;

export const CATEGORY_NAME_FRAGMENT = gql`
	fragment CategoryNameFragment on CategoryNew {
		id
		name
	}
`;

export const CATEGORY_COLOR_FRAGMENT = gql`
	fragment CategoryColorFragment on CategoryNew {
		id
		color
	}
`;

export const CATEGORY_INDEX_FRAGMENT = gql`
	fragment CategoryIndexFragment on CategoryNew {
		id
		index
	}
`;

export const CATEGORY_ERRORS_FRAGMENT = gql`
	fragment CategoryErrorsFragment on CategoryNew {
		id
		errors {
			name
		}
	}
`;

export const CATEGORY_NEW_FRAGMENT = gql`
	${CATEGORY_ID_FRAGMENT}
	${CATEGORY_CATEGORIZATIONID_FRAGMENT}
	${CATEGORY_NAME_FRAGMENT}
	${CATEGORY_COLOR_FRAGMENT}
	${CATEGORY_INDEX_FRAGMENT}
	${CATEGORY_ERRORS_FRAGMENT}
	fragment CategoryNewFragment on CategoryNew {
		...CategoryIdFragment
		...CategoryCategorizationIdFragment
		...CategoryNameFragment
		...CategoryColorFragment
		...CategoryIndexFragment
		...CategoryErrorsFragment
	}
`;
