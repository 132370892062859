import { gql } from "@apollo/client";
import { COLUMN_NAME_AND_FILTER_FRAGMENT } from "@graphQl/fragments/column";

export const GROUP_BY_FRAGMENT = gql`
	fragment GroupByFragment on GroupBy {
		id
		selected
		expanded
	}
`;

export const TABLE_GROUP_BY_FRAGMENT = gql`
	${GROUP_BY_FRAGMENT}
	fragment TableGroupByFragment on OldTable {
		id
		groupBy {
			...GroupByFragment
		}
	}
`;

export const TABLE_COLUMNS_ID_AND_NAME_FRAGMENT = gql`
	fragment TableColumnsIdAndNameFragment on OldTable {
		id
		columns {
			id
			name
		}
	}
`;

export const TABLE_COLUMNS_FILTER_FRAGMENT = gql`
	${COLUMN_NAME_AND_FILTER_FRAGMENT}
	fragment TableColumnsFilterFragment on OldTable {
		id
		columns {
			...ColumnNameAndFilterFragment
		}
	}
`;
