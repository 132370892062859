import { gql } from "@apollo/client";

export const GET_PRODUCTS_BY_PERIOD_ID = gql`
	query getProductsByPeriodId($periodId: ID!) {
		getProductsByPeriodId(periodId: $periodId) {
			id
			index
			name
			description
			imagePath
			price
			periodId
			tva
			deductibleTva
			volume
			volumeMetric
			frequency
			frequencyMetric
			discount
			createdBy
			updatedBy
			createdAt
			updatedAt
			variableCosts
			priceIsKnowingIn
			paymentFrequency
			timeMetric
			netPriceExclVAT
			netPriceInclVAT
			priceExclVAT
			priceInclVAT
			grossUnitProfit
			revenue
			semiVariableCostParts {
				id
				value
				productId
				fixedCostId
			}
			categories {
				id
				categorizationId
				name
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
		}
	}
`;
