import { createGlobalStyle } from "styled-components";
import Highcharts from "highcharts";

export const Style = createGlobalStyle`
	/* latin-ext */
	@font-face {
	  font-family: 'Lato';
	  font-style: normal;
	  font-weight: 400;
	  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
	  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}
	/* latin */
	@font-face {
	 	font-family: 'Lato';
	  	font-style: normal;
	 	font-weight: 400;
		src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}
	
	* {
		scrollbar-color: #A5B9C8;
		scrollbar-width: thin;
	}

	body {
		font-family: ${props => props.fontFamily};
		overflow-x: hidden;
	}

    ::-webkit-scrollbar {
		width: 10px;
		height: 10px;
      }

	::-webkit-scrollbar-corner {
		background-color: transparent;
	}
      
	/* Track */
	::-webkit-scrollbar-track {
	border-radius: 10px;
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
	background: #A5B9C8; 
	border-radius: 10px;
	}
	
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	background: #71889A; 
	}

	.ant-slider-handle{
		border: 2px solid #f8b500 !important;
		background-color: #f8b500 !important;
	}

	.ant-slider-handle:hover{
		border: 2px solid #f8b500!important;
		background-color: #f8b500!important;
	}

	.ant-slider-track{
		background-color: #f8b500!important;
	}

	.ant-slider-track:hover{
		background-color: #f8b500!important;

	}

	.ant-slider-handle-focused{
		background-color: #f8b500!important;
	}

	.ant-slider-handle.ant-tooltip-open{
		border-color: #f8b500!important;
	}

	.ant-slider-rail:hover + .ant-slider-track {
		border-color: #f8b500!important;
		background-color: #f8b500!important;

	}

	.ant-slider-step:hover ~ .ant-slider-track {
		border-color: #f8b500!important;
		background-color: #f8b500!important;

	}

	.ant-slider.ant-slider-vertical:hover .ant-slider-track {
		border-color: #f8b500!important;
		background-color: #f8b500!important;

	}

	.ant-slider.ant-slider-vertical:hover .ant-slider-handle {
		border-color: #f8b500!important;
		box-shadow: 0 0 0 5px rgba(248, 181, 0, 0.2)!important;
		background-color: #f8b500!important;

	}

	.ant-slider-handle-click-focused {
		border-color: #f8b500!important;
		box-shadow: 0 0 0 5px rgba(248, 181, 0, 0.2)!important;
		background-color: #f8b500!important;

	}

	.ant-slider-handle:focus {
		box-shadow: 0 0 0 5px rgba(248, 181, 0, 0.2)!important;
		border-color: #f8b500!important;
		background-color: #f8b500!important;

	}
	
	.rc-notification {
		z-index: 1001 !important; 
	}
    
`;

Highcharts.createElement(
	"link",
	{
		href: "https://fonts.googleapis.com/css?family=Unica+One",
		rel: "stylesheet",
		type: "text/css",
	},
	null,
	document.getElementsByTagName("head")[0]
);
Highcharts.theme = {
	colors: [
		"#212730",
		"#f8b500",
		"#f45b5b",
		"#7798BF",
		"#aaeeee",
		"#ff0066",
		"#eeaaee",
		"#55BF3B",
		"#DF5353",
		"#7798BF",
		"#aaeeee",
	],
	plotOptions: {
		series: {
			borderColor: "#f8b50000",
			animation: false,
		},
	},

	exporting: {
		buttons: {
			contextButton: {
				menuItems: ["viewFullscreen", "downloadSVG", "printChart"],
			},
		},
		chartOptions: {
			chart: {
				animation: false,
				spacing: [15, 15, 30, 15],
				// events: {
				// 	load: function () {
				// 		this.renderer
				// 			.image(
				// 				"https://priceit-prod-assets.s3-eu-west-3.amazonaws.com/images/Pricing+bird.png",
				// 				10, // x
				// 				this.plotTop + this.plotHeight + 40, // y
				// 				30, // width
				// 				30 // height
				// 			)
				// 			.add();
				// 		this.renderer
				// 			.text(
				// 				"Made with Givemefive",
				// 				50, // x
				// 				this.plotTop + this.plotHeight + 50, // y
				// 				30, // width
				// 				30 // height
				// 			)
				// 			.add()
				// 			.toFront();
				// 	},
				// },
			},
		},
	},
	responsive: {},
	chart: {
		style: { fontFamily: "Lato", fontStyle: "normal", fontWeight: 400 },
	},
};
// Apply the theme
Highcharts.setOptions(Highcharts.theme);
