import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ExpirationDateLabel,
	ExpirationDateLabelBis,
	InputTitle,
	InputWrapper,
	StyledIconButton,
	StyledInfoIcon,
	StyledInputNumber,
	StyledSingleSelect,
	StyledTooltip,
	WrapperButtonAndDate,
	WrapperDateInput,
	WrapperDelay,
	WrapperDelayInput,
	WrapperDelayInputAndSelect,
	WrapperEmpty,
	WrapperExpirationDateInputs,
	WrapperInputButtons,
	WrapperLineComponentNeedRowDirection,
	WrapperRadioButton,
	WrapperTextAndIconForParameters,
	WrapperTitleIconAndSwitchForParameters,
} from "./Components.style";
import { Button, Radio, SingleSelect, ToggleSwitch } from "priceit-ui";
import { RadioButton } from "@pages/settings/settings/components/termsAndConditions/TermsAndConditions.style";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_OFFER_TOKEN_BY_OFFER_ID } from "@graphQl/queries/offer";

import {
	updateTokenEnableExpirationDateMutation,
	updateTokenExpirationDateMutation,
} from "@graphQl/mutations/token";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
	clientOffersIsValidDateSelector,
	clientsOffersIsNewOfferVersionSelector,
	clientsOffersSelectedIdSelector,
} from "@recoil/clientOffers";
import { priceMetricTrad, timeMetricsObj } from "@services/i18n";
import { convertMetric } from "@services/metrics";
import { useThemeContext } from "@theme/Theme";
import { ThemeContext } from "styled-components";

export const ExpirationDate = memo(({ isDrawer }) => {
	const { t } = useTranslation("specific/clientsOffers");
	const { mode } = useThemeContext();
	const timeMetricsTrad = timeMetricsObj();
	const theme = useContext(ThemeContext);

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const [expirationDateType, setExpirationDateType] = useState("delay");
	const [showInput, setShowInput] = useState(false);

	const [date, setDate] = useState(null);
	const [tempDate, setTempDate] = useState(null);
	const [delay, setDelay] = useState(1);
	const [delayUnit, setDelayUnit] = useState("month");

	const setIsValidDate = useSetRecoilState(clientOffersIsValidDateSelector);

	const isNewOfferVersion = useRecoilValue(clientsOffersIsNewOfferVersionSelector);

	const timeMetrics = ["day", "week", "month", "semester", "year"]; //TODO: a refacto et utiliser timeMetricsEnum et filtrer

	const { data: dataOffer } = useQuery(GET_OFFER_TOKEN_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const { tokenObj } = dataOffer?.getOfferById || { tokenObj: {} };
	const { id: tokenId, enableExpirationDate, expirationDate, active } = tokenObj || {};

	const expirationDateFormatted = moment(expirationDate).format("DD-MM-YYYY");

	useEffect(() => {
		if (isDrawer) {
			setDate(expirationDateFormatted);
		}
	}, [expirationDateFormatted]);

	useEffect(() => {
		if (isNewOfferVersion) {
			setExpirationDateType("date");
			setTempDate(expirationDateFormatted);
		}
	}, []);

	const [updateTokenEnableExpirationDate] = updateTokenEnableExpirationDateMutation();
	const [updateTokenExpirationDate] = updateTokenExpirationDateMutation();

	const updateExpirationDateWithDelay = ({ delayInput, delayUnitInput }) => {
		const formattedDate = moment
			.utc()
			.add(convertMetric(delayInput, delayUnitInput).day, "days")
			.format();
		setIsValidDate(moment(formattedDate).format("DD-MM-YYYY"));
		const isExpired = moment(formattedDate).isBefore(moment(), "day");

		updateTokenExpirationDate({
			variables: {
				token: {
					id: tokenId,
					expirationDate: formattedDate,
				},
			},
			optimisticResponse: {
				updateToken: {
					__typename: "Token",
					id: tokenId,
					expirationDate: formattedDate,
					isExpired,
					active: isExpired ? false : active,
				},
			},
		});
	};

	const updateExpirationDate = async (newDate = date) => {
		const newDateValue = moment.utc(newDate, "DDMMYYYY", true).isValid()
			? moment.utc(newDate, "DDMMYYYY").format()
			: null;
		const isExpired = moment(newDateValue).isBefore(moment(), "day");

		await updateTokenExpirationDate({
			variables: {
				token: {
					id: tokenId,
					expirationDate: newDateValue,
				},
			},
			optimisticResponse: {
				updateToken: {
					__typename: "Token",
					id: tokenId,
					expirationDate: newDateValue,
					isExpired,
					active: isExpired ? false : active,
				},
			},
		});
	};

	const delaysMetricsData = useMemo(
		() =>
			timeMetrics.map(timeMetric => ({
				value: timeMetric,
				option: (
					<SingleSelect.Option key={timeMetric} value={timeMetric}>
						{priceMetricTrad({
							priceMetricList: timeMetricsTrad,
							priceMetric: timeMetric,
							count: parseInt(delay) || 1,
						})}
					</SingleSelect.Option>
				),
			})),
		[timeMetrics, timeMetricsTrad, delay]
	);

	const confirmExpirationDateChange = () => {
		setShowInput(false);
		void updateExpirationDate();
	};

	const dateInput = (
		<StyledInputNumber
			mode={mode}
			width={"160px"}
			height={"30px"}
			format="##-##-####"
			mask={[
				t("maskDateDay"),
				t("maskDateDay"),
				t("maskDateMonth"),
				t("maskDateMonth"),
				t("maskDateYear"),
				t("maskDateYear"),
				t("maskDateYear"),
				t("maskDateYear"),
			]}
			placeholder={t("DD-MM-YYYY")}
			disabled={expirationDateType !== "date" && !isDrawer}
			value={showInput ? tempDate : expirationDateFormatted}
			onChange={value => {
				setIsValidDate(value.formattedValue);
				const newDate = moment(value.formattedValue, "DD-MM-YYYY").format("DDMMYYYY");
				setDate(newDate);
				setTempDate(value.formattedValue);
			}}
			onBlur={() => {
				if (!isDrawer) {
					void updateExpirationDate();
				}
			}}
			onPressEnter={() => (isDrawer ? confirmExpirationDateChange() : null)}
		/>
	);

	return (
		<WrapperLineComponentNeedRowDirection isDrawer={isDrawer}>
			<WrapperTitleIconAndSwitchForParameters isDrawer={isDrawer}>
				<WrapperTextAndIconForParameters isMinWidth={isDrawer}>
					<InputTitle>{t("Expiration date")}</InputTitle>
					<StyledTooltip
						placement={"top"}
						mode={mode}
						type="primary"
						maxWidth="350px"
						content={t(
							"Date when the offer link will become inaccessible to your prospects. This date can be changed at any time."
						)}
						appendTo={document.body}
						interactive={false}
					>
						<StyledInfoIcon type={"info"} />
					</StyledTooltip>
				</WrapperTextAndIconForParameters>
				<ToggleSwitch
					type={"outer"}
					mode={mode}
					checked={enableExpirationDate}
					onClick={() => {
						void updateTokenEnableExpirationDate({
							variables: {
								token: {
									id: tokenId,
									enableExpirationDate: !enableExpirationDate,
									expirationDate:
										!enableExpirationDate && !expirationDate
											? moment().add(1, "month")
											: undefined,
								},
							},
							optimisticResponse: {
								updateToken: {
									__typename: "Token",
									id: tokenId,
									enableExpirationDate: !enableExpirationDate,
									expirationDate:
										!enableExpirationDate && !expirationDate
											? moment().add(1, "month")
											: undefined,
								},
							},
						});
					}}
				/>
			</WrapperTitleIconAndSwitchForParameters>
			{enableExpirationDate &&
				(isDrawer ? (
					showInput ? (
						<InputWrapper>
							{dateInput}
							{date && (
								<WrapperInputButtons>
									<StyledIconButton
										type={"checkMark"}
										onClick={confirmExpirationDateChange}
									/>
								</WrapperInputButtons>
							)}
						</InputWrapper>
					) : (
						<WrapperButtonAndDate>
							<ExpirationDateLabelBis>
								{t(moment(date, "DDMMYYYY").format("DD-MM-YYYY"))}
							</ExpirationDateLabelBis>
							<Button
								onClick={() => {
									setIsValidDate(expirationDateFormatted);
									const newDate = moment(
										expirationDateFormatted,
										"DD-MM-YYYY"
									).format("DDMMYYYY");
									setDate(newDate);
									setTempDate(expirationDateFormatted);
									setShowInput(true);
								}}
								type={"add"}
								icon={"editPlain"}
								mode={mode}
								padding={"0"}
								styleLeftIcon={{
									fill: theme.primaryColor,
									stroke: theme.primaryColor,
								}}
							></Button>
						</WrapperButtonAndDate>
					)
				) : (
					<Radio.Group
						mode={mode}
						selected={expirationDateType}
						onChange={async val => {
							setExpirationDateType(val);
							let newDate;
							if (val === "delay") {
								setDelay(1);
								setDelayUnit("month");
								newDate = moment()
									.add(convertMetric(1, "month").day, "days")
									.format("DDMMYYYY");
								setIsValidDate(
									moment()
										.add(convertMetric(1, "month").day, "days")
										.format("DD-MM-YYYY")
								);
							} else {
								if (!newDate) {
									newDate = moment().add(1, "month");
								}
								setIsValidDate(newDate);
							}
							setDate(newDate);
							await updateExpirationDate(newDate);
						}}
						vertical
						shape={"circle"}
					>
						<WrapperExpirationDateInputs>
							<WrapperDateInput>
								<WrapperRadioButton>
									<RadioButton value="date">{t("Date:")}</RadioButton>
								</WrapperRadioButton>
								{expirationDateType === "date" && <>{dateInput}</>}
							</WrapperDateInput>
							<WrapperDelayInput>
								<WrapperRadioButton>
									<Radio.Button value="delay">{t("Deadline:")}</Radio.Button>
								</WrapperRadioButton>
								{expirationDateType === "delay" && (
									<>
										<WrapperDelay>
											<WrapperDelayInputAndSelect>
												<StyledInputNumber
													mode={mode}
													width={"45px"}
													height={"30px"}
													delay={100}
													disabled={expirationDateType !== "delay"}
													onChange={e => {
														setDelay(e.formattedValue);
														updateExpirationDateWithDelay({
															delayInput: e.formattedValue,
															delayUnitInput: delayUnit,
														});
													}}
													value={delay}
													theme={{
														textAlign: "center",
													}}
												/>
												<StyledSingleSelect
													type="basic"
													mode={mode}
													width={"120px"}
													appendTo={document.body}
													disabled={expirationDateType !== "delay"}
													onChange={e => {
														setDelayUnit(e);
														updateExpirationDateWithDelay({
															delayInput: delay,
															delayUnitInput: e,
														});
													}}
													value={delayUnit}
													placeHolder={t("-")}
													data={delaysMetricsData}
												/>
											</WrapperDelayInputAndSelect>
										</WrapperDelay>
										<WrapperEmpty />
										<ExpirationDateLabel>
											{t("expires on {{date}}", {
												date: moment()
													.add(
														convertMetric(delay, delayUnit).day,
														"days"
													)
													.format("DD-MM-YYYY"),
											})}
										</ExpirationDateLabel>
									</>
								)}
							</WrapperDelayInput>
						</WrapperExpirationDateInputs>
					</Radio.Group>
				))}
		</WrapperLineComponentNeedRowDirection>
	);
});

ExpirationDate.displayName = "ExpirationDate";
export default ExpirationDate;
