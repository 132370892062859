import { gql, useMutation } from "@apollo/client";

const CREATE_TABLE_PROPS = gql`
	mutation CreateTable($createTable: CreateTable!) {
		createTable(table: $createTable) {
			id
			name
			periodId
			workspaceId
			userId
			columns {
				id
				tableId
				collapsed
				hidden
				index
				name
				width
			}
		}
	}
`;

export const createPeriodTableMutation = () => {
	const [createTable] = useMutation(CREATE_TABLE_PROPS, {
		update(cache, { data: { createTable } }) {
			cache.modify({
				fields: {
					getTableByPeriodIdAndName(existingTableRef, { storeFieldName }) {
						const newTableRef = cache.writeFragment({
							data: createTable,
							fragment: gql`
								fragment NewTable on Table {
									id
									name
									periodId
									workspaceId
									userId
									columns {
										id
										tableId
										collapsed
										hidden
										index
										name
										width
									}
								}
							`,
						});
						if (
							storeFieldName ===
							`getTableByPeriodIdAndName({"name":"${createTable.name}","periodId":"${createTable.periodId}"})`
						) {
							return newTableRef;
						} else {
							return existingTableRef;
						}
					},
				},
			});
		},
	});

	return createTable;
};

export const createTableMutation = () => {
	const [createTable] = useMutation(CREATE_TABLE_PROPS, {
		update(cache, { data: { createTable } }) {
			cache.modify({
				fields: {
					getOldTableByName(existingTableRef, { storeFieldName }) {
						const newTableRef = cache.writeFragment({
							data: createTable,
							fragment: gql`
								fragment NewTable on Table {
									id
									name
									periodId
									workspaceId
									userId
									columns {
										id
										tableId
										collapsed
										hidden
										index
										name
										width
									}
								}
							`,
						});
						if (
							storeFieldName === `getOldTableByName({"name":"${createTable.name}"})`
						) {
							return newTableRef;
						} else {
							return existingTableRef;
						}
					},
				},
			});
		},
	});

	return createTable;
};

const CREATE_COLUMN = gql`
	mutation CreateColumn($createColumn: CreateColumn!) {
		createColumn(column: $createColumn) {
			id
			tableId
			collapsed
			hidden
			index
			name
			width
			sort
		}
	}
`;

export const createColumnMutation = () => {
	const [createColumn] = useMutation(CREATE_COLUMN, {
		update(cache, { data: { createColumn } }) {
			cache.modify({
				id: `Table:${createColumn.tableId}`,
				fields: {
					columns(existingColumns) {
						const newColumnRef = cache.writeFragment({
							data: createColumn,
							fragment: gql`
								fragment NewColumn on Column {
									id
								}
							`,
						});
						return [...existingColumns, newColumnRef];
					},
				},
			});
		},
	});
	return createColumn;
};

const UPDATE_COLUMN = gql`
	mutation UpdateColumn($updateColumn: UpdateOldColumn!) {
		updateOldColumn(column: $updateColumn) {
			id
			tableId
			collapsed
			hidden
			index
			name
			width
			sort
		}
	}
`;

export const updateColumnMutation = () => {
	const [updateColumn] = useMutation(UPDATE_COLUMN);
	return updateColumn;
};

const SET_VIEW_AS_DEFAULT = gql`
	mutation SetDefaultViewForRoles($roleIds: [ID]!, $tableId: ID!) {
		setDefaultViewForRoles(roleIds: $roleIds, tableId: $tableId)
	}
`;

export const setViewAsDefaultMutation = () => {
	const [setDefaultView] = useMutation(SET_VIEW_AS_DEFAULT);
	return setDefaultView;
};
