import styled, { css } from "styled-components";
import { Icon } from "priceit-ui";

export const Wrapper = styled.div`
	&:first-child {
		margin-left: 5px;
	}
	margin-right: 7px;
	&:last-child {
		margin-right: 0;
	}
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledIcon = styled(Icon)`
	width: ${props => props.iconWidth ?? "24px"};
	height: ${props => props.iconHeight ?? "22px"};
	fill: ${props =>
		props.disabled
			? props.theme.header.disabledTextColor
			: props.colorFill ?? props.theme.header.textColor};
	${props =>
		!props.disabled &&
		css`
			&:hover {
				fill: ${props.hoverFill ?? props.colorFill ?? props.theme.header.hoverTextColor};
			}
		`};
	${props =>
		props.isCursor &&
		!props.disabled &&
		css`
			cursor: pointer;
		`}
	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
		`}
`;
