import { gql } from "@apollo/client";

export const GET_USER_WORKSPACE_DEFAULT_PRICE_MODEL_ID_BY_USER_ID = gql`
	query GetUserWorkspaceDefaultPriceModelIdByUserId($userId: ID) {
		getUserWorkspaceDefaultByUserId(userId: $userId) {
			id
			priceModelId
		}
	}
`;

export const GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID = gql`
	query GetUserWorkspaceDefaultPeriodIdByUserId($userId: ID!) {
		getUserWorkspaceDefaultByUserId(userId: $userId) {
			id
			periodId
		}
	}
`;
