import styled from "styled-components";
import { Icon } from "priceit-ui";

export const Wrapper = styled.div`
	height: 80px;
	min-height: 80px;
	border-bottom: 1px solid ${({ theme }) => theme.page.borderColor};
`;

export const WrapperFlowControl = styled.div`
	padding: 20px 40px;
	height: 100%;
	transition: width 0.3s ease-in-out;
	width: 60%;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
`;

export const StyledIcon = styled(Icon)`
	width: 10px;
	height: 10px;
	fill: ${({ theme }) => theme.page.textColor};
`;
