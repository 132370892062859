import { useCallback, useEffect, useMemo, useRef } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import { currentPeriodIdVar } from "@src/cache/cache";
import { GET_TABLE_PROPS, GET_TABLE_PROPS_BY_NAME } from "@hooks/useSaveTable/graphQl/query";
import {
	createColumnMutation,
	createPeriodTableMutation,
	createTableMutation,
	updateColumnMutation,
} from "@hooks/useSaveTable/graphQl/mutation";

export const tableNames = {
	period: "period",

	fixedCostView: "fixedCostView",
	listView: "listView",

	revenue: "revenue",

	fixedCosts: "fixedCosts",
	variableCosts: "variableCosts",
};

export const useSaveTable = ({ tableName }) => {
	const currentPeriodId = useReactiveVar(currentPeriodIdVar);

	let dataTableProps = null;
	let errorTableProps = null;
	let loadingTableProps = null;

	const isWorkspaceTable = [tableNames.period].includes(tableName);

	const {
		loading: loadingTablePropsByWorkspace,
		error: errorTablePropsByWorkspace,
		data: dataTablePropsByWorkspace,
	} = useQuery(GET_TABLE_PROPS_BY_NAME, {
		skip: !isWorkspaceTable,
		variables: {
			name: tableName,
		},
	});

	const {
		loading: loadingTablePropsByPeriod,
		error: errorTablePropsByPeriod,
		data: dataTablePropsByPeriod,
	} = useQuery(GET_TABLE_PROPS, {
		skip: currentPeriodId === "0" || isWorkspaceTable,
		variables: {
			periodId: currentPeriodId,
			name: tableName,
		},
	});

	if (isWorkspaceTable) {
		dataTableProps = dataTablePropsByWorkspace;
		errorTableProps = errorTablePropsByWorkspace;
		loadingTableProps = loadingTablePropsByWorkspace;
	} else {
		dataTableProps = dataTablePropsByPeriod;
		errorTableProps = errorTablePropsByPeriod;
		loadingTableProps = loadingTablePropsByPeriod;
	}

	const tablePropsRef = useRef(null);
	useEffect(() => {
		if (currentPeriodId !== "0") {
			tablePropsRef.current = dataTableProps;
		}
	}, [dataTableProps]);

	const createPeriodTable = createPeriodTableMutation();
	const createTable = createTableMutation();
	const updateColumn = updateColumnMutation();
	const createColumn = createColumnMutation();

	const objEquals = (newObj, oldObj) => {
		const keys1 = Object.keys(newObj);

		for (const key of keys1) {
			if (newObj[key] !== oldObj[key]) {
				return false;
			}
		}
		return true;
	};

	const saveTable = useCallback(
		async ({ currentState, columns }) => {
			columns = columns
				.map(col => {
					if (col?.columns) {
						return col.columns;
					}
					return col;
				})
				.flat();
			if (isWorkspaceTable) {
				let table = tablePropsRef?.current?.getOldTableByName;
				if (!table?.id) {
					table = (
						await createTable({
							variables: {
								createTable: {
									name: tableName,
								},
							},
						})
					)?.data?.createTable;
				}

				const columnsProps = [];
				Object.keys(currentState?.columnResizing?.columnWidths)?.forEach(colName => {
					columnsProps.push({
						name: colName,
						width: currentState?.columnResizing?.columnWidths[colName],
					});
				});
				currentState?.hiddenColumns?.forEach(colName => {
					const idx = columnsProps.findIndex(x => x.name === colName);
					if (idx === -1) {
						columnsProps.push({
							name: colName,
							hidden: true,
						});
					} else {
						columnsProps[idx] = { ...columnsProps[idx], hidden: true };
					}
				});
				currentState?.sortBy?.forEach(item => {
					const idx = columnsProps.findIndex(x => x.name === item.id);
					if (idx === -1) {
						columnsProps.push({
							name: item.id,
							sort: item.desc ? "desc" : "asc",
						});
					} else {
						columnsProps[idx] = {
							...columnsProps[idx],
							sort: item.desc ? "desc" : "asc",
						};
					}
				});

				tablePropsRef?.current?.getOldTableByName?.columns
					?.filter(x => x.hidden || !!x.sort)
					.forEach(col => {
						const idx = columnsProps.findIndex(x => x.name === col.name);
						if (col.hidden) {
							if (idx === -1) {
								columnsProps.push({
									name: col.name,
									hidden: false,
								});
							} else if (!columnsProps[idx]?.hidden) {
								columnsProps[idx] = { ...columnsProps[idx], hidden: false };
							}
						} else if (col.sort) {
							if (idx === -1) {
								columnsProps.push({
									name: col.name,
									sort: null,
								});
							} else if (!columnsProps[idx]?.sort) {
								columnsProps[idx] = { ...columnsProps[idx], sort: null };
							}
						}
					});

				columnsProps.forEach(col => {
					const savedCol = {
						...tablePropsRef?.current?.getOldTableByName?.columns?.find(
							x => x.name === col.name
						),
					};
					delete savedCol.__typename;
					if (Object.keys(savedCol).length !== 0) {
						if (!objEquals(col, savedCol)) {
							updateColumn({
								variables: {
									updateColumn: {
										...savedCol,
										...col,
									},
								},
							});
						}
					} else {
						createColumn({
							variables: {
								createColumn: {
									collapsed: false,
									hidden: false,
									index: 0,
									width:
										columns.find(
											x => x.accessor === col.name || x.id === col.name
										)?.width || 100,
									...col,
									tableId: table?.id,
								},
							},
						});
					}
				});
			} else {
				let table = tablePropsRef?.current?.getTableByPeriodIdAndName;
				if (!table?.id) {
					table = (
						await createPeriodTable({
							variables: {
								createTable: {
									periodId: currentPeriodIdVar(),
									name: tableName,
								},
							},
						})
					)?.data?.createTable;
				}
				const columnsProps = [];
				Object.keys(currentState?.columnResizing?.columnWidths)?.forEach(colName => {
					columnsProps.push({
						name: colName,
						width: currentState?.columnResizing?.columnWidths[colName],
					});
				});
				currentState?.hiddenColumns?.forEach(colName => {
					const idx = columnsProps.findIndex(x => x.name === colName);
					if (idx === -1) {
						columnsProps.push({
							name: colName,
							hidden: true,
						});
					} else {
						columnsProps[idx] = { ...columnsProps[idx], hidden: true };
					}
				});
				currentState?.sortBy?.forEach(item => {
					const idx = columnsProps.findIndex(x => x.name === item.id);
					if (idx === -1) {
						columnsProps.push({
							name: item.id,
							sort: item.desc ? "desc" : "asc",
						});
					} else {
						columnsProps[idx] = {
							...columnsProps[idx],
							sort: item.desc ? "desc" : "asc",
						};
					}
				});

				tablePropsRef?.current?.getTableByPeriodIdAndName?.columns
					?.filter(x => x.hidden || !!x.sort)
					.forEach(col => {
						const idx = columnsProps.findIndex(x => x.name === col.name);
						if (col.hidden) {
							if (idx === -1) {
								columnsProps.push({
									name: col.name,
									hidden: false,
								});
							} else if (!columnsProps[idx]?.hidden) {
								columnsProps[idx] = { ...columnsProps[idx], hidden: false };
							}
						} else if (col.sort) {
							if (idx === -1) {
								columnsProps.push({
									name: col.name,
									sort: null,
								});
							} else if (!columnsProps[idx]?.sort) {
								columnsProps[idx] = { ...columnsProps[idx], sort: null };
							}
						}
					});

				columnsProps.forEach(col => {
					const savedCol = {
						...tablePropsRef?.current?.getTableByPeriodIdAndName?.columns?.find(
							x => x.name === col.name
						),
					};
					delete savedCol.__typename;
					if (Object.keys(savedCol).length !== 0) {
						if (!objEquals(col, savedCol)) {
							updateColumn({
								variables: {
									updateColumn: {
										...savedCol,
										...col,
									},
								},
							});
						}
					} else {
						createColumn({
							variables: {
								createColumn: {
									collapsed: false,
									hidden: false,
									index: 0,
									width:
										columns.find(
											x => x.accessor === col.name || x.id === col.name
										)?.width || 100,
									...col,
									tableId: table?.id,
								},
							},
						});
					}
				});
			}
		},
		[tablePropsRef.current, tableName]
	);

	const initialTableState = useMemo(() => {
		const hiddenCols = [];
		const sortBy = [];
		let resize = { columnWidths: {} };

		if (isWorkspaceTable) {
			if (dataTableProps?.getOldTableByName?.id) {
				dataTableProps?.getOldTableByName?.columns?.forEach(col => {
					if (col.hidden) {
						hiddenCols.push(col.name);
					}
					if (col.sort) {
						sortBy.push({ id: col.name, desc: col.sort === "desc" });
					}
					if (col.width) {
						resize.columnWidths[`${col.name}`] = col?.width;
					}
				});
			}
		} else {
			if (dataTableProps?.getTableByPeriodIdAndName?.id) {
				dataTableProps?.getTableByPeriodIdAndName?.columns?.forEach(col => {
					if (col.hidden) {
						hiddenCols.push(col.name);
					}
					if (col.sort) {
						sortBy.push({ id: col.name, desc: col.sort === "desc" });
					}
					if (col.width) {
						resize.columnWidths[`${col.name}`] = col.width;
					}
				});
			}
		}

		return {
			hiddenColumn: hiddenCols,
			sortBy,
			resize,
		};
	}, [dataTableProps]);

	return {
		table: isWorkspaceTable
			? tablePropsRef?.current?.getOldTableByName
			: tablePropsRef?.current?.getTableByPeriodIdAndName,
		tableExists:
			!!dataTableProps?.getTableByPeriodIdAndName?.id || !!dataTableProps?.getOldTableByName?.id,
		saveTable,
		initialTableState,
		loadingTableProps,
	};
};
