import React, { useMemo, useState, useEffect, useContext, useCallback } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentPeriodIdVar } from "@src/cache/cache";
import moment from "moment";
import { Tablev3, Loader, Tooltipv3 } from "priceit-ui";
import { textColor } from "@src/services/textColor";
import { useTranslation } from "react-i18next";

import {
	CenteredSpan,
	CenteredDiv,
	EllipsisDiv,
	WrapperLoader,
	WrapperTable,
} from "../TableView.style";
import { periodMultiplier } from "@services/periodMultiplier";
import {
	computeGrossProfit,
	computeUnitFixedCosts,
	computeUnitNetPrice,
	computeUnitNetProfit,
	searchProducts,
} from "@components/productsDrawer/services/ProductsDrawer.service";
import { ProgressBar } from "@components/progressBar/ProgressBar";

import { customSort } from "@services/columns/customSort";
import { suffixMetrics } from "@services/text";
import { periodTypesEnum } from "@services/periodMultiplier";
import {
	HeaderCategorization,
	WrapperCategoryName,
	TitleCategory,
	TitleCategoryText,
	WrapperTitleCategory,
	WrapperTooltipContent,
	WrapperHeaderCategorization,
} from "./ListView.style";
import { initialState, productsDrawerReducer } from "../../../reducer/reducer";
import { useSaveTable, tableNames } from "@hooks/useSaveTable/UseSaveTable";
import { priceMetricsObj, priceMetricTrad } from "@services/i18n";
import { getMinWidthColumn } from "@services/minWidthColumn";
import { GET_CATEGORIZATION_BY_PERIOD_ID } from "@src/graphQl/queries/categorizationNewQuery";

export const ListView = ({
	searchInput,
	period,
	products,
	productsSelected,
	onChangeProductsSelected,
	periodType,
	loadingProducts,
}) => {
	const currentPeriodId = useReactiveVar(currentPeriodIdVar);
	const { t } = useTranslation("drawer/page");
	const priceMetrics = priceMetricsObj();

	const listOfProducts = useMemo(() => {
		if (products) {
			return searchProducts(searchInput, [...products]).sort((a, b) => a.id - b.id);
		}
		return [];
	}, [products, searchInput]);

	const { saveTable, initialTableState, loadingTableProps } = useSaveTable({
		tableName: tableNames.listView,
	});

	const [sortByState, setSortByState] = useState([]);
	const [hiddenColumnsState, setHiddenColumnsState] = useState([]);
	const [resizeState, setResizeState] = useState({ columnWidths: {} });

	const sortByCallBack = useCallback(sortBy => setSortByState(sortBy), []);
	const hiddenCallBack = useCallback(hiddenColumns => setHiddenColumnsState(hiddenColumns), []);
	const resizeCallBack = useCallback(
		resize => setResizeState(resize.columnWidths),
		[resizeState]
	);

	useEffect(() => {
		if (initialTableState.sortBy) {
			setSortByState(initialTableState.sortBy);
		}
	}, [initialTableState.sortBy]);

	useEffect(() => {
		if (initialTableState.hiddenColumn) {
			setHiddenColumnsState(initialTableState.hiddenColumn);
		}
	}, [initialTableState.hiddenColumn]);

	useEffect(() => {
		if (initialTableState.resize.columnWidths) {
			setResizeState(initialTableState.resize.columnWidths);
		}
	}, [initialTableState.resize]);

	useEffect(() => {
		setSortByState([]);
		setHiddenColumnsState([]);
		setResizeState({ columnWidths: {} });
	}, [currentPeriodId]);

	const endDate = moment(period?.endDate);
	const startDate = moment(period?.startDate);
	const monthDifference =
		periodType === "period"
			? endDate.diff(startDate, "months") + 1
			: periodMultiplier(periodType);
	const coefficientNonRecurring =
		periodType === "period"
			? 1
			: periodMultiplier(periodType) / (endDate.diff(startDate, "months") + 1);
	const {
		loading: loadingCategorization,
		error: errorCategorization,
		data: dataCategorization,
	} = useQuery(GET_CATEGORIZATION_BY_PERIOD_ID, {
		skip: currentPeriodId === "0",
		variables: {
			id: currentPeriodId,
		},
	});

	const isInLoss = useMemo(
		() =>
			period?.profitParameter?.revenue?.realRevenue -
				(period?.profitParameter?.variableCost?.variableCosts +
					period?.profitParameter?.totalFixedCosts?.totalSemiVariableCostPart) -
				period?.profitParameter?.totalFixedCosts?.realTotalFixedCosts <
			0,
		[period?.profitParameter]
	);

	const columns = useMemo(
		() => [
			{
				Header: t("PRICE DESCRIPTION"),
				sticky: "left",
				columns: [
					{
						Header: t("ID"),
						accessor: "id",
						width:
							resizeState.columnWidths?.id ||
							initialTableState?.resize?.columnWidths?.id ||
							60,
						Cell: item => {
							return item.row.index + 1;
						},
					},
					{
						Header: t("Price point name"),
						accessor: "name",
						width:
							resizeState.columnWidths?.name ||
							initialTableState?.resize?.columnWnameths?.id ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							return <EllipsisDiv>{element.value}</EllipsisDiv>;
						},
					},
					{
						Header: t("Description"),
						accessor: "description",
						width:
							resizeState.columnWidths?.description ||
							initialTableState?.resize?.columnWidths?.description ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							return <EllipsisDiv>{element.value}</EllipsisDiv>;
						},
					},
				],
			},
			{
				Header: () => (
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div>{t("CATEGORIES")}</div>
					</div>
				),
				accessor: "productsAndServicesListView",
				columns: [
					...[...(dataCategorization?.getCategorizationByPeriodId || [])]
						.sort((a, b) => a.id - b.id)
						.map(cat => {
							return {
								Header: () => {
									return (
										<WrapperHeaderCategorization>
											<HeaderCategorization primary={cat.description ? 0 : 1}>
												{cat.name}
											</HeaderCategorization>
											<HeaderCategorization primary={cat.description ? 1 : 0}>
												{cat.description}
											</HeaderCategorization>
										</WrapperHeaderCategorization>
									);
								},
								id: "categorization-" + cat.name,
								hiddenName:
									cat.name + (cat.description ? " - " + cat.description : ""),
								accessor: originalRow => {
									const selected = cat.category?.find(x =>
										originalRow.categories.map(y => y.id)?.includes(x.id)
									);
									return selected?.name;
								},
								centerHeader: true,
								centerBody: true,
								width:
									resizeState.columnWidths?.[`categorization-${cat.name}`] ||
									initialTableState?.resize?.columnWidths?.[
										`categorization-${cat.name}`
									] ||
									200,
								minWidth: getMinWidthColumn("dropdown"),
								paddingBodyCell: "0px",
								Cell: item => {
									const selected = cat.category?.find(x =>
										item?.row.state?.categories?.map(y => y.id)?.includes(x.id)
									);
									const [isTextOverflowing, setIsTextOverflowing] =
										useState(false);
									const categoryTitleRef = useCallback(
										node => {
											if (node !== null) {
												if (node.clientWidth < node.scrollWidth) {
													setIsTextOverflowing(true);
												} else {
													setIsTextOverflowing(false);
												}
											}
										},
										[selected?.name, item]
									);

									return (
										<WrapperCategoryName backgroundColor={selected?.color}>
											<Tooltipv3
												placement={"top"}
												mode="dark"
												type="primary"
												content={
													<WrapperTooltipContent>
														{selected?.name}
													</WrapperTooltipContent>
												}
												disabled={!isTextOverflowing}
												appendTo={document.body}
												style={{
													width: "100%",
												}}
												maxWidth={"250px"}
											>
												<WrapperTitleCategory>
													<TitleCategoryText
														ref={categoryTitleRef}
														color={textColor(selected?.color)}
													>
														{selected?.name}
													</TitleCategoryText>
												</WrapperTitleCategory>
											</Tooltipv3>
										</WrapperCategoryName>
									);
								},
							};
						})
						.flat(2),
				],
			},
			{
				Header: t("VOLUME"),
				columns: [
					{
						Header: t("Volume"),
						accessor: originalRow => {
							const volume = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? originalRow.volume * coefficientNonRecurring
								: originalRow.volume;
							return volume;
						},
						centerHeader: true,
						centerCell: true,
						id: "volume",
						width:
							resizeState.columnWidths?.volume ||
							initialTableState?.resize?.columnWidths?.volume ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredSpan>
									{element.value
										? `${element.value?.fnb({
												stringText: true,
												zeroIfNan: true,
										  })} ${priceMetricTrad({
												priceMetricList: priceMetrics,
												priceMetric: item.volumeMetric,
												count: element.value || 1,
										  })}`
										: "-"}
								</CenteredSpan>
							);
						},
					},
				],
			},
			{
				Header: t("PRICE"),
				columns: [
					{
						Header: () => (
							<CenteredDiv>
								<div style={{ whiteSpace: "nowrap" }}>{t("Unit list price")}</div>
								<div style={{ whiteSpace: "nowrap" }}>{t("(excl. VAT)")}</div>
							</CenteredDiv>
						),
						hiddenName: t("Unit list price (excl. VAT)"),
						accessor: "priceInclVAT",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.priceInclVAT ||
							initialTableState?.resize?.columnWidths?.priceInclVAT ||
							235,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							const price = item?.priceExclVAT;
							return (
								<CenteredSpan style={{ margin: "0 20px" }}>
									{price
										? price.fnb({
												stringText: true,
												zeroIfNan: true,
												withCurrency: true,
										  }) + suffixMetrics(item)
										: "-"}
								</CenteredSpan>
							);
						},
					},
					{
						Header: () => <CenteredDiv>{t("Discounts")}</CenteredDiv>,
						accessor: "discount",
						centerHeader: true,
						centerCell: true,
						hiddenName: t("Discounts"),
						width:
							resizeState.columnWidths?.discount ||
							initialTableState?.resize?.columnWidths?.discount ||
							150,
						minWidth: getMinWidthColumn("progressBar"),
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredDiv>
									<ProgressBar percent={item.discount} fillColor={"#e657dc"} />
								</CenteredDiv>
							);
						},
					},
					{
						Header: () => (
							<CenteredDiv>
								<div style={{ whiteSpace: "nowrap" }}>{t("Unit net price")}</div>
								<div style={{ whiteSpace: "nowrap" }}>{t("(excl. VAT)")}</div>
							</CenteredDiv>
						),
						accessor: "netPriceExclVAT",
						centerHeader: true,
						centerCell: true,
						hiddenName: t("Unit net price (excl. VAT)"),
						width:
							resizeState.columnWidths?.netPriceExclVAT ||
							initialTableState?.resize?.columnWidths?.netPriceExclVAT ||
							235,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							const price = item.netPriceExclVAT;
							return (
								<CenteredSpan>
									{price
										? price.fnb({
												stringText: true,
												zeroIfNan: true,
												withCurrency: true,
										  }) + suffixMetrics(item)
										: "-"}
								</CenteredSpan>
							);
						},
					},
				],
			},
			{
				Header: t("REVENUE"),
				columns: [
					{
						Header: t("Revenue"),
						accessor: originalRow => {
							const coefficient = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(originalRow.timeMetric) || monthDifference);

							const revenue =
								originalRow.netPriceExclVAT * originalRow.volume * coefficient;
							return revenue;
						},
						id: "revenue",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.revenue ||
							initialTableState?.resize?.columnWidths?.revenue ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							return (
								<CenteredSpan>
									{element.value
										? element.value.fnb({
												stringText: true,
												zeroIfNan: true,
												withCurrency: true,
										  })
										: "-"}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("% of the total revenue"),
						accessor: "percentTotalRevenue",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentTotalRevenue ||
							initialTableState?.resize?.columnWidths?.percentTotalRevenue ||
							150,
						sortType: (rowA, rowB) => {
							return customSort(
								rowA.values.revenue / period?.profitParameter?.revenue?.realRevenue,
								rowB.values.revenue / period?.profitParameter?.revenue?.realRevenue
							);
						},
						minWidth: getMinWidthColumn("progressBar"),
						Cell: element => {
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(element.row.values.revenue /
												period?.profitParameter?.revenue?.realRevenue) *
											100
										}
										fillColor={"#FDB432"}
									/>
								</CenteredDiv>
							);
						},
					},
				],
			},
			{
				Header: t("VARIABLE COST"),
				columns: [
					{
						Header: t("Unit variable costs"),
						accessor: "variableCosts",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.variableCosts ||
							initialTableState?.resize?.columnWidths?.variableCosts ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;

							const semiVariableCost = item?.semiVariableCostParts?.reduce(
								(accumulator, currentValue) => {
									return accumulator + currentValue.value;
								},
								0
							);
							const variableCost =
								(item.variableCosts || 0) + (semiVariableCost || 0);
							return (
								<CenteredSpan>
									{variableCost.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									}) + suffixMetrics(item)}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("Variable costs in % of the net price"),
						accessor: "percentVariableCosts",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentVariableCosts ||
							initialTableState?.resize?.columnWidths?.percentVariableCosts ||
							150,
						minWidth: getMinWidthColumn("progressBar"),
						sortType: (rowA, rowB) => {
							const semiVariableCostRowA =
								rowA.original?.semiVariableCostParts?.reduce(
									(accumulator, currentValue) => {
										return accumulator + currentValue.value;
									},
									0
								);
							const variableCostRowA =
								(rowA.original.variableCosts || 0) + (semiVariableCostRowA || 0);

							const semiVariableCostRowB =
								rowB.original?.semiVariableCostParts?.reduce(
									(accumulator, currentValue) => {
										return accumulator + currentValue.value;
									},
									0
								);
							const variableCostRowB =
								(rowB.original.variableCosts || 0) + (semiVariableCostRowB || 0);
							return customSort(
								variableCostRowA / computeUnitNetPrice(rowA.original),
								variableCostRowB / computeUnitNetPrice(rowB.original)
							);
						},
						Cell: element => {
							const item = element.row.original;
							const semiVariableCost = item?.semiVariableCostParts?.reduce(
								(accumulator, currentValue) => {
									return accumulator + currentValue.value;
								},
								0
							);
							const variableCost =
								(item.variableCosts || 0) + (semiVariableCost || 0);
							const price = computeUnitNetPrice(item);
							return (
								<CenteredDiv>
									<ProgressBar
										percent={(variableCost / price) * 100}
										fillColor={"#a082d2"}
									/>
								</CenteredDiv>
							);
						},
					},
					{
						Header: t("Total variable costs"),
						id: "totalVariableCosts",
						width:
							resizeState.columnWidths?.totalVariableCosts ||
							initialTableState?.resize?.columnWidths?.totalVariableCosts ||
							150,
						accessor: originalRow => {
							const semiVariableCost = originalRow?.semiVariableCostParts?.reduce(
								(accumulator, currentValue) => {
									return accumulator + currentValue.value;
								},
								0
							);
							const variableCost =
								(originalRow.variableCosts || 0) + (semiVariableCost || 0);

							const coefficient = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(originalRow.timeMetric) || monthDifference);
							return variableCost * originalRow.volume * coefficient;
						},
						centerHeader: true,
						centerCell: true,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							return (
								<CenteredSpan>
									{element.value?.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									})}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("% of the total variable costs"),
						accessor: "percentTotalVariableCosts",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentTotalVariableCosts ||
							initialTableState?.resize?.columnWidths?.percentTotalVariableCosts ||
							150,
						minWidth: getMinWidthColumn("progressBar"),
						sortType: (rowA, rowB) => {
							return customSort(
								rowA?.values?.totalVariableCosts /
									(period?.profitParameter?.variableCost?.variableCosts +
										period?.profitParameter?.totalFixedCosts
											?.totalSemiVariableCostPart),
								rowB?.values?.totalVariableCosts /
									(period?.profitParameter?.variableCost?.variableCosts +
										period?.profitParameter?.totalFixedCosts
											?.totalSemiVariableCostPart)
							);
						},
						Cell: element => {
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(element?.row?.values?.totalVariableCosts /
												(period?.profitParameter?.variableCost
													?.variableCosts +
													period?.profitParameter?.totalFixedCosts
														?.totalSemiVariableCostPart)) *
											100
										}
										fillColor={"#a082d2"}
									/>
								</CenteredDiv>
							);
						},
					},
				],
			},
			{
				Header: t("GROSS PROFIT"),
				columns: [
					{
						Header: t("Gross unit profit"),
						accessor: "grossUnitProfit",
						width:
							resizeState.columnWidths?.grossUnitProfit ||
							initialTableState?.resize?.columnWidths?.grossUnitProfit ||
							220,
						centerHeader: true,
						centerCell: true,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							const grossProfit = computeGrossProfit(element.row.state);
							return (
								<CenteredSpan>
									{grossProfit.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									}) + suffixMetrics(item)}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("Gross unit profit in % of the net price"),
						accessor: originalRow => {
							return originalRow.grossUnitProfit / originalRow.netPriceExclVAT;
						},
						id: "percentGrossProfit",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentGrossProfit ||
							initialTableState?.resize?.columnWidths?.percentGrossProfit ||
							180,
						minWidth: getMinWidthColumn("progressBar"),
						Cell: element => {
							const grossProfit = computeGrossProfit(element.row.state);
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(grossProfit / element.row.state.netPriceExclVAT) * 100
										}
										colorNegativeProp={"#a5b9c8"}
										negative
										colorPositiveProp={"#e65757"}
									/>
								</CenteredDiv>
							);
						},
					},
					{
						Header: t("Total gross profit"),
						accessor: originalRow => {
							const coefficient = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(originalRow.timeMetric) || monthDifference);

							return originalRow.grossUnitProfit * originalRow.volume * coefficient;
						},
						id: "totalGrossProfit",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.totalGrossProfit ||
							initialTableState?.resize?.columnWidths?.totalGrossProfit ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const coefficient = element.row.state.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(element.row.state.timeMetric) ||
										monthDifference);
							const grossProfit = computeGrossProfit(element.row.state);
							const totalGrossProfit =
								grossProfit * element.row.state.volume * coefficient;
							return (
								<CenteredSpan>
									{totalGrossProfit.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									})}
								</CenteredSpan>
							);
						},
					},
					{
						Header: isInLoss
							? t("% of the total gross loss")
							: t("% of the total gross profit"),
						accessor: "percentTotalGrossProfit",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentTotalGrossProfit ||
							initialTableState?.resize?.columnWidths?.percentTotalGrossProfit ||
							150,
						sortType: (rowA, rowB) => {
							return customSort(
								rowA.row.values.totalGrossProfit /
									period?.profitParameter?.profit?.grossProfit,
								rowB.row.values.totalGrossProfit /
									period?.profitParameter?.profit?.grossProfit
							);
						},
						minWidth: getMinWidthColumn("progressBar"),
						Cell: element => {
							const coefficient =
								monthDifference /
								(periodMultiplier(element.row.state.timeMetric) || monthDifference);
							const grossProfit = computeGrossProfit(element.row.state);
							const totalGrossProfit =
								grossProfit * element.row.state.volume * coefficient;
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(totalGrossProfit /
												period?.profitParameter?.profit?.grossProfit) *
											100
										}
										colorNegativeProp={"#a5b9c8"}
										negative
										colorPositiveProp={"#e65757"}
									/>
								</CenteredDiv>
							);
						},
					},
				],
			},
			{
				Header: t("FIXED COST"),
				columns: [
					{
						Header: t("Allocated unit fixed costs"),
						accessor: "unitFixedCostsColumn",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.unitFixedCostsColumn ||
							initialTableState?.resize?.columnWidths?.unitFixedCostsColumn ||
							220,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredSpan>
									{computeUnitFixedCosts(item, period).fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									}) + suffixMetrics(item)}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("Fixed costs in % of the price"),
						accessor: "percentFixedCostsPrice",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentFixedCostsPrice ||
							initialTableState?.resize?.columnWidths?.percentFixedCostsPrice ||
							150,
						sortType: (rowA, rowB) => {
							return customSort(
								computeUnitFixedCosts(rowA.original, period) /
									computeUnitNetPrice(rowA.original),
								computeUnitFixedCosts(rowB.original, period) /
									computeUnitNetPrice(rowB.original)
							);
						},
						minWidth: getMinWidthColumn("progressBar"),
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(computeUnitFixedCosts(item, period) /
												computeUnitNetPrice(item)) *
											100
										}
										fillColor={"#85E6D6"}
									/>
								</CenteredDiv>
							);
						},
					},
					{
						Header: t("Total fixed costs"),
						accessor: originalRow => {
							const coefficient = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(originalRow.timeMetric) || monthDifference);
							return (
								computeUnitFixedCosts(originalRow, period) *
								originalRow.volume *
								coefficient
							);
						},
						id: "totalFixedCosts",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.totalFixedCosts ||
							initialTableState?.resize?.columnWidths?.totalFixedCosts ||
							150,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							return (
								<CenteredSpan>
									{element.value.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									})}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("% of the total fixed costs"),
						accessor: "percentTotalFixedCosts",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentTotalFixedCosts ||
							initialTableState?.resize?.columnWidths?.percentTotalFixedCosts ||
							150,
						minWidth: getMinWidthColumn("progressBar"),
						sortType: (rowA, rowB) => {
							return customSort(
								rowA.values.totalFixedCosts /
									period?.profitParameter?.totalFixedCosts?.realTotalFixedCosts,
								rowB.values.totalFixedCosts /
									period?.profitParameter?.totalFixedCosts?.realTotalFixedCosts
							);
						},
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(element.row.values.totalFixedCosts /
												period?.profitParameter?.totalFixedCosts
													?.realTotalFixedCosts) *
											100
										}
										fillColor={"#85E6D6"}
									/>
								</CenteredDiv>
							);
						},
					},
				],
			},
			{
				Header: t("NET PROFIT"),
				columns: [
					{
						Header: t("Unit net profit"),
						accessor: "unitNetProfit",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.unitNetProfit ||
							initialTableState?.resize?.columnWidths?.unitNetProfit ||
							220,
						minWidth: getMinWidthColumn("result"),
						sortType: (rowA, rowB) => {
							return customSort(
								computeUnitNetProfit(rowA.original, period),
								computeUnitNetProfit(rowB.original, period)
							);
						},
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredSpan style={{ margin: "0 20px" }}>
									{computeUnitNetProfit(item, period).fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									}) + suffixMetrics(item)}
								</CenteredSpan>
							);
						},
					},
					{
						Header: t("Unit net profit in % of the net price"),
						accessor: "percentNetProfitPrice",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentNetProfitPrice ||
							initialTableState?.resize?.columnWidths?.percentNetProfitPrice ||
							180,
						minWidth: getMinWidthColumn("progressBar"),
						sortType: (rowA, rowB) => {
							return customSort(
								computeUnitNetProfit(rowA.original, period) /
									computeUnitNetPrice(rowA.original),
								computeUnitNetProfit(rowB.original, period) /
									computeUnitNetPrice(rowB.original)
							);
						},
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(computeUnitNetProfit(item, period) /
												computeUnitNetPrice(item)) *
											100
										}
										negative
									/>
								</CenteredDiv>
							);
						},
					},
					{
						Header: t("Total net profit"),
						accessor: originalRow => {
							const coefficient = originalRow.timeMetric.includes(
								periodTypesEnum.ONLY_ONCE
							)
								? coefficientNonRecurring
								: monthDifference /
								  (periodMultiplier(originalRow.timeMetric) || monthDifference);

							return (
								computeUnitNetProfit(originalRow, period) *
								originalRow.volume *
								coefficient
							);
						},
						id: "totalNetProfit",
						width:
							resizeState.columnWidths?.percentNetProfitPrice ||
							initialTableState?.resize?.columnWidths?.percentNetProfitPrice ||
							150,
						centerHeader: true,
						centerCell: true,
						minWidth: getMinWidthColumn("result"),
						Cell: element => {
							const item = element.row.original;
							return (
								<CenteredSpan>
									{element.value.fnb({
										stringText: true,
										zeroIfNan: true,
										withCurrency: true,
									})}
								</CenteredSpan>
							);
						},
					},
					{
						Header: isInLoss
							? t("% of the total net loss")
							: t("% of the total net profit"),
						accessor: "percentTotalNetProfit",
						centerHeader: true,
						centerCell: true,
						width:
							resizeState.columnWidths?.percentTotalNetProfit ||
							initialTableState?.resize?.columnWidths?.percentTotalNetProfit ||
							180,
						minWidth: getMinWidthColumn("progressBar"),
						sortType: (rowA, rowB) => {
							return customSort(
								rowA.values.totalNetProfit /
									period?.profitParameter?.profit?.realProfit,
								rowB.values.totalNetProfit /
									period?.profitParameter?.profit?.realProfit
							);
						},
						Cell: element => {
							return (
								<CenteredDiv>
									<ProgressBar
										percent={
											(element.row.values.totalNetProfit /
												period?.profitParameter?.profit?.realProfit) *
											100
										}
										negative
										reverseColor={isInLoss}
									/>
								</CenteredDiv>
							);
						},
					},
				],
			},
		],
		[listOfProducts?.length, monthDifference, loadingTableProps]
	);

	const rowSelection = React.useMemo(
		() => ({
			onChange: value => {
				onChangeProductsSelected(
					listOfProducts.filter(product => value.includes(product.id))
				);
			},
			onShift: value => {
				onChangeProductsSelected(
					listOfProducts.filter(product => value.includes(product.id))
				);
			},
			selectedRowIds: productsSelected.map(x => x.id),
		}),
		[productsSelected]
	);
	return (
		<WrapperTable>
			{products.length === 0 || !period || loadingTableProps ? (
				<WrapperLoader>
					<Loader />
				</WrapperLoader>
			) : (
				<Tablev3
					columns={columns}
					mode={"dark"}
					data={listOfProducts}
					rowSelection={rowSelection}
					maxHeight={"calc(100vh - 290px)"}
					showHidingBox
					onUnload={currentState => saveTable({ currentState, columns })}
					initialState={{
						sortBy: sortByState,
						hiddenColumns: hiddenColumnsState,
						columnResizing: { columnWidths: resizeState },
					}}
					callBackSortBy={sortByCallBack}
					callBackHiddenColumns={hiddenCallBack}
					callBackResize={resizeCallBack}
					editText={t("Edit")}
					sortText={t("Sort")}
					collapseText={t("Collapse")}
					hideText={t("Hide")}
					selectedRowsWording={t("selected line", {
						count: rowSelection?.selectedRowIds?.length,
					})}
				/>
			)}
		</WrapperTable>
	);
};
