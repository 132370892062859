import styled from "styled-components";

export const Wrapper = styled.div`
	width: 320px;
	min-width: 320px;
	background-color: ${({ theme }) => theme.page.tertiaryBackgroundColor};
	padding: 35px 20px;
	display: flex;
	overflow: auto;
	scrollbar-gutter: stable both-edges;
	::-webkit-scrollbar {
		width: 7px;
	}
	:empty {
		display: none;
	}
`;
