import styled, { css } from "styled-components";

export const WrapperProgressBar = styled.div`
	position: relative;
	width: ${props => (props.barWidth ? props.barWidth : "94px")};
	opacity: ${props => props.opacity ?? 1};
`;

export const ContainerStyle = styled.div`
	height: ${props => (props.labelValue && !props.hidePercentInsideBar ? "15px" : "10px")};
	background-color: #5c6f7e;
	border-radius: ${props => (props.labelValue && !props.hidePercentInsideBar ? "3px" : "50px")};
	position: relative;
`;

export const FillerStyle = styled.div`
	height: 100%;
	width: ${props => props.percent}%;
	background-color: ${props => props.fillColor};
	border-radius: inherit;
	text-align: right;
	transition: width 1s ease-in-out;
	left: ${props =>
		props.negative ? (props.rotate ? "calc(50% - " + props.percent + "%)" : "50%") : "0"};
	position: absolute;
	${({ percent, rotate }) => {
		if (!rotate) {
			if (percent <= 1) {
				return css`
					height: 40%;
					top: 30%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 2) {
				return css`
					height: 50%;
					top: 25%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 3) {
				return css`
					height: 60%;
					top: 20%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 4) {
				return css`
					height: 70%;
					top: 15%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 5) {
				return css`
					height: 80%;
					top: 10%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 6) {
				return css`
					height: 90%;
					top: 5%;
					border-radius: 50% 0 0 50%;
				`;
			} else if (percent <= 9) {
				return css`
					border-radius: 50% 0 0 50%;
				`;
			}
		}
	}};
	${props =>
		props.negative &&
		((props.rotate &&
			css`
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			`) ||
			css`
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			`)};
`;

export const LabelStyle = styled.div`
	text-align: left;
	margin-left: ${props => props.position};
	color: ${props => (props.color === "#38444D" ? "black" : props.color)};
	font-size: 14px;
	transition: left 1s ease-in-out;
	line-height: 14px;
	margin-bottom: 1px;
`;

export const ValueInside = styled.div`
	z-index: 2;
	position: absolute;
	left: 5%;
	font-size: 10px;
	height: 100%;
	display: flex;
	align-items: center;
	color: ${props => (props.isEqualTo0 ? "#A5B9C8" : "white")};
`;
