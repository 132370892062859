import React, { useContext, useMemo } from "react";
import { useParams } from "react-router";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Tooltipv3, Button } from "priceit-ui";
import { WrapperHead, Title, WrapperList, RightPart, WrapperArrow } from "./PeriodList.style";
import { GET_PERIODS_BY_WORKSPACE_ID } from "../graphQl/query";
import { updateUserWorkspaceDefaultMutation } from "../graphQl/mutation";

import { useQuery } from "@apollo/client";
import { PeriodComponent } from "@components/headerCustom/periods/periodComponent/PeriodComponent";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID } from "@src/graphQl/queries/userWorkspaceDefault";

export const PeriodList = ({ onHover, openDrawer }) => {
	const { t } = useTranslation("shared/header/periodList");
	const { workspaceId } = useParams();

	const {
		loading: loadingPeriod,
		error: errorPeriod,
		data: dataPeriod,
	} = useQuery(GET_PERIODS_BY_WORKSPACE_ID, {
		variables: { workspaceId },
	});

	const { auth } = useAuth();

	const {
		loading: loadingUserWorkspaceDefault,
		error: errorUserWorkspaceDefault,
		data: dataUserWorkspaceDefault,
	} = useQuery(GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID, {
		skip: !auth?.id,
		variables: { userId: auth?.id },
	});

	const updateUserWorkspaceDefault = updateUserWorkspaceDefaultMutation();

	const listOfPeriods = useMemo(() => {
		if (!loadingPeriod) {
			return [...dataPeriod.getPeriodsByWorkspaceId].sort((a, b) => a.index - b.index);
		}
		return [];
	}, [dataPeriod?.getPeriodsByWorkspaceId]);

	return (
		<div
			style={{
				width: "350px",
				height: "calc(100vh - 71px)",
				backgroundColor: "#38444d",
				overflow: "hidden",
			}}
		>
			<WrapperHead onClick={openDrawer}>
				<Tooltipv3
					placement={"left"}
					content={t("Create or edit periods")}
					mode="dark"
					type="primary"
				>
					<RightPart>
						<WrapperArrow>
							<Icon type={"left"} width={"10px"} fill="#FFCD38" />
						</WrapperArrow>
					</RightPart>
				</Tooltipv3>
				<Icon fill="#FFCD38" type="calendar" />
				<Title>{t("Periods")}</Title>
			</WrapperHead>
			<WrapperList>
				{listOfPeriods.map(period => (
					<PeriodComponent
						key={period?.id}
						period={period}
						isSelected={
							dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.periodId ===
							period?.id
						}
						onHover={onHover}
						openDrawer={openDrawer}
						onClick={() => {
							if (!!dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.id) {
								updateUserWorkspaceDefault({
									variables: {
										updateUserWorkspaceDefault: {
											id: dataUserWorkspaceDefault
												?.getUserWorkspaceDefaultByUserId?.id,
											periodId: period.id,
										},
									},
								});
							}
						}}
					/>
				))}
				<Button
					type="add"
					iconHeight="0px"
					iconWidth="0px"
					mode="dark"
					style={{ marginTop: "10px", marginLeft: "15px" }}
					onClick={openDrawer}
					color="#747474"
					hoverColor="black"
				>
					{t("Add or edit periods")}
				</Button>
			</WrapperList>
		</div>
	);
};
