import styled, { css } from "styled-components";
import {
	Avatar,
	Button,
	Checkbox,
	Dialog,
	Icon,
	Selector,
	ToggleSwitch,
	Tooltipv3,
	Upload,
} from "priceit-ui";
import { SwitchLabel } from "@pages/myPricingTools/buildingTools/priceModels/priceCommunication/components/priceCommunicationConfigurationPanel/PriceCommunicationConfigurationPanel.style";
import { CellText } from "@components/cellText/CellText";

const { Modal } = Dialog;

export const StyledCheckboxGroupQuoteGenerator = styled(Checkbox.Group)`
	margin-left: -7.5px;
`;

export const WrapperDragnDrop = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	flex-wrap: wrap;
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
	height: 30px;
`;

export const WrapperItems = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-between;
`;

export const StyledTextCheckbox = styled.div`
	font-size: 0.75rem;
	color: ${props => props.theme.page.textColor};
`;
export const WrapperCheckboxesToggleSwitchesTitles = styled.div`
	display: flex;
`;

export const WrapperAdditionalFieldButton = styled.div`
	display: flex;
	align-items: center;
`;

export const AdditionalFieldButton = styled(Button)`
	${props =>
		props.disabled
			? css`
					opacity: 0.5;
				`
			: null}
`;

export const ModalTitle = styled.div``;

export const WrapperFooterModal = styled.div`
	display: flex;
	gap: 10px;
`;

export const WrapperModalErrors = styled.div``;
export const ErrorText = styled.div``;

export const WrapperAdditionalFields = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
export const WrapperAdditionalFieldRow = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 16px;

	& > ${WrapperAdditionalFieldInput}:first-child {
		flex: 2;
	}
	& > ${WrapperAdditionalFieldInput}:not(:first-child) {
		flex: 1;
	}
`;

export const WrapperAdditionalFieldInput = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperAdditionalFieldLabel = styled.div`
	display: flex;
	align-items: center;
`;

export const AdditionalFieldLabel = styled.div``;

export const WrapperTitleAndSelector = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 26px;
`;

export const WrapperAddedQuoteField = styled.div`
	display: flex;
`;
export const WrapperCheckboxesAndTitle = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	flex-basis: 40%;
`;
export const WrapperCheckboxesAndTitleAddedQuoteField = styled.div`
	flex-basis: calc(40% + 5px);
	display: flex;
	margin-right: 30px;
	align-items: center;
`;

export const WrapperEditDeleteAddedQuoteField = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

export const WrapperSwitchAddedQuoteField = styled.div`
	flex-basis: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WrapperItemsAndTitle = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 70px;
`;

export const WrapperLabelAndInput = styled.div`
	display: flex;
	flex-direction: column;
`;
export const WrapperButton = styled.div``;

export const WrapperContent = styled.div`
	padding: 0 0 10px 0;
	overflow: auto;
	max-height: calc(100vh - 200px);
`;

export const WrapperSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const WrapSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 10px 0;
`;

export const WrapperTitleSection = styled.div`
	color: ${props => props.color ?? "#ffcd38"};
	margin: ${props => props.margin ?? "0 0 17px 0"};
	text-transform: uppercase;
	${props =>
		props.isFlex &&
		css`
			display: flex;
			align-items: center;
		`};
`;

export const WrapperInputs = styled.div`
	width: 100%;
`;

export const StyledCheckboxGroup = styled(Checkbox.Group)`
	width: fit-content;
`;

export const WrapperTitleInput = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #a5b9c8;
	margin: 0 0 3px 0;
`;

export const WrapperInputHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
`;

export const WrapperNbChar = styled.div``;

export const InputTitle = styled.div`
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
	display: flex;
	font-size: 12px;
	flex-wrap: nowrap;
`;

export const StyledSelector = styled(Selector)``;

export const TitleInput = styled.div``;

export const MaxLengthInput = styled.div``;

export const WrapperInputLine = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperLanguageSelector = styled.div`
	display: flex;
	align-items: center;
`;

export const InfoIcon = styled(Icon)`
	fill: ${props => (props.checked ? "#FFFFFF" : "#A5B9C8")};
	width: 16px;
	height: ${props => props.height ?? "24px"};
	margin: ${props => props.margin ?? 0};

	&:hover {
		fill: #fff;
	}
`;

export const WarningIcon = styled(Icon)`
	width: 16px;
	height: ${props => props.height ?? "24px"};
	margin: ${props => props.margin ?? 0};
`;

export const WrapperDnD = styled.div`
	position: absolute;
	top: 25px;
	left: 5px;
	visibility: hidden;
	z-index: 2;
	width: auto !important;

	${WrapperInputLine}:hover & {
		visibility: visible;
	}
`;

export const DragIcon = styled(Icon)`
	margin-left: 5px;
	cursor: pointer;
	fill: #a5b9c8;

	&:hover {
		fill: #fff;
	}
`;
export const WrapperTitleAndTooltip = styled.div`
	display: flex;
`;

export const StyledInfoTooltip = styled(Tooltipv3)`
	margin: 0 0 0 5px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledInfoIcon = styled(Icon)`
	width: 15px;
	height: 15px;
	fill: #a5b9c8;

	:hover {
		fill: #fff;
	}
`;

export const WrapperTooltipInfoContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 0 10px;
`;

export const TooltipInfoText = styled.div`
	color: #38444d;
	font-size: 14px;
`;

export const TooltipInfoImage = styled.img`
	width: 200px;
	flex: 1;
	margin: 5px 0 0;
`;

export const WrapperUpload = styled.div`
	display: flex;
	margin-bottom: 5px;
`;

export const StyledUpload = styled(Upload)`
	cursor: pointer;
	color: white;
`;

export const UploadAvatar = styled.div`
	position: relative;
`;

export const StyledAvatar = styled(Avatar)`
	background-position: center;
	${props =>
		props.marginLeft &&
		css`
			margin-left: ${props.marginLeft}px;
		`}
`;

export const UploadText = styled.div`
	position: absolute;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	cursor: pointer;
	font-size: 12px;

	${UploadAvatar}:hover & {
		top: 50%;
		left: 50%;
		opacity: 1;
	}

	${props =>
		props.isDragging &&
		css`
			top: 50%;
			left: 50%;
			opacity: 1;
		`}
`;

export const UploadDescription = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #a5b9c8;
	font-size: 12px;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: white;
	}
`;

export const StyledCameraIcon = styled(Icon)`
	cursor: pointer;
	height: 60px;
	width: 60px;
	fill: #a5b9c8;
	${StyledUpload}:hover & {
		fill: white;
	}
	&:hover {
		fill: white;
	}
`;

export const WrapperPictureAndCross = styled.div`
	position: relative;
`;

export const Round = styled.div`
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 20px;
	min-height: 20px;
	height: 20px;
	width: 20px;
	cursor: pointer;
	background-color: #1e272e;
	border-radius: 50%;
	box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.16);
	transition: background-color 0.25s;

	&:hover {
		background-color: #5c6f7e;
	}
`;

export const CrossButton = styled(Icon)`
	height: 10px;
	width: 10px;
	transition:
		transform 0.25s,
		fill 0.25s;
	fill: #fff;
	${Round}:hover & {
		transform: rotate(90deg);
		fill: ${props => props.hoverColorCrossButton};
	}
`;

export const TooltipContent = styled.div`
	max-width: 200px;
	text-align: center;
	white-space: pre-wrap;
`;

export const WrapperTitleTabs = styled.div`
	font-size: 12px;
	color: #a5b9c8;
	margin: 0 0 0 25px;
`;

export const WrapperSelector = styled.div`
	flex: 1;
	width: ${props => props.width ?? "200px"};
`;

export const WrapperCheckboxBranding = styled.div`
	display: flex;
	background-color: #495965;
`;

export const StyledModal = styled(Modal)`
	& > div:first-child {
		width: 100%;
		& > div:first-child {
			width: 92%;
		}
	}
`;

export const WrapperTitleAndColorPickers = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ColorPickerTitle = styled.div`
	overflow: hidden;
	font-size: 12px;
	color: white;
`;

export const WrapperTitleAndInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	min-width: 175px;
`;

export const WrapperColorPickers = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 40px;
`;

export const WrapperColorPicker = styled.div`
	margin-bottom: 10px;
`;

export const StyledTooltip = styled(Tooltipv3)`
	margin: 0 15px 0 5px;
`;

export const WrapperInfoIcon = styled.div`
	padding: ${props => props.padding ?? 0};
	display: flex;
	align-items: center;
`;

export const WrapperSwitch = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`;

export const LabelItem = styled(SwitchLabel)`
	display: flex;
	align-items: center;
	white-space: nowrap;
`;

export const WrapperFirstLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Email = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
`;
export const WrapperSubtitles = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
`;

export const WrapperMandatory = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	transform: translateY(10px);
	z-index: 1000;
	margin: 0 0 5px 0;
`;

export const WrapperCategories = styled.div`
	display: flex;
	width: 100%;
	margin: 0;
	border-top: 1px solid #5c6f7e;
	border-bottom: 1px solid #5c6f7e;
	padding: 10px 0;
`;

export const WrapperCategory = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 100px;
	align-items: start;
	margin: 0 5px 0 0;
	&:last-child {
		margin: 0;
	}
`;

export const CategoryTitle = styled(CellText)`
	font-size: 12px;
	color: #a5b9c8;
	margin: 0 0 4px 0;
`;

export const WrapperInputLabel = styled.div`
	display: flex;
	margin-left: 15px;
	font-size: 12px;
	flex-direction: column;
	width: 100%;
	padding-bottom: 15px;
`;

export const SubSectionTitle = styled.div``;

export const OnlyOneTabWrapper = styled.div`
	font-size: 12px;
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 10px 0 10px 0px;
`;

export const WrapperAdvancedSettings = styled.div`
	& > * {
		padding: 10px 0;
	}
`;

export const SectionName = styled.div`
	font-size: 16px;
	color: ${props => props.theme.page.textColor};
`;

export const StyledIcon = styled(Icon)`
	fill: ${props => props.theme.modalAdditionalQuoteFields.labelColor};
	cursor: pointer;
	&:hover {
		fill: #fff;
	}
`;

export const DnDIconWrapper = styled.div`
	width: 15px;
	height: 15px;
`;

export const ActionIcon = styled(Icon)`
	fill: ${props => props.theme.modalAdditionalQuoteFields.labelColor};
	width: 15px;
	height: 15px;
	cursor: pointer;
	&:hover {
		fill: #ffffff;
	}
`;

export const PackDnDIcon = styled(ActionIcon)`
	cursor: grab;
`;

export const WrapperDrag = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WrapperSelect = styled.div`
	display: flex;
	flex-direction: row;
`;
