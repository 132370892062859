import styled, { css } from "styled-components";

export const TextDiv = styled.div`
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	${props =>
		props.twoLines &&
		css`
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		`};
`;

export const StyledSpan = styled.span`
	width: 100%;
	overflow: hidden;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: pre-wrap;
`;

export const WrapperTooltipTextInColumnFormat = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin: 0;
	padding: 15px;
`;
