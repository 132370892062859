import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "./Components.style";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
	clientOffersResetFiltersSelector,
	clientOffersTemplateFiltersSelector,
} from "@recoil/clientOffers/clientsOffersSelector";
import { useThemeContext } from "@theme/Theme";

export const ResetButton = memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode } = useThemeContext();
	const filter = useRecoilValue(clientOffersTemplateFiltersSelector);
	const resetFilters = useSetRecoilState(clientOffersResetFiltersSelector);

	return Object.values(filter).every(element => !element) ? null : (
		<StyledButton mode={mode} type="add" icon="reset" onClick={() => resetFilters()}>
			{t("Reset")}
		</StyledButton>
	);
});
ResetButton.whyDidYouRender = true;
ResetButton.displayName = "ResetButton";
export default ResetButton;
