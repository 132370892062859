import { gql, useMutation } from "@apollo/client";

const CREATE_ERROR_TASK = gql`
	mutation CreateErrorTask($error: String, $componentStack: String, $location: String, $sessionURL: String) {
		createErrorTask(error: $error, componentStack: $componentStack, location : $location, sessionURL: $sessionURL)
	}
`;

export const createErrorTaskMutation = () => {
	const [createErrorTask] = useMutation(CREATE_ERROR_TASK, {
		fetchPolicy: "no-cache",
	});
	return createErrorTask;
};
