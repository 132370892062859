import React from "react";
import { Cross, Round } from "./CrossButton.style";

export const CrossButton = ({
	onClickFunction,
	noAbsolutePosition,
	roundBackgroundColor,
	hoverRoundBackgroundColor,
	colorCrossButton,
	hoverColorCrossButton,
	margin,
	zIndex,
	disabled,
	roundStyle,
	crossStyle,
}) => (
	<Round
		key={"crossButton"}
		className="crossButton"
		onClick={onClickFunction}
		roundBackgroundColor={roundBackgroundColor}
		hoverRoundBackgroundColor={hoverRoundBackgroundColor}
		noAbsolutePosition={true}
		withShadow={true}
		margin={margin}
		zIndex={zIndex}
		headerType={noAbsolutePosition ? "" : "tabs"}
		disabled={disabled}
		style={roundStyle}
	>
		<Cross
			type="cross"
			colorCrossButton={colorCrossButton}
			hoverColorCrossButton={hoverColorCrossButton}
			disabled={disabled}
			style={crossStyle}
		/>
	</Round>
);
