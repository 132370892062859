import { useEffect, useState } from "react";
import { message } from "priceit-ui";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { GET_PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_BY_ID } from "@graphQl/queries/priceModelCombinationQuery";
import analytics from "../../../config/analytics/analytics";

import { useWorkspace } from "@hooks/useWorkspace/UseWorkspace";

import {
	clientsOfferNewVersionIdSelector,
	clientsOffersIsNewOfferVersionSelector,
	clientsOffersSelectedIdSelector,
	sectionName,
} from "@recoil/clientOffers";

import {
	clientOfferLoadingCreationVersionAtom,
	clientsOffersCreationStepAtom,
	clientsOffersEnableCrmDeal,
	clientsOffersIsNewOfferAtom,
	clientsOffersNewPriceModelCombinationAtom,
	clientsOffersNextButtonDisabledAtom,
	lastOfferPropertiesDraftIdAtom,
	lastQuoteConfigurationDraftIdAtom,
	offerComputedNameAtom,
} from "@recoil/clientOffers/atom";
import {
	confirmOfferCreationMutation,
	setDefaultOfferParameterMutation,
} from "@graphQl/mutations/offer";

import {
	confirmOfferVersionCreationMutation,
	createOfferVersionMutation,
} from "@graphQl/mutations/offerVersion";
import { GET_OFFER_CRM_DEAL_BY_OFFER_ID, GET_OFFER_MANDATORY_BY_ID } from "@graphQl/queries/offer";
import { GET_WORKSPACE_DATA_FOR_NEXT_BUTTON } from "@graphQl/queries/workspace";
import { useNavigate } from "react-router-dom";
import { resetRecoil } from "@recoil/component/RecoilOutsideTheBox";
import { getPublicLink } from "@pages/myPricingTools/buildingTools/priceModels/services/PriceModels.service";
import { copyTextToClipboard } from "@services/clipboard";

import { deleteOfferContactMutation } from "@src/graphQl/mutations/offerContact";

import * as atoms from "@recoil/publicPriceModel/atom";
import { useIframeActions } from "@hooks/UseIframeActions";
import { useGlobalContext } from "@globalContext";
import { isErrorOfferContact } from "@src/pages/clientsOffers/hooks/useCanNextCustomization/isErrorOfferContact/isErrorOfferContact";
import { useResetOfferCreationParameters } from "@pages/clientsOffers/hooks/useResetOfferCreationParameters";
import { createOfferPropertiesMutation } from "@src/graphQl/mutations/offerProperties";
import moment from "moment";
import { updateTokenExpirationDateMutation } from "@src/graphQl/mutations/token";
import { useComputeOfferName } from "./useComputeOfferName/UseComputeOfferName";
import { createQuoteConfigurationMutation } from "@src/graphQl/mutations/quoteConfiguration";

const isValidURL = stringURL => {
	let url;

	try {
		url = new URL(stringURL);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
};

export const useHandleOkActionCreationOfferForNextStepButton = () => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { workspace } = useWorkspace();
	const { cache } = useApolloClient();

	const [loadingNextButton, setLoadingNextButton] = useState(false);
	const [loadingCreateButton, setLoadingCreateButton] = useState(false);

	const navigate = useNavigate();
	const { light } = useGlobalContext();

	const [confirmCreateOffer] = useIframeActions();

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const enabled = useRecoilValue(clientsOffersEnableCrmDeal);

	const computedName = useComputeOfferName({ offerId, isDraft: true });

	const setComputedName = useSetRecoilState(offerComputedNameAtom);

	const newOfferVersionId = useRecoilValue(clientsOfferNewVersionIdSelector);

	const isNewOffer = useRecoilValue(clientsOffersIsNewOfferAtom);
	const currentPriceModelCombinationId = useRecoilValue(
		atoms.publicLinkCurrentPriceModelCombinationIdAtom
	);

	const [creationStep, setCreationStep] = useRecoilState(clientsOffersCreationStepAtom);
	const setIsNextButtonDisabled = useSetRecoilState(clientsOffersNextButtonDisabledAtom);
	const loadingCreationVersion = useRecoilValue(clientOfferLoadingCreationVersionAtom);
	const lastOfferPropertiesDraftId = useRecoilValue(lastOfferPropertiesDraftIdAtom);

	const [confirmOfferCreation] = confirmOfferCreationMutation(light, isNewOffer);

	const [confirmOfferVersionCreation] = confirmOfferVersionCreationMutation();

	const [deleteOfferContact] = deleteOfferContactMutation(offerId);

	const [updateTokenExpirationDate] = updateTokenExpirationDateMutation();

	const resetOfferCreationParameters = useResetOfferCreationParameters();
	const { data: dataOffer } = useQuery(GET_OFFER_MANDATORY_BY_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});

	const { data: dataCrmDeal } = useQuery(GET_OFFER_CRM_DEAL_BY_OFFER_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});
	const crmDeal = dataCrmDeal?.getOfferById || {};
	const {
		name,
		companyName,
		ownerId,
		offerContacts,
		offerProperties,
		tokenObj: offerTokenObj,
	} = dataOffer?.getOfferById || {};

	const {
		enableAttachedPresentation,
		attachedPresentation,
		attachedLink,
		enableAttachedLink,
		enableClientsLogo,
		clientsLogo,
	} = offerProperties || {};

	const errorOfferContact = isErrorOfferContact({ offerContacts: offerContacts });

	const updateExpirationDateAfterNewOfferVersion = async newDate => {
		const { id: tokenId, active } = offerTokenObj;
		const newDateValue = moment.utc(newDate, "DDMMYYYY", true).isValid()
			? moment.utc(newDate, "DDMMYYYY").format()
			: null;
		const isExpired = moment(newDateValue).isBefore(moment(), "day");

		await updateTokenExpirationDate({
			variables: {
				token: {
					id: tokenId,
					expirationDate: newDateValue,
				},
			},
			optimisticResponse: {
				updateToken: {
					__typename: "Token",
					id: tokenId,
					expirationDate: newDateValue,
					isExpired,
					active: isExpired ? false : active,
				},
			},
		});
	};

	function removeAllEmptyContactLines() {
		offerContacts?.forEach((contact, index) => {
			const errors = contact.errors;
			if (
				errors.length === 3 &&
				errors.filter(error => error.type.includes("empty")).length === 3 &&
				index !== 0
			) {
				deleteOfferContact({
					optimisticResponse: {
						__typename: "Mutation",
						deleteOfferContact: contact.id,
					},
					variables: {
						id: contact.id,
					},
				});
			}
		});
	}

	const { data: dataWorkspace } = useQuery(GET_WORKSPACE_DATA_FOR_NEXT_BUTTON);
	const { name: workspaceName } = dataWorkspace?.getCurrentWorkspace || {};

	const conditionAttachedPresentationEnableNexButton =
		(enableAttachedPresentation && attachedPresentation) ||
		!attachedPresentation ||
		!enableAttachedPresentation;

	const disableButton =
		(!enabled &&
			(!computedName ||
				!companyName ||
				!ownerId ||
				!conditionAttachedPresentationEnableNexButton ||
				errorOfferContact)) ||
		(enabled && (!crmDeal.crmDealId || !name)) ||
		(!isValidURL(attachedLink) && enableAttachedLink) ||
		(enableClientsLogo && !clientsLogo);

	useEffect(() => {
		if (!disableButton) {
			setIsNextButtonDisabled(false);
		}
	}, [disableButton]);

	//TODO: duplicated function
	const validateOfferCreation = async () => {
		setLoadingCreateButton(true);
		await confirmOfferVersionCreation({
			variables: {
				id: newOfferVersionId,
			},
		});

		const offerRes = await confirmOfferCreation({
			variables: {
				id: offerId,
			},
		});

		setLoadingCreateButton(false);

		cache.modify({
			id: cache.identify({
				id: offerId,
				__typename: "Offer",
			}),
			fields: {
				offerProperties() {
					return {
						__ref: cache.identify({
							id: lastOfferPropertiesDraftId,
							__typename: "OfferProperties",
						}),
					};
				},
			},
		});

		return offerRes;
	};

	const okActionCustomizationStepNextButton = () => {
		if (disableButton) {
			setIsNextButtonDisabled(true);
			analytics.track("Create a new offer - next button", {
				from: light ? "hubspot" : "webApp",
				type: isNewOffer ? "new offer" : "new version",
				step: "customization",
				error: true,
				workspaceName: workspace?.name,
				workspaceActive: workspace?.active,
				workspaceActivationDate: workspace?.createdAt,
				workspaceActiveToDate: workspace?.activeToDate,
			});
		} else {
			if (loadingCreationVersion) {
				setLoadingNextButton(true);
			} else {
				setComputedName(computedName);
				setCreationStep(sectionName.offerCreationStep.preSelectionOfOptions);
				removeAllEmptyContactLines();
				analytics.track("Create a new offer - next button", {
					from: light ? "hubspot" : "webApp",
					type: isNewOffer ? "new offer" : "new version",
					step: "customization",
					error: false,
					workspaceName: workspace?.name,
					workspaceActive: workspace?.active,
					workspaceActivationDate: workspace?.createdAt,
					workspaceActiveToDate: workspace?.activeToDate,
				});
			}
		}
	};

	const okActionCustomizationStepSkipButton = () => {
		if (disableButton) {
			setIsNextButtonDisabled(true);
			analytics.track("Create a new offer - skip button", {
				from: light ? "hubspot" : "webApp",
				type: isNewOffer ? "new offer" : "new version",
				step: "customization",
				error: true,
				workspaceName: workspace?.name,
				workspaceActive: workspace?.active,
				workspaceActivationDate: workspace?.createdAt,
				workspaceActiveToDate: workspace?.activeToDate,
			});
			return false;
		} else {
			if (loadingCreationVersion) {
				setLoadingNextButton(true);
			} else {
				removeAllEmptyContactLines();
				analytics.track("Create a new offer - skip button", {
					from: light ? "hubspot" : "webApp",
					type: isNewOffer ? "new offer" : "new version",
					step: "customization",
					error: false,
					workspaceName: workspace?.name,
					workspaceActive: workspace?.active,
					workspaceActivationDate: workspace?.createdAt,
					workspaceActiveToDate: workspace?.activeToDate,
				});
			}
			return true;
		}
	};

	const handleOkAction = async isFromSkipButton => {
		switch (creationStep) {
			case sectionName.offerCreationStep.customization:
				if (isFromSkipButton) {
					const goFurther = okActionCustomizationStepSkipButton();

					if (!goFurther) break;

					const offerRes = await validateOfferCreation();

					if (light) {
						const offerToken = offerRes?.data?.confirmOfferCreation?.tokenObj?.token;
						if (offerToken && workspaceName) {
							const offerLink = getPublicLink({
								token: offerToken,
								isGroup: false,
								isOffer: true,
								workspaceName,
							});
							const copy = await copyTextToClipboard(offerLink);
							if (copy) {
								message.success(t("The link is copied in your clipboard"));
								setTimeout(() => confirmCreateOffer(), 5000);
							} else {
								confirmCreateOffer();
							}
							// show copy text
						} else {
							// if we can't get the token, close the modal directly
							confirmCreateOffer();
						}
						analytics.track("Create a new offer - skip button", {
							from: "hubspot",
							type: isNewOffer ? "new offer" : "new version",
							step: "preSelectionOfOptions",
							error: false,
							workspaceName: workspace?.name,
							workspaceActive: workspace?.active,
							workspaceActivationDate: workspace?.createdAt,
							workspaceActiveToDate: workspace?.activeToDate,
						});
						navigate("/offercreated");
						break;
					}

					resetOfferCreationParameters();
					analytics.track("Create a new offer - skip button", {
						from: "webApp",
						type: isNewOffer ? "new offer" : "new version",
						step: "preSelectionOfOptions",
						error: false,
						workspaceName: workspace?.name,
						workspaceActive: workspace?.active,
						workspaceActivationDate: workspace?.createdAt,
						workspaceActiveToDate: workspace?.activeToDate,
					});
					break;
				} else {
					okActionCustomizationStepNextButton();
				}
				break;
			case sectionName.offerCreationStep.preSelectionOfOptions:
				const offerRes = await validateOfferCreation();

				if (light) {
					const offerToken = offerRes?.data?.confirmOfferCreation?.tokenObj?.token;
					if (offerToken && workspaceName) {
						const offerLink = getPublicLink({
							token: offerToken,
							isGroup: false,
							isOffer: true,
							workspaceName,
						});
						const copy = await copyTextToClipboard(offerLink);
						if (copy) {
							message.success(t("The link is copied in your clipboard"));
							setTimeout(() => confirmCreateOffer(), 5000);
						} else {
							confirmCreateOffer();
						}
						// show copy text
					} else {
						// if we can't get the token, close the modal directly
						confirmCreateOffer();
					}
					analytics.track("Create a new offer - next button", {
						from: "hubspot",
						type: isNewOffer ? "new offer" : "new version",
						step: "preSelectionOfOptions",
						error: false,
						workspaceName: workspace?.name,
						workspaceActive: workspace?.active,
						workspaceActivationDate: workspace?.createdAt,
						workspaceActiveToDate: workspace?.activeToDate,
					});
					navigate("/offercreated");
					break;
				}

				resetOfferCreationParameters();
				analytics.track("Create a new offer - next button", {
					from: "webApp",
					type: isNewOffer ? "new offer" : "new version",
					step: "preSelectionOfOptions",
					error: false,
					workspaceName: workspace?.name,
					workspaceActive: workspace?.active,
					workspaceActivationDate: workspace?.createdAt,
					workspaceActiveToDate: workspace?.activeToDate,
				});
				break;
			case sectionName.refreshTemplate:
				await confirmOfferVersionCreation({
					variables: {
						id: newOfferVersionId,
					},
				});
				cache.modify({
					id: cache.identify({
						id: offerId,
						__typename: "Offer",
					}),
					fields: {
						offerProperties() {
							return {
								__ref: cache.identify({
									id: lastOfferPropertiesDraftId,
									__typename: "OfferProperties",
								}),
							};
						},
					},
				});
				updateExpirationDateAfterNewOfferVersion(moment().add(1, "M"));
				resetOfferCreationParameters();
				break;
			default:
				break;
		}
	};

	return {
		handleOkAction,
		loadingCreationVersion,
		loadingNextButton,
		setLoadingNextButton,
		loadingCreateButton,
	};
};

export const useHandleOkActionCreationOfferTemplateStep = () => {
	const { workspace } = useWorkspace();

	const { light } = useGlobalContext();

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const setLoadingCreationVersion = useSetRecoilState(clientOfferLoadingCreationVersionAtom);
	const setNewOfferVersionId = useSetRecoilState(clientsOfferNewVersionIdSelector);
	const setLastOfferPropertiesDraftId = useSetRecoilState(lastOfferPropertiesDraftIdAtom);
	const setLastQuoteConfigurationDraftId = useSetRecoilState(lastQuoteConfigurationDraftIdAtom);

	const isNewOffer = useRecoilValue(clientsOffersIsNewOfferAtom);
	const isNewOfferVersion = useRecoilValue(clientsOffersIsNewOfferVersionSelector);

	const setNewPriceModelCombination = useSetRecoilState(
		clientsOffersNewPriceModelCombinationAtom
	);

	const setCreationStep = useSetRecoilState(clientsOffersCreationStepAtom);

	const [createOfferVersion] = createOfferVersionMutation();

	const [setDefaultOfferParameter] = setDefaultOfferParameterMutation();

	const [createOfferProperties, { loading: loadingCreateOfferProperties }] =
		createOfferPropertiesMutation();
	const [createQuoteConfiguration] = createQuoteConfigurationMutation();

	const [getPriceModelCombinationMail, {}] = useLazyQuery(
		GET_PRICE_MODEL_COMBINATION_FOR_OFFER_CREATION_MAIL_BY_ID,
		{ fetchPolicy: "cache-first" }
	);

	const setDefaultParameter = async ({ selectedTemplateId }) => {
		const priceModelCombinationQuery = await getPriceModelCombinationMail({
			variables: {
				id: selectedTemplateId,
			},
		});

		const priceModelCombinationDataForNextButton =
			priceModelCombinationQuery.data.getPriceModelCombinationById;

		if (!isNewOfferVersion) {
			setDefaultOfferParameter({
				variables: {
					updateOffer: {
						id: offerId,
						openOfferEmails:
							priceModelCombinationDataForNextButton.openCombinationLinkUsers.map(
								element => element.id
							),
						quoteOfferEmails:
							priceModelCombinationDataForNextButton.quoteCombinationLinkUsers.map(
								element => element.id
							),
					},
				},
				optimisticResponse: {
					updateOffer: {
						__typename: "Offer",
						id: offerId,
						openOfferEmails:
							priceModelCombinationDataForNextButton.openCombinationLinkUsers,
						quoteOfferEmails:
							priceModelCombinationDataForNextButton.quoteCombinationLinkUsers,
					},
				},
			});
		}
	};

	//FIXME: quelque chose ne va pas dans cette fonction, parfois le offerId est null
	const handleOkAction = async ({ selectedTemplateId }) => {
		if (isNewOffer || isNewOfferVersion) {
			const { data: offerProperties } = await createOfferProperties({
				variables: {
					offerProperties: {
						offerId,
						priceModelCombinationId: selectedTemplateId,
					},
				},
			}); // We must create offerProperties before go to the next step

			setLastOfferPropertiesDraftId(offerProperties?.createOfferProperties?.id);

			const { data: quoteConfiguration } = await createQuoteConfiguration({
				variables: {
					quoteConfiguration: {
						offerId,
						priceModelCombinationId: selectedTemplateId,
					},
				},
			});

			setLastQuoteConfigurationDraftId(quoteConfiguration?.createQuoteConfiguration?.id);

			setCreationStep(sectionName.offerCreationStep.customization);
			setDefaultParameter({ selectedTemplateId });
			setLoadingCreationVersion(true);
			analytics.track("Create a new offer - next button", {
				from: light ? "hubspot" : "webApp",
				type: isNewOffer ? "new offer" : "new version",
				step: "selectedTemplate",
				error: false,
				workspaceName: workspace?.name,
				workspaceActive: workspace?.active,
				workspaceActivationDate: workspace?.createdAt,
				workspaceActiveToDate: workspace?.activeToDate,
			});

			const { data } = await createOfferVersion({
				variables: {
					createOfferVersion: { offerId, priceModelCombinationId: selectedTemplateId },
				},
			});

			setLoadingCreationVersion(false);
			const priceModelCombinationId = data.createOfferVersion.priceModelCombination.id;
			const offerVersionId = data.createOfferVersion.id;
			Object.values(atoms).forEach(atom => {
				resetRecoil(atom);
			});
			setNewOfferVersionId(offerVersionId);
			setNewPriceModelCombination(priceModelCombinationId);
		}
	};

	return { handleOkAction, loadingCreateOfferProperties };
};
