import { useRecoilValue } from "recoil";
import { lastQuoteConfigurationDraftIdAtom } from "@recoil/clientOffers/atom";
import { useQuery } from "@apollo/client";
import {
	GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_ID,
	GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_OFFER_ID,
	GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_PRICE_MODEL_COMBINATION_ID,
} from "@graphQl/queries/quoteConfiguration";

export const useQuoteConfigurationData = ({ offerId, priceModelCombinationId }) => {
	const lastQuoteConfigurationDraftId = useRecoilValue(lastQuoteConfigurationDraftIdAtom);

	const { data: dataQuoteConfigurationPriceModelCombination } = useQuery(
		GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_PRICE_MODEL_COMBINATION_ID,
		{
			variables: {
				priceModelCombinationId: priceModelCombinationId,
			},
			skip: !priceModelCombinationId,
		}
	);

	const { data: dataQuoteConfigurationOffer } = useQuery(
		GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_OFFER_ID,
		{
			variables: {
				offerId: offerId,
			},
			skip: !offerId || lastQuoteConfigurationDraftId,
		}
	);

	const { data: dataDraftQuoteConfiguration } = useQuery(
		GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_ID,
		{
			variables: {
				id: lastQuoteConfigurationDraftId,
			},
			skip: !lastQuoteConfigurationDraftId && !offerId,
		}
	);

	const {
		id: quoteConfigurationId,
		layout,
		showFirstPaymentBox,
		showPaymentSchedule,
		showAttributesDescription,
		showIntroductionText,
		showIncludedMention,
		showVatDetail,
		showTotalBoxes,
		introductionText,
		useIntroTextFromIntroPage,
	} = dataQuoteConfigurationPriceModelCombination?.getQuoteConfigurationByPriceModelCombinationId ||
	dataQuoteConfigurationOffer?.getQuoteConfigurationByOfferId ||
	dataDraftQuoteConfiguration?.getQuoteConfigurationById ||
	{};

	return {
		quoteConfigurationId,
		layout,
		showFirstPaymentBox,
		showPaymentSchedule,
		showAttributesDescription,
		showIntroductionText,
		showIncludedMention,
		showVatDetail,
		showTotalBoxes,
		introductionText,
		useIntroTextFromIntroPage,
	};
};
