import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { useThemeContext } from "@theme/Theme";

import { LabelAndInputComponent } from "@components/labelAndInputComponent/LabelAndInputComponent";
import { useDefaultMailSubject } from "@pages/clientsOffers/hooks/useDefaultMailSubject";
import { clientsOffersSubjectAtom } from "@recoil/clientOffers/atom";

export const Subject = React.memo(() => {
	const { t } = useTranslation("clientsOffers/modalSendEmail");
	const { mode } = useThemeContext();

	const [mailSubject, setMailSubject] = useRecoilState(clientsOffersSubjectAtom);

	const mailSubjectValue = useDefaultMailSubject({ isSignatureLink: false });

	useEffect(() => {
		setMailSubject(currVal => (!!currVal ? currVal : mailSubjectValue));
	}, []);

	return (
		<LabelAndInputComponent
			mode={mode}
			width={"100%"}
			label={t("Subject")}
			isMandatory={true}
			value={mailSubject}
			setValue={value => setMailSubject(value)}
			errorTooltipContent={t("Subject is required")}
			fontWeight={200}
		/>
	);
});
Subject.whyDidYouRender = true;
Subject.displayName = "Subject";
