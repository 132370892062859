import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { menuCollapsedVar } from "@src/cache/cache";
import useResizeObserver from "@react-hook/resize-observer";

export const useWindowUnloadEffect = (handler, callOnCleanup) => {
	const cb = useRef();

	cb.current = handler;

	useEffect(() => {
		const handler = () => cb.current();

		window.addEventListener("beforeunload", handler);

		return () => {
			if (callOnCleanup) handler();

			window.removeEventListener("beforeunload", handler);
		};
	}, [cb]);
};

export const useClickAwayListener = refs => {
	const [clickedAway, setClickedAway] = useState(true);

	useEffect(() => {
		const handleClickOutside = event => {
			const boolRefs = refs.map(
				ref => !!(ref.current && !ref.current.contains(event.target))
			);
			setClickedAway(!boolRefs.includes(false));
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [refs]);

	return clickedAway;
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

export const useSize = target => {
	const [size, setSize] = useState();
	const menuCollapsed = useReactiveVar(menuCollapsedVar);
	const windowSize = useWindowSize();

	const widthOfTarget = target.current?.getBoundingClientRect()?.width || 0;
	const widthOfTargetInteger = parseInt(widthOfTarget);

	useLayoutEffect(() => {
		if (!target?.current?.getBoundingClientRect()?.toJSON()?.gmfequals(size?.toJSON())) {
			setSize(target?.current?.getBoundingClientRect());
		}
	}, [widthOfTargetInteger, windowSize, menuCollapsed]);

	useResizeObserver(target, entry => {
		if (size.height !== entry.contentRect.height || size.width !== entry.contentRect.width) {
			setSize(entry.contentRect);
		}
	});
	return size;
};

export const useSizeObserver = target => {
	const [size, setSize] = useState();
	const menuCollapsed = useReactiveVar(menuCollapsedVar);
	const windowSize = useWindowSize();

	useLayoutEffect(() => {
		const element = target?.current;
		element && setSize(element.getBoundingClientRect());
	}, [target, windowSize, menuCollapsed]);

	useResizeObserver(target || null, entry => setSize(entry.contentRect));
	return size;
};

export const useClientRect = ({ loaded }) => {
	const [rect, setRect] = useState(null);
	const windowSize = useWindowSize();
	const ref = useCallback(
		node => {
			if (node !== null) {
				setRect(node.getBoundingClientRect());
			}
		},
		[windowSize, loaded]
	);
	return [rect, ref];
};
