import { gql } from "@apollo/client";
import {
	MAIL_SERVICE_DESCRIPTION_FRAGMENT,
	MAIL_SERVICE_NAME_FRAGMENT,
	MAIL_CONNECTION_FRAGMENT,
} from "@graphQl/fragments/mail";

export const GET_AVAILABLE_MAIL_SERVICES = gql`
	${MAIL_SERVICE_NAME_FRAGMENT}
	${MAIL_SERVICE_DESCRIPTION_FRAGMENT}
	query GetAvailableMailServices {
		getAvailableMailServices {
			...MailServiceNameFragment
			...MailServiceDescriptionFragment
		}
	}
`;

export const GET_MAIL_CONNECTIONS = gql`
	${MAIL_CONNECTION_FRAGMENT}
	query GetUserMailConnections {
		getUserMailConnections {
			...MailConnectionFragment
		}
	}
`;
