import React, { useState, useCallback } from "react";

import { Tooltipv3 } from "priceit-ui";
import {
	StyledSpan,
	TextDiv,
	WrapperTooltipTextInColumnFormat,
	WrapperTooltipTextListFormat,
} from "./CellText.style";

export const CellText = ({
	answer,
	twoLines,
	maxWidth,
	textStyle,
	enableTooltipTextInColumnFormat,
	center,
}) => {
	const [isTextOverflowing, setIsTextOverflowing] = useState(false);
	const [checkOverflow, setCheckOverflow] = useState(false);

	const staticTextRef = useCallback(
		node => {
			if (node !== null) {
				if (node.clientWidth < node.scrollWidth || node.clientHeight < node.scrollHeight) {
					setIsTextOverflowing(true);
				} else {
					setIsTextOverflowing(false);
				}
			}
		},
		[checkOverflow]
	);

	return (
		<Tooltipv3
			placement={"top"}
			mode="dark"
			type="primary"
			content={
				enableTooltipTextInColumnFormat ? (
					<WrapperTooltipTextInColumnFormat>
						{answer.map((ans, index) => (
							<div>
								{ans}
								{index === answer.length - 1 ? "" : ","}
							</div>
						))}
					</WrapperTooltipTextInColumnFormat>
				) : (
					<StyledSpan>{answer}</StyledSpan>
				)
			}
			disabled={!isTextOverflowing}
			appendTo={document.body}
			style={{
				width: "100%",
				overflow: "hidden",
				textAlign: center ? "center" : "left",
			}}
			maxWidth={maxWidth ?? "250px"}
			interactive={false}
		>
			<TextDiv
				twoLines={twoLines}
				ref={staticTextRef}
				onMouseEnter={() => {
					setCheckOverflow(!checkOverflow);
				}}
				style={textStyle}
			>
				{answer}
			</TextDiv>
		</Tooltipv3>
	);
};
