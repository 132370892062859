import moment from "moment";

export const convertMetric = (commitment, timeMetric) => {
	if (["onlyOnce", "oneShot", null, undefined].includes(timeMetric)) {
		return {
			hour: 1,
			day: 1,
			week: 1,
			month: 1,
			quarter: 1,
			semester: 1,
			year: 1,
			twoYear: 1,
			threeYear: 1,
			fourYear: 1,
			fiveYear: 1,
		};
	}

	let d;
	if (timeMetric === "semester") {
		d = moment.duration(commitment * 2, "quarter");
	} else if (timeMetric === "twoYear") {
		d = moment.duration(commitment * 2, "year");
	} else if (timeMetric === "threeYear") {
		d = moment.duration(commitment * 3, "year");
	} else if (timeMetric === "fourYear") {
		d = moment.duration(commitment * 4, "year");
	} else if (timeMetric === "fiveYear") {
		d = moment.duration(commitment * 5, "year");
	} else {
		d = moment.duration(commitment, timeMetric);
	}

	return {
		hour: d.as("hours"),
		day: d.as("days"),
		week: d.as("weeks"),
		month: d.as("months"),
		quarter: d.as("quarters"),
		semester: d.as("quarters") / 2,
		year: d.as("years"),
		twoYear: d.as("years") / 2,
		threeYear: d.as("years") / 3,
		fourYear: d.as("years") / 4,
		fiveYear: d.as("years") / 5,
	};
};
