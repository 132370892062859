export const retrieveLockedObjectForDependency = discountRule => {
	switch (discountRule.type) {
		case "decreasePricePerUnit":
		case "tieredRule":
		case "rangeNumericalDiscount":
		case "rangeNumericalSupplement":
		case "tieredNumericalDiscount":
		case "tieredNumericalSupplement":
		case "linearNumericalDiscount":
		case "linearNumericalSupplement":
			return {
				isLocked: discountRule.volumeDiscount.isLocked,
				lockedExtraAddon: [],
			};
		case "paymentTermsDiscount":
			return {
				isLocked: discountRule.paymentTermDiscount.isLocked,
				lockedExtraAddon: [],
			};

		case "engagementDiscount":
			return {
				isLocked: discountRule.engagementDiscount.isLocked,
				lockedExtraAddon: [],
			};
		case "specialDiscount":
		case "specialCondition":
			return {
				isLocked: discountRule.specialDiscount.isLocked,
				lockedExtraAddon: [],
			};
		case "addon":
		case "extra":
			const specialDiscount = discountRule.specialDiscount;
			return {
				isLocked: null,
				lockedExtraAddon: specialDiscount.defaultPositions
					.map(postitions => ({
						isLocked: postitions.isAddonExtraLocked,
						packId: postitions.packId,
					}))
					.filter(x => !!x.packId),
			};
		default:
			return {
				isLocked: null,
				lockedExtraAddon: [],
			};
	}
};
