import React, { memo, useEffect } from "react";
import { Button, Loader } from "priceit-ui";
import { useApolloClient, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useHandleOkActionCreationOfferForNextStepButton } from "@hooks/useOffer/useHandleOkActionCreationOffer";
import analytics from "../../../../../../config/analytics/analytics"; //TODO: use alias
import { useWorkspace } from "@hooks/useWorkspace/UseWorkspace";

import {
	clientOfferVisibleEmailSelector,
	clientsOfferNewVersionIdSelector,
	clientsOffersSelectedIdSelector,
	sectionName,
} from "@recoil/clientOffers";

import {
	clientOfferLoadingCreationVersionAtom,
	clientsOffersCreationStepAtom,
	clientsOffersIsNewOfferAtom,
	clientsOffersNextButtonDisabledAtom,
	lastOfferPropertiesDraftIdAtom,
} from "@recoil/clientOffers/atom";

import { confirmOfferCreationMutation } from "@graphQl/mutations/offer";

import { confirmOfferVersionCreationMutation } from "@graphQl/mutations/offerVersion";
import { GET_OFFER_MANDATORY_BY_ID } from "@graphQl/queries/offer";
import { useThemeContext } from "@theme/Theme";
import {
	ButtonText,
	ButtonWrapper,
	LoaderWrapper,
	LoadingIcon,
	LoadingLabel,
} from "../CreateNewOfferHeader.style";

import ModalSendEmail from "@pages/clientsOffers/modalSendEmail/ModalSendEmail";
import { useGlobalContext } from "@globalContext";
import { GET_MAIL_CONNECTIONS } from "@graphQl/queries/mail";
import { useResetOfferCreationParameters } from "@pages/clientsOffers/hooks/useResetOfferCreationParameters";
import { useCanNextCustomization } from "@src/pages/clientsOffers/hooks/useCanNextCustomization/useCanNextCustomization";
import { useRemoveAllEmptyContactLines } from "@pages/clientsOffers/hooks/useRemoveAllEmptyContactLines";

export const NextButton = memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { workspace } = useWorkspace();
	const { cache } = useApolloClient();

	const { mode } = useThemeContext();
	const { light } = useGlobalContext();

	const setVisibleEmailModal = useSetRecoilState(clientOfferVisibleEmailSelector);
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const newOfferVersionId = useRecoilValue(clientsOfferNewVersionIdSelector);
	const isNewOffer = useRecoilValue(clientsOffersIsNewOfferAtom);
	const [creationStep, setCreationStep] = useRecoilState(clientsOffersCreationStepAtom);
	const isNextButtonDisabled = useRecoilValue(clientsOffersNextButtonDisabledAtom);
	const lastOfferPropertiesDraftId = useRecoilValue(lastOfferPropertiesDraftIdAtom);

	const { handleOkAction, loadingNextButton, setLoadingNextButton, loadingCreateButton } =
		useHandleOkActionCreationOfferForNextStepButton();

	const loadingCreationVersion = useRecoilValue(clientOfferLoadingCreationVersionAtom);
	const [confirmOfferCreation] = confirmOfferCreationMutation(light, isNewOffer);

	const [confirmOfferVersionCreation] = confirmOfferVersionCreationMutation();

	const { data: dataOffer } = useQuery(GET_OFFER_MANDATORY_BY_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});

	const { offerContacts } = dataOffer?.getOfferById || {};

	useCanNextCustomization();

	const removeAllEmptyContactLines = useRemoveAllEmptyContactLines(offerContacts);

	useEffect(() => {
		if (loadingNextButton && !loadingCreationVersion) {
			setLoadingNextButton(false);
			setCreationStep(sectionName.offerCreationStep.preSelectionOfOptions);
			removeAllEmptyContactLines();
			analytics.track("Create a new offer - next button", {
				from: light ? "hubspot" : "webApp",
				type: isNewOffer ? "new offer" : "new version",
				step: "customization",
				error: false,
				workspaceName: workspace?.name,
				workspaceActive: workspace?.active,
				workspaceActivationDate: workspace?.createdAt,
				workspaceActiveToDate: workspace?.activeToDate,
			});
		}
	}, [loadingCreationVersion]);

	const { data: dataConnections } = useQuery(GET_MAIL_CONNECTIONS);
	const connections = dataConnections?.getUserMailConnections;
	const isEmailConnection = connections?.length > 0;

	const displayOkButton = () => {
		switch (creationStep) {
			case sectionName.offerCreationStep.customization:
				return loadingNextButton ? <Loader /> : t("Next");

			case sectionName.offerCreationStep.preSelectionOfOptions:
				if (loadingCreateButton) {
					return <Loader />;
				}
				return light || !isEmailConnection ? t("Create") : t("Create without sending");
			case sectionName.refreshTemplate:
				return <ButtonText>{t("Reset template")}</ButtonText>;
			default:
				return null;
		}
	};

	const resetOfferCreationParameters = useResetOfferCreationParameters();

	const validateOfferCreation = async () => {
		await confirmOfferVersionCreation({
			variables: {
				id: newOfferVersionId,
			},
		});

		const offerRes = await confirmOfferCreation({
			variables: {
				id: offerId,
			},
		});

		cache.modify({
			id: cache.identify({
				id: offerId,
				__typename: "Offer",
			}),
			fields: {
				offerProperties() {
					return {
						__ref: cache.identify({
							id: lastOfferPropertiesDraftId,
							__typename: "OfferProperties",
						}),
					};
				},
			},
		});

		return offerRes;
	};

	if (loadingCreationVersion)
		return (
			<LoaderWrapper>
				<LoadingIcon type="loading" />
				<LoadingLabel>{t("Preparing your offer in background..")}</LoadingLabel>
			</LoaderWrapper>
		);

	return (
		<>
			<ButtonWrapper>
				<Button
					mode={mode}
					type={
						creationStep === sectionName.offerCreationStep.preSelectionOfOptions
							? "cancel"
							: "secondary"
					}
					width={"180px"}
					showRightArrowIcon
					onClick={() => handleOkAction(false)}
					disabled={
						isNextButtonDisabled ||
						loadingNextButton ||
						loadingCreationVersion ||
						loadingCreateButton
					}
				>
					{displayOkButton()}
				</Button>
				{creationStep === sectionName.offerCreationStep.preSelectionOfOptions &&
				!light &&
				isEmailConnection ? (
					<Button
						mode={mode}
						type={"secondary"}
						width={"180px"}
						showRightArrowIcon
						onClick={() => {
							setVisibleEmailModal(true);
						}}
						disabled={
							isNextButtonDisabled || loadingCreationVersion || !isEmailConnection
						}
					>
						{t("Share offer")}
					</Button>
				) : null}
			</ButtonWrapper>
			{!light ? (
				<ModalSendEmail
					offerVersionId={newOfferVersionId}
					beforeEmailFunction={() => validateOfferCreation()}
					afterEmailFunction={() => resetOfferCreationParameters()}
				/>
			) : null}
		</>
	);
});
NextButton.whyDidYouRender = true;
NextButton.displayName = "NextButton";
export default NextButton;
