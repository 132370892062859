import { useTranslation } from "react-i18next";

const sharedFixedCostAttributes = {
	cost: 0,
	vat: 21,
	vatDeductible: 0,
	timePeriod: "month",
	variableCostPart: 0,
	variableCostPartType: "amount",
	volumeAllocation: 50,
	revenueAllocation: 50,
	manualDispatching: false,
};

export const listOfFixedCostObj = () => {
	const { t } = useTranslation("listOfFixedCosts");

	return [
		{
			name: t("Rent"),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Insurance"),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Staff remuneration"),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Maintenance"),
			description: t("Machine etc."),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Fixed overhead costs"),
			description: t("Water, electricity etc."),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Costs related to marketing and communication"),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Fees to a third party"),
			description: t("Lawyer, accountant, notary, etc."),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Subcontracting and temping"),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Bank charges"),
			description: t("Financial interest, account charges etc."),
			...sharedFixedCostAttributes,
		},
		{
			name: t("Depreciation and amortisation"),
			...sharedFixedCostAttributes,
		},
	];
};
