import { atom } from "recoil";

export const publicLinkProfitabilityPeriodAtom = atom({
	key: "publicLinkProfitabilityPeriodAtom",
	default: 1,
});

export const publicLinkSelectedPackIdAtom = atom({
	key: "publicLinkSelectedPackIdAtom",
	default: {},
});

export const publicLinkHoveredPackIdAtom = atom({
	key: "publicLinkHoveredPackIdAtom",
	default: 0,
});

export const publicLinkShowPackSelectionInfoAtom = atom({
	key: "publicLinkShowPackSelectionInfoAtom",
	default: {},
});

export const publicLinkShowOfferConfirmationAtom = atom({
	key: "publicLinkShowOfferConfirmationAtom",
	default: false,
});

export const publicLinkPDFLinkAtom = atom({
	key: "publicLinkPDFLinkAtom",
	default: false,
});

export const publicLinkOfferVersionQuoteIdAtom = atom({
	key: "publicLinkOfferVersionQuoteIdAtom",
	default: null,
});

export const publicSignatureStatusAtom = atom({
	key: "publicSignatureStatusAtom",
	default: null,
});

export const publicShowSignatureActionAtom = atom({
	key: "publicShowSignatureActionAtom",
	default: false,
});

export const publicLinkSignInEmailAtom = atom({
	key: "publicLinkSignInEmailAtom",
	default: null,
});

export const publicLinkIsSideBarExpandedAtom = atom({
	key: "publicLinkIsSideBarExpandedAtom",
	default: {},
});

export const publicLinkHighlightDifferencesAtom = atom({
	key: "publicLinkHighlightDifferences",
	default: {},
});

export const publicLinkHidePricesAtom = atom({
	key: "publicLinkHidePricesAtom",
	default: {},
});

export const publicLinkShouldLinkVolumeAtom = atom({
	key: "publicLinkShouldLinkVolumeAtom",
	default: false,
});

export const publicLinkPriceModelTypeAtom = atom({
	key: "publicLinkPriceModelTypeAtom",
	default: {},
});

export const publicLinkCurrentPriceModelCombinationIdAtom = atom({
	key: "publicLinkCurrentPriceModelCombinationIdAtom",
	default: null,
});

export const publicLinkSelectedTabAtom = atom({
	key: "publicLinkSelectedTabAtom",
	default: null,
});

export const publicLinkTvaDisplayAtom = atom({
	key: "publicLinkTvaDisplayAtom",
	default: {},
});

export const publicLinkShowSavingsAtom = atom({
	key: "publicLinkShowSavingsAtom",
	default: {},
});

export const publicPreviewPacksHeaderHeightAtom = atom({
	key: "publicPreviewPacksHeaderHeightAtom",
	default: 0,
});

export const publicAppliedGlobalRulesAtom = atom({
	key: "publicAppliedGlobalRulesAtom",
	default: [],
});

export const publicLinkOpenedSectionAtom = atom({
	key: "publicLinkOpenedSectionAtom",
	default: {},
});

// Volume discount atoms

export const volumeDiscountStateAtom = atom({
	key: "volumeDiscountStateAtom",
	default: {},
});

export const volumeDiscountIdStateAtom = atom({
	key: "volumeDiscountIdStateAtom",
	default: {},
});

// Commtiment discount atoms

export const commitmentDiscountConditionShowSelectAtom = atom({
	key: "commitmentDiscountConditionShowSelectAtom",
	default: {},
});

export const commitmentDiscountStateAtom = atom({
	key: "commitmentDiscountStateAtom",
	default: {},
});

export const packDisabledDueToCommitmentDiscountDependencyAtom = atom({
	key: "packDisabledDueToCommitmentDiscountDependencyAtom",
	default: {},
});

// Payment terms discount atoms
export const paymentTermsDiscountStateAtom = atom({
	key: "paymentTermsDiscountStateAtom",
	default: {},
});

export const packDisabledDueToPaymentTermsDiscountDependencyAtom = atom({
	key: "packDisabledDueToPaymentTermsDiscountDependencyAtom",
	default: {},
});

// Additional values
export const additionalValuesStateAtom = atom({
	key: "additionalValuesStateAtom",
	default: {},
});

// Special discount
export const specialDiscountStateAtom = atom({
	key: "specialDiscountStateAtom",
	default: {},
});

export const packDisabledDueToSpecialDiscountDependencyAtom = atom({
	key: "packDisabledDueToSpecialDiscountDependencyAtom",
	default: {},
});

export const numericalDiscountStateAtom = atom({
	key: "numericalDiscountStateAtom",
	default: {},
});

export const numericalDiscountRangeStateAtom = atom({
	key: "numericalDiscountRangeStateAtom",
	default: {},
});

export const packDisabledDueToNumericalDiscountDependencyAtom = atom({
	key: "packDisabledDueToNumericalDiscountDependencyAtom",
	default: {},
});

export const boosterStateAtom = atom({
	key: "boosterStateAtom",
	default: {},
});

export const numericalValueFeatureAtom = atom({
	key: "numericalValueFeatureAtom",
	default: {},
});

export const discountPricePointAtom = atom({
	key: "discountPricePointAtom",
	default: {},
});

export const publicLinkTokenAtom = atom({
	key: "publicLinkTokenAtom",
	default: null,
});

export const publicIsPublicAtom = atom({
	key: "publicIsPublicAtom",
	default: false,
});

export const publicLinkTypeAtom = atom({
	key: "publicLinkTypeAtom",
	default: "",
});

export const publicIsLoadingLinkAtom = atom({
	key: "publicIsLoadingLinkAtom",
	default: true,
});

export const packPriceLoadingAtom = atom({
	key: "packPriceLoadingAtom",
	default: {},
});

export const contactEmailsErrorAtom = atom({
	key: "contactEmailsErrorAtom",
	default: false,
});

// Confirm Quote
export const generateQuoteErrorMessageAtom = atom({
	key: "generateQuoteErrorMessageAtom",
	default: "",
});
export const quoteFormShowErrorsAtom = atom({
	key: "quoteFormShowErrorsAtom",
	default: false,
});
export const quoteFormSnapshotAtom = atom({
	key: "quoteFormSnapshotAtom",
	default: {},
});
export const prospectEmailAtom = atom({
	key: "prospectEmailAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const companyNameAtom = atom({
	key: "companyNameAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const firstNameAtom = atom({
	key: "firstNameAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const lastNameAtom = atom({
	key: "lastNameAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const emailQuoteFormAtom = atom({
	key: "emailQuoteFormAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const vatAtom = atom({
	key: "vatAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});

export const addedQuoteFieldsAtom = atom({
	key: "addedQuoteFieldsAtom",
	default: [],
});

export const commentAtom = atom({
	key: "commentAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
		},
	},
});
export const postalAddressAtom = atom({
	key: "postalAddressAtom",
	default: {
		address: {
			value: null,
			errors: {
				missing: false,
				invalid: false,
			},
		},
		address2: {
			value: null,
			errors: {
				missing: false,
				invalid: false,
			},
		},
		zip: {
			value: null,
			errors: {
				missing: false,
				invalid: false,
			},
		},
		city: {
			value: null,
			errors: {
				missing: false,
				invalid: false,
			},
		},
		countryCode: {
			value: null,
			errors: {
				missing: false,
				invalid: false,
			},
		},
	},
});

export const licenseStartDateAtom = atom({
	key: "licenseStartDateAtom",
	default: {
		value: null,
		errors: {
			missing: false,
			invalid: false,
			inThePast: false,
		},
	},
});

export const hasPostalAddressAtom = atom({
	key: "hasPostalAddressAtom",
	default: false,
});

export const contactEmailsAtom = atom({
	key: "contactEmail",
	default: [],
});

//Metrics
export const primaryPriceMetricsAtom = atom({
	key: "primaryPriceMetricsAtom",
	default: {},
});

export const primaryPriceTimeMetricAtom = atom({
	key: "primaryPriceTimeMetricAtom",
	default: {},
});

export const publicNumericalInputErrorAtom = atom({
	key: "publicNumericalInputErrorAtom",
	default: {},
});

export const publicIsCommercialViewAtom = atom({
	key: "publicIsCommercialViewAtom",
	default: true,
});

export const publicIsRequestedHasBeenSentAtom = atom({
	key: "publicIsRequestedHasBeenSentAtom",
	default: false,
});

export const publicIsAdminRequestedHasBeenSentAtom = atom({
	key: "publicIsAdminRequestedHasBeenSentAtom",
	default: false,
});

export const publicIsUnknowToWorkspace = atom({
	key: "publicIsUnknowToWorkspace",
	default: false,
});

export const primaryMultiplierAtom = atom({
	key: "primaryMultiplierAtom",
	default: {},
});
