import styled from "styled-components";
import { Icon } from "priceit-ui";

export const Wrapper = styled.div``;

export const SelectTitle = styled.div`
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
`;

export const WrapperSelectAndTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
`;

export const WrapperSelectAndIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
`;

export const PacksAndSectionsOptionContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TooltipContent = styled.div`
	max-width: 400px;
`;

export const WrapperWarningIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const WarningIcon = styled(Icon)`
	height: 16px;
	width: 16px;
`;
