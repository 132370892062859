import { gql, useApolloClient, useQuery } from "@apollo/client";
import { GET_PERIOD_BY_ID } from "@hooks/usePeriod/graphQl/query";

import moment from "moment";

export const usePeriod = id => {
	const {
		loading: loadingPeriod,
		error: errorPeriod,
		data: dataPeriod,
	} = useQuery(GET_PERIOD_BY_ID, {
		skip: id === "0",
		variables: {
			id: id,
		},
	});

	const period = dataPeriod?.getPeriodById || {};
	const endDate = moment(dataPeriod?.getPeriodById?.endDate);
	const startDate = moment(dataPeriod?.getPeriodById?.startDate);
	const monthDifference = endDate.startOf("month").diff(startDate.startOf("month"), "months") + 1;

	return {
		period,
		startDate,
		endDate,
		monthDifference,
	};
};

export const useCachePeriod = id => {
	const { cache } = useApolloClient();
	const period = cache.readFragment({
		id: `Period:${id}`,
		fragment: gql`
			fragment MyPeriodDiff on Period {
				id
				startDate
				endDate
			}
		`,
	});

	const endDate = moment(period?.endDate);
	const startDate = moment(period?.startDate);
	const monthDifference =
		endDate?.startOf("month").diff(startDate.startOf("month"), "months") + 1;
	return {
		startDate,
		endDate,
		monthDifference,
	};
};
