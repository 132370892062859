import { gql } from "@apollo/client";
import {
	OFFER_ADDRESS_FRAGMENT,
	OFFER_CLOSING_DATE_FRAGMENT,
	OFFER_COMPANY_NAME_FRAGMENT,
	OFFER_CREATED_AT_FRAGMENT,
	OFFER_CREATED_BY_FRAGMENT,
	OFFER_CRM_DEAL_FRAGMENT,
	OFFER_CRM_DEAL_ID_AND_SERVICE_FRAGMENT,
	OFFER_CRM_DEAL_ID_FRAGMENT,
	OFFER_DRAWER_INFOS_FRAGMENT,
	OFFER_FRAGMENT,
	OFFER_HEAT_SCORE_FRAGMENT,
	OFFER_ID_FRAGMENT,
	OFFER_LICENSE_START_DATE_FRAGMENT,
	OFFER_MANDATORY_FRAGMENT,
	OFFER_MIN_AMOUNT_FRAGMENT,
	OFFER_NAME_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_FRAGMENT,
	OFFER_NAME_FRAGMENT,
	OFFER_NB_DAYS_BETWEEN_SENDING_AND_FIRST_OPENING_FRAGMENT,
	OFFER_NB_DAYS_SINCE_LAST_OPENING_FRAGMENT,
	OFFER_NB_UNIQUE_EMAIL_VIEWS_FRAGMENT,
	OFFER_NB_VIEWS_FRAGMENT,
	OFFER_OFFER_STATUS_FRAGMENT,
	OFFER_OFFER_VERSION_PRICE_MODEL_COMBINATION_QUOTE_FIELDS_FRAGMENT,
	OFFER_OFFER_VERSION_RULES_FRAGMENT,
	OFFER_OFFER_VERSIONS_FOR_LAST_QUOTE_COLUMN_FRAGMENT,
	OFFER_OFFER_VERSIONS_FOR_PRICING_PAGE_TEMPLATE_COLUMN_FRAGMENT,
	OFFER_OFFER_VERSIONS_FRAGMENT,
	OFFER_OPEN_OFFER_EMAILS_FRAGMENT,
	OFFER_OWNER_FOR_OWNER_COLUMN_FRAGMENT,
	OFFER_OWNER_FOR_OWNER_FILTER_FRAGMENT,
	OFFER_OWNER_FRAGMENT,
	OFFER_OWNER_ID_FRAGMENT,
	OFFER_PAYMENT_CONNECTION_FRAGMENT,
	OFFER_PUBLIC_FRAGMENT,
	OFFER_QUOTE_OFFER_EMAILS_FRAGMENT,
	OFFER_SEND_DATE_FRAGMENT,
	OFFER_SIGNATURE_CONNECTION_FRAGMENT,
	OFFER_TOKEN_FRAGMENT,
	OFFER_UPDATED_AT_FRAGMENT,
	OFFER_VAT_NUMBER_FRAGMENT,
	OFFER_WORKSPACE_ID_FRAGMENT,
	OFFER_WORKSPACE_NAME_FRAGMENT,
	PUBLIC_OFFER_CONTACT_FRAGMENT,
	PUBLIC_OFFER_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT,
	PUBLIC_OFFER_ID_FRAGMENT,
	PUBLIC_OFFER_LAST_OFFER_VERSION_OFFER_VERSION_QUOTE_FRAGMENT,
	PUBLIC_OFFER_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT,
	PUBLIC_OFFER_NAME_FRAGMENT,
	PUBLIC_OFFER_OFFER_PROPERTIES_FRAGMENT,
	SIGNATURE_LINK_PUBLIC_OFFER_LAST_OFFER_VERSIONS_FRAGMENT,
} from "@graphQl/fragments/offer";
import { USER_IMAGE_PATH_FRAGMENT } from "@graphQl/fragments/user";
import { OFFER_CONTACT_FRAGMENT } from "../fragments/offerContact";
import {
	OFFER_VERSION_INDEX_FRAGMENT,
	OFFER_VERSION_OFFER_VERSION_ACCESS_FRAGMENT,
} from "../fragments/offerVersion";
import {
	OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_TC_FRAGMENT,
	OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT,
	OFFER_PROPERTIES_FOR_OFFER_FRAGMENT,
	OFFER_PROPERTIES_MANDATORY_FRAGMENT,
	OFFER_PROPERTIES_TC_PDF_LINK_FRAGMENT,
	OFFER_PROPERTIES_TC_TYPE_FRAGMENT,
	OFFER_PROPERTIES_TC_URL_LINK_FRAGMENT,
} from "../fragments/offerProperties";

export const GET_OFFER_MANDATORY_BY_ID = gql`
	${OFFER_MANDATORY_FRAGMENT}
	${OFFER_CONTACT_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_PROPERTIES_MANDATORY_FRAGMENT}
	query GetOfferMandatoryById($id: ID!) {
		getOfferById(id: $id) {
			...OfferMandatoryFragment
			...OfferAddressFragment
			offerContacts {
				...OfferContactFragment
			}
			offerProperties {
				...OfferPropertiesMandatoryFragment
			}
			...OfferTokenFragment
		}
	}
`;

export const GET_OFFER_CREATED_AT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CREATED_AT_FRAGMENT}
	query GetOfferCreatedAtById($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferCreatedAtFragment
		}
	}
`;

export const GET_OFFER_OWNER_FOR_OWNER_COLUMN = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OWNER_FOR_OWNER_COLUMN_FRAGMENT}
	query GetOfferOwnerForOwnerColumn($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferOwnerForOwnerColumnFragment
		}
	}
`;

export const GET_OFFER_OWNER_FOR_OWNER_FILTER = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OWNER_FOR_OWNER_FILTER_FRAGMENT}
	query GetOfferOwnerForOwnerFilter {
		getOffers {
			...OfferIdFragment
			...OfferOwnerForOwnerFilterFragment
		}
	}
`;

export const GET_OFFER_NAME = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	query GetOfferName($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferNameFragment
		}
	}
`;

export const GET_OFFER_VAT_NUMBER = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VAT_NUMBER_FRAGMENT}
	query GetOfferVATNumber($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferVATNumberFragment
		}
	}
`;

export const GET_OFFER_LICENSE_START_DATE = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_LICENSE_START_DATE_FRAGMENT}
	query GetOfferLicenseStartDate($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferLicenseStartDateFragment
		}
	}
`;

export const GET_OFFER_CRM_DEAL_ID = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CRM_DEAL_ID_FRAGMENT}
	query GetOfferCrmDealId($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferCrmDealIdFragment
		}
	}
`;

export const GET_OFFER_COMPANY_NAME_AND_CRM_DEAL = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_CRM_DEAL_ID_AND_SERVICE_FRAGMENT}
	query GetOfferCompanyName($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferCompanyNameFragment
			...OfferCrmDealIdAndServiceFragment
		}
	}
`;

export const GET_OFFER_OFFER_VERSIONS = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OFFER_VERSIONS_FRAGMENT}
	query GetOfferOfferVersions($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferOfferVersionsFragment
		}
	}
`;

export const GET_OFFER_MIN_AMOUNT = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_MIN_AMOUNT_FRAGMENT}
	query GetOfferMinAmount($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferMinAmountFragment
		}
	}
`;

export const GET_OFFER_SEND_DATE = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_SEND_DATE_FRAGMENT}
	query GetOfferOfferVersionsSendDate($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferSendDateFragment
		}
	}
`;

export const GET_OFFER_CLOSING_DATE = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_CLOSING_DATE_FRAGMENT}
	query GetOfferOfferVersionsClosingDate($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferClosingDateFragment
		}
	}
`;

export const GET_OFFER_OFFER_VERSIONS_ACCESS = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_VERSION_OFFER_VERSION_ACCESS_FRAGMENT}
	${OFFER_VERSION_INDEX_FRAGMENT}
	query GetOfferOfferVersionsAccess($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			offerVersions {
				...OfferVersionIndexFragment
				...OfferVersionOfferVersionAccessFragment
			}
		}
	}
`;

export const GET_OFFER_OFFER_VERSIONS_FOR_LAST_QUOTE_COLUMN = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OFFER_VERSIONS_FOR_LAST_QUOTE_COLUMN_FRAGMENT}
	query GetOfferOfferVersionsLastQuote($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferOfferVersionsForLastQuoteColumnFragment
		}
	}
`;

export const GET_OFFER_QUOTE_BY_TOKEN = gql`
	${PUBLIC_OFFER_LAST_OFFER_VERSION_OFFER_VERSION_QUOTE_FRAGMENT}
	query GetOfferQuoteByToken($token: String!) {
		getOfferByToken(token: $token) {
			...PublicOfferLastOfferVersionOfferVersionQuoteFragment
		}
	}
`;

export const GET_OFFER_NAME_BY_TOKEN = gql`
	${PUBLIC_OFFER_NAME_FRAGMENT}
	query GetOfferNameByToken($token: String!) {
		getOfferByToken(token: $token) {
			...PublicOfferNameFragment
		}
	}
`;

export const GET_PUBLIC_OFFER_ENABLE_MAX_GLOBAL_DISCOUNT_BY_TOKEN = gql`
	${PUBLIC_OFFER_ENABLE_MAX_GLOBAL_DISCOUNT_FRAGMENT}
	query GetOfferEnableDiscountByToken($token: String!) {
		getOfferByToken(token: $token) {
			...PublicOfferEnableMaxGlobalDiscountFragment
		}
	}
`;

export const GET_PUBLIC_OFFER_MAX_GLOBAL_DISCOUNT_DATA_BY_TOKEN = gql`
	${PUBLIC_OFFER_MAX_GLOBAL_DISCOUNT_DATA_FRAGMENT}
	query GetOfferMaxGlobalDiscountDatatByToken($token: String!) {
		getOfferByToken(token: $token) {
			...PublicOfferMaxGlobalDiscountDataFragment
		}
	}
`;

export const GET_PUBLIC_OFFER_OWNER_IMAGE_BY_TOKEN = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${USER_IMAGE_PATH_FRAGMENT}
	query GetPublicOfferOwnerImageByToken($token: String!) {
		getOfferByToken(token: $token) {
			...PublicOfferIdFragment
			owner {
				...UserImagePathFragment
			}
		}
	}
`;

export const GET_OFFER_BY_TOKEN = gql`
	${OFFER_PUBLIC_FRAGMENT}
	query GetOfferByToken($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...PublicOfferFragment
		}
	}
`;

export const GET_OFFER_CONTACT_BY_TOKEN = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${PUBLIC_OFFER_CONTACT_FRAGMENT}
	query GetOfferContactByToken($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...PublicOfferIdFragment
			...PublicOfferContactFragment
		}
	}
`;

export const GET_OFFER_PROPERTIES_BY_TOKEN = gql`
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	query GetOfferPropertiesFromPriceModelCombinationId($id: ID!) {
		getPriceModelCombinationById(id: $id) {
			offerProperties {
				...OfferPropertiesForOfferFragment
			}
		}
	}
`;

export const GET_OFFER_OFFER_PROPERTIES_BY_TOKEN = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${PUBLIC_OFFER_OFFER_PROPERTIES_FRAGMENT}
	query GetOfferOfferPropertiesByToken($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...PublicOfferIdFragment
			...PublicOfferOfferPropertiesFragment
		}
	}
`;

export const GET_FOOTER_DATA_OFFER_PROPERTIES_BY_TOKEN = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT}
	${OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT}
	query GetFooterDataOfferByToken($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...PublicOfferIdFragment
			offerProperties {
				...OfferPropertiesEnableFooterFragment
				...OfferPropertiesFooterContentFragment
			}
		}
	}
`;

export const GET_OFFER_PROPERTIES_FOR_SIGNATURE_BOX = gql`
	${PUBLIC_OFFER_ID_FRAGMENT}
	${OFFER_PROPERTIES_ENABLE_TC_FRAGMENT}
	${OFFER_PROPERTIES_TC_PDF_LINK_FRAGMENT}
	${OFFER_PROPERTIES_TC_URL_LINK_FRAGMENT}
	${OFFER_PROPERTIES_TC_TYPE_FRAGMENT}
	query GetOfferPropertiesForSignatureBoxModals($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...PublicOfferIdFragment
			offerProperties {
				...OfferPropertiesEnableTcFragment
				...OfferPropertiesTcPdfLinkFragment
				...OfferPropertiesTcUrlLinkFragment
				...OfferPropertiesTcTypeFragment
			}
		}
	}
`;

export const GET_SIGNATURE_LINK_OFFER_BY_TOKEN = gql`
	${SIGNATURE_LINK_PUBLIC_OFFER_LAST_OFFER_VERSIONS_FRAGMENT}
	query GetSignatureLinkOfferByToken($token: String!, $id: ID) {
		getOfferByToken(token: $token, id: $id) {
			...SignatureLinkPublicOfferLastOfferVersionsFragment
		}
	}
`;

export const GET_OFFER_ACCESS_STATUS = gql`
	query GetOfferAccessStatus($token: String!, $userId: ID) {
		getOfferAccessStatus(token: $token, userId: $userId) {
			... on OfferAccess {
				token
				sessionId
				userId
				workspace {
					id
					name
					active
				}
			}
			... on ProtectedOffer {
				image
				isEmailProtected
				isPasswordProtected
				offerAccessPageTheme
				showPriceitBranding
				name
				token
				language
				workspace {
					id
					name
					active
				}
			}
			... on ExpiredOrUnpublishedOffer {
				id
				name
				image
				offerImage
				offerAccessPageTheme
				showPriceitBranding
				language
				expirationDate
				sessionId
				workspace {
					id
					name
					active
					imagePath
				}
			}
		}
	}
`;

export const GET_OFFER_OFFER_VERSIONS_FOR_PRICING_PAGE_TEMPLATE_COLUMN = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OFFER_VERSIONS_FOR_PRICING_PAGE_TEMPLATE_COLUMN_FRAGMENT}
	query GetOfferOfferVersionsForPricingPageTemplate($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferOfferVersionsForPricingPageTemplateColumnFragment
		}
	}
`;

export const GET_OFFERS_FOR_LAST_UPDATE_SECTION = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	${OFFER_UPDATED_AT_FRAGMENT}
	${OFFER_OFFER_STATUS_FRAGMENT}
	query GetOffersForLastUpdateSection {
		getOffers {
			...OfferIdFragment
			...OfferNameFragment
			...OfferUpdatedAtFragment
			...OfferOfferStatusFragment
		}
	}
`;

export const GET_OFFERS = gql`
	${OFFER_FRAGMENT}
	query GetOffers {
		getOffers {
			...OfferFragment
		}
	}
`;

export const GET_OFFER_BY_ID_FOR_HUBSPOT_VERSION_CREATION = gql`
	${OFFER_FRAGMENT}
	query GetOfferByIdForHubspotCreation($id: ID!) {
		getOfferById(id: $id) {
			...OfferFragment
		}
	}
`;

export const GET_OFFER_NAME_BY_OFFER_ID = gql`
	${OFFER_NAME_FRAGMENT}
	query GetOfferNameByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferNameFragment
		}
	}
`;

export const GET_OFFER_DRAWER_INFOS_BY_OFFER_ID = gql`
	${OFFER_DRAWER_INFOS_FRAGMENT}
	query GetOfferDrawerInfosByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferDrawerInfosFragment
		}
	}
`;

export const GET_OFFER_DATA_FOR_COMPANY_NAME = gql`
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_CRM_DEAL_FRAGMENT}
	query GetOfferDataForCompanyName($id: ID!) {
		getOfferById(id: $id) {
			...OfferCompanyNameFragment
			...OfferCrmDealFragment
		}
	}
`;

export const GET_OFFER_NAME_AND_COMPANY_NAME_BY_OFFER_ID = gql`
	${OFFER_NAME_FRAGMENT}
	${OFFER_COMPANY_NAME_FRAGMENT}
	query GetOfferNameAndCompanyNameByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferNameFragment
			...OfferCompanyNameFragment
		}
	}
`;

export const GET_OFFER_DATA_FOR_SUBJECT_DEFAULT_VALUE = gql`
	${OFFER_COMPANY_NAME_FRAGMENT}
	${OFFER_NAME_FRAGMENT}
	query GetOfferDataForSubjectDefaultValue($id: ID!) {
		getOfferById(id: $id) {
			...OfferCompanyNameFragment
			...OfferNameFragment
		}
	}
`;

export const GET_OFFER_OWNER_FRAGMENT = gql`
	${OFFER_OWNER_FRAGMENT}
	query GetOfferOwnerFragment($id: ID!) {
		getOfferById(id: $id) {
			...OfferOwnerFragment
		}
	}
`;

export const GET_OFFER_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_BY_OFFER_ID = gql`
	${OFFER_NAME_AND_COMPANY_NAME_AND_ENABLE_AND_HAS_PASSWORD_FRAGMENT}
	query GetOfferNameAndCompanyNameAndEnableAndHasPasswordByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferNameAndCompanyNameAndEnableAndHasPasswordFragment
		}
	}
`;

export const GET_OFFER_CREATED_BY_AND_OWNER_ID_BY_OFFER_ID = gql`
	${OFFER_OWNER_ID_FRAGMENT}
	${OFFER_CREATED_BY_FRAGMENT}
	query GetOfferCreatedByAndOwnerIdByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferOwnerIdFragment
			...OfferCreatedByFragment
		}
	}
`;

export const GET_OFFER_TOKEN_BY_OFFER_ID = gql`
	${OFFER_TOKEN_FRAGMENT}
	query GetOfferTokenByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferTokenFragment
		}
	}
`;

export const GET_OFFER_INFO_FOR_DRAWER_HEADER_BY_OFFER_ID = gql`
	${OFFER_NAME_FRAGMENT}
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	query GetOfferInfoForDrawerHeaderByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferNameFragment
			...OfferTokenFragment
			...OfferAddressFragment
		}
	}
`;

export const GET_OPEN_OFFER_EMAILS_BY_OFFER_ID = gql`
	${OFFER_OPEN_OFFER_EMAILS_FRAGMENT}
	${OFFER_OWNER_ID_FRAGMENT}
	query GetOpenOfferEmailsByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferOpenOfferEmailsFragment
			...OfferOwnerIdFragment
		}
	}
`;

export const GET_QUOTE_OFFER_EMAILS_BY_OFFER_ID = gql`
	${OFFER_QUOTE_OFFER_EMAILS_FRAGMENT}
	${OFFER_OWNER_ID_FRAGMENT}
	query GetQuoteOfferEmailsByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferQuoteOfferEmailsFragment
			...OfferOwnerIdFragment
		}
	}
`;

export const GET_OFFER_CRM_DEAL_BY_OFFER_ID = gql`
	${OFFER_CRM_DEAL_FRAGMENT}
	query GetOfferCrmDeal($id: ID!) {
		getOfferById(id: $id) {
			...OfferCrmDealFragment
		}
	}
`;

export const GET_OFFER_SIGNATURE_CONNECTION_BY_OFFER_ID = gql`
	${OFFER_SIGNATURE_CONNECTION_FRAGMENT}
	query GetOfferSignatureConnection($id: ID!) {
		getOfferById(id: $id) {
			...OfferSignatureConnectionFragment
		}
	}
`;

export const GET_OFFER_PAYMENT_CONNECTION_BY_OFFER_ID = gql`
	${OFFER_PAYMENT_CONNECTION_FRAGMENT}
	query GetOfferPaymentConnection($id: ID!) {
		getOfferById(id: $id) {
			...OfferPaymentConnectionFragment
		}
	}
`;

export const GET_OFFER_TOKEN_AND_WORKSPACE_NAME_BY_OFFER_ID = gql`
	${OFFER_TOKEN_FRAGMENT}
	${OFFER_WORKSPACE_NAME_FRAGMENT}
	query GetOfferTokenAndWorkspaceNameByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferTokenFragment
			...OfferWorkspaceNameFragment
		}
	}
`;

export const GET_OFFER_DATA_FOR_GLOBAL_DISCOUNTS_AND_GIFTS = gql`
	${OFFER_ID_FRAGMENT}
	${OFFER_OFFER_VERSION_RULES_FRAGMENT}
	${OFFER_WORKSPACE_ID_FRAGMENT}
	query GetOfferOfferVersionRulesByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferIdFragment
			...OfferOfferVersionRulesFragment
			...OfferWorkspaceIdFragment
		}
	}
`;

export const GET_OFFER_HEAT_SCORE_BY_OFFER_ID = gql`
	${OFFER_HEAT_SCORE_FRAGMENT}
	query GetOfferHeatScoreByOfferId($id: ID!) {
		getOfferById(id: $id) {
			...OfferHeatScoreFragment
		}
	}
`;

export const GET_OFFER_NB_VIEWS = gql`
	${OFFER_NB_VIEWS_FRAGMENT}
	query GetOfferNbViews($id: ID!) {
		getOfferById(id: $id) {
			...OfferNbViewsFragment
		}
	}
`;

export const GET_OFFER_NB_UNIQUE_EMAIL_VIEWS = gql`
	${OFFER_NB_UNIQUE_EMAIL_VIEWS_FRAGMENT}
	query GetOfferNbUniqueEmailViews($id: ID!) {
		getOfferById(id: $id) {
			...OfferNbUniqueEmailViewsFragment
		}
	}
`;

export const GET_OFFER_NB_DAYS_SINCE_LAST_OPENING = gql`
	${OFFER_NB_DAYS_SINCE_LAST_OPENING_FRAGMENT}
	query GetOfferNbDaysSinceLastOpening($id: ID!) {
		getOfferById(id: $id) {
			...OfferNbDaysSinceLastOpeningFragment
		}
	}
`;

export const GET_OFFER_NB_DAYS_BETWEEN_SENDING_AND_FIRST_OPENING = gql`
	${OFFER_NB_DAYS_BETWEEN_SENDING_AND_FIRST_OPENING_FRAGMENT}
	query GetOfferNbDaysBetweenSendingAndFirstOpening($id: ID!) {
		getOfferById(id: $id) {
			...OfferNbDaysBetweenSendingAndFirstOpeningFragment
		}
	}
`;

export const GET_OFFER_DATA_FOR_ADDRESS = gql`
	${OFFER_OFFER_VERSION_PRICE_MODEL_COMBINATION_QUOTE_FIELDS_FRAGMENT}
	${OFFER_ADDRESS_FRAGMENT}
	${OFFER_CRM_DEAL_FRAGMENT}
	query GetOfferOfferVersionsPriceModelCombination($id: ID!) {
		getOfferById(id: $id) {
			...OfferOfferVersionPriceModelCombinationQuoteFieldsFragment
			...OfferAddressFragment
			...OfferCrmDealFragment
		}
	}
`;

export const GET_OFFER_STATUS = gql`
	${OFFER_OFFER_STATUS_FRAGMENT}
	query GetOfferStatus($id: ID!) {
		getOfferById(id: $id) {
			...OfferOfferStatusFragment
		}
	}
`;

export const VERIFY_PUBLIC_OFFER_UPDATE = gql`
	query VerifyPublicOfferUpdate($token: String!) {
		verifyPublicOfferUpdate(token: $token)
	}
`;
