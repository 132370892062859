import styled, { css } from "styled-components";
import { Icon } from "priceit-ui";

export const WrapperPeriod = styled.div`
	background-color: ${props =>
		props.currentPeriod ? "transparent" : props.isSelected ? "#FFCD381A" : "#38444d"};
	display: flex;
	align-items: center;
	height: 70px;
	border-bottom: 1px solid #2b343b;
	cursor: pointer;

	&:hover {
		background-color: ${props => (props.isSelected ? "transparent" : "#4c575f")};
	}
`;

export const SelectedCursorIcon = styled(Icon)`
	transform: rotate(90deg) scaleY(0.45) scaleX(0.9);
	fill: #ffcd38;
	visibility: ${props => (props.isSelected ? "visible" : "hidden")};
`;
export const WrapperContent = styled.div`
	width: 100%;
	padding: ${props => (props.isPeriodDrawerOpen ? "0 15px" : "0 5px")};
	flex: 1;
`;
export const WrapperFirstLine = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const PeriodName = styled.span`
	color: #fff;
	font-size: 14px;
	max-width: 220px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`;

export const Duration = styled.span`
	color: #a5b9c8;
	font-style: italic;
`;

export const WrapperSecondLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const WrapperDates = styled.div`
	display: flex;
`;

export const StyledDate = styled.div`
	color: #a5b9c8;
	display: flex;
	align-items: center;
`;

export const StyledArrow = styled(Icon)`
	fill: #ffcd38;
	margin: 0 5px;
`;

export const WrapperTags = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperTag = styled.div`
	width: 65px;
	height: 18px;
	border: 1px solid ${props => props.color};
	color: ${props => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
`;

export const WrapperTempoSituation = styled(WrapperTag)`
	border-radius: 9px;
	margin-right: 5px;
`;

export const WrapperPeriodType = styled(WrapperTag)`
	border-radius: 3px;
`;

export const SidePart = styled.div`
	width: 24px;
	height: 70px;
`;

export const SelectedRowCursorIcon = styled(Icon)`
	z-index: 2;
	width: 15px;
	height: 15px;
	display: flex;
	transform: rotate(90deg) scaleY(0.6) scaleX(0.9);
	fill: #ffcd38;
`;

export const WrapperArrow = styled.div`
	height: 70px;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	fill: #fff;
	visibility: hidden;

	${WrapperPeriod}:hover & {
		visibility: visible;
	}

	${props =>
		props.currentPeriod &&
		css`
			background-color: transparent;
			visibility: visible;

			&:hover {
				background-color: #ffcd38;
				fill: #404d57;
			}
		`};
`;

export const WrapperSelectedArrow = styled.div`
	height: 70px;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
