import styled from "styled-components";
import { Icon } from "priceit-ui";
import { colors } from "@theme/userThemes/userColors";

export const WrapperRuleBasedAllocation = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperVariableCostColumnHeader = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperInputs = styled.div`
	display: flex;
	align-items: center;
	margin-left: 15px;
`;

export const LabelInput = styled.div`
	text-transform: lowercase;
	font-style: italic;
	color: ${props => props.color ?? "white"};
`;

export const LinkIcon = styled(Icon)`
	fill: ${colors.black11};
	margin-right: 10px;
	width: 20px;
	height: 20px;
`;

export const InfoIcon = styled(Icon)`
	fill: ${colors.black11};
	margin-right: ${props => props.marginRight ?? "10px"};
	width: 12px;
	height: 12px;
`;

export const HeaderCategorization = styled.div`
	font-size: ${props => (props.primary ? "14px" : "12px")};
	color: ${props => (props.primary ? "#FFF" : "#71889A")};
`;

export const WrapperHeaderCategorization = styled.div`
	&:first-child {
		margin-bottom: 4px;
	}
`;

export const TooltipContent = styled.div`
	max-width: 250px;
`;

export const WrapperInfoIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WrapperCategoryName = styled.div`
	display: flex;
	background-color: ${props => props.backgroundColor};
	width: 150px;
	justify-content: center;
	height: 30px;
	border-radius: 3px;
	align-items: center;
	margin: auto;
	padding: 0 10px;
	color: ${props => props.color};
`;

export const TitleCategory = styled.div`
	color: white;
	display: -webkit-box;
	width: 90px;
	font-size: 14px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-align: center;
`;

export const TitleCategoryText = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const MissingDataTooltip = styled.div`
	text-align: center;

	& div {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	& h5 {
		color: #404d57;
		font-size: 14px;
		font-weight: bold;
	}
`;
