import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Title, Wrapper, WrapperScroll, WrapperTitleAndResetBtn } from "./SearchFilter.style";
import { LanguageSelect } from "@pages/clientsOffers/createNewOfferPage/rightPanel/searchFilter/components/LanguageSelect";

import { CategoriesWrapper } from "./components/CategoriesWrapper";
import ResetButton from "./components/ResetButton";

export const SearchFilter = memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");

	return (
		<Wrapper>
			<WrapperTitleAndResetBtn>
				<Title>{t("Filter your search:")}</Title>
				<ResetButton />
			</WrapperTitleAndResetBtn>
			<WrapperScroll>
				<LanguageSelect />
				<CategoriesWrapper />
			</WrapperScroll>
		</Wrapper>
	);
});
SearchFilter.whyDidYouRender = true;
SearchFilter.displayName = "SearchFilter";
export default SearchFilter;
