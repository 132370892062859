const recoilActions = {
	UPDATE_RANGE_LIST: "UPDATE_RANGE_LIST",
	UPDATE_STATE: "UPDATE_STATE",
	FECTH_DATA: "FECTH_DATA",
	UPDATE_VOLUME_BASED_ON: "UPDATE_VOLUME_BASED_ON",
	TOGGLE_IS_SIDE_BAR_EXPANDED: "TOGGLE_IS_SIDE_BAR_EXPANDED",
	UPDATE_CONDITION_SHOW_SELECT: "UPDATE_CONDITION_SHOW_SELECT",
	UPDATE_SELECT_DATA: "UPDATE_SELECT_DATA",
	UPDATE_DISPLAYED_INPUT_DEFAULT: "UPDATE_DISPLAYED_INPUT_DEFAULT",
};

export const priceModelType = {
	nonRecurring: "nonRecurring",
	recurring: "recurring",
	oneShotRecurring: "oneShotRecurring",
};

export default recoilActions;
