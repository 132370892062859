import styled from "styled-components";

export const WrapperCategorization = styled.div`
	padding: 20px 0 0;
`;

export const WrapperSelect = styled.div``;

export const WrapperCategoryTitleAndDescription = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 0 0 5px;
	width: 100%;
`;

export const WrapperCategory = styled.div`
	margin: 0 0 10px;

	&:first-child ${WrapperSelect} {
		display: none;
	}
	&:first-child ${WrapperCategoryTitleAndDescription} {
		margin: 0;
	}
`;

export const WrapperSelectAndTitle = styled.div`
	display: flex;
	align-items: center;
`;

export const CategorizationTitle = styled.div`
	color: #a5b9c8;
	font-size: 12px;
	white-space: nowrap;
`;

export const CategorizationDescription = styled.div`
	color: #fff;
`;

export const WrapperCategoryTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
