import { profitabilityColors } from "@services/constants";

export const componentToHex = c => {
	const hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r, g, b) =>
	"#" +
	componentToHex(Math.round(r)) +
	componentToHex(Math.round(g)) +
	componentToHex(Math.round(b));

export const hexToRgb = hex => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
		: null;
};

export const pickHex = (lowColor, mediumColor, highColor, weight) => {
	let w1 = weight;
	let w2 = 1 - w1;
	let rgb = null;
	if (mediumColor) {
		if (weight > 0.5) {
			w1 = w1 * 2 - 1;
			w2 = 1 - w1;
			rgb = [
				Math.round(lowColor[0] * w1 + mediumColor[0] * w2),
				Math.round(lowColor[1] * w1 + mediumColor[1] * w2),
				Math.round(lowColor[2] * w1 + mediumColor[2] * w2),
			];
			return rgb;
		}
		w1 *= 2;
		w2 = 1 - w1;
		rgb = [
			Math.round(mediumColor[0] * w1 + highColor[0] * w2),
			Math.round(mediumColor[1] * w1 + highColor[1] * w2),
			Math.round(mediumColor[2] * w1 + highColor[2] * w2),
		];
		return rgb;
	}
	rgb = [
		Math.round(lowColor[0] * w1 + highColor[0] * w2),
		Math.round(lowColor[1] * w1 + highColor[1] * w2),
		Math.round(lowColor[2] * w1 + highColor[2] * w2),
	];
	return rgb;
};

export const colors = [
	"#605C9F",
	"#D60F94",
	"#BE0D0D",
	"#FF9100",
	"#FFCD38",
	"#5C9E62",
	"#40C1AB",
	"#0C62E3",
	"#000",

	"#7066B1",
	"#E33DAC",
	"#E50E0E",
	"#FFA631",
	"#FFDD67",
	"#66B072",
	"#67C8B8",
	"#2D7DF6",
	"#38444D",

	"#8070C4",
	"#FE58C6",
	"#FF3030",
	"#FFBD65",
	"#FFE27E",
	"#70C482",
	"#71DCCA",
	"#4A90F9",
	"#71889A",

	"#9281D1",
	"#F97DCF",
	"#FF6464",
	"#FFCF91",
	"#FFE89A",
	"#82D196",
	"#7EE6D5",
	"#80B1FB",
	"#A5B9C8",

	"#9B8AD7",
	"#F99AD9",
	"#FF8A8A",
	"#FFDFB5",
	"#FFEDAF",
	"#92DEA7",
	"#8CF2E1",
	"#9BC2FC",
	"#fff",
];

export const offerVersionStatusColors = {
	draft: "#A5B9C8",
	sent: "#FF9C1A",
	consulted: "#4B9DF1",
	quoteRequested: "#C668EE",
	deal: "#7EC576",
	paid: "#0C3200",
	notDeal: "#E15C5C",
};

export const offerExpirationDatesLightColors = {
	valid: "#82C760",
	expiresSoon: "#FF7F11",
	expired: "#EF6F6C",
	signed: "#87BFFF",
	noExpirationDate: "#71889a",
};

export const offerExpirationDatesDarkColors = {
	valid: "#78ce5e",
	expiresSoon: "#e68357",
	expired: "#ff6060",
	signed: "#4FA1FF",
	noExpirationDate: "#71889a",
};

export const addOpacityToHexColor = (color, opacity) => {
	if (color.includes("rgba")) {
		return color.replace(/[\d\.]+\)$/g, opacity) + ")";
	}
	let hexColor = color.replace("#", "");
	if (hexColor.length === 3) {
		hexColor =
			hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
	}
	const r = parseInt(hexColor.substring(0, 2), 16);
	const g = parseInt(hexColor.substring(2, 4), 16);
	const b = parseInt(hexColor.substring(4, 6), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const padHexColor = color => {
	if (!color || !color?.startsWith("#")) {
		return color;
	}
	let hexColor = color.replace("#", "");
	if (hexColor.length === 3) {
		hexColor =
			hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
	}
	return `#${hexColor}`;
};

export const profitabilityColor = ({
	formattedTotalProfitability,
	totalProfitability,
	isApprovalRequired,
}) => {
	if (formattedTotalProfitability === "-") {
		return profitabilityColors.UNSET;
	} else if (!totalProfitability || isApprovalRequired) {
		return profitabilityColors.NEGATIVE;
	}
	return profitabilityColors.POSITIVE;
};

export function lightenDarkenColor(col, amt) {
	let usePound = false;

	if (col[0] === "#") {
		col = col.slice(1);
		usePound = true;
	}

	const num = parseInt(col, 16);

	let r = (num >> 16) + amt;

	if (r > 255) {
		r = 255;
	} else if (r < 0) {
		r = 0;
	}

	let b = ((num >> 8) & 0x00ff) + amt;

	if (b > 255) {
		b = 255;
	} else if (b < 0) {
		b = 0;
	}

	let g = (num & 0x0000ff) + amt;

	if (g > 255) {
		g = 255;
	} else if (g < 0) {
		g = 0;
	}

	return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}
