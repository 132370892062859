import { gql } from "@apollo/client";
import {
	CATEGORY_COLOR_FRAGMENT,
	CATEGORY_ID_FRAGMENT,
	CATEGORY_NAME_FRAGMENT,
	CATEGORY_NEW_FRAGMENT,
} from "@graphQl/fragments/categoryNewFragment";

export const CATEGORIZATION_ID_FRAGMENT = gql`
	fragment CategorizationIdFragment on CategorizationNew {
		id
	}
`;

export const CATEGORIZATION_NAME_FRAGMENT = gql`
	fragment CategorizationNameFragment on CategorizationNew {
		id
		name
	}
`;

export const CATEGORIZATION_TYPE_FRAGMENT = gql`
	fragment CategorizationTypeFragment on CategorizationNew {
		id
		type
	}
`;

export const CATEGORIZATION_INDEX_FRAGMENT = gql`
	fragment CategorizationIndexFragment on CategorizationNew {
		id
		index
	}
`;

export const CATEGORIZATION_DESCRIPTION_FRAGMENT = gql`
	fragment CategorizationDescriptionFragment on CategorizationNew {
		id
		description
	}
`;

export const CATEGORIZATION_NBCATEGORIES_FRAGMENT = gql`
	fragment CategorizationNbCategoriesFragment on CategorizationNew {
		id
		nbCategories
	}
`;

export const CATEGORIZATION_CATEGORIES_FRAGMENT = gql`
	${CATEGORY_NEW_FRAGMENT}
	fragment CategorizationCategoriesFragment on CategorizationNew {
		categories {
			...CategoryNewFragment
		}
	}
`;

export const CATEGORIZATION_CATEGORIES_NAME_FRAGMENT = gql`
	${CATEGORY_NAME_FRAGMENT}
	fragment CategorizationCategoriesNameFragment on CategorizationNew {
		id
		categories {
			...CategoryNameFragment
		}
	}
`;

export const CATEGORIZATION_CATEGORIES_COLOR_AND_NAME_FRAGMENT = gql`
	${CATEGORY_NAME_FRAGMENT}
	${CATEGORY_COLOR_FRAGMENT}
	fragment CategorizationCategoriesColorAndNameFragment on CategorizationNew {
		id
		categories {
			...CategoryNameFragment
			...CategoryColorFragment
		}
	}
`;

export const CATEGORIZATION_COLUMN_FRAGMENT = gql`
	fragment CategorizationColumnFragment on CategorizationNew {
		id
		column {
			id
		}
	}
`;

export const CATEGORIZATION_FRAGMENT = gql`
	${CATEGORIZATION_ID_FRAGMENT}
	${CATEGORIZATION_NAME_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	${CATEGORIZATION_TYPE_FRAGMENT}
	${CATEGORIZATION_INDEX_FRAGMENT}
	${CATEGORIZATION_NBCATEGORIES_FRAGMENT}
	${CATEGORIZATION_CATEGORIES_FRAGMENT}
	${CATEGORIZATION_COLUMN_FRAGMENT}
	fragment CategorizationFragment on CategorizationNew {
		...CategorizationIdFragment
		...CategorizationNameFragment
		...CategorizationDescriptionFragment
		...CategorizationTypeFragment
		...CategorizationIndexFragment
		...CategorizationNbCategoriesFragment
		...CategorizationCategoriesFragment
		...CategorizationColumnFragment
	}
`;
