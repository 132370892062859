import { gql, useMutation } from "@apollo/client";

import {
	QUOTE_CONFIGURATION_FULL_FRAGMENT,
	QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT,
	QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT,
	QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT,
	QUOTE_CONFIGURATION_LAYOUT_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION_FRAGMENT,
	QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_VAT_DETAIL_FRAGMENT,
	QUOTE_CONFIGURATION_INTRODUCTION_TEXT_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT_FRAGMENT,
	QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES_FRAGMENT,
} from "../fragments/quoteConfiguration";

// ========== HEADER ========== //
// == LOGO == //
const UPDATE_QUOTE_CONFIGURATION_HEADER_LOGO = gql`
	${QUOTE_CONFIGURATION_HEADER_LOGO_FRAGMENT}
	mutation UpdateQuoteConfigurationHeaderLogo(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationHeaderLogoFragment
		}
	}
`;

export const updateQuoteConfigurationHeaderLogoMutation = () => {
	const [updateQuoteConfigurationHeaderLogo, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_HEADER_LOGO
	);
	return [updateQuoteConfigurationHeaderLogo, { loading, error }];
};

// == BACKGROUND COLOR == //
const UPDATE_QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR = gql`
	${QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR_FRAGMENT}
	mutation UpdateQuoteConfigurationHeaderBackgroundColor(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationHeaderBackgroundColorFragment
		}
	}
`;

export const updateQuoteConfigurationHeaderBackgroundColorMutation = () => {
	const [updateQuoteConfigurationHeaderBackgroundColor, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_HEADER_BACKGROUND_COLOR
	);
	return [updateQuoteConfigurationHeaderBackgroundColor, { loading, error }];
};

// == FONT COLOR == //
const UPDATE_QUOTE_CONFIGURATION_HEADER_FONT_COLOR = gql`
	${QUOTE_CONFIGURATION_HEADER_FONT_COLOR_FRAGMENT}
	mutation UpdateQuoteConfigurationHeaderFontColor(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationHeaderFontColorFragment
		}
	}
`;

export const updateQuoteConfigurationHeaderFontColorMutation = () => {
	const [updateQuoteConfigurationHeaderFontColor, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_HEADER_FONT_COLOR
	);
	return [updateQuoteConfigurationHeaderFontColor, { loading, error }];
};

// ========== QUOTE LAYOUT ========== //
// == LAYOUT == //
const UPDATE_QUOTE_CONFIGURATION_LAYOUT = gql`
	${QUOTE_CONFIGURATION_LAYOUT_FRAGMENT}
	mutation UpdateQuoteConfigurationLayout(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationLayoutFragment
		}
	}
`;

export const updateQuoteConfigurationLayoutMutation = () => {
	const [updateQuoteConfigurationLayout, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_LAYOUT
	);
	return [updateQuoteConfigurationLayout, { loading, error }];
};

// == SHOW FIRST PAYMENT BOX == //
const UPDATE_QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX = gql`
	${QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX_FRAGMENT}
	mutation UpdateQuoteConfigurationShowFirstPaymentBox(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowFirstPaymentBoxFragment
		}
	}
`;

export const updateQuoteConfigurationShowFirstPaymentBoxMutation = () => {
	const [updateQuoteConfigurationShowFirstPaymentBox, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_FIRST_PAYMENT_BOX
	);
	return [updateQuoteConfigurationShowFirstPaymentBox, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES = gql`
	${QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES_FRAGMENT}
	mutation UpdateQuoteConfigurationShowTotalBoxes(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowTotalBoxesFragment
		}
	}
`;

export const updateQuoteConfigurationShowTotalBoxesMutation = () => {
	const [updateQuoteConfigurationShowTotalBoxes, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_TOTAL_BOXES
	);
	return [updateQuoteConfigurationShowTotalBoxes, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE = gql`
	${QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE_FRAGMENT}
	mutation UpdateQuoteConfigurationShowPaymentSchedule(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowPaymentScheduleFragment
		}
	}
`;

export const updateQuoteConfigurationShowPaymentScheduleMutation = () => {
	const [updateQuoteConfigurationShowPaymentSchedule, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_PAYMENT_SCHEDULE
	);
	return [updateQuoteConfigurationShowPaymentSchedule, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION = gql`
	${QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION_FRAGMENT}
	mutation UpdateQuoteConfigurationShowAttributesDescription(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowAttributesDescriptionFragment
		}
	}
`;

export const updateQuoteConfigurationShowAttributesDescriptionMutation = () => {
	const [updateQuoteConfigurationShowAttributesDescription, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_ATTRIBUTES_DESCRIPTION
	);
	return [updateQuoteConfigurationShowAttributesDescription, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY = gql`
	${QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY_FRAGMENT}
	mutation UpdateQuoteConfigurationShowBrandGeneratedBy(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowBrandGeneratedBy
		}
	}
`;

export const updateQuoteConfigurationShowBrandGeneratedByMutation = () => {
	const [updateQuoteConfigurationShowBrandGeneratedBy, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_BRAND_GENERATED_BY
	);
	return [updateQuoteConfigurationShowBrandGeneratedBy, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_INTRODUCTION_TEXT = gql`
	${QUOTE_CONFIGURATION_INTRODUCTION_TEXT_FRAGMENT}
	mutation UpdateQuoteConfigurationIntroductionText(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationIntroductionTextFragment
		}
	}
`;

export const updateQuoteConfigurationIntroductionTextMutation = () => {
	const [updateQuoteConfigurationIntroductionText, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_INTRODUCTION_TEXT
	);
	return [updateQuoteConfigurationIntroductionText, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE = gql`
	${QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE_FRAGMENT}
	mutation UpdateQuoteConfigurationUseIntroTextFromIntroPage(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationUseIntroTextFromIntroPageFragment
		}
	}
`;

export const updateQuoteConfigurationUseIntroTextFromIntroPageMutation = () => {
	const [updateQuoteConfigurationUseIntroTextFromIntroPage, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_USE_INTRO_TEXT_FROM_INTRO_PAGE
	);
	return [updateQuoteConfigurationUseIntroTextFromIntroPage, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT = gql`
	${QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT_FRAGMENT}
	mutation UpdateQuoteConfigurationShowIntroductionText(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowIntroductionTextFragment
		}
	}
`;

export const updateQuoteConfigurationShowIntroductionTextMutation = () => {
	const [updateQuoteConfigurationShowIntroductionText, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_INTRODUCTION_TEXT
	);
	return [updateQuoteConfigurationShowIntroductionText, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_VAT_DETAIL = gql`
	${QUOTE_CONFIGURATION_SHOW_VAT_DETAIL_FRAGMENT}
	mutation UpdateQuoteConfigurationShowVatDetail(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowVatDetailFragment
		}
	}
`;

export const updateQuoteConfigurationShowVatDetailMutation = () => {
	const [updateQuoteConfigurationShowVatDetail, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_VAT_DETAIL
	);
	return [updateQuoteConfigurationShowVatDetail, { loading, error }];
};

const UPDATE_QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION = gql`
	${QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION_FRAGMENT}
	mutation UpdateQuoteConfigurationShowIncludedMention(
		$updateQuoteConfiguration: UpdateQuoteConfigurationInput!
	) {
		updateQuoteConfiguration(quoteConfiguration: $updateQuoteConfiguration) {
			...QuoteConfigurationShowIncludedMentionFragment
		}
	}
`;
export const updateQuoteConfigurationShowIncludedMentionMutation = () => {
	const [updateQuoteConfigurationShowIncludedMention, { loading, error }] = useMutation(
		UPDATE_QUOTE_CONFIGURATION_SHOW_INCLUDED_MENTION
	);
	return [updateQuoteConfigurationShowIncludedMention, { loading, error }];
};

const CREATE_QUOTE_CONFIGURATION = gql`
	${QUOTE_CONFIGURATION_FULL_FRAGMENT}
	mutation CreateQuoteConfiguration($quoteConfiguration: CreateQuoteConfigurationInput!) {
		createQuoteConfiguration(quoteConfiguration: $quoteConfiguration) {
			...QuoteConfigurationFullFragment
		}
	}
`;

export const createQuoteConfigurationMutation = () => {
	const [createQuoteConfiguration, { loading }] = useMutation(CREATE_QUOTE_CONFIGURATION);
	return [createQuoteConfiguration, { loading }];
};
