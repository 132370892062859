import { gql, useMutation } from "@apollo/client";
import { GET_PERIOD_BY_ID } from "@hooks/usePeriod/graphQl/query";

const UPDATE_PRODUCTS = gql`
	mutation UpdateBulkProducts($updateBulkProducts: [UpdateProductInput]!) {
		updateBulkProducts(updateBulkProducts: $updateBulkProducts) {
			id
			periodId
			index
			name
			description
			imagePath
			price
			tva
			deductibleTva
			volume
			volumeMetric
			frequency
			frequencyMetric
			discount
			createdBy
			updatedBy
			createdAt
			updatedAt
			variableCosts
			priceIsKnowingIn
			paymentFrequency
			timeMetric
			priceInclVAT
			priceExclVAT
			netPriceExclVAT
			netPriceInclVAT
			categories {
				id
				name
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
		}
	}
`;

export const updateProductsMutation = () => {
	const [updateBulkProducts] = useMutation(UPDATE_PRODUCTS, {
		refetchQueries: result => [
			{
				query: GET_PERIOD_BY_ID,
				variables: { id: result.data.updateBulkProducts[0].periodId },
			},
		],
	});
	return updateBulkProducts;
};

const UPDATE_PRODUCT = gql`
	mutation UpdateProduct($updateProductInput: UpdateProductInput!) {
		updateProduct(updateProductInput: $updateProductInput) {
			id
			periodId
			index
			name
			description
			imagePath
			price
			tva
			deductibleTva
			volume
			volumeMetric
			frequency
			frequencyMetric
			discount
			createdBy
			updatedBy
			createdAt
			updatedAt
			variableCosts
			priceIsKnowingIn
			paymentFrequency
			timeMetric
			priceInclVAT
			priceExclVAT
			netPriceExclVAT
			netPriceInclVAT
			categories {
				id
				name
				color
				index
				createdBy
				updatedBy
				createdAt
				updatedAt
			}
		}
	}
`;

export const updateProductMutation = () => {
	const [updateProduct] = useMutation(UPDATE_PRODUCT, {
		refetchQueries: result => [
			{
				query: GET_PERIOD_BY_ID,
				variables: { id: result.data.updateProduct.periodId },
			},
		],
	});
	return updateProduct;
};
