import { filterProducts } from "@pages/specificAnalyses/distributions/services/Distributions.service";

export const actions = {
	UPDATE_TIMEFRAME: "UPDATE_TIMEFRAME",
	UPDATE_BASED_ON: "UPDATE_BASED_ON",
	UPDATE_TIME_METRIC: "UPDATE_TIME_METRIC",
	UPDATE_RECURRING: "UPDATE_RECURRING",
	UPDATE_METRICS: "UPDATE_METRICS",
	UPDATE_RANGE: "UPDATE_RANGE",
	RESET_ALL_BUSINESS_SELECTED: "RESET_ALL_BUSINESS_SELECTED",

	UPDATE_CATEGORIES_SELECTION: "UPDATE_CATEGORIES_SELECTION",
	UPDATE_AND_OR_SELECT: "UPDATE_AND_OR_SELECT",

	UPDATE_IS_MODAL_SAVE_VISIBLE: "UPDATE_IS_MODAL_SAVE_VISIBLE",
	UPDATE_IS_MODIFIED: "UPDATE_IS_MODIFIED",

	IS_SAVING: "IS_SAVING",
	FETCH_DATA_SAVED: "FETCH_DATA_SAVED",
	RESET_UPDATED_ELEMENT: "RESET_UPDATED_ELEMENT",

	UPDATE_SELECTED_TAB: "UPDATE_SELECTED_TAB",
	UPDATE_SELECTED_CHART_TYPE: "UPDATE_SELECTED_CHART_TYPE",

	UPDATE_TEMPORARY_PRODUCTS_SELECTED: "UPDATE_TEMPORARY_PRODUCTS_SELECTED",

	UPDATE_IS_VAT_INCLUDED: "UPDATE_IS_VAT_INCLUDED",
};

export const basedOnEnum = {
	listPrices: "listPrices",
	netPrices: "netPrices",
	discount: "discount",
	discountPercent: "discountPercent",
};

export const initialState = {
	isModalSaveVisible: false,
	isSaveAs: false,
	isModified: false,
	isSaving: false,
	timeframe: "year",
	basedOn: basedOnEnum.listPrices,
	range: "100",
	recurring: "",
	timeMetric: "",
	metrics: [],

	categorizationsSelected: [[], [], []],
	orAnd: ["and", "and"],
	temporaryProductsSelected: [],

	selectedTab: "volume",
	selectedChartType: "currency",

	showCategoryError: false,

	updatedElements: new Set(),

	isVATIncluded: false,
};

export const distributionsReducer = (state, action) => {
	const reducerState = { ...state };
	switch (action.type) {
		case actions.UPDATE_TIMEFRAME:
			reducerState.timeframe = action.value;
			return reducerState;

		case actions.UPDATE_BASED_ON:
			reducerState.basedOn = action.value;
			return reducerState;

		case actions.UPDATE_TIME_METRIC:
			reducerState.timeMetric = action.value;
			return reducerState;

		case actions.UPDATE_RECURRING:
			reducerState.recurring = action.value;
			reducerState.metrics = [];
			reducerState.categorizationsSelected = [[], [], []];
			reducerState.temporaryProductsSelected = [];
			return reducerState;

		case actions.UPDATE_METRICS:
			reducerState.metrics = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				metrics: reducerState.metrics,
			});
			return reducerState;

		case actions.UPDATE_RANGE:
			reducerState.range = action.value;
			return reducerState;

		case actions.RESET_ALL_BUSINESS_SELECTED:
			reducerState.temporaryProductsSelected = action.listOfProducts;
			reducerState.categorizationsSelected = [[], [], []];
			reducerState.orAnd = ["and", "and"];
			return reducerState;

		case actions.UPDATE_CATEGORIES_SELECTION:
			reducerState.categorizationsSelected[action.id] = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				metrics: reducerState.metrics,
			});
			return reducerState;

		case actions.UPDATE_AND_OR_SELECT:
			reducerState.orAnd[action.id] = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				metrics: reducerState.metrics,
			});
			return reducerState;
		case actions.UPDATE_IS_MODAL_SAVE_VISIBLE:
			reducerState.isSaveAs = action.value.isSaveAs || false;
			reducerState.isModalSaveVisible = action.value.isModalSaveVisible || false;
			return reducerState;

		case actions.UPDATE_IS_MODIFIED:
			reducerState.isModified = action.value || false;
			if (action?.id) {
				reducerState.updatedElements.add(action.id);
			}
			return reducerState;

		case actions.IS_SAVING:
			return { ...reducerState, isSaving: action.value };

		case actions.FETCH_DATA_SAVED:
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: action.categorizationsSelected,
				orAnd: action.orAnd,
				metrics: action.metrics,
			});
			return { ...reducerState, ...action.value, isModified: false };

		case actions.RESET_UPDATED_ELEMENT:
			reducerState.updatedElements.clear();
			return reducerState;

		case actions.UPDATE_SELECTED_TAB:
			reducerState.selectedTab = action.value;
			return reducerState;

		case actions.UPDATE_SELECTED_CHART_TYPE:
			reducerState.selectedChartType = action.value;
			return reducerState;

		case actions.UPDATE_TEMPORARY_PRODUCTS_SELECTED:
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				metrics: reducerState.metrics,
			});
			return reducerState;

		case actions.UPDATE_IS_VAT_INCLUDED:
			reducerState.isVATIncluded = action.value;
			return reducerState;

		default:
			throw new Error("Unexpected action");
	}
};
