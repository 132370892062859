import React from "react";
import { message, SingleSelect } from "priceit-ui";

import { actions, basedOnEnum } from "../reducer/reducer";

import { periodMultiplier } from "@services/periodMultiplier";
import { productsComputation, getPriceVirtualUnit } from "@services/computation";

export const computeKPIDistribution = ({
	monthDifference,
	products,
	timeframe,
	timeMetric,
	recurring,
	isVATIncluded,
}) => {
	const coefficient = timeframe === "period" ? 1 : periodMultiplier(timeframe) / monthDifference;
	const coefficientRecurring =
		timeframe === "period"
			? monthDifference / periodMultiplier("month")
			: recurring === "recurring"
			? periodMultiplier(timeframe) / periodMultiplier("month")
			: 1;

	const coefficientTimeMetric =
		timeframe === "period" && recurring === "recurring"
			? monthDifference / periodMultiplier("month")
			: recurring === "recurring"
			? periodMultiplier(timeMetric) / periodMultiplier(timeframe)
			: 1;

	const averageData = productsComputation(products || [], monthDifference, !isVATIncluded);

	const totalVolume =
		averageData.totalVolumeOneShot * coefficient + averageData.totalVolumeRecurring;

	const meanListPrice =
		(averageData.meanListPriceRecurring *
			coefficientRecurring *
			averageData.totalVolumeRecurring +
			averageData.meanListPriceOneShot * averageData.totalVolumeOneShot * coefficient) /
		totalVolume;

	const meanNetPrice =
		(averageData.meanPriceRecurring * averageData.totalVolumeRecurring * coefficientRecurring +
			averageData.meanPriceOneShot * averageData.totalVolumeOneShot * coefficient) /
		totalVolume;

	const meanVariableCost =
		(averageData.meanVariableCostRecurring * averageData.totalVolumeRecurring * coefficient +
			averageData.meanVariableCostOneShot * averageData.totalVolumeOneShot * coefficient) /
		totalVolume;
	const meanSemiVariableCost =
		(averageData.meanSemiVariableCostRecurring *
			averageData.totalVolumeRecurring *
			coefficient +
			averageData.meanSemiVariableCostOneShot *
				averageData.totalVolumeOneShot *
				coefficient) /
		totalVolume;

	const percentDiscount = averageData.meanDiscountPercent;
	const meanDiscount = averageData.meanDiscount;

	const totalVariableCost = meanVariableCost * totalVolume;
	const totalSemiVariableCosts = meanSemiVariableCost * totalVolume;

	const totalGrossRevenue = meanListPrice * totalVolume;
	const totalRevenue = meanNetPrice * totalVolume;
	const totalGrossProfit = totalRevenue - totalVariableCost - totalSemiVariableCosts;
	const meanUnitGrossProfit = totalGrossProfit / totalVolume;

	const virtualUnit = getPriceVirtualUnit({
		products: products || [],
		monthDifference,
		periodVisualized: periodMultiplier(timeframe),
	});

	const virtualVolumeUnitOneShot = virtualUnit.virtualVolumeUnitOneShot;
	const virtualVolumeUnitRecurring = virtualUnit.virtualVolumeUnitRecurring;

	const virtualPriceUnitOneShot = virtualUnit.virtualPriceUnitOneShot;
	const virtualPriceUnitRecurring = virtualUnit.virtualPriceUnitRecurring;

	const virtualDiscountUnitOneShot = virtualUnit.virtualDiscountUnitOneShot;
	const virtualDiscountUnitRecurring = virtualUnit.virtualDiscountUnitRecurring;

	return {
		totalGrossRevenue,
		totalRevenue,
		totalVolume,
		totalGrossProfit,
		meanUnitGrossProfit: meanUnitGrossProfit * coefficientTimeMetric,
		meanListPrice: meanListPrice * coefficientTimeMetric,
		meanNetPrice: meanNetPrice * coefficientTimeMetric,
		percentDiscount: percentDiscount * coefficientTimeMetric,
		meanDiscount: meanDiscount * coefficientTimeMetric,
		virtualVolumeUnitOneShot,
		virtualVolumeUnitRecurring,
		virtualPriceUnitOneShot,
		virtualPriceUnitRecurring,
		virtualDiscountUnitOneShot,
		virtualDiscountUnitRecurring,
	};
};

export const computeChartValues = ({
	monthDifference,
	products,
	timeframe,
	range,
	timeMetric,
	basedOn,
	isVATIncluded,
}) => {
	const listOfPrice = products.map(product => {
		const coefficientRecurringTimeMetric = !["onlyOnce"].includes(product.timeMetric)
			? periodMultiplier(timeMetric) / periodMultiplier(product.timeMetric)
			: 1;
		const productPrice = isVATIncluded ? product.priceInclVAT : product.priceExclVAT;
		const productNetPrice = isVATIncluded ? product.netPriceInclVAT : product.netPriceExclVAT;
		return basedOn === basedOnEnum.listPrices
			? productPrice * coefficientRecurringTimeMetric
			: basedOn === basedOnEnum.netPrices
			? productNetPrice * coefficientRecurringTimeMetric
			: basedOn === basedOnEnum.discount
			? (productPrice - productNetPrice) * coefficientRecurringTimeMetric
			: product.discount * coefficientRecurringTimeMetric;
	});
	const maxPrice = Math.round(Math.max(...listOfPrice) * 100) / 100;
	const minPrice = Math.min(...listOfPrice);

	const tableOfRange = [];

	const numRange = parseFloat(range);

	for (let indexPrice = 0; indexPrice <= maxPrice; indexPrice += numRange) {
		const productInRange = products.filter(product => {
			const coefficientRecurringTimeMetric = !["onlyOnce"].includes(product.timeMetric)
				? periodMultiplier(timeMetric) / periodMultiplier(product.timeMetric)
				: 1;
			const productPrice =
				Math.round((isVATIncluded ? product.priceInclVAT : product.priceExclVAT) * 100) /
				100;
			const productNetPrice =
				Math.round(
					(isVATIncluded ? product.netPriceInclVAT : product.netPriceExclVAT) * 100
				) / 100;

			return basedOn === basedOnEnum.listPrices
				? Math.round(productPrice * coefficientRecurringTimeMetric * 100) / 100 <
						indexPrice + numRange &&
						Math.round(productPrice * coefficientRecurringTimeMetric * 100) / 100 >=
							indexPrice
				: basedOn === basedOnEnum.netPrices
				? Math.round(productNetPrice * coefficientRecurringTimeMetric * 100) / 100 <
						indexPrice + numRange &&
				  Math.round(productNetPrice * coefficientRecurringTimeMetric * 100) / 100 >=
						indexPrice
				: basedOn === basedOnEnum.discount
				? Math.round(
						(productPrice - productNetPrice) * coefficientRecurringTimeMetric * 100
				  ) /
						100 <
						indexPrice + numRange &&
				  Math.round(
						(productPrice - productNetPrice) * coefficientRecurringTimeMetric * 100
				  ) /
						100 >=
						indexPrice
				: Math.round(product.discount * coefficientRecurringTimeMetric * 100) / 100 <
						indexPrice + numRange &&
				  Math.round(product.discount * coefficientRecurringTimeMetric * 100) / 100 >=
						indexPrice;
		});

		if (productInRange.length === 0 && tableOfRange.length === 0) continue;
		//FIXME: we continue in all cases no?

		let volume = 0;
		let netRevenue = 0;
		let listRevenue = 0;
		let profit = 0;
		let numberOfLines = 0;

		productInRange.forEach(product => {
			const coefficient = ["onlyOnce"].includes(product.timeMetric)
				? periodMultiplier(timeframe) / monthDifference
				: 1;
			const coefficientRecurring = !["onlyOnce"].includes(product.timeMetric)
				? periodMultiplier(timeframe) / periodMultiplier(product.timeMetric)
				: 1;
			const productPrice = isVATIncluded ? product.priceInclVAT : product.priceExclVAT;
			const productNetPrice = isVATIncluded
				? product.netPriceInclVAT
				: product.netPriceExclVAT;

			volume += product.volume * coefficient;

			netRevenue += productNetPrice * product.volume * coefficient * coefficientRecurring;

			listRevenue += productPrice * product.volume * coefficient * coefficientRecurring;

			const unitSemiVariableCost = product.semiVariableCostParts.reduce((prev, current) => {
				return prev + current.value;
			}, 0);

			profit +=
				(productNetPrice - unitSemiVariableCost - product.variableCosts) *
				product.volume *
				coefficient *
				coefficientRecurring;

			numberOfLines += 1;
		});

		tableOfRange.push({
			name:
				basedOn === basedOnEnum.discountPercent
					? `${indexPrice.fnb({
							stringText: true,
							decimal: 1,
					  })}%-${(indexPrice + numRange - 0.01).fnb({
							stringText: true,
							decimal: 2,
					  })}%`
					: `${indexPrice.fnb({
							stringText: true,
							withCurrency: true,
							decimal: 2,
					  })}-${(indexPrice + numRange - 0.01).fnb({
							stringText: true,
							withCurrency: true,
							decimal: 2,
					  })}`,
			volume,
			netRevenue,
			listRevenue,
			profit,
			numberOfLines,
		});
	}

	return {
		tableOfRange,
		minRange: (maxPrice - minPrice) / 100,
	};
};

export const onSave = async ({
	t,
	distributionId,
	distributionName,
	currentPeriodId,
	state,
	dispatch,
	updateDistribution,
	updateSimulationProductSelection,
	updateCategorizationCombination,
	priceMetrics,
}) => {
	try {
		await dispatch({
			type: actions.IS_SAVING,
			value: true,
		});

		await Promise.all(
			await [...state.updatedElements].map(async elt => {
				const name = elt.split(":")[0];
				const id = elt.split(":")[1];

				switch (name) {
					case "distribution":
						await updateDistribution({
							variables: {
								updateDistributionInput: {
									id: id,
									name: distributionName,
									periodId: currentPeriodId,
									timeframe: state.timeframe,
									basedOn: state.basedOn,
									range: state.range,
									isRecurring: state.recurring === "recurring",
									timeMetric: state.timeMetric,
									isVATIncluded: state.isVATIncluded,
									metrics: priceMetrics
										.map(metric =>
											state.metrics.includes(metric.key) ? metric.id : null
										)
										.filter(metric => metric),
								},
							},
						});
						break;

					case "simulationProductSelection":
						await updateSimulationProductSelection({
							variables: {
								updateSimulationProductSelectionInput: {
									id: id,
									periodId: currentPeriodId,
									selectAllBusiness:
										state.categorizationsSelected.flat().length === 0,
								},
							},
						});
						break;

					case "categorizationCombination":
						const index = elt.split(":")[2];
						await updateCategorizationCombination({
							variables: {
								updateCategorizationCombinationInput: {
									id: id,
									combination: state.orAnd[index - 1],
									categories: state.categorizationsSelected[index],
								},
							},
						});
						break;
				}

				await updateDistribution({
					variables: {
						updateDistributionInput: {
							id: distributionId,
							name: distributionName,
							periodId: currentPeriodId,
							metrics: priceMetrics
								.map(metric =>
									state.metrics.includes(metric.key) ? metric.id : null
								)
								.filter(metric => metric),
						},
					},
				});
			})
		);

		dispatch({
			type: actions.RESET_UPDATED_ELEMENT,
		});
		await dispatch({
			type: actions.IS_SAVING,
			value: false,
		});

		message.saved(t("Saved"));
		await dispatch({
			type: actions.UPDATE_IS_MODIFIED,
			value: false,
		});
		return true;
	} catch (error) {
		console.log(error);
		message.error(t("An error occurred"));
		return false;
	}
};

export const filterProducts = ({ listOfProducts, categorizationsSelected, orAnd, metrics }) => {
	if (metrics.length === 0) return [];
	return listOfProducts.filter(product => {
		if (!metrics.includes(product.volumeMetric)) {
			return false;
		}
		if (categorizationsSelected.flat().length === 0) return true;

		const categoriesSorted = [...product.categories].sort(
			(a, b) => a.categorizationId - b.categorizationId
		);

		const test = `${
			!categorizationsSelected[0]?.length
				? ""
				: "categorizationsSelected[0].includes(categoriesSorted[0].id)"
		}  ${
			!categorizationsSelected[1]?.length || !categorizationsSelected[0]?.length
				? ""
				: `${orAnd[0] === "and" ? "&&" : "||"}`
		}${
			!categorizationsSelected[1]?.length
				? ""
				: "categorizationsSelected[1].includes(categoriesSorted[1].id)"
		}${
			(!categorizationsSelected[1]?.length && !categorizationsSelected[0]?.length) ||
			!categorizationsSelected[2]?.length
				? ""
				: `${orAnd[1] === "and" ? "&&" : "||"}`
		}${
			!categorizationsSelected[2]?.length
				? ""
				: "categorizationsSelected[2].includes(categoriesSorted[2].id)"
		};`;

		return eval(test);
	});
};

export const computeRanges = ({
	minRange,
	basedOnColor,
	state,
	dispatch,
	currentDistributionId,
}) => {
	const baseRanges = [
		{
			value: "0.01",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(0.01).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "0.05",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(0.05).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "0.1",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(0.1).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "0.25",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(0.25).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "0.5",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(0.5).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "1",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(1).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "2",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(2).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "5",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(5).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "10",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(10).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "20",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(20).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "25",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(25).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "50",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(50).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "100",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(100).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "250",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(250).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "500",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(500).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
		{
			value: "1000",
			color: basedOnColor,
			option: (
				<SingleSelect.Option center>
					{(1000).fnb({
						stringText: true,
						decimal: 2,
						withCurrency: true,
					})}
				</SingleSelect.Option>
			),
		},
	].filter(element => Number(element.value) >= minRange);

	if (!state.range || Number(state.range) < Number(baseRanges[0].value)) {
		dispatch({
			type: actions.UPDATE_RANGE,
			value: baseRanges[0].value,
		});
		dispatch({
			type: actions.UPDATE_IS_MODIFIED,
			value: true,
			id: "distribution:" + currentDistributionId,
		});
	}

	return baseRanges;
};
