import { priceMetricsObj, priceMetricTrad, timeMetricsObj } from "./i18n";
import { hyphenated } from "hyphenated";

export const isTextOverflowing = ref => {
	if (ref && ref.current) {
		return ref.current.scrollWidth > ref.current.offsetWidth;
	}
	return false;
};

export const isTextOverflowingVertically = ref => {
	if (ref && ref.current) {
		return ref.current.scrollHeight > ref.current.offsetHeight;
	}
	return false;
};

export const getUID = pattern => {
	let dt = new Date().getTime();
	return pattern.replace(/[xy]/g, c => {
		const r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const generateToken = async (dispatch, update, id, updateObjectName, actions) => {
	const uuid = getUID("xxxxxxxxy");
	await update({
		variables: {
			[updateObjectName]: {
				id: id,
				token: uuid,
			},
		},
	});
	dispatch({
		type: actions.UPDATE_TOKEN,
		value: uuid,
	});

	return uuid;
};

export const computePasswordStrengthText = (score, t) => {
	if (!score || (score === 0 && score > 5)) return "";
	if ([1, 2].includes(score)) return t("Low");
	if (score === 3) return t("So-so");
	if (score === 4) return t("Good");
	if (score === 5) return t("Very good");
};

export const suffixMetrics = (item, plurals, removeFirstSlash) => {
	const priceMetrics = priceMetricsObj();
	const timeMetrics = timeMetricsObj();
	let suff = "";
	if (item.volumeMetric && item.volumeMetric !== "") {
		if (plurals) {
			suff +=
				(removeFirstSlash ? " " : "/") +
				priceMetricTrad({
					priceMetricList: priceMetrics,
					priceMetric: item.volumeMetric,
					count: 2,
				});
		} else {
			suff +=
				(removeFirstSlash ? " " : "/") +
				priceMetricTrad({
					priceMetricList: priceMetrics,
					priceMetric: item.volumeMetric,
				});
		}
	}
	if (item.timeMetric !== "onlyOnce" && item.timeMetric && item.timeMetric !== "") {
		suff += `/${timeMetrics[item.timeMetric]()}`;
	}
	return suff;
};

export const doesImageExist = url =>
	new Promise(resolve => {
		const img = new Image();

		img.src = url;
		img.onload = () => resolve(true);
		img.onerror = () => resolve(false);
	});

export const blobToBase64 = blob =>
	new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});

export const downloadBase64File = ({ base64Data, fileName }) => {
	const linkSource = base64Data;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
};

export const capitalizeString = str => str?.[0]?.toUpperCase() + str?.slice(1)?.toLowerCase();

export const normalizeString = str => {
	if (!str) {
		return "";
	}
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/[^\w\s]/g, match => {
			switch (match) {
				case "@":
					return "a";
				case "&":
					return "and";
				case " ":
					return "";

				default:
					return "";
			}
		})
		.replace(/\s/g, "")
		.toLowerCase();
};

export const labelFromCrmServiceUser = user => {
	if (user.firstName && user.lastName) {
		return `${user.firstName} ${user.lastName} - ${user.email}`;
	}
	return user.email;
};

export const labelFromCrmServiceCompany = company => {
	if (company.name) {
		return company.name;
	} else if (company.domain) {
		return company.domain;
	}
	return null;
};

export const customHyphenate = (word, div) => {
	if (!div) {
		return word;
	}

	const lines = word.split("\n");
	if (lines.length > 1) {
		return lines.map(line => customHyphenate(line, div)).join("\n");
	}

	const fontSize = window.getComputedStyle(div, null).getPropertyValue("font-size");

	const text = document.createTextNode(word);
	const span = document.createElement("span");
	span.appendChild(text);
	span.style.fontSize = fontSize;
	span.style.display = "inline";
	div.appendChild(span);
	const wordWidth = span.offsetWidth;
	div.removeChild(span);

	const divWidth = div.offsetWidth;
	if (wordWidth > divWidth) {
		return hyphenated(word);
	}
	return word;
};
