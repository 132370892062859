import styled from "styled-components";
import { Icon, InputNumber } from "priceit-ui";

export const WrapperFixedCostViewHeader = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
`;

export const Titles = styled.div`
	margin-left: 25px;
	display: flex;
	flex-direction: column;
	width: 225px;
	min-width: 225px;
`;

export const Title = styled.div`
	font-size: 18px;
	color: white;
`;

export const SubTitle = styled.div`
	color: #a5b9c8;
`;

export const BigRightArrow = styled(Icon)`
	width: 12px;
	transform: rotate(90deg) scaleX(1.5);
	fill: white;
	margin: 0 15px;
`;

export const SmallRightArrow = styled(Icon)`
	margin: 0 15px;
	width: 9px;
	transform: rotate(90deg) scaleX(1.5);
	fill: #5c6f7e;
`;

export const WrapperDataExtension = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledInputNumber = styled(InputNumber)`
	color: white;
	border-radius: 3px;
	text-align: center;
	background-color: #354048;
	border-color: #91999f;
	&:hover {
		border-color: #a5b9c8;
	}
	margin: ${props => props.margin ?? "0 5px 0 0"};
`;

export const InfoIconWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-right: 10px;
`;

export const WrapperTitleAndPeriodSelector = styled.div`
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
`;

export const WrapperCharts = styled.div`
	max-width: calc(100% - 335px);
	overflow: auto;
	height: 100%;
	::-webkit-scrollbar {
		height: 4px;
	}
`;
