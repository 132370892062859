import styled from "styled-components";

export const CategoryButton = styled.div`
	margin-bottom: ${props => (!props.noMargin ? "7px" : 0)};
	border-radius: 3px;
	opacity: ${props => (props.isSelected ? "1" : "0.3")};
	height: 30px;
	font-style: italic;
	text-align: center;
	color: ${props => props.color};
	white-space: nowrap;
	cursor: pointer;
	background-color: ${props => props.backgroundColor};
	max-width: 200px;
	&:hover {
		opacity: 1;
	}
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CategoryText = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 10px;
`;

export const WrapperHeaderLeftPanel = styled.div`
	display: flex;
	height: 100px;
`;

export const FirstColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 25px;
`;

export const SecondColumn = styled.div`
	display: flex;
	align-items: flex-end;
`;

export const SelectedProductsText = styled.div`
	color: #ffcd38;
`;

export const ArrowAndText = styled.div`
	display: flex;
	align-items: center;
`;

export const SelectedProductsRelatedText = styled.div`
	display: inline-block;
	font-style: italic;
`;

export const SelectLinesTitle = styled.div`
	font-size: 14px;
	color: #ffcd38;
	margin-bottom: 10px;
`;

export const GenericRNRButton = styled.div`
	margin-bottom: ${props => (!props.noMargin ? "7px" : 0)};
	border-radius: 3px;
	height: 30px;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	max-width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
`;

export const WrapperNumberOfLinesSelected = styled.div`
	background-color: #2b343b;
	height: 100px;
	border-bottom: 1px solid #1e272e;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 7px;
`;

export const WraperTotalNumberofLines = styled.div`
	font-size: 12px;
	color: #a5b9c8;
	display: flex;
`;

export const TotalNumberOfLines = styled.div`
	color: #fff;
`;

export const QuickSelectionTitle = styled.div`
	font-size: 14px;
	color: #a5b9c8;
	padding: 0 0 10px;
	text-transform: uppercase;
`;