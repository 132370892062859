import React, { lazy, Suspense, useReducer } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useReactiveVar } from "@apollo/client";
import { SimpleCurrentPeriod } from "../currentPeriod/SimpleCurrentPeriod";
import { currentPeriodIdVar } from "../../../../cache/cache";
import { Button, Dialog, Drawer, Icon, Input, Loader, message } from "priceit-ui";

import {
	ContainerSearchBar,
	CustomHeaderSubTitle,
	CustomHeaderTitle,
	HeaderDrawer,
	MyPeriodHeaderColumnIcon,
	MyPeriodHeaderColumnText,
	MyPeriodHeaderColumnTitle,
	TableContainer,
	WrapperButtons,
	WrapperCustomHeader,
	WrapperCustomHeaderFirstLine,
	WrapperPeriodLeftHeader,
} from "./PeriodsDrawer.style";
import { actions, initialState, periodsDrawerReducer } from "./reducer";

import { deletePeriodsMutation, updateUserWorkspaceDefaultMutation } from "../graphQl/mutation";

import { ModalePeriodCreation } from "./modalPeriodCreation/ModalPeriodCreation";
import { usePeriod } from "@hooks/usePeriod/UsePeriod";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { CrossButton } from "../../crossButton/CrossButton";
import { GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID } from "@src/graphQl/queries/userWorkspaceDefault";

const TableComponent = lazy(() => import("./tablePeriod/TablePeriod"));

const { ErrorModal } = Dialog;

export const PeriodsDrawer = ({ onCloseDrawer, drawerVisible }) => {
	const { t } = useTranslation("shared/periodsDrawer");

	const currentPeriodId = useReactiveVar(currentPeriodIdVar);

	const { period } = usePeriod(currentPeriodId);

	const [statePeriodDrawer, dispatchPeriodDrawer] = useReducer(
		periodsDrawerReducer,
		initialState
	);

	const { auth } = useAuth();

	const { data: dataUserWorkspaceDefault } = useQuery(
		GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID,
		{
			skip: !auth?.id,
			variables: { userId: auth?.id },
		}
	);

	const updateUserWorkspaceDefault = updateUserWorkspaceDefaultMutation();
	const deletePeriods = deletePeriodsMutation();

	const closeModal = e => {
		e.stopPropagation();
		if (statePeriodDrawer.deleteInput === t("delete")) {
			deletePeriods({ variables: { ids: statePeriodDrawer.selectedRows } });

			dispatchPeriodDrawer({
				type: actions.UPDATE_DELETE_INPUT,
				value: "",
			});
			dispatchPeriodDrawer({
				type: actions.UPDATE_MODAL_CONFIRM_VISIBLE,
				value: false,
			});
			dispatchPeriodDrawer({ type: actions.UPDATE_PERIODS_SELECTED, value: [] });
		} else {
			message.error(t("Incorrect word"));
		}
	};

	const customHeader = (
		<WrapperCustomHeader>
			<WrapperCustomHeaderFirstLine>
				<Icon type="warning" fill="#E65757" />
				<CustomHeaderTitle>{t("WARNING")}</CustomHeaderTitle>
			</WrapperCustomHeaderFirstLine>
			<CustomHeaderSubTitle>
				{t(
					"You are about to delete {{numberOfPeriod}} period. All products created in it will be automatically deleted.",
					{ numberOfPeriod: statePeriodDrawer.selectedRows.length }
				)}
				<br />
				{t('To confirm, please enter "delete" in the box below')}
			</CustomHeaderSubTitle>
			<CrossButton
				onClickFunction={() => {
					dispatchPeriodDrawer({
						type: actions.UPDATE_DELETE_INPUT,
						value: "",
					});
					dispatchPeriodDrawer({
						type: actions.UPDATE_MODAL_CONFIRM_VISIBLE,
						value: false,
					});
				}}
			/>
		</WrapperCustomHeader>
	);

	return createPortal(
		<Drawer
			placement="right"
			onClose={onCloseDrawer}
			visible={drawerVisible}
			width="calc(100vw - 90px)"
			style={{ zIndex: 1000 }}
		>
			<HeaderDrawer>
				<WrapperPeriodLeftHeader>
					<MyPeriodHeaderColumnTitle>
						<MyPeriodHeaderColumnIcon type="calendar" />
						<MyPeriodHeaderColumnText>{t("Periods")}</MyPeriodHeaderColumnText>
					</MyPeriodHeaderColumnTitle>
					<SimpleCurrentPeriod period={period} isPeriodDrawerOpen />
				</WrapperPeriodLeftHeader>

				<ContainerSearchBar>
					<Input
						type={"search"}
						mode="dark"
						placeholder="Search"
						enableDelay
						onChange={e => {
							dispatchPeriodDrawer({
								type: actions.UPDATE_SEARCH_INPUT,
								value: e.target.value,
							});
						}}
					/>
				</ContainerSearchBar>
				<CrossButton
					onClickFunction={() => {
						dispatchPeriodDrawer({
							type: actions.UPDATE_PERIODS_SELECTED,
							value: [],
						});
						onCloseDrawer(true);
					}}
				/>
			</HeaderDrawer>
			<TableContainer>
				<Suspense fallback={<Loader />}>
					<div>
						<TableComponent
							currentPeriodId={currentPeriodId}
							dispatchPeriodDrawer={dispatchPeriodDrawer}
							selectedRows={statePeriodDrawer.selectedRows}
							searchInput={statePeriodDrawer.searchInput}
							statePeriodDrawer={statePeriodDrawer}
						/>
					</div>
				</Suspense>

				<WrapperButtons>
					<Button
						key="cancel"
						type="cancel"
						width={"160px"}
						mode="dark"
						style={{ marginLeft: "35px" }}
						onClick={() => {
							dispatchPeriodDrawer({
								type: actions.UPDATE_PERIODS_SELECTED,
								value: [],
							});
							onCloseDrawer(true);
						}}
					>
						{t("Close")}
					</Button>
					<Button
						key="ok2"
						type={"secondary"}
						width={"fit-content"}
						style={{ marginLeft: "15px" }}
						disabled={statePeriodDrawer.selectedRows.length !== 1}
						onClick={() => {
							if (!!dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId.id) {
								updateUserWorkspaceDefault({
									variables: {
										updateUserWorkspaceDefault: {
											id: dataUserWorkspaceDefault
												?.getUserWorkspaceDefaultByUserId.id,
											periodId: statePeriodDrawer.selectedRows[0],
										},
									},
								});
							}
							dispatchPeriodDrawer({
								type: actions.UPDATE_PERIODS_SELECTED,
								value: [],
							});
							onCloseDrawer(true);
						}}
					>
						{t("Open selected period")}
					</Button>
				</WrapperButtons>
			</TableContainer>

			<ModalePeriodCreation
				visible={statePeriodDrawer.isCreating}
				dispatchPeriodDrawer={dispatchPeriodDrawer}
			/>
			<ErrorModal
				noFixPlaceHolder
				customHeader={customHeader}
				reverseButtonHighlight
				haveFooterInput
				width={"40vw"}
				okText={t("Confirm")}
				cancelText={t("Cancel")}
				type="2buttons"
				onOkDisabled={statePeriodDrawer.deleteInput !== t("delete")}
				onOk={e => closeModal(e)}
				onCancel={() => {
					dispatchPeriodDrawer({
						type: actions.UPDATE_DELETE_INPUT,
						value: "",
					});
					dispatchPeriodDrawer({
						type: actions.UPDATE_MODAL_CONFIRM_VISIBLE,
						value: false,
					});
				}}
				onChangeCallback={e =>
					dispatchPeriodDrawer({
						type: actions.UPDATE_DELETE_INPUT,
						value: e.target.value,
					})
				}
				inputStyle={{
					backgroundColor: "#354048",
				}}
				inputPlaceholder={t('Write "delete" here')}
				inputValue={statePeriodDrawer.deleteInput}
				style={{
					zIndex: 2000,
				}}
				visible={statePeriodDrawer.modalConfirmVisible}
			/>
		</Drawer>,
		document.body
	);
};
