import React from "react";
import { useTranslation } from "react-i18next";
import { SingleSelect } from "priceit-ui";
import {
	Counter,
	Label,
	StyledSingleSelect,
	WrapperLabelAndCounter,
	WrapperLabelAndSelect,
} from "./LabelAndDropdownComponent.style";

export const LabelAndDropdownComponent = React.memo(
	({
		data,
		width,
		label,
		maxLength,
		hideMaxLength,
		value,
		setValue,
		errorDisplayed,
		disabled,
		mode,
		isMandatory,
		hidden = false,
		placeHolder = "",
	}) => {
		const { t } = useTranslation("generic");
		return (
			<WrapperLabelAndSelect width={width}>
				<WrapperLabelAndCounter disabled={disabled} hidden={hidden}>
					<Label>
						{isMandatory ? "*" : null}
						{label}
						{!isMandatory ? t("(optional)") : null}
					</Label>
					{maxLength && !hideMaxLength ? (
						<Counter>
							{value?.length ?? 0}/{maxLength}
						</Counter>
					) : null}
				</WrapperLabelAndCounter>
				<StyledSingleSelect
					disableSingleOption={false}
					errorDisplayed={errorDisplayed}
					type="basic"
					mode={mode}
					appendTo={document.body}
					width={width}
					onChange={e => setValue(e)}
					value={value}
					placeHolder={placeHolder}
					data={data.map(element => {
						return {
							value: element.id,
							option: (
								<SingleSelect.Option value={element.id}>
									{element.value}
								</SingleSelect.Option>
							),
						};
					})}
				/>
			</WrapperLabelAndSelect>
		);
	}
);
LabelAndDropdownComponent.whyDidYouRender = true;
LabelAndDropdownComponent.displayName = "LabelAndDropdownComponent";
