import React, { memo, useEffect } from "react";
import { Button, Loader } from "priceit-ui";
import { useThemeContext } from "@theme/Theme";
import { ButtonWrapper } from "../CreateNewOfferHeader.style";
import { useHandleOkActionCreationOfferForNextStepButton } from "@hooks/useOffer/useHandleOkActionCreationOffer";
import { useCanNextCustomization } from "@src/pages/clientsOffers/hooks/useCanNextCustomization/useCanNextCustomization";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import analytics from "../../../../../../config/analytics/analytics";
import { useRecoilValue } from "recoil";
import {
	clientsOffersIsNewOfferAtom,
	clientsOffersNextButtonDisabledAtom,
} from "@recoil/clientOffers/atom";
import { useWorkspace } from "@hooks/useWorkspace/UseWorkspace";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "@src/globalContext";
import { useRemoveAllEmptyContactLines } from "@pages/clientsOffers/hooks/useRemoveAllEmptyContactLines";
import { useQuery } from "@apollo/client";
import { GET_OFFER_MANDATORY_BY_ID } from "@graphQl/queries/offer";

export const SkipButton = memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { workspace } = useWorkspace();

	const { mode } = useThemeContext();
	const { light } = useGlobalContext();

	const { handleOkAction, loadingCreationVersion, loadingNextButton, setLoadingNextButton } =
		useHandleOkActionCreationOfferForNextStepButton();

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const isNewOffer = useRecoilValue(clientsOffersIsNewOfferAtom);
	const isNextButtonDisabled = useRecoilValue(clientsOffersNextButtonDisabledAtom);

	const { data: dataOffer } = useQuery(GET_OFFER_MANDATORY_BY_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});

	const { offerContacts } = dataOffer?.getOfferById || {};

	useCanNextCustomization();
	const removeAllEmptyContactLines = useRemoveAllEmptyContactLines(offerContacts);

	useEffect(() => {
		if (loadingNextButton && !loadingCreationVersion) {
			setLoadingNextButton(false);
			removeAllEmptyContactLines();
			analytics.track("Create a new offer - skip button", {
				from: light ? "hubspot" : "webApp",
				type: isNewOffer ? "new offer" : "new version",
				step: "customization",
				error: false,
				workspaceName: workspace?.name,
				workspaceActive: workspace?.active,
				workspaceActivationDate: workspace?.createdAt,
				workspaceActiveToDate: workspace?.activeToDate,
			});
		}
	}, [loadingCreationVersion]);

	if (loadingCreationVersion) return null;

	return (
		<ButtonWrapper>
			<Button
				mode={mode}
				type={"cancel"}
				showRightArrowIcon
				onClick={() => handleOkAction(true)}
				disabled={isNextButtonDisabled || loadingNextButton || loadingCreationVersion}
			>
				{loadingNextButton ? <Loader /> : t('Skip "Default configuration" step and create')}
			</Button>
		</ButtonWrapper>
	);
});
SkipButton.whyDidYouRender = true;
SkipButton.displayName = "SkipButton";

export default SkipButton;
