import { selectorFamily } from "recoil";

import { convertMetric } from "@services/metrics";

import {
	commitmentDiscountStateAtom,
	paymentTermsDiscountStateAtom,
	commitmentDiscountConditionShowSelectAtom,
	packDisabledDueToCommitmentDiscountDependencyAtom,
} from "./atom";
import { priceModelTypeByPriceModelIdSelector } from "./publicLinkSelector";
import { paymentTermsDiscountStateByPriceModelIdSelector } from "./paymentTermsDiscountSelector";
import defaultText from "@src/services/defaultText";

export const commitmentDiscountStateByPriceModelIdSelector = selectorFamily({
	key: "commitmentDiscountStateByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(commitmentDiscountStateAtom)[priceModelId],
	set:
		priceModelId =>
		(
			{ get, set },
			{ newValue, discountRule, timeMetric, updateDefaultSelectedPaymentTermRange }
		) => {
			const paymentTermsList = discountRule?.find(rule => !!rule?.paymentTermDiscount)
				?.paymentTermDiscount?.paymentTermRange;
			const paymentTermSelectedId = get(paymentTermsDiscountStateAtom)[priceModelId];
			const paymentTermSelected = paymentTermsList?.find(
				paymentTerm => paymentTerm.id === paymentTermSelectedId
			);
			if (paymentTermSelected) {
				const engagementDuration = get(
					commitmentDiscountConditionShowSelectByPriceModelIdSelector(priceModelId)
				)
					? discountRule
							?.find(rule => !!rule?.engagementDiscount)
							?.engagementDiscount?.engagementRange?.find(
								range => range.id === newValue
							)?.duration
					: newValue;

				const engagementDurationConvert = convertMetric(engagementDuration, timeMetric);

				const engagementForPaymentTermSelected =
					engagementDurationConvert[paymentTermSelected.term];

				if (
					(paymentTermSelected.term === defaultText.oneShot &&
						engagementDuration === 1) ||
					(engagementForPaymentTermSelected &&
						!Number.isInteger(engagementForPaymentTermSelected))
				) {
					const paymentTermToUpdate = [...paymentTermsList]
						.reverse()
						.find(paymentTerm =>
							Number.isInteger(engagementDurationConvert[paymentTerm.term])
						);
					if (paymentTermToUpdate) {
						set(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId), {
							newValue: paymentTermToUpdate.id,
							discountRule,
							timeMetric,
						});
						if (updateDefaultSelectedPaymentTermRange) {
							updateDefaultSelectedPaymentTermRange(paymentTermToUpdate.id);
						}
					}
				}
			}

			const newCommitment = get(
				commitmentDiscountConditionShowSelectByPriceModelIdSelector(priceModelId)
			)
				? discountRule
						?.find(rule => !!rule?.engagementDiscount)
						?.engagementDiscount?.engagementRange?.find(range => range.id === newValue)
						?.duration
				: get(commitmentDiscountStateByPriceModelIdSelector(priceModelId));

			set(priceModelTypeByPriceModelIdSelector(priceModelId), {
				timeMetric,
				newCommitment,
				discountRule,
			});

			set(commitmentDiscountStateAtom, {
				...get(commitmentDiscountStateAtom),
				[priceModelId]: newValue,
			});
		},
});

export const commitmentDiscountConditionShowSelectByPriceModelIdSelector = selectorFamily({
	key: "commitmentDiscountConditionShowSelectByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(commitmentDiscountConditionShowSelectAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(commitmentDiscountConditionShowSelectAtom, {
				...get(commitmentDiscountConditionShowSelectAtom),
				[priceModelId]: newValue || false,
			});
		},
});

export const commitmentDiscountValueByPriceModelIdSelector = selectorFamily({
	key: "commitmentDiscountValueByPriceModelIdSelector",
	get:
		({ priceModelId, discountRule }) =>
		({ get }) =>
			get(commitmentDiscountConditionShowSelectByPriceModelIdSelector(priceModelId))
				? discountRule
						?.find(rule => !!rule?.engagementDiscount)
						?.engagementDiscount?.engagementRange?.find(
							range =>
								range.id ===
								get(commitmentDiscountStateByPriceModelIdSelector(priceModelId))
						)?.duration
				: get(commitmentDiscountStateByPriceModelIdSelector(priceModelId)),
});

export const packDisabledDueToCommitmentDiscountDependencyByPriceModelIdSelector = selectorFamily({
	key: "packDisabledDueToCommitmentDiscountDependencyByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(packDisabledDueToCommitmentDiscountDependencyAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(packDisabledDueToCommitmentDiscountDependencyAtom, {
				...get(packDisabledDueToCommitmentDiscountDependencyAtom),
				[priceModelId]: newValue,
			});
		},
});
