import styled, { css } from "styled-components";
import { verticalCustomGap } from "@services/Utils.style";

export const Wrapper = styled.div`
	flex: 1;
`;

export const WrapperPreselectionOfOptions = styled.div`
	${({ visible }) => {
		if (!visible) {
			return css`
				display: none;
			`;
		} else {
			return css`
				display: flex;
				flex-direction: column;
				//overflow: hidden;
				scrollbar-gutter: stable both-edges;
				padding: 20px 30px;
				${verticalCustomGap("10px")};
				//flex: 1;
			`;
		}
	}};
`;

export const WrapperOfferDefinition = styled.div`
	${({ visible }) => {
		if (!visible) {
			return css`
				display: none;
			`;
		} else {
			return css`
				display: flex;
				flex-direction: column;
				flex: 1;
				width: 100%;
				padding-right: 10px;
				overflow: hidden;
			`;
		}
	}};
`;

export const WrapperOfferDefinitionTemplateSelection = styled.div`
	${({ visible }) => {
		if (!visible) {
			return css`
				display: none;
			`;
		} else {
			return css`
				display: flex;
				flex-direction: column;
				overflow: hidden;
				scrollbar-gutter: stable both-edges;
				padding: 20px 0;
				${verticalCustomGap("10px")};
				flex: 1;
			`;
		}
	}};
`;
