import React, { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
	CategorizationIntro,
	CategorizationTitle,
	WrapperButtonAndProgressBar,
	ColoredSpan,
	WrapperProgressBar,
} from "./CategorizationAllocation.style";
import { CategoryButton, CategoryText } from "../LeftPanel.style";
import { ProgressBar } from "@components/progressBar/ProgressBar";
import {
	allocatedVariableCost,
	computeVariableCostPartAmount,
	percentCoefficients,
} from "@pages/myBusinessModel/fixedCosts/service/FixedCosts.service";
import moment from "moment";
import { productsComputation } from "@services/computation";
import { textColor } from "@src/services/textColor";

export const CategorizationAllocation = ({
	listOfCategorization,
	selectedCategoryIds,
	onClickCategory,
	productsSelected,
	data,
	period,
	periodType,
}) => {
	const { t } = useTranslation("drawer/page");

	const endDate = moment(period?.endDate);
	const startDate = moment(period?.startDate);
	const monthDifference = endDate.diff(startDate, "months") + 1;

	const totalComputationalData = productsComputation(productsSelected || [], monthDifference);
	const variableCostPartAmount = computeVariableCostPartAmount(
		data?.fixedCost,
		periodType,
		monthDifference
	);

	return (
		<div style={{ padding: "0 5px" }}>
			{[...listOfCategorization]
				.sort((a, b) => a.id - b.id)
				.map(categorization => {
					return categorization.category.length > 1 ||
						(categorization.category.length === 1 &&
							categorization.category.name === "-") ? (
						<div
							key={"PD-listOfCategories-" + categorization.id}
							style={{ marginBottom: "40px" }}
						>
							<Trans
								t={t}
								i18nKey="<CategorizationIntro><ColoredSpan1>Semi-variable costs</ColoredSpan1><ColoredSpan2>distribution according to</ColoredSpan2></CategorizationIntro>"
								defaults="<CategorizationIntro><ColoredSpan1>Semi-variable costs</ColoredSpan1><ColoredSpan2>distribution according to</ColoredSpan2></CategorizationIntro>"
								ns="drawer/page"
								components={{
									CategorizationIntro: <CategorizationIntro />,
									ColoredSpan1: <ColoredSpan color={"#00CBFF"} rightSpace />,
									ColoredSpan2: <ColoredSpan color={"#A5B9C8"} />,
									ColoredSpan3: (
										<ColoredSpan color={"#00CBFF"} rightSpace leftSpace />
									),
								}}
							>
								Semi-variable costs distribution according to
							</Trans>
							<CategorizationTitle>{categorization.name}</CategorizationTitle>
							{[...categorization?.category]
								.sort((a, b) => a.index - b.index)
								.map(category => {
									const isSelected = selectedCategoryIds.includes(category.id);
									const products = productsSelected.filter(x =>
										x.categories.map(y => y.id).includes(category.id)
									);
									const totalAllocatedVariableCosts = products.reduce(
										(oldVal, product) => {
											const {
												allocatedSemiVariableCost,
											} = allocatedVariableCost(
												data?.fixedCost,
												periodType,
												monthDifference,
												product,
												totalComputationalData
											);
											return oldVal + allocatedSemiVariableCost;
										},
										0
									);
									const percentSemiVarTotal =
										(totalAllocatedVariableCosts / variableCostPartAmount) *
										100;
									return (
										<WrapperButtonAndProgressBar>
											<CategoryButton
												key={category.id}
												isSelected={isSelected}
												color={textColor(category.color)}
												backgroundColor={category.color}
												onClick={() => {
													category.name === "-"
														? null
														: onClickCategory(category, isSelected);
												}}
												noMargin
											>
												<CategoryText>{category.name}</CategoryText>
											</CategoryButton>

											<WrapperProgressBar>
												<ProgressBar
													percent={percentSemiVarTotal}
													fillColor={category.color}
													labelValue={totalAllocatedVariableCosts}
													opacity={isSelected ? "||" : "0.3"}
													rectangle
												/>
											</WrapperProgressBar>
										</WrapperButtonAndProgressBar>
									);
								})}
						</div>
					) : null;
				})}
		</div>
	);
};
