// algo from this stackoverflow thread https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function hexToRgb(hex) {
	if (![null, undefined, NaN].includes(hex)) {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: { r: 250, g: 250, b: 250 };
	}
	return { r: 250, g: 250, b: 250 };
}
//formula below from this thread : https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const textColor = (backgroundColor, darkColor = "#404D74", brightColor = "#ffffff") => {
	const object = hexToRgb(backgroundColor);
	const verifyColor = object.r * 0.299 + object.g * 0.587 + object.b * 0.114 > 186;
	return verifyColor ? darkColor : brightColor;
};
