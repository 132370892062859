import { gql, useMutation } from "@apollo/client";
import {
	CATEGORIZATION_DESCRIPTION_FRAGMENT,
	CATEGORIZATION_ID_FRAGMENT,
	CATEGORIZATION_NAME_FRAGMENT,
} from "@graphQl/fragments/categorizationNewFragment";

const CREATE_CATEGORIZATION = gql`
	mutation CreateCategorization($createCategorizationInput: CreateCategorizationInput!) {
		createCategorization(createCategorizationInput: $createCategorizationInput) {
			id
			name
			description
			createdAt
			updatedAt
			createdBy
			updatedBy
		}
	}
`;
export const createCategorizationMutation = () => {
	const [createCategorization] = useMutation(CREATE_CATEGORIZATION);
	return createCategorization;
};

const CREATE_PERIOD_CATEGORIZATION = gql`
	mutation CreatePeriodCategorization(
		$createPeriodCategorizationInput: CreatePeriodCategorizationInput!
	) {
		createPeriodCategorization(
			createPeriodCategorizationInput: $createPeriodCategorizationInput
		) {
			id
		}
	}
`;

export const createPeriodCategorizationMutation = () => {
	const [createPeriodCategorization] = useMutation(CREATE_PERIOD_CATEGORIZATION);
	return createPeriodCategorization;
};

const CREATE_FIXED_COST_PERIOD_CATEGORIZATION = gql`
	mutation CreateFixedCostPeriodCategorization(
		$createFixedCostPeriodCategorizationInput: CreateFixedCostPeriodCategorizationInput!
	) {
		createFixedCostPeriodCategorization(
			createFixedCostPeriodCategorizationInput: $createFixedCostPeriodCategorizationInput
		) {
			id
		}
	}
`;

export const createFixedCostPeriodCategorizationMutation = () => {
	const [createFixedCostPeriodCategorization] = useMutation(
		CREATE_FIXED_COST_PERIOD_CATEGORIZATION
	);
	return createFixedCostPeriodCategorization;
};

const UPDATE_CATEGORIZATION_NAME = gql`
	${CATEGORIZATION_ID_FRAGMENT}
	${CATEGORIZATION_NAME_FRAGMENT}
	mutation UpdateCategorizationName(
		$updateCategorizationInputNew: UpdateCategorizationInputNew!
	) {
		updateCategorizationNew(updateCategorizationInputNew: $updateCategorizationInputNew) {
			...CategorizationIdFragment
			...CategorizationNameFragment
		}
	}
`;

export const updateCategorizationNameMutation = () => {
	const [updateCategorizationName, { loading, error }] = useMutation(UPDATE_CATEGORIZATION_NAME);
	return [updateCategorizationName, { loading, error }];
};

const UPDATE_CATEGORIZATION_DESCRIPTION = gql`
	${CATEGORIZATION_ID_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	mutation UpdateCategorizationDescription(
		$updateCategorizationInputNew: UpdateCategorizationInputNew!
	) {
		updateCategorizationNew(updateCategorizationInputNew: $updateCategorizationInputNew) {
			...CategorizationIdFragment
			...CategorizationDescriptionFragment
		}
	}
`;

export const updateCategorizationDescriptionMutation = () => {
	const [updateCategorizationDescription, { loading, error }] = useMutation(
		UPDATE_CATEGORIZATION_DESCRIPTION
	);
	return [updateCategorizationDescription, { loading, error }];
};
