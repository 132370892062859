import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const WrapperParameters = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
	width: 100%;
	& > * {
		padding: 10px 0;
	}
`;

export const OfferParametersTitle = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: ${({ theme }) => theme.page.textColor};
	border-bottom: unset;
	padding-bottom: unset;
`;

export const WrapperNotification = styled(WrapperParameters)``;

export const WrapperAccess = styled(WrapperParameters)``;

export const WrapperQuote = styled(WrapperParameters)``;

export const WrapperConditions = styled(WrapperParameters)``;
