import React, { useMemo } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentPeriodIdVar } from "@src/cache/cache";
import { GET_FIXED_COST_BY_PERIOD_ID } from "@hooks/useFixedCosts/graphQl/query";
import {
	deleteFixedCostsMutation,
	updateFixedCostMutation,
	updateFixedCostsMutation,
} from "@hooks/useFixedCosts/graphQl/mutation";
import { fixedCostComputation } from "@services/computation";
import { usePeriod } from "@hooks/usePeriod/UsePeriod";

export const useFixedCosts = periodId => {
	const currentPeriodId = useReactiveVar(currentPeriodIdVar);

	const updateFixedCost = updateFixedCostMutation();
	const updateFixedCosts = updateFixedCostsMutation();
	const deleteFixedCosts = deleteFixedCostsMutation();

	const { monthDifference } = usePeriod(currentPeriodId);

	const {
		loading: loadingFixedCosts,
		error: errorFixedCosts,
		data: dataFixedCosts,
	} = useQuery(GET_FIXED_COST_BY_PERIOD_ID, {
		skip: (periodId ?? currentPeriodId) === "0",
		variables: {
			periodId: periodId ?? currentPeriodId,
		},
	});

	const totalFixedCost = useMemo(() => {
		return fixedCostComputation(dataFixedCosts?.getFixedCostsByPeriodId || [], monthDifference);
	}, [monthDifference, dataFixedCosts?.getFixedCostsByPeriodId]);

	return {
		fixedCosts: dataFixedCosts?.getFixedCostsByPeriodId || [],
		updateFixedCost,
		updateFixedCosts,
		deleteFixedCosts,
		totalFixedCost,
		loadingFixedCosts,
	};
};
