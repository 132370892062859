import React from "react";
import {
	ArrowNbProducts,
	WrapperCategories,
	WrapperFirstColumn,
	WrapperNbProducts,
	WrapperQuickSelectionAndCategories,
} from "../../ProductsDrawer.style";
import { priceMetricsObj } from "@src/services/i18n";
import {
	WrapperHeaderLeftPanel,
	FirstColumn,
	SecondColumn,
	SelectedProductsText,
	ArrowAndText,
	SelectedProductsRelatedText,
	WrapperNumberOfLinesSelected,
	WraperTotalNumberofLines,
	TotalNumberOfLines,
	QuickSelectionTitle,
} from "./LeftPanel.style";
import { Categorization } from "./categorization/Categorization";
import { CategorizationAllocation } from "./categorizationAllocation/CategorizationAllocation";
import { useTranslation } from "react-i18next";
import { Icon } from "priceit-ui";
import RecurringSelection from "@components/productsDrawer/components/LeftPanels/recurringSelection/RecurringSelection";

const LeftPanel = ({
	view,
	productsSelected,
	dataCategorization,
	selectedCategoryIds,
	onClickCategory,
	data,
	period,
	periodType,
	listOfProducts,
}) => {
	const { t } = useTranslation(["drawer/leftPanel"]);
	const priceMetrics = priceMetricsObj();
	return (
		<WrapperFirstColumn>
			{view === "productsListView" || view === "productsCardView" ? (
				<>
					<WrapperNumberOfLinesSelected>
						<WraperTotalNumberofLines>
							{t("Total number of lines")}:&nbsp;
							<TotalNumberOfLines>
								{`${listOfProducts.length} ${priceMetrics["line"](
									listOfProducts.length || 1
								)}`}
							</TotalNumberOfLines>
						</WraperTotalNumberofLines>
						<ArrowAndText>
							<ArrowNbProducts type="upTriangleSharp" fill={"#FFCD38"} noMargin />
							&nbsp;&nbsp;
							<SelectedProductsText>
								{`${t("{{productLength}} selected element", {
									productLength: productsSelected.length,
									count: productsSelected.length || 1,
								})}
							`}
							</SelectedProductsText>
						</ArrowAndText>
					</WrapperNumberOfLinesSelected>
					<WrapperCategories>
						<QuickSelectionTitle>{t("Quick selection")}:</QuickSelectionTitle>
						<RecurringSelection listOfProducts={listOfProducts} />
						<Categorization
							listOfProducts={listOfProducts}
							listOfCategorization={
								dataCategorization?.getCategorizationByPeriodId || []
							}
						/>
					</WrapperCategories>
				</>
			) : view === "fixedCostView" ? (
				<>
					<WrapperHeaderLeftPanel>
						<FirstColumn>
							<ArrowAndText>
								<ArrowNbProducts type="upTriangleSharp" fill={"#FFCD38"} />
								<SelectedProductsText>
									{`${productsSelected.length} ${t("selected {{element}}", {
										element: priceMetrics["element"](
											productsSelected.length || 1
										),
									})} `}
								</SelectedProductsText>
							</ArrowAndText>
						</FirstColumn>
						<SecondColumn>
							<Icon type="spiralArrow" stroke="#A5B9C8" width="60px" height="60px" />
						</SecondColumn>
					</WrapperHeaderLeftPanel>
					<WrapperCategories>
						<CategorizationAllocation
							listOfCategorization={
								dataCategorization?.getCategorizationByPeriodId || []
							}
							selectedCategoryIds={selectedCategoryIds}
							onClickCategory={onClickCategory}
							productsSelected={productsSelected}
							data={data}
							period={period}
							periodType={periodType}
						/>
					</WrapperCategories>
				</>
			) : null}
		</WrapperFirstColumn>
	);
};

export default LeftPanel;
