import { gql } from "@apollo/client";
import { COLUMN_NAME_AND_FILTER_FRAGMENT } from "@graphQl/fragments/column";
import { TABLE_GROUP_BY_FRAGMENT } from "@graphQl/fragments/table";

export const GET_TABLE_PROPS = gql`
	query GetTableByPeriodIdAndName($periodId: ID!, $name: String!) {
		getTableByPeriodIdAndName(periodId: $periodId, name: $name) {
			id
			name
			periodId
			userId
			columns {
				id
				tableId
				collapsed
				hidden
				index
				name
				width
				sort
			}
		}
	}
`;

export const GET_TABLE_PROPS_BY_NAME = gql`
	${TABLE_GROUP_BY_FRAGMENT}
	${COLUMN_NAME_AND_FILTER_FRAGMENT}
	query GetTableByName($name: String!) {
		getOldTableByName(name: $name) {
			id
			name
			workspaceId
			userId
			...TableGroupByFragment
			columns {
				...ColumnNameAndFilterFragment
				tableId
				collapsed
				hidden
				index
				width
				sort
			}
		}
	}
`;

export const GET_TABLE_PROPS_BY_PERIOD_ID = gql`
	query GetTableByPeriodIdAndName($periodId: ID!, $name: String!) {
		getTableByPeriodIdAndName(periodId: $periodId, name: $name) {
			id
			name
			periodId
			userId
			columns {
				id
				tableId
				collapsed
				hidden
				index
				name
				width
				sort
			}
		}
	}
`;
