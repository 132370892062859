import { useQuery } from "@apollo/client";
import { GET_QUOTE_FIELDS_BY_PRICE_MODEL_COMBINATION_ID } from "@src/graphQl/queries/priceModelCombinationQuery";

export const useGetDisplayedAndMandatoryQuoteField = ({
	priceModelCombinationId,
	quoteFieldName,
}) => {
	const { data: dataPriceModelCombination, loading: loadingPriceModelCombination } = useQuery(
		GET_QUOTE_FIELDS_BY_PRICE_MODEL_COMBINATION_ID,
		{
			variables: {
				id: priceModelCombinationId,
			},
			skip: !priceModelCombinationId,
		}
	);

	const { quoteFields } = dataPriceModelCombination?.getPriceModelCombinationById || {};
	const retrievedQuoteField = quoteFields?.find(quoteField => quoteField.name === quoteFieldName);

	return {
		loading: loadingPriceModelCombination,
		mandatory: retrievedQuoteField?.mandatory,
		display: retrievedQuoteField?.display,
	};
};
