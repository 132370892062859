import { useRecoilValue } from "recoil";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import { useQuery } from "@apollo/client";
import { GET_OFFER_TOKEN_AND_WORKSPACE_NAME_BY_OFFER_ID } from "@graphQl/queries/offer";
import { getPublicLink } from "@pages/myPricingTools/buildingTools/priceModels/services/PriceModels.service";

export const useOfferLink = () => {
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const {
		data: offerData,
		loading: offerLoading,
		error: offerError,
	} = useQuery(GET_OFFER_TOKEN_AND_WORKSPACE_NAME_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const loading = offerId && offerLoading && !offerError;
	const workspaceName = offerData?.getOfferById?.workspace?.name;
	const offerToken = offerData?.getOfferById?.tokenObj?.token;
	let offerLink;
	if (offerToken && workspaceName) {
		offerLink = getPublicLink({
			token: offerToken,
			isGroup: false,
			isOffer: true,
			workspaceName,
		});
	}
	return { loading, offerLink };
};
