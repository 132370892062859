import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
	clientOfferVisibleEmailSelector,
	clientsOffersContactSelector,
	clientsOffersSelectedIdSelector,
} from "@recoil/clientOffers";

import { sendOfferMailMutation } from "@graphQl/mutations/mail";

import {
	ModalSendEmailStyled,
	WarningModal,
	WrapperBodyModal,
} from "@components/modalSendEmail/ModalSendEmail.style";
import { useThemeContext } from "@theme/Theme";

import { Body } from "./body/Body";
import { Contact } from "./contact/Contact";
import { Subject } from "./subject/Subject";
import { SelectMail } from "./selectMail/SelectMail";
import {
	clientsOffersBodyAtom,
	clientsOffersEmailSelectedAtom,
	clientsOffersMailOfferLinkAtom,
	clientsOffersMailQuoteGeneratorLinkAtom,
	clientsOffersSubjectAtom,
} from "@recoil/clientOffers/atom";

export const ModalSendEmail = ({ beforeEmailFunction, afterEmailFunction, offerVersionId }) => {
	const { t } = useTranslation("clientsOffers/modalSendEmail");
	const { mode } = useThemeContext();
	const [loading, setLoading] = useState(false);
	const [isWarningModalVisible, showWarningModal] = useState(false);

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const [visibleEmailModal, setVisibleEmailModal] = useRecoilState(
		clientOfferVisibleEmailSelector
	);
	const clientsOffersBodyValue = useRecoilValue(clientsOffersBodyAtom);
	const clientsOffersContactValues = useRecoilValue(clientsOffersContactSelector);
	// const clientsOffersContactCcValues = useRecoilValue(clientsOffersContactCcSelector);
	// const clientsOffersContactCciValues = useRecoilValue(clientsOffersContactCciSelector);
	const clientsOffersSubjectValue = useRecoilValue(clientsOffersSubjectAtom);
	const clientsOffersEmailSelectedValue = useRecoilValue(clientsOffersEmailSelectedAtom);
	const mailOfferLink = useRecoilValue(clientsOffersMailOfferLinkAtom);
	const setQuoteGeneratorEmail = useSetRecoilState(clientsOffersMailQuoteGeneratorLinkAtom);

	const [sendOfferMail] = sendOfferMailMutation({ offerId, offerVersionId });

	const handleSendEmail = async () => {
		setLoading(true);
		if (beforeEmailFunction) {
			await beforeEmailFunction();
		}
		await sendOfferMail({
			variables: {
				id: clientsOffersEmailSelectedValue,
				to: clientsOffersContactValues.join(),
				// cc: clientsOffersContactCcValues.join(),
				// bcc: clientsOffersContactCciValues.join(),
				subject: clientsOffersSubjectValue,
				body: clientsOffersBodyValue,
				offerId,
			},
		});

		setLoading(false);
		setVisibleEmailModal(false);
		setQuoteGeneratorEmail("");
		if (afterEmailFunction) {
			afterEmailFunction();
		}
	};

	const handleCloseWarningModal = () => {
		showWarningModal(false);
	};

	const handleCloseSendEmailModal = () => {
		setVisibleEmailModal(false);
		setQuoteGeneratorEmail("");
	};

	if (!visibleEmailModal) return null;

	return (
		<>
			<ModalSendEmailStyled
				positionFromTop={"50px"}
				mode={mode}
				visible={visibleEmailModal}
				width={"600px"}
				title={t("New mail")}
				footerType={"2buttons"}
				loading={loading}
				onOk={() => {
					if (!clientsOffersBodyValue.includes(mailOfferLink)) {
						showWarningModal(true);
					} else {
						handleSendEmail();
					}
				}}
				onClickOutside={handleCloseSendEmailModal}
				onCancel={handleCloseSendEmailModal}
				onClose={handleCloseSendEmailModal}
				okText={t("Send")}
				cancelText={t("Cancel")}
			>
				<WrapperBodyModal>
					<SelectMail />
					<Contact />
					<Subject />
					<Body offerVersionId={offerVersionId} />
				</WrapperBodyModal>
			</ModalSendEmailStyled>

			<WarningModal
				visible={isWarningModalVisible}
				footerType={"2buttons"}
				onClose={handleCloseWarningModal}
				onCancel={handleCloseWarningModal}
				onOk={handleSendEmail}
				title={t(
					"The offer link was not detected in the email body, send the email anyway?"
				)}
				okText={t("Send")}
				cancelText={t("Change message")}
				width="450px"
			/>
		</>
	);
};

ModalSendEmail.displayName = "ModalSendEmail";
export default ModalSendEmail;
