import { gql } from "@apollo/client";
import {
	WORKSPACE_ACTIVE_FRAGMENT,
	WORKSPACE_ADDRESS_FRAGMENT,
	WORKSPACE_CITY_FRAGMENT,
	WORKSPACE_COMPANY_NAME_FRAGMENT,
	WORKSPACE_COUNTRY_FRAGMENT,
	WORKSPACE_EMAIL_CONTACT_FRAGMENT,
	WORKSPACE_FROM_CURRENT_USER_FRAGMENT,
	WORKSPACE_IMAGE_PATH_FRAGMENT,
	WORKSPACE_NAME_FRAGMENT,
	WORKSPACE_PHONE_CONTACT_FRAGMENT,
	WORKSPACE_TC_TYPE_FRAGMENT,
	WORKSPACE_VAT_NUMBER_FRAGMENT,
	WORKSPACE_ZIP_CODE_FRAGMENT,
} from "@graphQl/fragments/workspace";

export const GET_WORKSPACES_FROM_CURRENT_USER = gql`
	${WORKSPACE_FROM_CURRENT_USER_FRAGMENT}
	query GetWorkspacesFromCurrentUser {
		getWorkspacesFromCurrentUser {
			...WorkspaceFromCurrentUserFragment
		}
	}
`;

export const GET_WORKSPACE_IMAGE_PATH = gql`
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	query GetWorkspaceImagePath {
		getCurrentWorkspace {
			...WorkspaceImagePathFragment
		}
	}
`;

export const GET_WORKSPACE_NAME_AND_IMAGE_PATH = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_IMAGE_PATH_FRAGMENT}
	query GetWorkspaceNameAndImagePath {
		getCurrentWorkspace {
			...WorkspaceNameFragment
			...WorkspaceImagePathFragment
		}
	}
`;

export const GET_WORKSPACE_TC_TYPE = gql`
	${WORKSPACE_TC_TYPE_FRAGMENT}
	query GetWorkspaceTCType {
		getCurrentWorkspace {
			...WorkspaceTCTypeFragment
		}
	}
`;

export const GET_WORKSPACE_DATA_FOR_NEXT_BUTTON = gql`
	${WORKSPACE_NAME_FRAGMENT}
	${WORKSPACE_TC_TYPE_FRAGMENT}
	query GetWorkspaceDataForNextButton {
		getCurrentWorkspace {
			...WorkspaceNameFragment
			...WorkspaceTCTypeFragment
		}
	}
`;

export const GET_WORKSPACE_ACTIVE = gql`
	${WORKSPACE_ACTIVE_FRAGMENT}
	query GetWorkspaceActive {
		getCurrentWorkspace {
			...WorkspaceActiveFragment
		}
	}
`;

export const GET_WORKSPACE_COMPANY_SETTINGS = gql`
	${WORKSPACE_COMPANY_NAME_FRAGMENT}
	${WORKSPACE_ADDRESS_FRAGMENT}
	${WORKSPACE_ZIP_CODE_FRAGMENT}
	${WORKSPACE_CITY_FRAGMENT}
	${WORKSPACE_COUNTRY_FRAGMENT}
	${WORKSPACE_VAT_NUMBER_FRAGMENT}
	${WORKSPACE_EMAIL_CONTACT_FRAGMENT}
	${WORKSPACE_PHONE_CONTACT_FRAGMENT}
	query GetWorkspaceCompanySettings {
		getCurrentWorkspace {
			...WorkspaceCompanyNameFragment
			...WorkspaceAddressFragment
			...WorkspaceZipCodeFragment
			...WorkspaceCityFragment
			...WorkspaceCountryFragment
			...WorkspaceVatNumberFragment
			...WorkspaceEmailContactFragment
			...WorkspacePhoneContactFragment
		}
	}
`;

export const GET_WORKSPACE_NB_FEATURES = gql`
	query GetWorkspaceNbFeatures {
		getCurrentWorkspace {
			id
			nbFeatures
		}
	}
`;
