import React, { useState, useEffect, useMemo, useReducer } from "react";
import { createPortal } from "react-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Drawer, Button, Input } from "priceit-ui";

import {
	SearchAndCurrentPeriod,
	SearchDiv,
	WrapperColumns,
	WrapperFirstLine,
	WrapperProductsDrawer,
	WrapperSearch,
	WrapperSecondLine,
	WrapperTitle1,
	WrapperTitle2,
	WrapperTitles,
	WrapperFooter,
} from "./ProductsDrawer.style";
import { currentPeriodIdVar } from "@src/cache/cache";
import { averageFixedCostComputation, productsComputation } from "@services/computation";
import { CurrentPeriod } from "@components/headerCustom/periods/currentPeriod/CurrentPeriod";
import TableView from "@components/productsDrawer/components/TableViews/TableView";
import LeftPanel from "@components/productsDrawer/components/LeftPanels/LeftPanel";
import SecondLineHeader from "@components/productsDrawer/components/secondLineHeader/SecondLineHeader";
import { initialState, actions, productsDrawerReducer } from "./reducer/reducer";
import { useProducts } from "@hooks/useProducts/UseProducts";
import { usePeriod } from "@hooks/usePeriod/UsePeriod";
import { useAuth } from "@hooks/useAuth/UseAuth";
import DispatchProductDrawerContext from "./context/DispatchProductDrawerContext";
import { GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID } from "@src/graphQl/queries/userWorkspaceDefault";
import { GET_CATEGORIZATION_BY_PERIOD_ID } from "@src/graphQl/queries/categorizationNewQuery";

export const ProductsDrawer = React.memo(
	({ onCloseDrawer, drawerVisible, productsSelected, onConfirmSelection, title, view, data }) => {
		const { t } = useTranslation("drawer/page");
		const [state, dispatch] = useReducer(productsDrawerReducer, initialState);
		const productDrawerContext = { state, dispatch };
		const currentPeriodId = useReactiveVar(currentPeriodIdVar);

		const [searchInput, setSearchInput] = useState("");
		// const [checked, setChecked] = useState([]);

		const { data: dataCategorization } = useQuery(GET_CATEGORIZATION_BY_PERIOD_ID, {
			skip: !currentPeriodId,
			variables: {
				id: currentPeriodId,
			},
		});

		const { auth } = useAuth();

		const { data: dataUserWorkspaceDefault } = useQuery(
			GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID,
			{
				skip: !auth?.id,
				variables: { userId: auth?.id },
			}
		);

		const { products } = useProducts();
		const { period, monthDifference } = usePeriod(currentPeriodId);
		const listOfProducts = useMemo(() => {
			if (products) {
				return [...products];
			}
			return [];
		}, [products]);

		useEffect(() => {
			dispatch({
				type: actions.RESET_SELECTION,
			});
			dispatch({
				type: actions.UPDATE_PRODUCTS_SELECTED,
				value: productsSelected,
			});
		}, [productsSelected, drawerVisible]);

		// useEffect(() => {
		// 	if (state.temporaryProductsSelected.length === listOfProducts?.length) {
		// 		setChecked(["all"]);
		// 	} else {
		// 		setChecked([]);
		// 	}
		// }, [listOfProducts, state.temporaryProductsSelected]);
		//
		// const onClickProduct = (item, isSelected) => {
		// 	if (isSelected) {
		// 		dispatch({
		// 			type: actions.UPDATE_PRODUCTS_SELECTED,
		// 			value: state.temporaryProductsSelected.filter(element => element.id !== item.id),
		// 		});
		// 	} else {
		// 		dispatch({
		// 			type: actions.UPDATE_PRODUCTS_SELECTED,
		// 			value: state.temporaryProductsSelected.concat([item]),
		// 		});
		// 	}
		// };

		const listOfCategories = useMemo(() => {
			const categorizations = dataCategorization?.getCategorizationByPeriodId || [];
			return categorizations
				.map(categorization => {
					return categorization.category.map(x => {
						const productsArr = [];
						listOfProducts.forEach(y => {
							if (y.categories.find(z => z.id === x.id)) {
								productsArr.push(y);
							}
						});
						return { ...x, products: productsArr };
					});
				})
				.flat(2);
		}, [dataCategorization, listOfProducts]);

		const onClickCategory = (item, isSelected) => {
			let newProducts = [...state.temporaryProductsSelected];
			const cat = listOfCategories.find(x => x.id === item.id);
			const filterProducts = cat.products.filter(
				element =>
					element.periodId ===
					dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.periodId
			);
			if (isSelected) {
				newProducts = newProducts.filter(
					element => !filterProducts.map(x => x.id).includes(element.id)
				);
				dispatch({
					type: actions.UPDATE_PRODUCTS_SELECTED,
					value: newProducts,
				});
			} else {
				newProducts = newProducts
					.filter(element => !filterProducts.map(x => x.id).includes(element.id))
					.concat(filterProducts);
				dispatch({
					type: actions.UPDATE_PRODUCTS_SELECTED,
					value: newProducts,
				});
			}
		};

		const selectedCategoryIds = useMemo(() => {
			let j;
			let selectedCategories = [];
			const unSelectedCategoriesId = [];

			if (
				dataCategorization?.getCategorizationByPeriodId?.length > 0 &&
				listOfProducts?.length > 0 &&
				state.temporaryProductsSelected?.length > 0
			) {
				for (let i = 0; i < listOfProducts.length; i++) {
					const isSelected =
						state.temporaryProductsSelected.findIndex(
							element => element.id === listOfProducts[i].id
						) !== -1;
					if (isSelected) {
						if (listOfProducts[i].categories.length > 0) {
							for (j = 0; j < listOfProducts[i].categories.length; j++) {
								if (
									selectedCategories.findIndex(
										element => element === listOfProducts[i].categories?.[j].id
									) === -1 &&
									unSelectedCategoriesId.findIndex(
										element => element === listOfProducts[i].categories?.[j].id
									) === -1
								) {
									selectedCategories.push(listOfProducts[i].categories[j].id);
								}
							}
						}
					} else {
						for (j = 0; j < listOfProducts[i].categories.length; j++) {
							selectedCategories = selectedCategories.filter(
								element => element !== listOfProducts[i].categories?.[j].id
							);
							unSelectedCategoriesId.push(listOfProducts[i].categories?.[j].id);
						}
					}
				}
			}
			return selectedCategories;
		}, [listOfCategories, listOfProducts, state.temporaryProductsSelected]);

		const averageData = productsComputation(
			state.temporaryProductsSelected || [],
			monthDifference
		);

		const meanFixedCost = averageFixedCostComputation(
			state.temporaryProductsSelected,
			monthDifference,
			period?.profitParameter?.revenue?.realRevenue,
			period?.profitParameter?.totalFixedCosts?.realTotalFixedCosts
		);

		const closeDrawer = () => {
			setSearchInput("");
			onCloseDrawer();
		};

		useEffect(() => {
			dispatch({
				type: actions.RESET_SELECTION,
			});
		}, [currentPeriodId]);

		const portal = createPortal(
			<DispatchProductDrawerContext.Provider value={productDrawerContext}>
				<Drawer
					placement="right"
					onClose={closeDrawer}
					visible={drawerVisible}
					width={"calc(100vw - 80px)"}
					style={{ minWidth: "750px" }}
				>
					<WrapperProductsDrawer>
						<WrapperFirstLine>
							{title ? (
								<WrapperTitles>
									<WrapperTitle1>{title[0]}</WrapperTitle1>
									<WrapperTitle2>{title[1]}</WrapperTitle2>
								</WrapperTitles>
							) : (
								<div></div>
							)}
							<SearchAndCurrentPeriod>
								<WrapperSearch>
									<SearchDiv>
										<Input
											type={"search"}
											mode="dark"
											placeholder="Search"
											onChange={e => setSearchInput(e.target.value)}
										/>
									</SearchDiv>
								</WrapperSearch>
								<CurrentPeriod periodListHover={() => {}} />
							</SearchAndCurrentPeriod>
						</WrapperFirstLine>
						<WrapperSecondLine>
							<SecondLineHeader
								view={view}
								period={period}
								averageData={averageData}
								meanFixedCost={meanFixedCost}
								periodType={state.periodType}
								dispatch={dispatch}
								actions={actions}
								data={data}
								productsSelected={state.temporaryProductsSelected}
							/>
						</WrapperSecondLine>
						<WrapperColumns>
							<LeftPanel
								view={view}
								productsSelected={state.temporaryProductsSelected}
								dataCategorization={dataCategorization}
								selectedCategoryIds={selectedCategoryIds}
								onClickCategory={onClickCategory}
								data={data}
								period={period}
								periodType={state.periodType}
								periodProducts={products.filter(
									element =>
										element.periodId ===
										dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId
											?.periodId
								)}
								listOfProducts={listOfProducts}
							/>
							<TableView
								view={view}
								data={data}
								searchInput={searchInput}
								period={period}
								productsSelected={state.temporaryProductsSelected}
								onChangeProductsSelected={value => {
									dispatch({
										type: actions.UPDATE_PRODUCTS_SELECTED,
										value: value,
									});
								}}
								loadingProducts={false}
								periodType={state.periodType}
								products={products}
							/>
						</WrapperColumns>
						<WrapperFooter>
							<Button
								key="ok1"
								type={"cancel"}
								width={"160px"}
								style={{ margin: "15px 0 15px 20px" }}
								onClick={() => {
									closeDrawer(true);
								}}
							>
								{t("Close and cancel")}
							</Button>
							<Button
								key="ok2"
								type={"secondary"}
								width={"160px"}
								style={{ margin: "15px 0 15px 20px" }}
								onClick={() => {
									onConfirmSelection(state.temporaryProductsSelected);
									closeDrawer(true);
								}}
								showRightArrowIcon
							>
								{view === "productsListView" || view === "productsCardView"
									? t("Confirm selection")
									: view === "fixedCostView"
										? t("Confirm allocation")
										: t("Confirm")}
							</Button>
						</WrapperFooter>
					</WrapperProductsDrawer>
				</Drawer>
			</DispatchProductDrawerContext.Provider>,
			document.body
		);

		if (listOfProducts) {
			return portal;
		}
		return null;
	},
	(prevProps, nextProps) => prevProps.gmfequals(nextProps)
);
ProductsDrawer.whyDidYouRender = true;
ProductsDrawer.displayName = "ProductsDrawer";
