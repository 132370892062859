import React from "react";
import { ThemeComponent, themeList } from "@theme/Theme";
import { createBrowserRouter, BrowserRouter as Router } from "react-router-dom";
import GlobalRoutes from "@light/routes/Routes";
import { useQuery } from "@apollo/client";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { GET_USER_THEME } from "@graphQl/queries/user";

export const router = createBrowserRouter(Router);
export const Main = React.memo(() => {
	const { data: dataUser } = useQuery(GET_USER_THEME);

	const { theme } = dataUser?.getAuth || {};

	return (
		<ThemeComponent mode={theme || "dark"} theme={themeList.theme1}>
			<Router
				basename={
					process.env.LIGHTAPP_BASEPATH ? `/${process.env.LIGHTAPP_BASEPATH}` : undefined
				}
			>
				<GlobalRoutes />
			</Router>
		</ThemeComponent>
	);
});
Main.whyDidYouRender = true;
Main.displayName = "Main";
