import { gql } from "@apollo/client";

export const GET_PERIOD_BY_ID = gql`
	query GetPeriodById($id: ID!) {
		getPeriodById(id: $id) {
			name
			comment
			index
			id
			startDate
			endDate
			estimationFixedCost
			estimationProducts
			estimationVariableCosts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			nbDistributions
			profitParameter {
				revenue {
					id
					realRevenue
					totalRevenueOneShot
					totalRevenueRecurring
					estimatedRevenue
					meanPrice
					totalVolume
					meanVariableCost
					totalVolumeOneShot
					totalVolumeRecurring
					totalVariableCostOneShot
					totalVariableCostRecurring
				}
				totalFixedCosts {
					id
					totalSemiVariableCostPart
					totalSemiVariableCostOneShot
					totalSemiVariableCostRecurring
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					id
					nbProfitability
					realProfit
					grossProfit
					estimatedProfit
					date
					user
				}
				variableCost {
					id
					variableCosts
					date
					user
				}
				potential {
					id
					nbSpectrum
					nbScenario
					nbFavorites
					date
					user
				}
				projection {
					id
					nbProjection
					nbFavorites
					date
					user
				}
				costBasedInformation {
					id
					nbCostBased
					nbFavorites
					date
					user
				}
			}
		}
	}
`;
export const GET_PERIODS_BY_WORKSPACE_ID = gql`
	query GetPeriodsByWorkspaceId($workspaceId: ID!) {
		getPeriodsByWorkspaceId(workspaceId: $workspaceId) {
			name
			comment
			index
			id
			startDate
			endDate
			estimationFixedCost
			estimationProducts
			estimationVariableCosts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
			nbPackagePricing
			nbDistributions
			workspaceId
			profitParameter {
				revenue {
					id
					realRevenue
					totalRevenueOneShot
					totalRevenueRecurring
					estimatedRevenue
					meanPrice
					totalVolume
					meanVariableCost
					totalVolumeOneShot
					totalVolumeRecurring
					totalVariableCostOneShot
					totalVariableCostRecurring
				}
				totalFixedCosts {
					id
					totalSemiVariableCostPart
					totalSemiVariableCostOneShot
					totalSemiVariableCostRecurring
					realTotalFixedCosts
					estimatedTotalFixedCosts
					date
					user
				}
				profit {
					id
					nbProfitability
					realProfit
					grossProfit
					estimatedProfit
					date
					user
				}
				variableCost {
					id
					variableCosts
					date
					user
				}
				potential {
					id
					nbSpectrum
					nbScenario
					nbFavorites
					date
					user
				}
				projection {
					id
					nbProjection
					nbFavorites
					date
					user
				}
				costBasedInformation {
					id
					nbCostBased
					nbFavorites
					date
					user
				}
			}
		}
	}
`;

export const GET_CURRENT_PERIOD = gql`
	query GetCurrentPeriod {
		currentPeriod @client
	}
`;

export const GET_USER_WORKSPACE_DEFAULT_BY_PERIOD_ID_AND_USER_WORKSPACE_DEFAULT_ID = gql`
	query getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId(
		$userWorkspaceDefaultId: ID!
		$periodId: ID!
	) {
		getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId(
			userWorkspaceDefaultId: $userWorkspaceDefaultId
			periodId: $periodId
		) {
			id
			costBasedId
			leverId
			potentialId
			profitabilityId
			simulationId
			distributionId
		}
	}
`;
