import { selector, selectorFamily } from "recoil";
import {
	numericalDiscountRangeStateAtom,
	numericalDiscountStateAtom,
	packDisabledDueToNumericalDiscountDependencyAtom,
	volumeDiscountIdStateAtom,
	volumeDiscountStateAtom,
} from "./atom";

import defaultText from "@services/defaultText";

export const volumeDiscountStateByPriceModelIdSelector = selectorFamily({
	key: "volumeDiscountStateByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(volumeDiscountStateAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, { newValue, listOfVolumeDiscountRange, priceModelMetric, basedOn }) => {
			set(volumeDiscountStateAtom, {
				...get(volumeDiscountStateAtom),
				[priceModelId]: newValue,
			});
			if (!!priceModelMetric) {
				if (listOfVolumeDiscountRange?.length) {
					const volumeDiscountPerRange = basedOn === defaultText.range;

					if (!volumeDiscountPerRange) {
						const volumeStateRangeSelected = listOfVolumeDiscountRange.find(
							volumeRange =>
								newValue >= volumeRange.from &&
								(newValue <= volumeRange.to || !volumeRange.to)
						);
						set(volumeDiscountIdStateAtom, {
							...get(volumeDiscountIdStateAtom),
							[priceModelId]: volumeStateRangeSelected?.id,
						});
					} else {
						set(volumeDiscountIdStateAtom, {
							...get(volumeDiscountIdStateAtom),
							[priceModelId]: newValue,
						});
					}
				}
			}
		},
});

export const volumeDiscountVolumeLinkedStateByPriceModelIdSelector = selectorFamily({
	key: "volumeDiscountVolumeLinkedStateByPriceModelIdSelector",
	set:
		priceModelId =>
		({ get, set }, { newValue, linkedVolumePriceMetricsAndVolumeReference }) => {
			if (linkedVolumePriceMetricsAndVolumeReference.shouldLinkVolumes) {
				const stateAtom = get(volumeDiscountStateAtom);
				const newStates = {};
				Object.keys(stateAtom || {}).forEach(pmId => {
					if (
						linkedVolumePriceMetricsAndVolumeReference[pmId]?.priceMetric ===
							linkedVolumePriceMetricsAndVolumeReference[priceModelId]?.priceMetric &&
						linkedVolumePriceMetricsAndVolumeReference[pmId]?.volumeReference ===
							linkedVolumePriceMetricsAndVolumeReference[priceModelId]
								?.volumeReference
					) {
						newStates[pmId] = newValue;
					} else {
						newStates[pmId] = stateAtom[pmId];
					}
				});

				set(volumeDiscountStateAtom, newStates);
			}
		},
});

export const numericalDiscountsStateByPriceModelIdSelector = selectorFamily({
	key: "numericalDiscountsStateByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(numericalDiscountStateAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			set(numericalDiscountStateAtom, {
				...get(numericalDiscountStateAtom),
				[priceModelId]: newValue,
			});
		},
});

export const numericalDiscountRangesStateByPriceModelIdSelector = selectorFamily({
	key: "numericalDiscountRangesStateByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) => {
			return get(numericalDiscountRangeStateAtom)?.[priceModelId];
		},
});

export const numericalDiscountRangeStateByPriceModelIdAndUnitMetricSelector = selectorFamily({
	key: "numericalDiscountRangeStateByPriceModelIdAndVolumeDiscountIdSelector",
	get:
		({ priceModelId, uuid }) =>
		({ get }) => {
			return get(numericalDiscountRangeStateAtom)?.[priceModelId]?.[uuid];
		},
	set:
		({ priceModelId, uuid }) =>
		({ get, set }, volumeRangeId) => {
			const atom = get(numericalDiscountRangeStateAtom);
			set(numericalDiscountRangeStateAtom, {
				...(atom || {}),
				[priceModelId]: {
					...(atom?.[priceModelId] || {}),
					[uuid]: {
						value: volumeRangeId,
					},
				},
			});
		},
});

export const numericalDiscountStateByPriceModelIdAndUnitMetricSelector = selectorFamily({
	key: "numericalDiscountStateByPriceModelIdAndVolumeDiscountIdSelector",
	get:
		({ priceModelId, uuid }) =>
		({ get }) => {
			return get(numericalDiscountStateAtom)?.[priceModelId]?.[uuid];
		},
	set:
		({ priceModelId, uuid }) =>
		({ get, set }, { newValue, volumeRangeId, inputRangeId }) => {
			const atom = get(numericalDiscountStateAtom);
			set(numericalDiscountStateAtom, {
				...(atom || {}),
				[priceModelId]: {
					...(atom?.[priceModelId] || {}),
					[uuid]: {
						type: volumeRangeId ? "dropdown" : "input",
						value: newValue ?? volumeRangeId,
					},
				},
			});
			if (inputRangeId) {
				set(
					numericalDiscountRangeStateByPriceModelIdAndUnitMetricSelector({
						priceModelId,
						uuid,
					}),
					inputRangeId
				);
			}
		},
});

export const initNumericalDiscountSelector = selector({
	key: "initNumericalDiscountSelector",
	get: ({ get }) => 1,
	set: ({ get, set }, priceModel) => {
		const numericalDiscounts =
			priceModel?.discountRule?.filter(
				rule => !!rule?.volumeDiscount && !!rule?.volumeDiscount?.unitMetric
			) || [];
		const numericalRangeState = {};
		const numericalInitData = numericalDiscounts.reduce((data, numericalDiscount) => {
			const sortedVolumeRanges = [...numericalDiscount?.volumeDiscount?.volumeRange]?.sort(
				(rangeA, rangeB) => rangeA?.index - rangeB?.index
			);
			const defaultRange = sortedVolumeRanges?.find(range => range.isDefaultSelected)?.id;
			const type = numericalDiscount?.volumeDiscount?.displayedAs;
			const defaultValue =
				type === "dropdown" ? defaultRange : numericalDiscount?.defaultValue;
			const firstRange = sortedVolumeRanges?.[0];
			const value = defaultValue ?? (type === "dropdown" ? firstRange?.id : firstRange?.from);
			const rangeState = numericalDiscount.volumeDiscount.volumeRange.find(
				range => value >= range.from && value <= range.to
			)?.id;
			if (rangeState) {
				numericalRangeState[numericalDiscount.volumeDiscount?.uuid] = {
					value: rangeState,
				};
			}
			data[numericalDiscount.volumeDiscount?.uuid] = {
				type,
				value,
			};
			return data;
		}, {});

		set(numericalDiscountStateAtom, {
			...get(numericalDiscountStateAtom),
			[priceModel?.id]: numericalInitData,
		});

		if (Object.keys(numericalRangeState).length) {
			set(numericalDiscountRangeStateAtom, {
				...get(numericalDiscountRangeStateAtom),
				[priceModel?.id]: numericalRangeState,
			});
		}
	},
});

export const priceModelIdOfLinkedVolumeDiscountSelector = selectorFamily({
	key: "priceModelIdOfLinkedVolumeDiscountSelector",
	get:
		priceModelId =>
		({ get }) => {
			if (get(shouldLinkVolumeSelector)) {
				const priceModelMetric = get(priceMetricByPriceModelIdSelector(priceModelId));
				const volumeReference = get(volumeReferenceByPriceModelIdSelector(priceModelId));
				const volumeDiscountState = get(volumeDiscountStateAtom);
				const priceModelIds = Object.keys(volumeDiscountState || {});
				return priceModelIds.filter(
					pmId =>
						get(priceMetricByPriceModelIdSelector(pmId)) === priceModelMetric &&
						get(volumeReferenceByPriceModelIdSelector(pmId)) === volumeReference
				);
			}
			return [priceModelId];
		},
});

export const packDisabledDueToNumericalDiscountDependencyByPriceModelIdSelector = selectorFamily({
	key: "packDisabledDueToNumericalDiscountDependencyByPriceModelIdSelector",
	get:
		({ priceModelId }) =>
		({ get }) =>
			get(packDisabledDueToNumericalDiscountDependencyAtom)?.[priceModelId],
	set:
		({ priceModelId }) =>
		({ get, set }, { discountId, newValue }) => {
			const atom = get(packDisabledDueToNumericalDiscountDependencyAtom);

			set(packDisabledDueToNumericalDiscountDependencyAtom, {
				...atom,
				[priceModelId]: {
					...atom?.[priceModelId],
					[discountId]: newValue,
				},
			});
		},
});
