import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useParams } from "react-router";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
	currentCostBasedIdVar,
	currentDistributionIdVar,
	currentLeverIdVar,
	currentPeriodIdVar,
	currentPotentialIdVar,
	currentProfitabilityIdVar,
	currentProjectionIdVar,
	currentUserWorkspaceDefaultIdVar,
	currentUserWorkspacePeriodDefaultIdVar,
} from "@src/cache/cache";
import { WrapperGlobal } from "./CurrentPeriod.style";
import { PeriodList } from "../periodList/periodList";
import { PeriodsDrawer } from "../periodsDrawer/PeriodsDrawer";
import {
	GET_PERIODS_BY_WORKSPACE_ID,
	GET_USER_WORKSPACE_DEFAULT_BY_PERIOD_ID_AND_USER_WORKSPACE_DEFAULT_ID,
} from "../graphQl/query";
import { updateUserWorkspaceDefaultMutation } from "../graphQl/mutation";
import { PeriodComponent } from "@components/headerCustom/periods/periodComponent/PeriodComponent";
import { usePeriod } from "@hooks/usePeriod/UsePeriod";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID } from "@src/graphQl/queries/userWorkspaceDefault";

export const CurrentPeriod = ({ periodListHover, openPeriodDrawer, setOpenPeriodDrawer }) => {
	const { t } = useTranslation("shared/header/currentPeriod");
	const currentPeriodId = useReactiveVar(currentPeriodIdVar);
	const currentUserWorkspaceDefaultId = useReactiveVar(currentUserWorkspaceDefaultIdVar);
	const { workspaceId } = useParams();

	const { auth, loadingAuth } = useAuth();

	const {
		loading: loadingUserWorkspaceDefault,
		error: errorUserWorkspaceDefault,
		data: dataUserWorkspaceDefault,
	} = useQuery(GET_USER_WORKSPACE_DEFAULT_PERIOD_ID_BY_USER_ID, {
		skip: loadingAuth || !auth?.id,
		variables: { userId: auth?.id },
	});

	const {
		loading: loadingPeriods,
		error: errorPeriods,
		data: dataPeriods,
	} = useQuery(GET_PERIODS_BY_WORKSPACE_ID, {
		variables: { workspaceId },
	});

	const updateUserWorkspaceDefault = updateUserWorkspaceDefaultMutation();

	useEffect(() => {
		if (
			!loadingUserWorkspaceDefault &&
			!loadingAuth &&
			!dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.periodId &&
			dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.id &&
			dataPeriods?.getPeriodsByWorkspaceId?.[0]?.id &&
			!!dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId.id
		) {
			updateUserWorkspaceDefault({
				variables: {
					updateUserWorkspaceDefault: {
						id: dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId.id,
						periodId: dataPeriods?.getPeriodsByWorkspaceId?.[0]?.id,
					},
				},
			});
		}
	}, [
		dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.periodId,
		dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.id,
		dataPeriods?.getPeriodsByWorkspaceId?.[0]?.id,
		loadingUserWorkspaceDefault,
		loadingAuth,
	]);

	useEffect(() => {
		if (!loadingUserWorkspaceDefault) {
			currentPeriodIdVar(
				dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.periodId ||
					dataPeriods?.getPeriodsByWorkspaceId?.[0]?.id ||
					"0"
			);

			currentUserWorkspaceDefaultIdVar(
				dataUserWorkspaceDefault?.getUserWorkspaceDefaultByUserId?.id || "0"
			);
		}
	}, [dataUserWorkspaceDefault, dataPeriods]);

	const {
		loading: loadingUserWorkspacePeriodDefault,
		error: errorUserWorkspacePeriodDefault,
		data: dataUserWorkspacePeriodDefault,
	} = useQuery(GET_USER_WORKSPACE_DEFAULT_BY_PERIOD_ID_AND_USER_WORKSPACE_DEFAULT_ID, {
		skip: currentPeriodId === "0" || currentUserWorkspaceDefaultId === "0",
		variables: {
			periodId: currentPeriodId,
			userWorkspaceDefaultId: currentUserWorkspaceDefaultId,
		},
	});

	useEffect(() => {
		if (
			dataUserWorkspacePeriodDefault?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId &&
			!loadingUserWorkspacePeriodDefault
		) {
			currentUserWorkspacePeriodDefaultIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId?.id || "0"
			);
			currentProfitabilityIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId
					?.profitabilityId || "0"
			);
			currentPotentialIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId
					?.potentialId || "0"
			);
			currentProjectionIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId
					?.simulationId || "0"
			);
			currentDistributionIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId
					?.distributionId || "0"
			);
			currentCostBasedIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId
					?.costBasedId || "0"
			);
			currentLeverIdVar(
				dataUserWorkspacePeriodDefault
					?.getUserWorkspacePeriodDefaultByPeriodIdAndUserWorkspaceDefaultId?.leverId ||
					"0"
			);
		}
	}, [dataUserWorkspacePeriodDefault]);

	const { period } = usePeriod(currentPeriodId);

	const [drawerVisible, setDrawerVisible] = useState(false);
	useEffect(() => {
		if (openPeriodDrawer) {
			setDrawerVisible(true);
			setOpenPeriodDrawer(false);
		}
	}, [openPeriodDrawer]);

	const [listVisible, setListVisible] = useState(false);
	return (
		<>
			<WrapperGlobal>
				<Tippy
					arrow={false}
					placement={"bottom"}
					appendTo={document.body}
					offset={[0, 0]}
					interactive={true}
					trigger={"mouseenter click"}
					render={() => (
						<PeriodList
							onHover={e => periodListHover(e)}
							openDrawer={() => setDrawerVisible(!drawerVisible)}
						/>
					)}
					onHide={() => {
						periodListHover(period);
						setListVisible(false);
					}}
					disabled={drawerVisible}
					onShow={() => setListVisible(true)}
					popperOptions={{
						modifiers: [
							{
								name: "preventOverflow",
								options: {
									mainAxis: false,
								},
							},
						],
					}}
				>
					<div style={{ width: "350px" }}>
						<PeriodComponent
							period={period}
							openDrawer={() => setDrawerVisible(!drawerVisible)}
							listVisible={listVisible}
							currentPeriod={true}
						/>
					</div>
				</Tippy>
			</WrapperGlobal>
			<PeriodsDrawer
				onCloseDrawer={() => setDrawerVisible(false)}
				drawerVisible={drawerVisible}
			/>
		</>
	);
};
