import React from "react";
import { useQuery } from "@apollo/client";
import { GET_LINKS_CATEGORIZATIONS_DATA_FOR_CLIENT_OFFER } from "@graphQl/queries/categorizationNewQuery";
import { Loader } from "priceit-ui";
import { CategoriesSelectWrapper, CategorySelectWrapper, SelectHeader } from "./Components.style";
import { useTranslation } from "react-i18next";
import { CategoriesSelect } from "./CategoriesSelect";

export const CategoriesWrapper = React.memo(() => {
	const { t } = useTranslation("clientsOffers/newOffer");

	const { loading: loadingCategorizations, data: dataCategorizations } = useQuery(
		GET_LINKS_CATEGORIZATIONS_DATA_FOR_CLIENT_OFFER
	);
	const categorizations = dataCategorizations?.getLinksCategorizations || [];

	if (loadingCategorizations) {
		return <Loader />;
	}

	return (
		<CategoriesSelectWrapper>
			{categorizations.reduce((categoriesSelect, categorization) => {
				if (
					categorization?.categories?.length > 1 ||
					(categorization?.categories?.[0]?.name !== "-" &&
						categorization?.categories?.length > 0)
				) {
					categoriesSelect.push(
						<CategorySelectWrapper key={categorization.id}>
							<SelectHeader>
								{categorization.description ||
									t("Categorization {{index}}", {
										index: categorization.index + 1,
									})}
							</SelectHeader>
							<CategoriesSelect categorization={categorization} />
						</CategorySelectWrapper>
					);
				}
				return categoriesSelect;
			}, [])}
		</CategoriesSelectWrapper>
	);
});
CategoriesWrapper.whyDidYouRender = true;
CategoriesWrapper.displayName = "CategoriesWrapper";
