import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import {
	InputTitle,
	StyledIconButton,
	WrapperInputHeader,
	WrapperLineComponentNeedRowDirection,
	WrapperNbChar,
	WrapperTextAndIconForParameters,
	WrapperTextInputWithLabelForAccessCode,
	WrapperTitleIconAndSwitchForParameters,
} from "./Components.style";
import { Button, Input, ToggleSwitch } from "priceit-ui";
import {
	InputWrapper,
	WrapperInputButtons,
} from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/advancedSettings/AccessRights.style";
import { MAX_LENGTH_PASSWORD } from "@services/constants";
import { useQuery } from "@apollo/client";
import {
	updateTokenEnablePasswordMutation,
	updateTokenPasswordMutation,
} from "@graphQl/mutations/token";
import { GET_OFFER_TOKEN_BY_OFFER_ID } from "@graphQl/queries/offer";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import { useThemeContext } from "@theme/Theme";

export const AccessCode = ({ isDrawer }) => {
	const { t } = useTranslation("specific/clientsOffers");
	const { mode } = useThemeContext();
	const [newPassword, setNewPassword] = useState("");
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const { data: dataOffer } = useQuery(GET_OFFER_TOKEN_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const { tokenObj } = dataOffer?.getOfferById || { tokenObj: {} };
	const { id: tokenId, enablePassword, hasPassword } = tokenObj || {};

	const [updateTokenEnablePassword] = updateTokenEnablePasswordMutation();
	const [updateTokenPassword] = updateTokenPasswordMutation();

	const updatePassword = newPasswordInput => {
		updateTokenPassword({
			variables: {
				token: {
					id: tokenId,
					password: newPasswordInput,
				},
			},
			optimisticResponse: {
				updateToken: {
					__typename: "Token",
					id: tokenId,
					hasPassword: !!newPasswordInput,
				},
			},
		});
		setNewPassword("");
	};

	return (
		<WrapperLineComponentNeedRowDirection isDrawer={isDrawer}>
			<WrapperTitleIconAndSwitchForParameters isDrawer={isDrawer}>
				<WrapperTextAndIconForParameters isMinWidth={isDrawer}>
					<InputTitle>{t("Secured access code")}</InputTitle>
				</WrapperTextAndIconForParameters>
				<ToggleSwitch
					type={"outer"}
					mode={mode}
					checked={enablePassword}
					onClick={() =>
						updateTokenEnablePassword({
							variables: {
								token: {
									id: tokenId,
									enablePassword: !enablePassword,
								},
							},
							optimisticResponse: {
								updateToken: {
									__typename: "Token",
									id: tokenId,
									enablePassword: !enablePassword,
								},
							},
						})
					}
				/>
			</WrapperTitleIconAndSwitchForParameters>
			{!enablePassword ? null : !hasPassword ? (
				<WrapperTextInputWithLabelForAccessCode isDrawer={isDrawer}>
					<WrapperInputHeader>
						<InputTitle>*{t("Access code")}</InputTitle>
						<WrapperNbChar>
							{newPassword?.length || 0}/{MAX_LENGTH_PASSWORD}
						</WrapperNbChar>
					</WrapperInputHeader>
					<InputWrapper>
						<Input
							mode={mode}
							type={"password"}
							maxLength={MAX_LENGTH_PASSWORD}
							width="100%"
							height="30px"
							placeholder={t("Enter a code")}
							onChange={e => setNewPassword(e.target.value)}
							value={newPassword}
							onPressEnter={() => updatePassword(newPassword)}
							tooltipContentShowPasswordEye={t("Show")}
							tooltipContentHidePasswordEye={t("Hide")}
						/>
						{newPassword && (
							<WrapperInputButtons>
								<StyledIconButton
									type={"checkMark"}
									onClick={() => updatePassword(newPassword)}
								/>
								<StyledIconButton
									type={"cross"}
									onClick={() => {
										setNewPassword("");
									}}
								/>
							</WrapperInputButtons>
						)}
					</InputWrapper>
				</WrapperTextInputWithLabelForAccessCode>
			) : (
				<Button
					onClick={() => updatePassword(null)}
					type={"noIcon"}
					mode={mode}
					padding={"0"}
				>
					{t("Change your code")}
				</Button>
			)}
		</WrapperLineComponentNeedRowDirection>
	);
};

AccessCode.displayName = "AccessCode";
export default AccessCode;
