import { gql, useMutation } from "@apollo/client";
import {
	OFFER_CONTACT_EMAIL_FRAGMENT,
	OFFER_CONTACT_FIRST_NAME_FRAGMENT,
	OFFER_CONTACT_FRAGMENT,
	OFFER_CONTACT_ID_FRAGMENT,
	OFFER_CONTACT_LAST_NAME_FRAGMENT,
} from "../fragments/offerContact";

const CREATE_OFFER_CONTACT = gql`
	${OFFER_CONTACT_FRAGMENT}
	mutation CreateOfferContact($createOfferContactInput: CreateOfferContactInput!) {
		createOfferContact(createOfferContactInput: $createOfferContactInput) {
			...OfferContactFragment
		}
	}
`;

export const createOfferContactMutation = () => {
	const [createOfferContact, { loading }] = useMutation(CREATE_OFFER_CONTACT, {
		update(cache, { data: { createOfferContact } }) {
			cache.modify({
				id: `Offer:${createOfferContact.offerId}`,
				fields: {
					offerContacts(existingOfferContactRef) {
						const newOfferContactRef = cache.writeFragment({
							data: createOfferContact,
							fragment: OFFER_CONTACT_ID_FRAGMENT,
							fragmentName: "OfferContactIdFragment",
						});
						return [...existingOfferContactRef, newOfferContactRef];
					},
				},
			});
		},
	});

	return [createOfferContact, { loading }];
};

const UPDATE_OFFER_CONTACT_EMAIL = gql`
	${OFFER_CONTACT_EMAIL_FRAGMENT}
	mutation updateOfferContactEmail($updateOfferContactInput: UpdateOfferContactInput!) {
		updateOfferContact(updateOfferContactInput: $updateOfferContactInput) {
			...OfferContactEmailFragment
		}
	}
`;

export const updateOfferContactEmailMutation = () => {
	const [updateOfferContactEmail, { loading }] = useMutation(UPDATE_OFFER_CONTACT_EMAIL);
	return [updateOfferContactEmail, { loading }];
};

const UPDATE_OFFER_CONTACT_FIRST_NAME = gql`
	${OFFER_CONTACT_FIRST_NAME_FRAGMENT}
	mutation updateOfferContactFirstName($updateOfferContactInput: UpdateOfferContactInput!) {
		updateOfferContact(updateOfferContactInput: $updateOfferContactInput) {
			...OfferContactFirstNameFragment
		}
	}
`;

export const updateOfferContactFirstNameMutation = () => {
	const [updateOfferContactFirstName, { loading }] = useMutation(UPDATE_OFFER_CONTACT_FIRST_NAME);
	return [updateOfferContactFirstName, { loading }];
};

const UPDATE_OFFER_CONTACT_LAST_NAME = gql`
	${OFFER_CONTACT_LAST_NAME_FRAGMENT}
	mutation updateOfferContactLastName($updateOfferContactInput: UpdateOfferContactInput!) {
		updateOfferContact(updateOfferContactInput: $updateOfferContactInput) {
			...OfferContactLastNameFragment
		}
	}
`;

export const updateOfferContactLastNameMutation = () => {
	const [updateOfferContactLastName, { loading }] = useMutation(UPDATE_OFFER_CONTACT_LAST_NAME);
	return [updateOfferContactLastName, { loading }];
};

const DELETE_OFFER_CONTACT = gql`
	mutation DeleteOfferContact($id: ID!) {
		deleteOfferContact(id: $id)
	}
`;

export const deleteOfferContactMutation = offerId => {
	const [deleteOfferContact, { loading, error }] = useMutation(DELETE_OFFER_CONTACT, {
		update(cache, { data: { deleteOfferContact } }) {
			const normalizedId = cache.identify({
				id: deleteOfferContact,
				__typename: "OfferContact",
			});
			cache.modify({
				id: cache.identify({
					id: offerId,
					__typename: "Offer",
				}),
				fields: {
					offerContacts(existingOfferContactRef) {
						return existingOfferContactRef.filter(
							offerContactRef => offerContactRef !== normalizedId
						);
					},
				},
			});
			cache.evict({ id: normalizedId });

			//cache.gc(); Not used beacause the offer in creation is not present in any query
		},
	});
	return [deleteOfferContact, { loading, error }];
};
