import { gql } from "@apollo/client";

export const EXPIRATION_DATE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment ExpirationDateExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		expirationDate
	}
`;

export const NAME_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment NameExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		name
	}
`;

export const OFFER_IMAGE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OfferImageExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		offerImage
	}
`;

export const SUMMARY_PAGE_BACKGROUND_COLOR_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SummaryPageBackgroundColorExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		summaryPageBackgroundColor
	}
`;

export const SUMMARY_PAGE_FONT_COLOR_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SummaryPageFontColorExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		summaryPageFontColor
	}
`;

export const LANGUAGE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment LanguageExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		language
	}
`;

export const ACTIVE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment ActiveExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		active
	}
`;

export const SESSION_ID_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment SessionIdExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		sessionId
	}
`;

export const OFFER_ACCESS_PAGE_THEME_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	fragment OfferAccessPageThemeExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		offerAccessPageTheme
	}
`;

export const EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT = gql`
	${NAME_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_IMAGE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${LANGUAGE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${EXPIRATION_DATE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_BACKGROUND_COLOR_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SUMMARY_PAGE_FONT_COLOR_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${ACTIVE_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${SESSION_ID_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}
	${OFFER_ACCESS_PAGE_THEME_EXPIRED_OR_UNPUBLISHED_PRICE_MODEL_COMBINATION_FRAGMENT}

	fragment ExpiredOrUnpublishedPriceModelCombinationFragment on ExpiredOrUnpublishedPriceModelCombination {
		...NameExpiredOrUnpublishedPriceModelCombinationFragment
		...OfferImageExpiredOrUnpublishedPriceModelCombinationFragment
		...LanguageExpiredOrUnpublishedPriceModelCombinationFragment
		...ExpirationDateExpiredOrUnpublishedPriceModelCombinationFragment
		...OfferAccessPageThemeExpiredOrUnpublishedPriceModelCombinationFragment
		workspace {
			name
			imagePath
		}
		...SummaryPageBackgroundColorExpiredOrUnpublishedPriceModelCombinationFragment
		...SummaryPageFontColorExpiredOrUnpublishedPriceModelCombinationFragment
		...ActiveExpiredOrUnpublishedPriceModelCombinationFragment
		...SessionIdExpiredOrUnpublishedPriceModelCombinationFragment
	}
`;
