import { useMemo } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentPeriodIdVar } from "@src/cache/cache";
import { GET_PRODUCTS_BY_PERIOD_ID } from "@hooks/useProducts/graphQl/query";
import { updateProductMutation, updateProductsMutation } from "@hooks/useProducts/graphQl/mutation";
import { useCachePeriod } from "@hooks/usePeriod/UsePeriod";
import { productsComputation } from "@services/computation";

export const useProducts = () => {
	const currentPeriodId = useReactiveVar(currentPeriodIdVar);
	const updateProducts = updateProductsMutation();
	const updateProduct = updateProductMutation();

	const { monthDifference } = useCachePeriod(currentPeriodId);

	const {
		loading: loadingProducts,
		error: errorProducts,
		data: dataProducts,
	} = useQuery(GET_PRODUCTS_BY_PERIOD_ID, {
		skip: currentPeriodId === "0",
		variables: {
			periodId: currentPeriodId,
		},
	});

	const products = useMemo(
		() =>
			(dataProducts?.getProductsByPeriodId
				? [...dataProducts?.getProductsByPeriodId]
				: []
			)?.sort((a, b) => a?.index - b?.index),
		[dataProducts]
	);

	const averageData = useMemo(
		() => productsComputation(dataProducts?.getProductsByPeriodId || [], monthDifference),
		[dataProducts?.getProductsByPeriodId, monthDifference]
	);

	return {
		products,
		errorProducts,
		loadingProducts,
		updateProducts,
		updateProduct,
		averageData,
	};
};
