import styled from "styled-components";

export const Wrapper = styled.div`
	width: 100%;
	padding: 10px 0;
	margin: 10px 0;
	border-top: 1px solid #5c6f7e;
`;

export const WrapperCheckboxesAndTitle = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const WrapperCheckbox = styled.div`
	margin: 10px 0;
`;
