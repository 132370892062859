import React from "react";

import {
	Counter,
	Label,
	StyledTextArea,
	WrapperBody,
	WrapperLabelAndCounter,
	WrapperLabelAndInput,
} from "./LabelAndTextAreaComponent.style";
import { useTranslation } from "react-i18next";

export const LabelAndTextAreaComponent = React.memo(
	({
		width,
		label,
		maxLength,
		hideMaxLength,
		value,
		setValue,
		error,
		errorTooltipContent,
		onFocus,
		onBlur,
		disabled,
		mode,
		isMandatory,
		hidden = false,
	}) => {
		const { t } = useTranslation("clientsOffers/modalSendEmail");
		return (
			<WrapperLabelAndInput width={width}>
				<WrapperLabelAndCounter disabled={disabled} hidden={hidden}>
					<Label>
						{isMandatory ? "*" : null}
						{label}
						{!isMandatory ? t("(optional)") : null}
					</Label>
					{maxLength && !hideMaxLength ? (
						<Counter>
							{value?.length ?? 0}/{maxLength}
						</Counter>
					) : null}
				</WrapperLabelAndCounter>
				<WrapperBody>
					<StyledTextArea
						maxLength={maxLength}
						mode={mode}
						value={value}
						width={"100%"}
						noCenter
						tooltipMaxWidth={"445px"}
						maxHeight={"275px"}
						height={"275px"}
						lineHeight={25}
						fontWeight={200}
						error={error}
						disableTooltip={true}
						errorTooltipContent={errorTooltipContent}
						onChange={e => {
							setValue(e?.target?.value);
						}}
						onFocus={onFocus}
						onBlur={onBlur}
					/>
				</WrapperBody>
			</WrapperLabelAndInput>
		);
	}
);
LabelAndTextAreaComponent.whyDidYouRender = true;
LabelAndTextAreaComponent.displayName = "LabelAndTextAreaComponent";
