import { metrics } from "./text";
import { useTranslation } from "react-i18next";
import { priceMetricTrad } from "@services/i18n";
import { normalizeString } from "@src/services/text";

const getAllPossibility = arr => {
	if (arr.length === 1) {
		return arr[0];
	}
	const result = [];
	const allCasesOfRest = getAllPossibility(arr.slice(1));
	for (let i = 0; i < allCasesOfRest.length; i++) {
		for (let j = 0; j < arr[0].length; j++) {
			result.push(arr[0][j] * allCasesOfRest[i]);
		}
	}
	return result;
};

export const determinePricePointComputation = (
	allVolumeDiscounts,
	allEngagementDiscounts,
	allPaymentTermsDiscounts,
	allSpecialDiscounts,
	allAdditionalValue
) => {
	const discountArrays = [];

	if (allVolumeDiscounts.length > 0) {
		discountArrays.push(allVolumeDiscounts);
	}
	if (allEngagementDiscounts.length > 0) {
		const engagements = [...allEngagementDiscounts?.filter(el => el.length > 0)];
		if (engagements.length > 0) {
			discountArrays.push(engagements);
		}
	}
	if (allPaymentTermsDiscounts.length > 0) {
		discountArrays.push(allPaymentTermsDiscounts);
	}
	if (allSpecialDiscounts.length > 0) {
		allSpecialDiscounts.forEach(element => discountArrays.push([element]));
	}
	if (allAdditionalValue.length > 0) {
		allAdditionalValue.forEach(element => discountArrays.push([element]));
	}

	let arrayLoopFunction = (price, priceIndex, allPrices) => {};

	if (discountArrays.length === 0) {
		arrayLoopFunction = (price, priceIndex, allPrices) =>
			allPrices.push(Math.round(price * 100) / 100);
	} else {
		arrayLoopFunction = (price, priceIndex, allPrices) => {
			const discountsToMultiply = [];
			discountArrays.forEach(discountArray => {
				discountArray.forEach(discounts => {
					discountsToMultiply.push(
						discounts.map(discountRange => discountRange[priceIndex])
					);
				});
			});

			const allDiscountPossibility = getAllPossibility(discountsToMultiply);

			allDiscountPossibility.forEach(discount => {
				allPrices.push((Math.round(price * 100) / 100) * discount);
			});
		};
	}
	return arrayLoopFunction;
};

export const computePriceOfPackage = packs => {
	const prices = [];
	[...packs]
		?.sort((a, b) => a.index - b.index)
		?.forEach(pack => {
			if (pack.type === "price" && pack.price > 0) {
				prices.push({
					name: pack.name,
					price: pack.price,
				});
			}
		});
	return prices;
};

export const computeNumberOfPricePoints = (
	prices,
	tableVolumeDiscount,
	tableEngagementDiscount,
	tablePaymentTermsDiscount
) => {
	const allVolumeDiscounts = [
		...tableVolumeDiscount.map(volumeDiscount =>
			volumeDiscount.data.map(range =>
				range.packs
					.sort((a, b) => a.packIndex - b.packIndex)
					.map(pack => 1 - pack.priceDiscount / 100)
			)
		),
	];

	const allEngagementDiscounts =
		tableEngagementDiscount?.length > 0
			? [
					...tableEngagementDiscount.map(engagementDiscount =>
						engagementDiscount.data.map(range =>
							range.packs
								.sort((a, b) => a.packIndex - b.packIndex)
								.map(pack => 1 - pack.priceDiscount / 100)
						)
					),
				]
			: [];

	const allPaymentTermsDiscounts = [
		...tablePaymentTermsDiscount.map(paymentTermsDiscount =>
			paymentTermsDiscount.data.map(range =>
				range.packs
					.sort((a, b) => a.packIndex - b.packIndex)
					.map(pack => 1 - pack.priceDiscount / 100)
			)
		),
	];

	const allPrices = [];
	const arrayLoopFunction = determinePricePointComputation(
		allVolumeDiscounts,
		allEngagementDiscounts,
		allPaymentTermsDiscounts
	);
	prices
		.map(price => price.price)
		.forEach((price, priceIndex) => {
			arrayLoopFunction(price, priceIndex, allPrices);
		});

	return [...new Set(allPrices)].length || 0;
};

export const computeExtremaPricePoint = (
	packs,
	volumeReference,
	tableVolumeDiscount,
	tableEngagementDiscount,
	tablePaymentTermsDiscount,
	timeMetric,
	basedOn
) => {
	const { t } = useTranslation("priceModel/page");

	const { textTimeMetricBasic } = metrics();

	let dataMinPricePoint = {
		price: 0,
		name: null,
		volume: null,
		engagement: null,
		paymentTerms: null,
	};

	let dataMaxPricePoint = {
		price: 0,
		name: null,
		volume: null,
		engagement: null,
		paymentTerms: null,
	};

	if (packs) {
		const prices = packs.map(pack => ({
			id: pack.id,
			minPrice: {
				price: pack.price / volumeReference,
				name: pack.name,
				volume: null,
				engagement: null,
				paymentTerms: null,
			},
			maxPrice: {
				price: pack.price / volumeReference,
				name: pack.name,
				volume: null,
				engagement: null,
				paymentTerms: null,
			},
		}));

		tableVolumeDiscount.forEach(discount => {
			discount.data.forEach(range => {
				range.packs.forEach(pack => {
					const packPrice = prices.find(x => x.id === pack.packId);

					if (packPrice) {
						const minPriceTotal =
							basedOn === "unit" ? pack.unitPrice : pack.pricePerUserMin;
						if (packPrice.minPrice.price >= minPriceTotal) {
							packPrice.minPrice.price = minPriceTotal;
							packPrice.minPrice.volume = `volume: ${range.range}`;
						}
						const maxPriceTotal =
							basedOn === "unit" ? pack.unitPrice : pack.pricePerUserMax;
						if (packPrice.maxPrice.price <= maxPriceTotal) {
							packPrice.maxPrice.price = maxPriceTotal;
							packPrice.maxPrice.volume = `volume: ${range.range}`;
						}
					}
				});
			});
		});

		const prices2 = prices.map(data => ({
			id: data.id,
			minPrice: { ...data.minPrice },
			maxPrice: { ...data.maxPrice },
		}));

		tableEngagementDiscount.forEach(discount => {
			discount.data.forEach(range => {
				range.packs.forEach(pack => {
					const packPrice = prices.find(x => x.id === pack.packId);
					const packPrice2 = prices2.find(x => x.id === pack.packId);

					if (packPrice) {
						const minPriceTotal =
							packPrice2.minPrice.price * (1 - pack.priceDiscount / 100);
						if (packPrice.minPrice.price >= minPriceTotal) {
							packPrice.minPrice.price = minPriceTotal;
							packPrice.minPrice.engagement =
								timeMetric === "onlyOnce"
									? t("No engagement rules")
									: `${t("engagement")}: ${range.duration} ${
											textTimeMetricBasic[timeMetric]
										}`;
						}
						const maxPriceTotal =
							packPrice.maxPrice.price * (1 - pack.priceDiscount / 100);
						if (packPrice.maxPrice.price <= maxPriceTotal) {
							packPrice.maxPrice.price = maxPriceTotal;
							packPrice.maxPrice.engagement =
								timeMetric === "onlyOnce"
									? t("No engagement rules")
									: `${t("engagement")}: ${range.duration} ${
											textTimeMetricBasic[timeMetric]
										}`;
						}
					}
				});
			});
		});

		const prices3 = prices.map(data => ({
			id: data.id,
			minPrice: { ...data.minPrice },
			maxPrice: { ...data.maxPrice },
		}));

		tablePaymentTermsDiscount.forEach(discount => {
			discount.data.forEach(range => {
				range.packs.forEach(pack => {
					const packPrice = prices.find(x => x.id === pack.packId);
					const packPrice3 = prices3.find(x => x.id === pack.packId);
					if (packPrice) {
						const minPriceTotal =
							packPrice3.minPrice.price * (1 - pack.priceDiscount / 100);
						if (packPrice.minPrice.price >= minPriceTotal) {
							packPrice.minPrice.price = minPriceTotal;
							packPrice.minPrice.paymentTerms =
								timeMetric === "onlyOnce" || !range.term
									? t("No payment terms rules")
									: `${t("payment terms")}: ${range.term}`;
						}
						const maxPriceTotal =
							packPrice.maxPrice.price * (1 - pack.priceDiscount / 100);
						if (packPrice.maxPrice.price <= maxPriceTotal) {
							packPrice.maxPrice.price = maxPriceTotal;
							packPrice.maxPrice.paymentTerms =
								timeMetric === "onlyOnce" || !range.term
									? t("No payment terms rules")
									: `${t("payment terms")}: ${range.term}`;
						}
					}
				});
			});
		});

		if (prices && prices.length > 0) {
			dataMinPricePoint = prices.reduce((prev, current) =>
				prev?.minPrice?.price < current?.minPrice?.price ? prev : current
			)?.minPrice;

			dataMaxPricePoint = prices.reduce((prev, current) =>
				prev?.maxPrice?.price > current?.maxPrice?.price ? prev : current
			)?.maxPrice;
		}
	}

	return [dataMinPricePoint, dataMaxPricePoint];
};

export const timeMetrics = [
	{ key: "onlyOnce", value: "Non-recurring", color: "#E657DC" },
	{ key: "month", value: "Per month", color: "#5790E6" },
	{ key: "quarter", value: "Per quarter", color: "#E5DC5C" },
	{ key: "semester", value: "Per semester", color: "#78CE5E" },
	{ key: "year", value: "Per year", color: "#FFA631" },
];

export const packsColor = ["#0C62E3", "#2D7DF6", "#4A90F9", "#80B1FB", "#9BC2FC"];

export const findUniqueColor = (packs, index) => {
	for (let i = 0; i < packsColor.length; i++) {
		if (!packs.find(element => element.color === packsColor[i])) {
			return packsColor[i];
		}
	}
	return packs[index];
};

export const priceModelTabsEnum = {
	PROPERTIES: "PROPERTIES",
	CONFIGURE_YOUR_OFFER: "CONFIGURE_YOUR_OFFER",
	DIFFERENTIATE_THE_RULES: "DIFFERENTIATE_THE_RULES",
	DEPENDENCIES: "DEPENDENCIES",
	PRICE_COMMUNICATION: "PRICE_COMMUNICATION",
	SIMULATOR: "SIMULATOR",
};

export const computeUnitPriceForRange = ({
	currentVolumeRange,
	range,
	pack,
	isInputNotRange,
	volumeState,
}) => {
	const packPriceWithTvaHandle = pack.price;

	const price = packPriceWithTvaHandle * (1 + (range?.discount || 0) / 100);

	const minNumberOfUnits = isInputNotRange ? volumeState : currentVolumeRange?.from;
	const maxNumberOfUnits = isInputNotRange ? volumeState : currentVolumeRange?.to || null;

	const maxUnitPrice = price / minNumberOfUnits;
	const minUnitPrice = maxNumberOfUnits ? price / maxNumberOfUnits : 0;

	return { minUnitPrice, maxUnitPrice, minNumberOfUnits, maxNumberOfUnits };
};

export const computeSavesForVolumeDiscountRangeForOnePack = ({
	firstRange,
	currentVolumeRange,
	volumeDiscountRange,
	pack,
	isInputNotRange,
	volumeState,
}) => {
	const range = volumeDiscountRange?.find(el => el.packId === pack.id);

	const { maxUnitPrice: unitPriceFirstRange } = computeUnitPriceForRange({
		currentVolumeRange: firstRange,
		range: { discount: 0 },
		pack,
		isInputNotRange: false,
		volumeState: null,
	});

	const {
		maxUnitPrice: maxUnitPriceCurrentRange,
		maxNumberOfUnits: maxNumberOfUnitsCurrentRange,
		minUnitPrice: minUnitPriceCurrentRange,
		minNumberOfUnits: minNumberOfUnitsCurrentRange,
	} = computeUnitPriceForRange({
		currentVolumeRange,
		range,
		pack,
		isInputNotRange,
		volumeState,
	});

	const minPercentageSavedPerUnit = 1 - maxUnitPriceCurrentRange / unitPriceFirstRange;
	const minSavedAmountPerUnit = unitPriceFirstRange * minPercentageSavedPerUnit;
	const minTotalSavedAmount = minSavedAmountPerUnit * minNumberOfUnitsCurrentRange;

	const maxPercentageSavedPerUnit = 1 - minUnitPriceCurrentRange / unitPriceFirstRange;
	const maxSavedAmountPerUnit = unitPriceFirstRange * maxPercentageSavedPerUnit;
	const maxTotalSavedAmount = maxSavedAmountPerUnit * maxNumberOfUnitsCurrentRange;

	return {
		minPercentageSavedPerUnit,
		minSavedAmountPerUnit,
		minTotalSavedAmount,
		maxPercentageSavedPerUnit,
		maxSavedAmountPerUnit,
		maxTotalSavedAmount,
	};
};

export const computeSavesForVolumeDiscountRangeForAllPacks = ({
	firstRange,
	currentVolumeRange,
	volumeDiscountRange,
	packs,
	isInputNotRange,
	volumeState,
}) => {
	const saves = {};
	packs.forEach(pack => {
		if (pack.type !== "hidden") {
			const {
				minPercentageSavedPerUnit,
				minSavedAmountPerUnit,
				minTotalSavedAmount,
				maxPercentageSavedPerUnit,
				maxSavedAmountPerUnit,
				maxTotalSavedAmount,
			} = computeSavesForVolumeDiscountRangeForOnePack({
				firstRange,
				currentVolumeRange,
				volumeDiscountRange,
				pack,
				isInputNotRange,
				volumeState,
			});
			saves[pack.id] = {
				minPercentageSavedPerUnit,
				minSavedAmountPerUnit,
				minTotalSavedAmount,
				maxPercentageSavedPerUnit,
				maxSavedAmountPerUnit,
				maxTotalSavedAmount,
			};
		}
	});
	return saves;
};

export const computeSavesRangePercentsForVolumeDiscountRange = ({
	firstRange,
	currentVolumeRange,
	volumeDiscountRange,
	packs,
	isInputNotRange,
	volumeState,
}) => {
	const saves = computeSavesForVolumeDiscountRangeForAllPacks({
		firstRange,
		currentVolumeRange,
		volumeDiscountRange,
		packs,
		isInputNotRange,
		volumeState,
	});
	const savedPercents = [
		...Object.values(saves).map(save => save.maxPercentageSavedPerUnit),
		...Object.values(saves).map(save => save.minPercentageSavedPerUnit),
	];
	const minPercentage = Math.min(...savedPercents) * 100;
	const maxPercentage = Math.max(...savedPercents) * 100;
	return { min: minPercentage, max: maxPercentage };
};

export const getPublicLink = ({ token, isGroup, isOffer, workspaceName, workspaceId }) => {
	const encodedWorkspaceName = normalizeString(workspaceName);
	const link = isGroup
		? `/workspaces/${workspaceId}/packagesPricing/`
		: isOffer
			? `/offer/${encodedWorkspaceName}/`
			: "/public/packagePricing/";
	let hostname = window.location.origin;
	if (hostname.includes(process.env.PRICEIT_LIGHT_APP_DNS)) {
		hostname = `https://${process.env.PRICEIT_WEB_APP_DNS}`;
	}
	// if (window.location.origin.includes("localhost")) {
	// 	hostname =
	// 		window.location.origin.split("localhost")[0] +
	// 		"localhost:8000" +
	// 		window.location.origin.split("localhost")[1];
	// }
	return hostname + link + token;
};

export const computeNumericalRangeName = ({ numericalData, numericalRangeData, t }) => {
	const { isRange, unitMetric, pluralMetric, timeMetric } = numericalData || {};
	const { isUnlimited, from, name, index } = numericalRangeData || {};

	const suffix = count => {
		let ret = "";
		if (unitMetric && count <= 1) {
			ret = " " + unitMetric;
		} else if (pluralMetric && count >= 1) {
			ret = " " + pluralMetric;
		}
		if (timeMetric && timeMetric !== "onlyOnce") {
			ret +=
				"/" +
				priceMetricTrad({
					priceMetricList: timeMetrics,
					priceMetric: timeMetric,
				});
		}
		return ret;
	};
	if (index === 0) {
		return t("Not included");
	}

	if (isUnlimited) {
		if (isRange) {
			return t("Above {{rangeFrom}} {{suffixFrom}}", {
				rangeFrom: from.fnb({
					stringText: true,
					decimal: 0,
					zeroIfNan: true,
				}),
				suffixFrom:
					suffix(
						from.fnb({
							stringText: true,
							decimal: 0,
							zeroIfNan: true,
						})
					) ?? "",
			});
		}
		return name;
	} else if (!isUnlimited && index > 0) {
		if (isRange) {
			return t("Up to {{rangeFrom}} {{suffixFrom}}", {
				rangeFrom: from,
				suffixFrom: suffix(from),
			});
		}
		return `${from} ${suffix(from)}`;
	}
};
