import { periodTypesEnum } from "@services/periodMultiplier";
import { timeMetricsEnum } from "@services/constants";

const filterProducts = ({ listOfProducts, categorizationsSelected, orAnd, recurringSelection }) => {
	return listOfProducts.filter(listOfProduct => {
		let filter= true;
		if (recurringSelection === "recurring") {
			if (listOfProduct.timeMetric === timeMetricsEnum.ONLY_ONCE) {
				return false;
			}
		}
		if (recurringSelection === "nonRecurring") {
			if (listOfProduct.timeMetric !== timeMetricsEnum.ONLY_ONCE) {
				return false;
			}
		}
		if (categorizationsSelected.every(x => x.length === 0)) {
			return true;
		}
		
		const categoriesSorted = [...listOfProduct.categories].sort((a,b) => a.categorizationId-b.categorizationId);
		const test = `${
			!categorizationsSelected[0]?.length
				? ""
				: "categorizationsSelected[0].includes(categoriesSorted[0].id)"
		}  ${
			!categorizationsSelected[1]?.length || !categorizationsSelected[0]?.length
				? ""
				: `${orAnd[0] === "and" ? "&&" : "||"}`
		}${
			!categorizationsSelected[1]?.length
				? ""
				: "categorizationsSelected[1].includes(categoriesSorted[1].id)"
		}${
			(!categorizationsSelected[1]?.length && !categorizationsSelected[0]?.length) ||
			!categorizationsSelected[2]?.length
				? ""
				: `${orAnd[1] === "and" ? "&&" : "||"}`
		}${
			!categorizationsSelected[2]?.length
				? ""
				: "categorizationsSelected[2].includes(categoriesSorted[2].id)"
		};`;

		return eval(test);
	});
};

export const actions = {
	UPDATE_PERIOD_TYPE: "UPDATE_PERIOD_TYPE",
	UPDATE_PRODUCTS_SELECTED: "UPDATE_PRODUCTS_SELECTED",
	SET_MODAL_CATEGORIZATION: "SET_MODAL_CATEGORIZATION",

	UPDATE_RECURRING_SELECTION: "UPDATE_RECURRING_SELECTION",
	UPDATE_CATEGORIES_SELECTION: "UPDATE_CATEGORIES_SELECTION",
	UPDATE_AND_OR_SELECT: "UPDATE_AND_OR_SELECT",
	RESET_SELECTION: "RESET_SELECTION",
};

export const initialState = {
	periodType: periodTypesEnum.PERIOD,
	temporaryProductsSelected: [],
	modalCategorization: { show: false, id: null },

	recurringSelection: "recurringAndNonRecurring",
	categorizationsSelected: [[], [], []],
	orAnd: ["and", "and"],
};

export const productsDrawerReducer = (state, action) => {
	const reducerState = { ...state };
	switch (action.type) {
		case actions.UPDATE_PERIOD_TYPE:
			reducerState.periodType = action.value;
			return reducerState;

		case actions.UPDATE_PRODUCTS_SELECTED:
			reducerState.temporaryProductsSelected = [...action.value];
			return reducerState;

		case actions.SET_MODAL_CATEGORIZATION:
			reducerState.modalCategorization = action.value;
			return reducerState;

		case actions.UPDATE_RECURRING_SELECTION:
			reducerState.recurringSelection = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				recurringSelection: reducerState.recurringSelection,
			});
			return reducerState;

		case actions.UPDATE_CATEGORIES_SELECTION:
			reducerState.categorizationsSelected[action.id] = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				recurringSelection: reducerState.recurringSelection,
			});
			return reducerState;

		case actions.RESET_SELECTION:
			reducerState.categorizationsSelected = [[], [], []];
			reducerState.temporaryProductsSelected = [];
			reducerState.recurringSelection = "recurringAndNonRecurring";
			reducerState.orAnd = ["and", "and"];
			return reducerState;

		case actions.UPDATE_AND_OR_SELECT:
			reducerState.orAnd[action.id] = action.value;
			reducerState.temporaryProductsSelected = filterProducts({
				listOfProducts: action.listOfProducts,
				categorizationsSelected: reducerState.categorizationsSelected,
				orAnd: reducerState.orAnd,
				recurringSelection: reducerState.recurringSelection,
			});
			return reducerState;

		default:
			throw new Error("Unexpected action");
	}
};
