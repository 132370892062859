import styled, { css } from "styled-components";
import {
	Button,
	Icon,
	InputNumber,
	Selector,
	SingleSelect,
	ToggleSwitch,
	Tooltipv3,
} from "priceit-ui";
import { customGap, verticalCustomGap } from "@services/Utils.style";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

export const Name = styled.div`
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.secondaryTextColor};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
	${props =>
		props.center &&
		css`
			text-align: center;
		`};
	${props =>
		!props.center &&
		css`
			text-align: left;
		`};
`;

export const Categories = styled.div`
	width: 100%;
	display: flex;

	& > div {
		:not(:last-child) {
			margin-right: 5px;
		}
	}
`;

export const WrapperLinkInput = styled.div`
	display: flex;
	flex: 1;

	& > :not(:last-child) {
		margin-right: 5px;
	}

	& > * {
		flex: 1;
	}
`;

export const WrapperTextInput = styled.div`
	display: flex;
	flex: 1;
	${customGap("10px")};
`;

export const WrapperTextInputWithLabel = styled.div`
	flex: 1;
`;

export const WrapperTextInputWithLabelForAccessCode = styled(WrapperTextInputWithLabel)`
	padding-top: ${props => (!props.isDrawer ? "10px" : "0px")};
`;

export const WrapperInputHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
`;

export const WrapperInputHeaderOfferName = styled(WrapperInputHeader)`
	max-width: 350px;
`;

export const WrapperNbChar = styled.div``;

export const WrapperDropdownHeader = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	align-items: center;
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
`;

export const MultiSelectWrapper = styled.div`
	flex: 1;
`;

export const StyledTooltip = styled(Tooltipv3)`
	${props =>
		props.margin &&
		css`
			margin: ${props.margin};
		`}
`;

const wrapperDrawerParameter = props =>
	(props.isDrawer || props.isPersonalizationSettings) &&
	css`
		display: flex;
		flex-direction: column;
		align-items: ${props.flexStart ? "flex-start" : "flex-end"};
		justify-content: flex-start;
		padding: ${props.index === 0 && props.length === 1 ? "10px 0 0 0" : "10px 0"};
		${customGap("20px")};
	`;

const wrapperPersonalizationSettings = props =>
	props.isPersonalizationSettings &&
	css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		${customGap("20px")};
	`;

const wrapperDrawerParameterTextAndSwitcher = props =>
	(props.isDrawer || props.isPersonalizationSettings) &&
	css`
		margin: 0 10px 0 0;
		padding: ${props.center ? "0" : "0 0 7px 0"};
	`;

export const InputTitle = styled.div`
	color: ${({ theme }) => theme.textColor.primary};
	display: flex;
	text-align: left;
	font: normal normal normal 12px/15px Lato;
	letter-spacing: 0;
	opacity: 1;
	flex-wrap: nowrap;
`;

export const WrapperCompanyName = styled.div`
	flex: ${props => (props.isDrawer ? "3" : "1")};
	${props => wrapperDrawerParameter(props)}
`;

export const DropdownTitle = styled.div`
	padding-right: 5px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
	font: normal normal normal 12px/15px Lato;
	${props => wrapperDrawerParameter(props)}
`;

export const WrapperDealOwner = styled.div`
	flex: ${props => (props.isDrawer ? "3" : "1")};
	${props =>
		props.isDrawer ? wrapperDrawerParameter(props) : wrapperPersonalizationSettings(props)}
`;

export const WrapperDrawerIcon = styled.div`
	margin: 0 24px 0 0;
`;

export const WrapperExpirationDateInputs = styled.div`
	display: flex;
	flex-direction: column;
	${verticalCustomGap("10px")};
`;

export const WrapperDelayInput = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

export const CompanyNameTitleWrapper = styled.div`
	margin: 0 24px 0 0;
`;

export const ContactTitleWrapper = styled.div`
	margin: 0 24px 0 0;
`;

export const OfferVATNumberTitleWrapper = styled.div`
	margin: 0 24px 0 0;
`;

export const OfferLicenseStartDateTitleWrapper = styled.div`
	margin: 0 24px 0 0;
`;

export const WrapperDateInput = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding-top: 10px;
`;

export const WrapperRadioButton = styled.div`
	width: 80px;
`;

export const WrapperDelay = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

export const WrapperEmpty = styled.div`
	width: 80px;
`;

export const WrapperDelayInputAndSelect = styled.div`
	display: flex;
`;

export const StyledInputNumber = styled(InputNumber)`
	margin-left: 10px;
`;

export const StyledSingleSelect = styled(SingleSelect.Group)`
	margin-left: 10px;
`;

export const ExpirationDateLabel = styled.div`
	flex: 1;
	padding-left: 10px;
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
`;

export const WrapperOpeningNotification = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const DrawerParameterText = styled.div`
	display: flex;
	color: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
	font-size: 12px;
	min-width: 130px;
	flex-wrap: wrap;
	${props => wrapperDrawerParameterTextAndSwitcher(props)}
	${props =>
		props.italic &&
		css`
			font-style: italic;
		`}
`;

export const WrapperDropdown = styled.div`
	flex: 1;
`;

export const InputWrapper = styled.div`
	position: relative;
	height: 35px;
	display: flex;
	align-items: center;
`;

export const WrapperInputButtons = styled.div`
	position: absolute;
	right: 10px;
	display: flex;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
`;

export const StyledIconEmptyDocument = styled(Icon)`
	cursor: pointer;
	color: ${({ theme }) => theme.page.secondaryTextColor};
	fill: ${({ theme }) => theme.page.secondaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
		color: ${({ theme }) => theme.page.textColor};
	}
`;

export const DocumentText = styled.div`
	text-align: left;
	text-decoration: underline;
	font-size: 14px;
	cursor: pointer;
	letter-spacing: 0;
	display: flex;
	align-items: center;
	padding-left: 5px;
	opacity: 1;
`;

export const StyledIconAttachement = styled(Icon)``;

export const StyledIconButton = styled(Icon)`
	fill: ${({ theme }) => theme.drawer.offers.secondaryTextColor};
	height: 10px;
	width: 10px;
	margin-right: 5px;
	cursor: pointer;

	&:hover {
		fill: ${({ theme }) => theme.primaryColor};
	}
`;

export const ExpirationDateLabelBis = styled.div`
	font-size: 12px;
	color: ${({ theme }) => theme.drawer.offers.secondaryTextColor};
`;

export const WrapperButtonAndDate = styled.div`
	display: flex;
	align-items: center;

	& > div:not(:last-child) {
		margin: 0 10px 0 0;
	}
`;

export const QuoteNotificationTitleWrapper = styled.div`
	margin: 0 24px 0 0;
`;

export const WrapperSelect = styled.div`
	overflow: hidden;
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const WrapperDealSearch = styled.div`
	flex: 1;
`;

export const ErrorMessage = styled.div`
	color: #d92f2f;
	font-size: 12px;
`;

export const SearchInfoMessage = styled.div`
	color: ${({ theme }) => theme.primaryColor};
	font-size: 12px;
	margin-top: 2px;
	height: 18px;
`;

export const WrapperDealFilters = styled.div`
	width: 100%;
`;

export const IconAndDropdownWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledIcon = styled(Icon)`
	width: 20px;
	height: 20px;
	margin-right: 5px;
	fill: ${({ theme }) => theme.tableFilterWidget.textColor};
`;

export const WrapperContactInputAndIcon = styled.div`
	display: flex;
	align-items: center;
	flex: ${props => (props.isDrawer ? "2" : "1")};
`;

export const DeleteIcon = styled(Icon)`
	display: none;
	${WrapperContactInputAndIcon}:hover & {
		display: block;
	}
	cursor: pointer;
	fill: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.drawer.offers.tertiaryHoverTextColor};
	}
	width: 15px;
	height: 15px;
	margin-left: 5px;
`;

export const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 150px;
	height: 30px;
	background-color: ${({ theme }) => theme.tableFilterWidget.selectBackgroundColor};
	border-radius: 3px;
	border: 1px solid ${({ theme }) => theme.tableFilterWidget.secondaryBorderColor};
	box-shadow: 0 3px 6px ${({ theme }) => theme.tableFilterWidget.shadowColor};
	fill: ${({ theme }) => theme.tableFilterWidget.textColor};
	cursor: pointer;
`;

export const NumberOfFiltersText = styled.div`
	flex: 1;
	color: ${({ theme }) => theme.tableFilterWidget.textColor};
	margin-left: 20px;
`;

export const ArrowIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 100%;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: ${({ isTippyOpen, theme }) =>
		isTippyOpen ? theme.tableFilterWidget.hoverTextColor : ""};
	${IconAndDropdownWrapper}:hover & {
		background-color: ${({ theme }) => theme.tableFilterWidget.hoverTextColor};
	}
`;

export const ArrowIcon = styled(Icon)`
	fill: ${({ theme }) => theme.tableFilterWidget.textColor};
	width: 8px;
	height: 8px;
	transition: transform 0.3s;
	${props =>
		props.isTippyCollapsed &&
		css`
			transform: rotate(180deg);
		`}
`;

export const StyledButton = styled(Button)`
	padding: 0 0;
`;

const tippyBorderRadius = "5px";
export const TippyWrapper = styled.div`
	width: 380px;
	max-height: 50vh;
	display: flex;
	flex-direction: column;
	border-radius: ${tippyBorderRadius};
	background-color: ${({ theme }) => theme.tableFilterWidget.backgroundColor};
	box-shadow: 0 3px 6px ${({ theme }) => theme.tableFilterWidget.shadowColor};
`;

export const WrapperResetAllFilters = styled.div`
	display: flex;
	justify-content: end;
	padding: 7px 25px;
	background-color: ${({ theme }) => theme.tableFilterWidget.backgroundColor};
	border-top-left-radius: ${tippyBorderRadius};
	border-top-right-radius: ${tippyBorderRadius};
	border-bottom: 2px solid ${({ theme }) => theme.tableFilterWidget.primaryBorderColor}60;
`;

export const WrapperFilters = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	border-bottom-left-radius: ${tippyBorderRadius};
	border-bottom-right-radius: ${tippyBorderRadius};
	padding: 16px;
`;

export const WrapperFilter = styled.div``;

export const FilterWrapper = styled.div`
	margin-bottom: 8px;
`;

export const SelectAndResetFilterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const SelectFilterWrapper = styled.div`
	flex: 1 0 0;
	max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "326px")};
`;

export const ResetFilterWrapper = styled.div`
	flex: 0 0 auto;
`;

export const StyledCrossIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	margin-left: 6px;
	cursor: pointer;
	display: flex;
	justify-self: center;
	align-self: center;
	fill: ${({ theme }) => theme.page.secondaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
	}
`;

export const DealOption = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const DealName = styled.div`
	text-decoration: ${({ crossed }) => (crossed ? "line-through" : "none")};
`;

export const WrapperWarningIcon = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 17px;
`;

export const WarningIcon = styled(Icon)`
	width: 12px;
	height: 12px;
	margin-left: 5px;
	fill: ${({ theme }) => theme.primaryColor};
`;

export const StyledInfoIcon = styled(Icon)`
	fill: ${({ theme }) => theme.drawer.offers.tertiaryTextColor};
	height: 14px;
	width: 14px;
`;

export const WrapperInput = styled.div`
	flex: 1;
`;

export const WrapperInputLabel = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	color: #a5b9c8;
`;

export const SubSectionTitle = styled.div`
	${props => wrapperDrawerParameter(props)}
`;

export const WrapperComponent = styled.div`
	flex: 1;
	min-height: 50px;
	${props => wrapperDrawerParameter(props)}
`;

export const WrapperNotificationInputComponent = styled.div`
	display: flex;
	flex: 3;
	min-height: 50px;
	align-items: ${props => (props.flexStart ? "flex-start" : "flex-end")};
	justify-content: flex-start;
	margin: 10px 0;
`;

export const WrapperLineComponent = styled.div`
	display: flex;
	min-height: 25px;
	margin: 10px 0;
	${customGap("20px")};
	align-items: center;
`;

export const WrapperLineComponentNeedRowDirection = styled(WrapperLineComponent)`
	flex-direction: ${props => (props.isDrawer ? "row" : "column")};
	${props => !props.isDrawer && customGap("0px")};
`;

export const WrapperComponentInputLabeled = styled(WrapperComponent)`
	${props =>
		props.enabled &&
		css`
			min-height: 68px;
		`};
`;

export const WrapperTextAndIcon = styled.div`
	${customGap("5px")};
	display: flex;
	align-items: center;
	flex: 1;
	${props =>
		props.isMinWidth &&
		css`
			min-width: 260px;
			width: 260px;
		`}
`;

export const WrapperTextAndIconForParameters = styled(WrapperTextAndIcon)`
	flex: 1;
`;

export const WrapperTextAndIconWhenNoSwitch = styled(WrapperTextAndIcon)`
	min-width: 224px;
	flex: 1;
`;

export const WrapperTitleIconAndSwitch = styled.div`
	display: flex;
`;

export const StartDateTooltipContent = styled.div`
	text-align: center;
`;

export const WrapperTitleIconAndSwitchForParameters = styled(WrapperTitleIconAndSwitch)`
	justify-content: space-between;
	overflow: hidden;
	${props =>
		!props.isDrawer &&
		css`
			width: 100%;
		`}
`;

export const WrapperTitle = styled.div`
	display: flex;
	align-self: ${props => {
		props.isDrawer ? "flex-start" : "center";
	}};
`;

export const WrapperTitleIconAndSwitchForParametersColumn = styled(
	WrapperTitleIconAndSwitchForParameters
)`
	display: flex;
	${props =>
		!props.isDrawer &&
		css`
			flex-direction: column;
			justify-content: flex-start;
			margin-bottom: 5px;
		`};
`;

export const WrapperTitleIconAndSwitchWithTextArea = styled(WrapperTitleIconAndSwitch)`
	align-self: start;
	display: flex;
`;

export const StyledSelector = styled(Selector)`
	box-shadow: none;
	margin-top: 5px;
`;

export const WrapperDocument = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.page.secondaryTextColor};
	fill: ${({ theme }) => theme.page.secondaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
		color: ${({ theme }) => theme.page.textColor};
	}
	cursor: pointer;
`;

export const WrapperEmptyDocAndDoc = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	white-space: nowrap;
`;

export const WrapperContact = styled.div`
	display: flex;
	justify-content: flex-start;
	flex: 1;
	padding: 5px 0;
	flex-direction: column;
	${props => wrapperDrawerParameter(props)}
	${props =>
		props.index > 0 &&
		css`
			padding: 0;
		`}
	${props =>
		props.length === 1 &&
		!props.isDrawer &&
		css`
			padding: 5px 0 0 0;
		`}
	${props =>
		props.isButtonAddContact &&
		css`
			padding: 0 0 5px 0;
		`}
	${props =>
		props.isDrawer &&
		props.isButtonAddContact &&
		css`
			height: 25px;
			margin-bottom: 10px;
		`}
`;

export const WrapperContacts = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	& > * {
		padding: 0 0 4px 0;
	}
`;

export const WrapperContactsAndAddButton = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
`;

export const WrapperContactInputs = styled.div`
	display: flex;
	& > :not(:last-child) {
		margin-right: 10px;
	}
`;

export const WrapperOfferVATNumber = styled.div`
	display: flex;
	flex-direction: column;
	flex: ${props => (props.isDrawer ? "2" : "1")};
	${props => wrapperDrawerParameter(props)}
`;

export const WrapperOfferLicenseStartDate = styled.div`
	display: flex;
	flex-direction: column;
	flex: ${props => (props.isDrawer ? "2" : "1")};
	${props => wrapperDrawerParameter(props)}
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
	align-self: flex-start;
	padding-top: 5px;
`;

export const WrapperGroup = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${props => props.theme.page.borderColor};
	border-radius: 10px;
	margin: ${props => (props.isDrawer ? "0 -10px 10px -10px" : "10px 0 10px -10px")};
	padding: 10px;

	${props =>
		!props.isDrawer &&
		css`
			// To fit with contacts length:
			// 136px : 2 inputs of 136px, 1 input of 340px, 2 margin of 10px each + 20px wrapper padding
			max-width: calc(136px + 10px + 136px + 10px + 340px + 20px);
		`}

	& > :not(:last-child) {
		margin-bottom: 10px;
	}
`;

export const WraperOneLine = styled.div`
	display: flex;
	flex: 1;
	& > * {
		flex: 1;
		:not(:last-child) {
			margin-right: 10px;
		}
	}
`;

export const StyledLockIcon = styled(Icon)`
	width: 14px;
	height: 14px;
	margin-left: 5px;
	cursor: pointer;
`;

export const StyledUnlockedIcon = styled(StyledLockIcon)`
	fill: #a5b9c8;
`;

export const StyledLockedIcon = styled(StyledLockIcon)`
	fill: #ffcd38;
`;

export const WrapperInputAndLock = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperOfferNameAndLock = styled.div`
	color: ${({ theme }) => theme.textColor.primary};
	display: flex;
	align-items: center;
	margin-left: 10px;
`;

export const WrapperDefaultName = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperLogo = styled.div`
	display: flex;
	align-items: center;
`;
