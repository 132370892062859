import React, { useState, useEffect, useCallback } from "react";

import {
	BorderColorDiv,
	DataBoxTitle,
	DataBoxValue,
	WrapperDataBox,
	WrapperValue,
	WrapperUnits,
	ContentContainer,
	Triangle,
} from "./DataBox.style";

const DataBox = ({
	title,
	value,
	valueUnits,
	color,
	valueColor,
	borderColor,
	width,
	height,
	shouldSplit,
	shouldSplitCallback,
	displayArrow,
	arrowColor,
	arrowDistance,
	dataExtension,
	backgroundColor,
	withoutRadiusTop,
	withoutRadiusBottom,
	addBorderTopContent,
	addBorderBottomContent,
	addBorderRightContent,
	addRadius,
	minWidth,
	centerContent,
	borderLeftColor
}) => {
	const [isValueBoxWiderThanWrapper, setIsValueBoxWiderThanWrapper] = useState(false);
	const [splitOnTwoLines, setSplitOnTwoLines] = useState(false);
	const [suffixStartsWithSlash, setSuffixStartsWithSlash] = useState(false);
	const [valueBoxClientWidth, setValueBoxClientWidth] = useState(0);
	const [valueBoxScrollWidth, setValueBoxScrollWidth] = useState(0);
	const [hiddenValueBoxForWiderScrollWidth, setHiddenValueBoxForWiderScrollWidth] = useState(0);
	const [hiddenValueBoxForSplitScrollWidth, setHiddenValueBoxForSplitScrollWidth] = useState(0);

	const valueBoxRef = useCallback(
		node => {
			if (node !== null) {
				setValueBoxClientWidth(node.clientWidth);
				setValueBoxScrollWidth(node.scrollWidth);
			}
		},
		[value, valueUnits]
	);

	const hiddenValueBoxForWiderRef = useCallback(
		node => {
			if (node !== null) {
				setHiddenValueBoxForWiderScrollWidth(node.scrollWidth);
			}
		},
		[value, valueUnits]
	);

	const hiddenValueBoxForSplitRef = useCallback(
		node => {
			if (node !== null) {
				setHiddenValueBoxForSplitScrollWidth(node.scrollWidth);
			}
		},
		[value, valueUnits]
	);

	useEffect(() => {
		if (valueBoxScrollWidth && !splitOnTwoLines) {
			if (hiddenValueBoxForWiderScrollWidth > valueBoxClientWidth) {
				setIsValueBoxWiderThanWrapper(true);
			} else {
				if (hiddenValueBoxForWiderScrollWidth < valueBoxClientWidth) {
					setIsValueBoxWiderThanWrapper(false);
				}
			}
		}
	}, [
		splitOnTwoLines,
		valueBoxScrollWidth,
		valueBoxClientWidth,
		hiddenValueBoxForWiderScrollWidth,
	]);

	useEffect(() => {
		if (valueBoxScrollWidth && isValueBoxWiderThanWrapper) {
			if (hiddenValueBoxForSplitScrollWidth > valueBoxClientWidth) {
				setSplitOnTwoLines(true);
			} else {
				if (hiddenValueBoxForSplitScrollWidth < valueBoxClientWidth) {
					setSplitOnTwoLines(false);
				}
			}
		}
	}, [
		isValueBoxWiderThanWrapper,
		valueBoxScrollWidth,
		valueBoxClientWidth,
		hiddenValueBoxForSplitScrollWidth,
	]);

	useEffect(() => {
		if (shouldSplitCallback) {
			shouldSplitCallback(splitOnTwoLines);
		}
	}, [splitOnTwoLines]);
	return (
		<WrapperDataBox
			width={width}
			minWidth={minWidth}
			splitOnTwoLines={splitOnTwoLines || shouldSplit}
			borderColor={borderColor}
			backgroundColor={backgroundColor}
			height={height}
			withoutRadiusBottom={withoutRadiusBottom}
			withoutRadiusTop={withoutRadiusTop}
		>
			<BorderColorDiv
				color={borderLeftColor || color}
				withoutRadiusBottom={withoutRadiusBottom}
				withoutRadiusTop={withoutRadiusTop}
			/>
			<ContentContainer
				addBorderTopContent={addBorderTopContent}
				addBorderBottomContent={addBorderBottomContent}
				addBorderRightContent={addBorderRightContent}
				addRadius={addRadius}
				centerContent={centerContent}
			>
				{displayArrow ? (
					<Triangle type="upTriangleSharp" color={arrowColor} distance={arrowDistance} />
				) : null}
				<DataBoxTitle color={color}>{title}</DataBoxTitle>
				<DataBoxValue
					ref={valueBoxRef}
					color={valueColor ?? "#CCD3D7"}
					isValueBoxWiderThanWrapper={isValueBoxWiderThanWrapper}
					splitOnTwoLines={splitOnTwoLines || shouldSplit}
					centerContent={centerContent}
				>
					<WrapperValue
						splitOnTwoLines={splitOnTwoLines}
						suffixStartsWithSlash={suffixStartsWithSlash}
					>
						{value}
					</WrapperValue>
					<WrapperUnits splitOnTwoLines={splitOnTwoLines}>{valueUnits}</WrapperUnits>
				</DataBoxValue>
				<DataBoxValue
					ref={hiddenValueBoxForWiderRef}
					style={{ visibility: "hidden", position: "absolute" }}
					color={valueColor ?? "#CCD3D7"}
					isValueBoxWiderThanWrapper={false}
					splitOnTwoLines={false}
				>
					<WrapperValue suffixStartsWithSlash={suffixStartsWithSlash}>
						{value}
					</WrapperValue>
					<WrapperUnits>{valueUnits}</WrapperUnits>
				</DataBoxValue>
				<DataBoxValue
					ref={hiddenValueBoxForSplitRef}
					style={{ visibility: "hidden", position: "absolute" }}
					color={valueColor ?? "#CCD3D7"}
					isValueBoxWiderThanWrapper={true}
					splitOnTwoLines={false}
				>
					<WrapperValue suffixStartsWithSlash={suffixStartsWithSlash}>
						{value}
					</WrapperValue>
					<WrapperUnits>{valueUnits}</WrapperUnits>
				</DataBoxValue>
			</ContentContainer>
			{dataExtension ?? null}
		</WrapperDataBox>
	);
};

export default DataBox;
