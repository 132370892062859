import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { MultiSelect } from "priceit-ui";
import { useQuery } from "@apollo/client";

import {
	DropdownTitle,
	MultiSelectWrapper,
	WrapperDropdownHeader,
	WrapperOpeningNotification,
} from "./Components.style";
import { GET_WORKSPACE_USERS } from "@graphQl/queries/user";
import { GET_OPEN_OFFER_EMAILS_BY_OFFER_ID } from "@graphQl/queries/offer";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import { updateOfferOpenOfferEmailsMutation } from "@graphQl/mutations/offer";
import { useWorkspace } from "@src/hooks/useWorkspace/UseWorkspace";
import { useThemeContext } from "@theme/Theme";
import { customSort } from "@src/services/columns/customSort";
import { Permission } from "@services/defaultPermissions";

export const OpeningNotification = () => {
	const { t } = useTranslation("specific/clientsOffers");
	const { mode } = useThemeContext();
	const { workspace } = useWorkspace();
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const { data: dataUsers } = useQuery(GET_WORKSPACE_USERS);
	const users = dataUsers?.getWorkspaceUsers || [];

	const { data: dataOffer } = useQuery(GET_OPEN_OFFER_EMAILS_BY_OFFER_ID, {
		variables: {
			id: offerId,
		},
		skip: !offerId,
	});
	const { openOfferEmails, ownerId } = dataOffer?.getOfferById || {
		openOfferEmails: [],
		ownerId: null,
	};
	const openOfferEmailsIds =
		openOfferEmails?.reduce((prev, current) => {
			if (current.id !== ownerId) {
				prev.push(current.id);
			}
			return prev;
		}, []) || [];
	openOfferEmailsIds.push(ownerId);

	const sortedUsers = [...users].sort((a, b) => customSort(a.email, b.email));
	const selectData = useMemo(
		() =>
			sortedUsers.reduce((filteredUsers, user) => {
				const isOwnerAndHasAccess =
					user?.id === ownerId && user?.role?.offer_read === Permission.assigned;
				if (
					(!user?.isRoot &&
						workspace.active &&
						(isOwnerAndHasAccess || user?.role?.offer_read === Permission.full)) ||
					(user?.isRoot && !workspace.active) ||
					(user?.isRoot && workspace.active && user?.id === ownerId)
				) {
					filteredUsers.push({
						value: user?.id,
						option: (
							<MultiSelect.Option disabled={user?.id === ownerId} value={user?.id}>
								{user.email}
							</MultiSelect.Option>
						),
					});
				}
				return filteredUsers;
			}, []),
		[users, workspace.active, ownerId]
	);

	const [updateOfferOpenOfferEmails] = updateOfferOpenOfferEmailsMutation();

	return (
		<WrapperOpeningNotification>
			<WrapperDropdownHeader>
				<DropdownTitle>{t("Person(s) notified if the offer is opened")}</DropdownTitle>
			</WrapperDropdownHeader>
			<MultiSelectWrapper>
				<MultiSelect.Group
					type="basic"
					mode={mode}
					appendTo={document.body}
					width={"100%"}
					placeHolder={t("-")}
					value={openOfferEmailsIds}
					showNumberOfSelectedChildren
					numberOfSelectedChildrenText={number =>
						t("{{number}} selected collaborator", { count: number, number })
					}
					onChange={userIds => {
						const selectedUsers = userIds.reduce((prev, current) => {
							const user = users.find(x => x.id === current);
							if (user) {
								prev.push(user);
							}
							return prev;
						}, []);
						updateOfferOpenOfferEmails({
							variables: {
								updateOffer: {
									id: offerId,
									openOfferEmails: userIds,
								},
							},
							optimisticResponse: {
								updateOffer: {
									__typename: "Offer",
									id: offerId,
									openOfferEmails: selectedUsers,
								},
							},
						});
					}}
					data={selectData}
				/>
			</MultiSelectWrapper>
		</WrapperOpeningNotification>
	);
};

OpeningNotification.displayName = "OpeningNotification";
export default OpeningNotification;
