import { gql, useMutation } from "@apollo/client";

const CREATE_PERIOD = gql`
	mutation CreatePeriod($createPeriodInput: CreatePeriodInput!) {
		createPeriod(createPeriodInput: $createPeriodInput) {
			name
			comment
			index
			id
			startDate
			endDate
			estimationFixedCost
			estimationProducts
			createdBy
			updatedBy
			createdAt
			updatedAt
			type
		}
	}
`;

export const createPeriodMutation = () => {
	const [createPeriod] = useMutation(CREATE_PERIOD, {
		update(cache, { data: { createPeriod } }) {
			cache.modify({
				fields: {
					getPeriodsByWorkspaceId(existingPeriods = []) {
						return [...existingPeriods, createPeriod];
					},
				},
			});
		},
	});
	return createPeriod;
};
