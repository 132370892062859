import { gql } from "@apollo/client";

export const GET_CURRENT_WORKSPACE = gql`
	query GetCurrentWorkspace {
		getCurrentWorkspace {
			id
			name
			description
			createdAt
			imagePath
			currency
			active
			clientWorkspace
			activeToDate
			logoLink
			moduleWorkspacePermissions {
				id
				active
				permission
				moduleName
				categoryName
				moduleWorkspacePermissionOptions {
					id
					moduleOptionName
					active
				}
			}
		}
	}
`;
