import React, { memo, useEffect } from "react";
import { Button, Dialog } from "priceit-ui";
import { useTranslation } from "react-i18next";
import { createErrorTaskMutation } from "./graphQl/mutation";
import { useLocation } from "react-router-dom";
import { useThemeContext } from "@theme/Theme";
import openReplayTracker from "../../../config/openReplay";

const CustomHeader = memo(({ image, width, height }) => (
	<div
		style={{
			display: "flex",
			color: "white",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<img
			style={{
				height,
				width,
				overflow: "hidden",
				margin: "50px 0 30px 0",
			}}
			alt={image}
			src={image}
		/>
	</div>
));
CustomHeader.displayName = "CustomHeader";

export const ErrorBoundary = ({ componentStack, error }) => {
	const { Modal } = Dialog;
	const { mode, errorBoundaryModal: errorBoundaryModalColors } = useThemeContext();

	const location = useLocation();
	const { t, i18n } = useTranslation("welcomeMessage/page");
	const createErrorTask = createErrorTaskMutation();

	const sessionURL = openReplayTracker.getSessionURL({ withCurrentTime: true });
	const errorMessage = error?.message;
	const loadingChunkError = error?.message && /Loading chunk [\d]+ failed/.test(error.message);
	useEffect(() => {
		if (errorMessage && !loadingChunkError) {
			openReplayTracker.handleError(error);
			createErrorTask({
				variables: {
					error: error?.toString(),
					componentStack,
					location: location.pathname,
					sessionURL,
				},
			});
		}
	}, [errorMessage]);

	if (loadingChunkError) {
		return (
			<Modal
				positionFromTop={"50px"}
				width="410px"
				footerType="1button"
				visible={true}
				theme={{
					backgroundColor: errorBoundaryModalColors.backgroundColor,
					dividerColor: errorBoundaryModalColors.dividerColor,
				}}
				onOk={() => window.location.reload()}
				okText={t("Refresh")}
				noContentPadding
				closable={false}
				customHeader={
					<CustomHeader
						image={`https://${process.env.IMAGE_PROVIDER}/images/miscellaneous/${
							i18n.languages[0] ?? "en"
						}/Refresh.svg`}
						width={"256px"}
						height={"auto"}
					/>
				}
			/>
		);
	}

	return (
		<Modal
			positionFromTop={"50px"}
			width="640px"
			visible={true}
			theme={{
				backgroundColor: errorBoundaryModalColors.backgroundColor,
				dividerColor: errorBoundaryModalColors.dividerColor,
			}}
			onOk={() => null}
			onCancel={() => null}
			noContentPadding
			customHeader={
				<CustomHeader
					image={`https://${process.env.IMAGE_PROVIDER}/images/${
						i18n.languages[0] ?? "en"
					}/AnErrorHasOccured_NewBis.svg`}
					width={"auto"}
					height={"100%"}
				/>
			}
			customFooter={[
				<div key={"buttonsDiv"} style={{ display: "flex" }}>
					<Button
						key="refresh"
						mode={mode}
						onClick={() => {
							window.location.reload();
						}}
						type="cancel"
						width="200px"
						style={{ margin: "0 10px" }}
					>
						{t("Refresh")}
					</Button>
				</div>,
			]}
		/>
	);
};
