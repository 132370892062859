export const actions = {
	UPDATE_SEARCH_INPUT: "UPDATE_SEARCH_INPUT",
	UPDATE_IS_CREATING: "UPDATE_IS_CREATING",

	UPDATE_MODAL_CONFIRM_VISIBLE: "UPDATE_MODAL_CONFIRM_VISIBLE",
	UPDATE_DELETE_INPUT: "UPDATE_DELETE_INPUT",
	UPDATE_PERIODS_SELECTED: "UPDATE_PERIODS_SELECTED",
	SET_NEW_PERIOD_ID: "SET_NEW_PERIOD_ID"
};

export const initialState = {
	searchInput: "",
	isCreating: false,
	modalConfirmVisible: false,
	deleteInput: "",
	selectedRows: [],
	newPeriodId: null
};

export const periodsDrawerReducer = (state, action) => {
	const reducerState = { ...state };
	switch (action.type) {
		case actions.UPDATE_SEARCH_INPUT:
			reducerState.searchInput = action.value;
			return reducerState;

		case actions.UPDATE_IS_CREATING:
			reducerState.isCreating = action.value;
			return reducerState;

		case actions.UPDATE_MODAL_CONFIRM_VISIBLE:
			reducerState.modalConfirmVisible = action.value;
			return reducerState;
		case actions.UPDATE_DELETE_INPUT:
			reducerState.deleteInput = action.value;
			return reducerState;

		case actions.UPDATE_PERIODS_SELECTED:
			reducerState.selectedRows = action.value;
			return reducerState;

		case actions.SET_NEW_PERIOD_ID:
			reducerState.newPeriodId = action.value || null;
			return reducerState;

		default:
			throw new Error("Unexpected action");
	}
};
