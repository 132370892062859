import { gql } from "@apollo/client";

import {
	QUOTE_CONFIGURATION_HEADER_FRAGMENT,
	QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT,
} from "../fragments/quoteConfiguration";

export const GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT}
	query GetQuoteConfigurationQuoteLayoutByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getQuoteConfigurationByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...QuoteConfigurationQuoteLayoutFragment
		}
	}
`;

export const GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_ID = gql`
	${QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT}
	query GetQuoteConfigurationQuoteLayoutById($id: ID!) {
		getQuoteConfigurationById(id: $id) {
			...QuoteConfigurationQuoteLayoutFragment
		}
	}
`;

export const GET_QUOTE_CONFIGURATION_QUOTE_LAYOUT_BY_OFFER_ID = gql`
	${QUOTE_CONFIGURATION_QUOTE_LAYOUT_FRAGMENT}
	query GetQuoteConfigurationQuoteLayoutByOfferId($offerId: ID!) {
		getQuoteConfigurationByOfferId(offerId: $offerId) {
			...QuoteConfigurationQuoteLayoutFragment
		}
	}
`;

export const GET_QUOTE_CONFIGURATION_HEADER_BY_PRICE_MODEL_COMBINATION_ID = gql`
	${QUOTE_CONFIGURATION_HEADER_FRAGMENT}
	query GetQuoteConfigurationHeaderByPriceModelCombinationId($priceModelCombinationId: ID!) {
		getQuoteConfigurationByPriceModelCombinationId(
			priceModelCombinationId: $priceModelCombinationId
		) {
			...QuoteConfigurationHeaderFragment
		}
	}
`;

export const GET_QUOTE_CONFIGURATION_HEADER_BY_OFFER_ID = gql`
	${QUOTE_CONFIGURATION_HEADER_FRAGMENT}
	query GetQuoteConfigurationHeaderByOfferId($offerId: ID!) {
		getQuoteConfigurationByOfferId(offerId: $offerId) {
			...QuoteConfigurationHeaderFragment
		}
	}
`;
