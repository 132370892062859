import React from "react";
import { ListView } from "./listView/ListView";
import { FixedCostView } from "./fixedCostView/FixedCostView";

const TableView = ({
	view,
	data,
	searchInput,
	period,
	productsSelected,
	onChangeProductsSelected,
	loadingProducts,
	periodType,
	products,
}) => {
	return (
		<div style={{ width: "100%", overflow: "hidden" }}>
			{view === "productsListView" ? (
				<ListView
					searchInput={searchInput}
					period={period}
					periodType={periodType}
					productsSelected={productsSelected}
					onChangeProductsSelected={onChangeProductsSelected}
					loadingProducts={loadingProducts}
					products={products}
				/>
			) : view === "productsCardView" ? null : view === "fixedCostView" ? (
				<FixedCostView
					data={data}
					searchInput={searchInput}
					period={period}
					products={products}
					productsSelected={productsSelected}
					onChangeProductsSelected={onChangeProductsSelected}
					loadingProducts={loadingProducts}
					periodType={periodType}
				/>
			) : null}
		</div>
	);
};
TableView.whyDidYouRender = true;
TableView.displayName = "TableView";
export default TableView;
