import styled from "styled-components";
import { ToggleSwitch } from "priceit-ui";

export const Wrapper = styled.div`
	width: 100%;
`;

export const WrapperSwitchAndTextArea = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperSwitchAndCounter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TextAreaWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const Counter = styled.div`
	font-size: 12px;
	color: #a5b9c8;
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
	& > div {
		:first-child {
			padding-left: 0;
		}
	}
`;

export const LabelIntroTextSwitch = styled.div`
	font-size: 12px;
`;
