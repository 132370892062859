import React, { memo } from "react";
import * as S from "./AttributesDescriptions.style";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { Checkbox } from "priceit-ui";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import { updateQuoteConfigurationShowAttributesDescriptionMutation } from "@graphQl/mutations/quoteConfiguration";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/QuoteLayout.style";

const AttributesDescriptions = memo(({ offerId, priceModelCombinationId }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode } = useThemeContext();

	const { quoteConfigurationId, showAttributesDescription } = useQuoteConfigurationData({
		offerId,
		priceModelCombinationId,
	});

	const [updateQuoteConfigurationShowAttributesDescription] =
		updateQuoteConfigurationShowAttributesDescriptionMutation();

	return (
		<S.Wrapper>
			<StyledCheckboxGroup
				checkIcon
				focusColor="#FFCD38"
				boxLabelDistance="0px"
				interBoxDistance="8px"
				style={{ marginLeft: "-7.5px" }}
				selected={showAttributesDescription ? ["showAttributesDescription"] : []}
				onChange={() => {
					updateQuoteConfigurationShowAttributesDescription({
						variables: {
							updateQuoteConfiguration: {
								id: quoteConfigurationId,
								showAttributesDescription: !showAttributesDescription,
							},
						},
						optimisticResponse: {
							updateQuoteConfiguration: {
								__typename: "QuoteConfiguration",
								id: quoteConfigurationId,
								showAttributesDescription: !showAttributesDescription,
							},
						},
						update(cache) {
							priceModelCombinationId !== null &&
								updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
						},
					});
				}}
			>
				<Checkbox.Button
					mode={mode}
					value={"showAttributesDescription"}
					width="15px"
					height="15px"
				>
					<StyledTextCheckbox>{t("Attributes description")}</StyledTextCheckbox>
				</Checkbox.Button>
			</StyledCheckboxGroup>
		</S.Wrapper>
	);
});

export default AttributesDescriptions;
