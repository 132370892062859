import styled, { css } from "styled-components";
import { Input, Icon } from "priceit-ui";
import { verticalCustomGap } from "@services/Utils.style";

export const Container = styled.div`
	flex-basis: 360px;
	min-width: 360px;
	border-right: 1px solid #1e272e;
	height: 100%;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #8fa1af;
	&::-webkit-scrollbar {
		width: 7px;
	}
	&::-webkit-scrollbar-track {
		background: #495965;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #8fa1af;
		border-radius: 6px;
		border: 3px solid #8fa1af;
	}
`;

export const StyledArrowIcon = styled(Icon)`
	fill: #fff;
	width: 10px;
	min-width: 10px;
	transition: transform 0.3s;
	${props =>
		props.isSectionExpanded &&
		css`
			transform: rotate(180deg);
		`}
`;

export const Displays = styled.div`
	z-index: 1;
	overflow: hidden;
	transition:
		padding 1s,
		max-height 1.25s;
	max-height: 2000px;
	${props =>
		!props.isSectionExpanded &&
		css`
			max-height: 0;
			border-bottom: 1px solid #1e272e;
			transition:
				padding 0.8s,
				max-height 0.4s;
		`};
`;

export const SectionTitle = styled.div`
	color: #ffcd38;
	margin-bottom: 10px;
`;

export const SubSectionTitle = styled.div`
	font-size: 12px;
	color: ${({ theme }) => theme.page.secondaryTextColor};
`;

export const TitleAndCounterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const WrapperTitleAndItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 10px;
`;

export const OptionTitle = styled.div`
	color: #a5b9c8;
	white-space: nowrap;
`;

export const SwitchLabel = styled.div`
	${props =>
		!props.checked &&
		css`
			&:hover {
				color: #a5b9c8;
			}
		`};
	color: ${props => (props.checked ? "#FFFFFF" : "#71889A")};
	cursor: pointer;
	margin-right: 10px;
	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
		`};
`;

export const StyledInput = styled(Input)`
	color: white;
	border-radius: 3px;
	margin: ${props => props.margin ?? "0 5px 0 0"};
`;

export const TooltipContent = styled.div`
	max-width: 250px;
`;

export const SectionBar = styled.div`
	position: sticky;
	top: 0;
	z-index: 2;
	height: 40px;
	background-color: #5c6f7e;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px 0 20px;
	cursor: ${props => (props.isLoading ? "not-allowed" : "pointer")};
`;

export const SectionBarTitle = styled.div`
	color: #fff;
	display: flex;
	flex-wrap: nowrap;
	font-size: 13px;
	align-items: center;
`;

export const PaddingOptions = styled.div`
	padding: ${props => props.padding ?? "10px 20px"};
	border-bottom: 1px solid #495965;
	&:last-child {
		border-bottom: unset;
		padding: ${props => props.lastChildPadding ?? "10px 20px 30px"};
	}
	&:empty {
		display: none;
	}
	${verticalCustomGap("10px")};
`;

export const ShowRangesTitle = styled(SubSectionTitle)``;

export const TemplateSubtitle = styled(SubSectionTitle)``;

export const WrapperTemplateSelectorAndTitle = styled(WrapperTitleAndItem)``;

export const WrapperTextLayout = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperTextLayoutDescription = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;
