export const timeMetricsEnum = {
	ONLY_ONCE: "onlyOnce",
	HOUR: "hour",
	DAY: "day",
	WEEK: "week",
	MONTH: "month",
	QUARTER: "quarter",
	SEMESTER: "semester",
	YEAR: "year",
};

export const boosterUnitMetric = {
	PRICE_MODEL_METRIC: "priceModelMetric",
	NUMERICAL_UNIT_METRIC: "numericalUnitMetric",
	NONE: "totalPrice",
};

export const inputTypeProperties = {
	SWITCH: {
		id: "2",
		color: "#00CBFF",
	},
	NUMERICAL: {
		id: "4",
		color: "#FFA631",
	},
	SELECT: {
		id: "3",
		color: "#E657DC",
	},
};

export const profitabilityColors = {
	UNSET: "#C4C4C4",
	POSITIVE: "#18bd51",
	NEGATIVE: "#F74B64",
};

export const tcTypes = {
	PDF: "pdf",
	LINK: "link",
};

export const tcPlaceholders = {
	FR: "J'ai lu et j'accepte les termes et conditions",
	EN: "I have read and accepted the terms and conditions",
	NL: "Ik heb de algemene voorwaarden gelezen en geaccepteerd",
};

export const priceModelInputDefaultValues = t => ({
	name: t("New pricing page name"),
	description: "",
	isFavorite: false,
	volumeReference: 1,
	minEngagement: 1,
	isTva: false,
	showCommitmentInput: false,
	priceMetric: "unit",
	timeMetric: "onlyOnce",
	language: "en",
	displayedGeneralTitle: t("Offers"),
	displayedVatDefault: "vatExcluded",
	showSwitcherVatDefault: false,
	// displayedPriceTimeMetricDefault: "unitPrice",
	// displayedPriceVolumeMetricDefault: "totalAmount",
	displayedCommitmentInputDefault: 1,
	displayedVolumeInputDefault: 1,
	flag: t("Most popular"),
	showSavings: true,
	backgroundPrimaryColor: "#1E272E",
	cornerRadius: "5px",
});

export const MAX_HEIGHT_UPLOAD_IMAGE_FEATURE = 360;
export const OFFER_NAME_MAX_LENGTH = 70;
export const OFFER_PROSPECT_NAME_MAX_LENGTH = 40;
export const MAX_LENGTH_PASSWORD = 30;
export const MAX_LENGTH_PRICE_MODEL_DESCRIPTION = 200;
export const MAX_LENGTH_PRICE_SENSITIVITY_METER_NAME = 70;
export const MAX_LENGTH_PRICE_SENSITIVITY_METER_DESCRIPTION = 150;
export const MAX_LENGTH_PACK_BUTTON_NAME = 40;
export const MAX_LENGTH_RULES_SECTION_NAME = 50;
export const MAX_LENGTH_TC_BUTTON = 60;
export const MAX_LENGTH_TC_FOOTER = 1000;
export const MAX_LENGTH_TC_NAME = 70;
export const RULE_NAME_MAX_LENGTH = 80;
export const MAX_LENGTH_RULE_DESCRIPTION = 500;
export const EXTRA_ADDON_RULE_NAME_MAX_LENGTH = 200;
export const MAX_LENGTH_VAT_NUMBER = 40;
export const NEW_WEBHOOK_NAME_MAX_LENGTH = 40;
export const MAX_FLAG_TITLE = 40;
export const MAX_LENGTH_INTRO_TEXT_QUOTE_CONFIG = 3000;
export const MAX_LENGTH_CUSTOM_PROMPT = 500;

export const publicLinkTypes = {
	OFFER: "offer",
	OFFERS_TEMPLATE: "offersTemplate",
	PRICING_PLAN: "pricingPlan",
};

export const tableTrad = ({ t, count }) => ({
	editText: t("Edit"),
	sortText: t("Sort"),
	hideText: t("Hide"),
	selectedRowsWording: t("selected line", {
		count,
	}),
	sideBarText: t("Display / Hide columns", {
		ns: "table/general",
	}),
	displayAllColumnsButtonText: t("Show all columns", {
		ns: "table/general",
	}),
	disabledDragAndDropTooltipContent: t("Drag-and-drop is disabled when sorting is applied", {
		ns: "table/general",
	}),
});

export const outlierTypes = {
	priceNotFilled: "priceNotFilled",
	wtpHigher: "wtpHigher",
	wtpLower: "wtpLower",
	priceNotAscending: "priceNotAscending",
	badPrice: "badPrice",
	manualOutlier: "manualOutlier",
};

export const quoteLayouts = {
	Detailed: "detailed",
	Compact: "compact",
};

export const priceitSignatureTypes = {
	TRACED: "traced",
	TYPED: "typed",
};

export const generationSteps = {
	CONTACT_FORM: "contactForm",
	QUOTE_GENERATION: "quoteGeneration",
	QUOTE_SIGNATURE: "quoteSignature",
};

export const signatureSteps = {
	QUOTE_GENERATION: "quoteGeneration",
	SIGN_QUOTE: "signQuote",
	QUOTE_SIGNED: "quoteSigned",
	SIGN_LATER: "signLater",
	OFFER_SENT: "offerSent",
	OFFER_COPIED: "offerCopied",
};

export const signatureFields = {
	FIRSTNAME: "firstName",
	LASTNAME: "lastName",
	EMAIL: "email",
	SIGNATURE: "signature",
	DATE: "date",
	LOCATION: "location",
	TERMS_AND_CONDITIONS: "termsAndConditions",
};

export const signatureStatuses = {
	SUCCESS: "success",
	ERROR: "error",
};

export const publicLinkTransitionDuration = "0.4s";

export const publicOfferTypes = {
	NORMAL_OFFER: "normalOffer",
	SIGNATURE_OFFER: "signatureOffer",
	SIGNED_OFFER: "signedOffer",
	PRICE_MODEL_COMBINATION: "priceModelCombination",
};

export const templateLoginSteps = {
	FORM: "form",
	CHECK_MAILS: "checkMails",
};

export const sendMailContext = {
	DRAWER: "drawer",
	COLUMN: "column",
};

export const offerVersionRuleValueTypes = {
	PERCENTAGE: "percentage",
	CURRENCY: "currency",
	UNIT_METRIC: "unitMetric",
	TIME_METRIC: "timeMetric",
};

export const offerVersionRuleTypes = {
	DISCOUNT: "discount",
	GIFT: "gift",
};

export const OFFER_VERSION_RULE_ALL_TABS = "allTabs";

const GIVEMEFIVE_WEBSITE_URL_BASE = "https://www.getgivemefive.com";
export const GIVEMEFIVE_WEBSITE_URLS = {
	en: `${GIVEMEFIVE_WEBSITE_URL_BASE}`,
	fr: `${GIVEMEFIVE_WEBSITE_URL_BASE}`,
	nl: `${GIVEMEFIVE_WEBSITE_URL_BASE}`,
};

export const quoteFieldsKeys = {
	COMMENT_SALES: "Comments on the quote (Sales view)",
	COMMENT_PROSPECT: "Contact comment (Prospect view)",
	COMPANY: "Company name",
	CONTACT_NAME: "Name of the client's contact person",
	POSTAL_ADDRESS: "Postal address",
	INFORMATION_DATE: "Information date",
	VAT_NUMBER: "VAT number",
};

export const reminderTypes = {
	OFFER_EXPIRATION_DATE: "offerExpirationDate",
};

export const reminderFrequencies = {
	ONCE: "once",
	DAYS: "days",
};

export const reminderRelativities = {
	BEFORE: "before",
	AFTER: "after",
};

export const textLayouts = {
	TEXT_LAYOUT_UNWRAPPED: "unwrapped",
	TEXT_LAYOUT_WRAPPED: "wrapped",
};

export const addedQuoteFields = {
	errors: {
		KEY_ALREADY_USED: "This unique key has already been used",
	},
};

export const SELECTED_EDITABLE_CELL_STYLE = {
	backgroundColor: "#ffcd380d",
	color: "#fff",
	display: "flex",
	justifyContent: "center",
};

export const CELL_EDITABLE_STYLE = {
	backgroundColor: "#ffcd380f",
	color: "#fff",
};

export const CELL_EDITABLE_STYLE_FIRST_IN_PACK = {
	backgroundColor: "#ffcd380f",
	color: "#fff",
	borderLeft: "1px solid #ffff",
};

export const CELL_EDITABLE_STYLE_LAST_IN_PACK = {
	backgroundColor: "#ffcd380f",
	color: "#fff",
	borderRight: "1px solid #ffff",
};

export const HEADER_COLUMN_STYLE_LAST_IN_PACK = {
	borderRight: "1px solid #ffff",
};

export const STYLE_KEYS = {
	letterKey: { width: "30px", height: "30px", textTransform: "uppercase" },
	ctrlKey: { width: "41px", height: "30px", textTransform: "uppercase" },
	ctrlKey2: { width: "32px", height: "30px" },
	shiftKey: { width: "44px", height: "30px", textTransform: "uppercase" },
	delKey: { width: "32px", height: "30px", textTransform: "uppercase" },
	altKey: { width: "32px", height: "30px", textTransform: "uppercase" },
	altKey2: { width: "32px", height: "30px" },
	tabKey: { width: "34px", height: "30px", textTransform: "uppercase" },
	spaceKey: { width: "52px", height: "30px", textTransform: "uppercase" },
	escapeKey: { width: "35px", height: "30px", textTransform: "uppercase" },
	enterKey: { width: "32px", height: "30px" },
	fnKey: { width: "30px", height: "30px", textTransform: "lowercase" },
	backspaceKey: { width: "32px", height: "30px" },
	arrowUpKey: { width: "30px", height: "30px" },
	arrowDownKey: { width: "30px", height: "30px", transform: "rotate(180deg)" },
	arrowLeftKey: { width: "30px", height: "30px", transform: "rotate(270deg)" },
	arrowRightKey: { width: "30px", height: "30px", transform: "rotate(90deg)" },
};

export const ATTRIBUTE_DESCRIPTION_MAX_LENGTH = 500;
export const ATTRIBUTE_NAME_MAX_LENGTH = 200;

export const attributesColumnsMapping = {
	name: "name",
	description: "description",
	inputTypeId: "attributeType",
	nbOfLevels: "numberOfLevels",
	mediaURL: "picture",
	link: "url",
	createdAt: "createdOn",
	createdById: "createdBy",
	updatedAt: "updatedAt",
	updatedById: "updatedBy",
	// pricingPlan: "pricingPlan",
	// pricingPage: "pricingPage",
	categorization: "categorization-",
};

export const descriptionGenerationTypes = {
	FEATURES: "features",
	LEVELS: "levels",
};

export const MAX_LENGTH_PACK_DESCRIPTION = 250;
