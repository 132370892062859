import { gql, useMutation } from "@apollo/client";
import { offerVersionStatusColors } from "@services/colors";
import { OFFER_OFFER_STATUS_FRAGMENT } from "@graphQl/fragments/offer";

const CREATE_MAIL_CONNECTION = gql`
	mutation CreateMailConnection($serviceId: ID!) {
		createMailConnection(serviceId: $serviceId) {
			status
			redirectUrl
		}
	}
`;

export const createMailConnectionMutation = () => {
	const [createConnection, { loading, error }] = useMutation(CREATE_MAIL_CONNECTION);
	return [createConnection, { loading, error }];
};

const DELETE_MAIL_CONNECTION = gql`
	mutation DeleteMailConnection($id: ID!) {
		deleteMailConnection(id: $id) {
			deletedId
		}
	}
`;

export const deleteMailConnectionMutation = () => {
	const [deleteConnection] = useMutation(DELETE_MAIL_CONNECTION, {
		update(cache, { data: { deleteMailConnection: _deleteConnection } }) {
			if (_deleteConnection?.deletedId) {
				const normalizedId = cache.identify({
					id: _deleteConnection.deletedId,
					__typename: "MailConnection",
				});
				cache.evict({ id: normalizedId });
				cache.gc();
			}
		},
	});
	return deleteConnection;
};

const SEND_OFFER_MAIL = gql`
	mutation sendOfferByMail(
		$id: ID!
		$to: String!
		$cc: String
		$bcc: String
		$subject: String!
		$body: String!
		$offerId: ID!
	) {
		sendOfferByMail(
			id: $id
			to: $to
			cc: $cc
			bcc: $bcc
			subject: $subject
			body: $body
			offerId: $offerId
		)
	}
`;

export const sendOfferMailMutation = ({ offerId, offerVersionId }) => {
	const [sendOfferByMail] = useMutation(SEND_OFFER_MAIL, {
		update(cache) {
			const offer = cache.readFragment({
				id: cache.identify({
					__typename: "Offer",
					id: offerId,
				}),
				fragment: OFFER_OFFER_STATUS_FRAGMENT,
				fragmentName: "OfferOfferStatusFragment",
			});

			if (offer.status.index === 0) {
				cache.modify({
					id: cache.identify({
						id: offerId,
						__typename: "OfferStatus",
					}),
					fields: {
						index() {
							return 1;
						},
						color() {
							return offerVersionStatusColors["sent"];
						},
						name() {
							return "sent";
						},
					},
				});
			}
		},
	});
	return [sendOfferByMail];
};

const SEND_SIGNATURE_BY_MAIL = gql`
	mutation SendSignatureByMail(
		$idOfOfferVersionQuote: ID!
		$language: String!
		$contactEmails: [String]
	) {
		sendSignatureByMail(
			idOfOfferVersionQuote: $idOfOfferVersionQuote
			language: $language
			contactEmails: $contactEmails
		)
	}
`;

export const sendSignatureByMailMutation = () => {
	const [sendSignatureByMail] = useMutation(SEND_SIGNATURE_BY_MAIL);
	return sendSignatureByMail;
};

const SEND_MAIL = gql`
	mutation sendMail(
		$id: ID!
		$to: String!
		$cc: String
		$bcc: String
		$subject: String!
		$body: String!
	) {
		sendMail(id: $id, to: $to, cc: $cc, bcc: $bcc, subject: $subject, body: $body)
	}
`;

export const sendMailMutation = () => {
	const [sendMail, { loading, error }] = useMutation(SEND_MAIL);
	return [sendMail, { loading, error }];
};
