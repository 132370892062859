import styled, { css } from "styled-components";

export const PeriodSelect = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	background-color: #495965;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	${props =>
		props.NoMarginLeft
			? null
			: css`
					margin-left: 30px;
			  `}
`;

export const DurationOption = styled.div`
	height: 100%;
	width: 59px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 12px;
	color: ${props => (props.isActive ? "#fff" : "#71889A")};
	${props =>
		!props.isFirst &&
		css`
			border-left: 1px solid #404d57;
		`};
	${props =>
		props.isFirst &&
		css`
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
		`};
	${props =>
		props.isLast &&
		css`
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
		`};
	cursor: pointer;
	${props =>
		props.isActive &&
		css`
			background-color: #a5b9c8;
		`};
	${props =>
		!props.isActive &&
		css`
			&:hover {
				background-color: rgba(255, 255, 255, 0.1);
				color: #a5b9c8;
			}
		`};
`;
