import styled, { css } from "styled-components";
import { Icon } from "priceit-ui";
import Tippy from "@tippyjs/react/headless";

export const WrapperGlobal = styled.div`
	border-left: 1px solid #1e272e;
`;

export const StyledTippy = styled(Tippy)`
	&.tippy-box {
		background-color: #495965;
		color: #ffffff;
	}

	.tippy-content {
		padding: 0;
	}
`;

export const WrapperBox = styled.div`
	position: relative;
	padding: 5px 10px;
	background-color: #404d57;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;

	&:hover {
		background-color: #38444d;
	}
`;

export const TitleCurrentPeriod = styled.div`
	font-size: 12px;
	margin-left: 20px;
	color: #cbcbcb;
`;

export const CPType = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
	width: 30px;
	height: 30px;
	border-radius: 8px;
	border: 4px solid #30363a;
	font-size: 14px;
	color: #fff;
`;

export const WrapperExpandLeftIcon = styled.div`
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	transition: all 0.3s ease-out;
	${props =>
		props.expanded &&
		css`
			transform: scale(-1, 1);
		`}
`;

export const ExpandLeftIcon = styled(Icon)`
	fill: #2b343b;
	width: 10px;

	${WrapperExpandLeftIcon}:hover & {
		fill: #404d57;
	}
`;

export const WhiteSpan = styled.span`
	color: #fff;
`;

export const ColorSpan = styled.span`
  color: ${props => props.color};
`;