import React, { useState } from "react";
import useResizeObserver from "use-resize-observer";
import { useTranslation } from "react-i18next";

import {
	Wrapper,
	WrapperProductsBarCustomHeader,
	OptionsSeparator,
} from "./SelectProductsBar.style";
import { ProductsDrawer } from "../../productsDrawer/ProductsDrawer";
import { Button } from "priceit-ui";
import { ProductBubble } from "@components/headerCustom/selectProductBar/components/productBubble/ProductBubble";

export const SelectProductsBar = ({
	productsSelected,
	onChangeProductsSelected,
	customHeaderInProductsBar,
	title,
}) => {
	const { t } = useTranslation("shared/header/selectProducts");
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [isHover, setIsHover] = useState(false);
	const {
		ref: wrapperProductListRef,
		width: widthWrapperProductListRef = 0,
	} = useResizeObserver();

	let numberOfProduct = 0;
	if (widthWrapperProductListRef > 170 * 12) {
		numberOfProduct = 12;
	} else if (widthWrapperProductListRef > 170 * 11) {
		numberOfProduct = 11;
	} else if (widthWrapperProductListRef > 170 * 10) {
		numberOfProduct = 10;
	} else if (widthWrapperProductListRef > 170 * 9) {
		numberOfProduct = 9;
	} else if (widthWrapperProductListRef > 170 * 8) {
		numberOfProduct = 8;
	} else if (widthWrapperProductListRef > 170 * 7) {
		numberOfProduct = 7;
	} else if (widthWrapperProductListRef > 170 * 6) {
		numberOfProduct = 6;
	} else if (widthWrapperProductListRef > 170 * 5) {
		numberOfProduct = 5;
	} else if (widthWrapperProductListRef > 170 * 4) {
		numberOfProduct = 4;
	} else if (widthWrapperProductListRef > 170 * 3) {
		numberOfProduct = 3;
	} else if (widthWrapperProductListRef > 170 * 2) {
		numberOfProduct = 2;
	} else if (widthWrapperProductListRef > 170) {
		numberOfProduct = 1;
	}

	const numberOfCurrentProducts = productsSelected.length;
	const numberOfHiddenProduct = numberOfCurrentProducts - numberOfProduct;
	const foundPlace = numberOfProduct - numberOfCurrentProducts >= 0;
	const displayProduct = !foundPlace
		? productsSelected.slice(0, numberOfProduct - numberOfCurrentProducts)
		: productsSelected;

	return (
		<>
			<Wrapper>
				<Button
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					type="select"
					style={{ overflow: "visible" }}
					onClick={() => setDrawerVisible(true)}
					styleLeftIcon={{
						fill: isHover ? "#FFFFFF" : undefined,
					}}
				>
					{t("Select revenue elements")}
				</Button>
				<div
					ref={wrapperProductListRef}
					style={{ display: "flex", flex: "1", alignItems: "center", overflow: "hidden" }}
				>
					{displayProduct !== undefined
						? displayProduct.map((product, index) => {
								return (
									<ProductBubble
										key={product.name + "z" + index}
										product={product}
										removeProduct={() => {
											onChangeProductsSelected(
												productsSelected.filter(
													element => element.id !== product.id
												)
											);
										}}
									/>
								);
						  })
						: ""}
				</div>
				<OptionsSeparator></OptionsSeparator>
				{numberOfHiddenProduct <= 0 ? (
					""
				) : (
					<Button mode="dark" type="noIcon" onClick={() => setDrawerVisible(true)}>
						{t("{{numberOfCurrentProducts}} revenue line", {
							numberOfCurrentProducts: numberOfCurrentProducts,
							count: numberOfCurrentProducts || 1,
						})}
					</Button>
				)}

				<WrapperProductsBarCustomHeader>
					{customHeaderInProductsBar}
				</WrapperProductsBarCustomHeader>
			</Wrapper>

			<ProductsDrawer
				onCloseDrawer={() => setDrawerVisible(false)}
				drawerVisible={drawerVisible}
				productsSelected={productsSelected}
				onConfirmSelection={onChangeProductsSelected}
				title={title}
				view={"productsListView"}
			/>
		</>
	);
};
