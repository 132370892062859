import React from "react";
import { Button, SingleSelect } from "priceit-ui";
import { WrapperFooterSelect } from "./SimulationsSelect.style";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";

const SimulationsSelect = ({
	currentValue,
	setCurrent,
	selectData,
	noSelectFooter,
	onShowSimulations,
	selectFooter,
}) => {
	const { t } = useTranslation("shared/header/saveBar");
	const { mode } = useThemeContext();
	const selectDataSortByName = selectData
		.map(element => ({
			...element,
		}))
		.sort((a, b) => a?.name.localeCompare(b?.name));
	return (
		<SingleSelect.Group
			mode={mode}
			height={"30px"}
			width={"300px"}
			showSearchBar
			placeHolderSearch={t("Search")}
			onChange={id => setCurrent(id)}
			value={currentValue}
			appendTo={document.body}
			placeHolder={"-"}
			data={selectDataSortByName?.map(item => ({
				option: (
					<SingleSelect.Option key={item?.id + "y" + item?.name}>
						{item?.name}
					</SingleSelect.Option>
				),
				value: item?.id,
			}))}
			footer={
				!noSelectFooter ? (
					<WrapperFooterSelect>
						<Button mode={mode} type="noIcon" onClick={() => onShowSimulations()}>
							{selectFooter}
						</Button>
					</WrapperFooterSelect>
				) : null
			}
		/>
	);
};

export default SimulationsSelect;
