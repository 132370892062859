import { gql, useMutation } from "@apollo/client";
import {
	LAST_OFFER_VERSION_FRAGMENT,
	OFFER_VERSION_CONFIRMATION_FRAGMENT,
	OFFER_VERSION_CREATION_FRAGMENT,
	OFFER_VERSION_ID_FRAGMENT,
} from "@graphQl/fragments/offerVersion";
import { OFFER_TOKEN_FRAGMENT, PUBLIC_OFFER_ID_FRAGMENT } from "@graphQl/fragments/offer";

export const CREATE_OFFER_VERSION = gql`
	${OFFER_VERSION_CREATION_FRAGMENT}
	${LAST_OFFER_VERSION_FRAGMENT}
	mutation CreateOfferVersion($createOfferVersion: CreateOfferVersion) {
		createOfferVersion(offerVersion: $createOfferVersion) {
			...OfferVersionCreationFragment
			...LastOfferVersionFragment
		}
	}
`;

export const createOfferVersionMutation = () => {
	const [createOfferVersion, { loading, error }] = useMutation(CREATE_OFFER_VERSION, {
		update(cache, { data: { createOfferVersion } }) {
			const newOfferVersionRef = cache.writeFragment({
				data: createOfferVersion,
				fragment: OFFER_VERSION_ID_FRAGMENT,
			});
			cache.modify({
				id: cache.identify({
					__typename: "Offer",
					id: createOfferVersion.offerId,
				}),
				fields: {
					offerVersions(existingOfferVersionsRef) {
						return [newOfferVersionRef, ...existingOfferVersionsRef];
					},
				},
			});
			const offer = cache.readFragment({
				id: cache.identify({
					__typename: "Offer",
					id: createOfferVersion.offerId,
				}),
				fragment: OFFER_TOKEN_FRAGMENT,
				fragmentName: "OfferTokenFragment",
			});
			const publicOfferCache = cache.identify({
				tokenObj: {
					token: offer.tokenObj.token,
				},
				__typename: "PublicOffer",
			});
			if (!!publicOfferCache) {
				cache.evict({ id: publicOfferCache });
			}
			cache.writeFragment({
				data: {
					id: offer.id,
					tokenObj: {
						token: offer.tokenObj.token,
					},
					__typename: "PublicOffer",
				},
				fragment: PUBLIC_OFFER_ID_FRAGMENT,
			});
		},
	});
	return [createOfferVersion, { loading, error }];
};

const CONFIRM_OFFER_VERSION_CREATION = gql`
	${OFFER_VERSION_CONFIRMATION_FRAGMENT}
	mutation ConfirmOfferVersionCreation($id: ID!) {
		confirmOfferVersionCreation(id: $id) {
			...OfferVersionConfirmationFragment
		}
	}
`;

export const confirmOfferVersionCreationMutation = () => {
	const [confirmOfferVersionCreation, { loading }] = useMutation(CONFIRM_OFFER_VERSION_CREATION, {
		update(cache, { data: { confirmOfferVersionCreation } }) {
			cache.modify({
				id: cache.identify({
					__typename: "Offer",
					id: confirmOfferVersionCreation.offerId,
				}),
				fields: {
					minAmount() {
						return confirmOfferVersionCreation?.offer?.minAmount || 0;
					},
				},
			});
		},
	});

	return [confirmOfferVersionCreation, { loading }];
};

const DELETE_OFFER_VERSION = gql`
	${OFFER_VERSION_ID_FRAGMENT}
	mutation DeleteOfferVersion($id: ID!) {
		deleteOfferVersion(id: $id) {
			...OfferVersionIdFragment
		}
	}
`;

export const deleteOfferVersionMutation = ({ offerId }) => {
	const [deleteOfferVersion, { loading, error }] = useMutation(DELETE_OFFER_VERSION, {
		update(cache, { data: { deleteOfferVersion } }) {
			const offer = cache.readFragment({
				id: cache.identify({
					__typename: "Offer",
					id: offerId,
				}),
				fragment: OFFER_TOKEN_FRAGMENT,
				fragmentName: "OfferTokenFragment",
				returnPartialData: true,
			});

			const offerVersion = cache.identify({
				__typename: "OfferVersion",
				id: deleteOfferVersion.id,
			});

			const offerNormalized = cache.identify({
				__typename: "Offer",
				id: offerId,
			});
			cache.retain(offerNormalized);

			if (offer?.tokenObj?.token) {
				const publicOfferNormalized = cache.identify({
					__typename: "PublicOffer",
					tokenObj: { token: offer.tokenObj.token },
				});
				cache.evict({ id: publicOfferNormalized });
			}

			cache.evict({ id: offerVersion });

			cache.gc();
		},
	});
	return [deleteOfferVersion, { loading, error }];
};
