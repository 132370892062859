import styled from "styled-components";

export const WrapperIframe = styled.div`
	height: 100vh;
	display: flex;
`;

export const WrapperZoom = styled.div`
	flex: 1;
	display: flex;
	transform: scale(0.7);
	margin: ${props => props.topBottomMargin}px ${props => props.leftRightMargin}px;
`;
