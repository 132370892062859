import styled, { css } from "styled-components";

export const WrapperContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	color: unset;
	background-color: ${({ theme }) => theme.page.backgroundColor};
	position: relative;

	& > div {
		flex: 1;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	${({ allowOverflow }) =>
		!allowOverflow &&
		css`
			overflow: hidden;
		`}
`;

export const LeftPanel = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: ${({ theme }) => theme.page.backgroundColor};
	overflow: hidden;
`;
