import React from "react";
import { Button } from "priceit-ui";
import { useThemeContext } from "@theme/Theme";
import { useTranslation } from "react-i18next";
import { useCloseEditingPreselection } from "@pages/clientsOffers/hooks/useCloseEditingPreselection";

export const CloseButton = React.memo(({}) => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode } = useThemeContext();

	const handleCloseAction = useCloseEditingPreselection();

	return (
		<Button mode={mode} type={"secondary"} width={"160px"} onClick={handleCloseAction}>
			{t("Close")}
		</Button>
	);
});
CloseButton.whyDidYouRender = true;
CloseButton.displayName = "CloseButton";
export default CloseButton;
