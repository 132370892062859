import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { FlowControl } from "priceit-ui";
import { sectionName } from "@recoil/clientOffers";
import { StyledIcon, Wrapper, WrapperFlowControl } from "./FlowPanel.style";
import { useThemeContext } from "@theme/Theme";
import {
	clientOffersIsEditingPreselectionAtom,
	clientsOffersCreationStepAtom,
} from "@recoil/clientOffers/atom";

export const FlowPanel = memo(({ hasOnlyOnePriceModelCombination }) => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode } = useThemeContext();

	const creationStep = useRecoilValue(clientsOffersCreationStepAtom);
	const isEditingPreselection = useRecoilValue(clientOffersIsEditingPreselectionAtom);

	const stepsIndexes = [
		sectionName.offerCreationStep.templateSelection,
		sectionName.offerCreationStep.customization,
		sectionName.offerCreationStep.preSelectionOfOptions,
	];
	if (hasOnlyOnePriceModelCombination) {
		stepsIndexes.shift();
	}

	const currentStepIndex = stepsIndexes.indexOf(creationStep);

	const checkMarkIcon = <StyledIcon type={"checkMark2"} />;

	const steps = [
		{
			label: t("Model selection"),
			indicator:
				currentStepIndex >=
				stepsIndexes.indexOf(sectionName.offerCreationStep.templateSelection)
					? checkMarkIcon
					: null,
		},
		{
			label: t("Customization"),
			indicator:
				currentStepIndex >=
				stepsIndexes.indexOf(sectionName.offerCreationStep.customization)
					? checkMarkIcon
					: null,
		},
		{
			label: t("Default configuration"),
			indicator:
				currentStepIndex >=
				stepsIndexes.indexOf(sectionName.offerCreationStep.preSelectionOfOptions)
					? checkMarkIcon
					: null,
		},
	];
	if (hasOnlyOnePriceModelCombination) {
		steps.shift();
	}

	if (creationStep === sectionName.refreshTemplate || isEditingPreselection) {
		return null;
	}

	return (
		<Wrapper>
			<WrapperFlowControl>
				<FlowControl steps={steps} currentStepIndex={currentStepIndex} mode={mode} />
			</WrapperFlowControl>
		</Wrapper>
	);
});
FlowPanel.whyDidYouRender = true;
FlowPanel.displayName = "FlowPanel";
export default FlowPanel;
