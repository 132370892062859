import { gql, useMutation } from "@apollo/client";
import {
	HAS_PASSWORD_TOKEN_FRAGMENT,
	TOKEN_ACTIVE_FRAGMENT,
	TOKEN_ENABLE_EXPIRATION_DATE_FRAGMENT,
	TOKEN_ENABLE_PASSWORD_FRAGMENT,
	TOKEN_EXPIRATION_DATE_FRAGMENT,
	TOKEN_FRAGMENT,
	TOKEN_HAS_PASSWORD_FRAGMENT,
	TOKEN_IS_EXPIRED_FRAGMENT,
} from "@graphQl/fragments/token";

const UPDATE_TOKEN_ENABLE_EXPIRATION_DATE = gql`
	${TOKEN_ENABLE_EXPIRATION_DATE_FRAGMENT}
	${TOKEN_EXPIRATION_DATE_FRAGMENT}
	mutation UpdateTokenEnableExpirationDate($token: UpdateToken!) {
		updateToken(token: $token) {
			...TokenEnableExpirationDateFragment
			...TokenExpirationDateFragment
		}
	}
`;

export const updateTokenEnableExpirationDateMutation = () => {
	const [updateTokenEnableExpirationDate, { loading, error }] = useMutation(
		UPDATE_TOKEN_ENABLE_EXPIRATION_DATE
	);
	return [updateTokenEnableExpirationDate, { loading, error }];
};

const UPDATE_TOKEN_EXPIRATION_DATE = gql`
	${TOKEN_EXPIRATION_DATE_FRAGMENT}
	${TOKEN_IS_EXPIRED_FRAGMENT}
	${TOKEN_ACTIVE_FRAGMENT}
	${HAS_PASSWORD_TOKEN_FRAGMENT}
	${TOKEN_ENABLE_EXPIRATION_DATE_FRAGMENT}
	mutation UpdateTokenExpirationDate($token: UpdateToken!) {
		updateToken(token: $token) {
			...TokenExpirationDateFragment
			...TokenIsExpiredFragment
			...TokenActiveFragment
			...HasPasswordTokenFragment
			...TokenEnableExpirationDateFragment
		}
	}
`;

export const updateTokenExpirationDateMutation = () => {
	const [updateTokenExpirationDate, { loading, error }] = useMutation(
		UPDATE_TOKEN_EXPIRATION_DATE
	);
	return [updateTokenExpirationDate, { loading, error }];
};

const UPDATE_TOKEN_ENABLE_PASSWORD = gql`
	${TOKEN_ENABLE_PASSWORD_FRAGMENT}
	mutation UpdateTokenEnablePassword($token: UpdateToken!) {
		updateToken(token: $token) {
			...TokenEnablePasswordFragment
		}
	}
`;

export const updateTokenEnablePasswordMutation = () => {
	const [updateTokenEnablePassword, { loading, error }] = useMutation(
		UPDATE_TOKEN_ENABLE_PASSWORD
	);
	return [updateTokenEnablePassword, { loading, error }];
};

const UPDATE_TOKEN_PASSWORD = gql`
	${TOKEN_HAS_PASSWORD_FRAGMENT}
	mutation UpdateTokenPassword($token: UpdateToken!) {
		updateToken(token: $token) {
			...TokenHasPasswordFragment
		}
	}
`;

export const updateTokenPasswordMutation = () => {
	const [updateTokenPassword, { loading, error }] = useMutation(UPDATE_TOKEN_PASSWORD);
	return [updateTokenPassword, { loading, error }];
};

const UPDATE_TOKEN = gql`
	${TOKEN_FRAGMENT}
	mutation UpdateToken($token: UpdateToken!) {
		updateToken(token: $token) {
			...TokenFragment
		}
	}
`;

export const updateTokenMutation = () => {
	const [updateToken, { loading, error }] = useMutation(UPDATE_TOKEN);
	return [updateToken, { loading, error }];
};

const UPDATE_PASSWORD_TOKEN = gql`
	${HAS_PASSWORD_TOKEN_FRAGMENT}
	mutation UpdatePasswordToken($token: UpdateToken!) {
		updateToken(token: $token) {
			...HasPasswordTokenFragment
		}
	}
`;

export const updatePasswordTokenMutation = () => {
	const [updatePasswordToken, { loading, error }] = useMutation(UPDATE_PASSWORD_TOKEN);
	return [updatePasswordToken, { loading, error }];
};
