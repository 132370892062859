import { useQuery } from "@apollo/client";
import { GET_AUTH } from "@graphQl/queries/user";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const useAuth = () => {
	const { workspaceId } = useParams();
	const { loading: loadingAuth, data: dataAuth } = useQuery(GET_AUTH, {
		variables: {
			workspaceId,
		},
	});

	return {
		auth: dataAuth?.getAuth || {},
		currency: dataAuth?.getAuth?.currency,
		loadingAuth,
		role: dataAuth?.getAuth?.role || {},
	};
};
