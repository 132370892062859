import React from "react";
import { SingleSelect } from "priceit-ui";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { clientOffersTemplateFiltersSelector } from "@recoil/clientOffers/clientsOffersSelector";
import { useThemeContext } from "@theme/Theme";

export const CategoriesSelect = React.memo(({ categorization }) => {
	const { t } = useTranslation("clientsOffers/newOffer");
	const { mode } = useThemeContext();
	const [filter, setFilter] = useRecoilState(clientOffersTemplateFiltersSelector);

	return (
		<SingleSelect.Group
			type="secondary"
			mode={mode}
			appendTo={document.body}
			width={"unset"}
			widthOption="250px"
			widthOptionColoredBox="100%"
			onChange={e => {
				if (e) {
					setFilter({
						key: categorization.id,
						newValue: e === filter?.[categorization.id] ? null : e,
					});
				}
			}}
			value={filter?.[categorization.id]}
			placeHolder={t("All categories")}
			data={categorization.categories.map(category => ({
				value: category?.id,
				color: category.color,
				option: (
					<SingleSelect.Option value={category?.id}>{category.name}</SingleSelect.Option>
				),
			}))}
		/>
	);
});
CategoriesSelect.whyDidYouRender = true;
CategoriesSelect.displayName = "CategoriesSelect";
