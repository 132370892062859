export const isErrorAddress = ({ address, isMandatory }) => {
	let isError = false;
	if (!address) {
		if (isMandatory) return true;
		else return false;
	}
	if (isMandatory) {
		isError = Object.keys(address)?.some(
			field =>
				!["address2", "__typename", "id"].includes(field) &&
				["", null].includes(address[field])
		);
	} else {
		isError = !(
			Object.keys(address)?.every(
				field =>
					["address2", "__typename", "id"].includes(field) ||
					!["", null].includes(address[field])
			) ||
			Object.keys(address)?.every(
				field => ["__typename", "id"].includes(field) || ["", null].includes(address[field])
			)
		);
	}
	return isError;
};
