import styled, { css } from "styled-components";
import { Icon, Input, InputNumber } from "priceit-ui";
import { customGap } from "@services/Utils.style";

export const MyPeriodHeaderColumnTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0 15px;
	border-right: 1px solid black;
`;

export const ContainerSearchBar = styled.div`
	margin-right: 150px;
`;

export const MyPeriodHeaderColumnText = styled.div`
	color: #ffcd38;
	margin-left: 5px;
`;

export const MyPeriodHeaderColumnIcon = styled(Icon)`
	fill: #ffcd38;
`;

export const CPDatesCreate = styled.div`
	display: flex;
	align-items: center;
`;

export const CPDatesDuplicate = styled.div`
	display: flex;
	align-items: center;
	width: 212px;
	margin-left: 10px;
	margin-right: 15px;
`;

export const TimeBubble = styled.div`
	margin-right: 15px;
	min-width: 81px;
	width: 81px;
	height: 30px;
	border: 0.5px solid #cbcbcb;
	border-radius: 20px;
	font-size: 14px;
	padding-left: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${props => props.color};
	border-color: ${props => props.color};
`;

export const CPDuration = styled.div`
	color: #a5b9c8;
	white-space: nowrap;
	font-style: italic;
`;

export const RightArrowIcon = styled(Icon)`
	fill: #a5b9c8;
	width: 12px;
	transform: rotate(90deg) scaleY(0.6) scaleX(0.9);
	margin: 0 15px;
`;

export const CPType = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 8px;
	border: 4px solid #30363a;
	font-size: 14px;
	color: #fff;
`;

export const WhiteSpan = styled.span`
	color: #fff;
`;

export const HeaderDrawer = styled.div`
	height: 70px;
	width: 100%;
	background-color: #1e272e;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const WrapperPeriodLeftHeader = styled.div`
	display: flex;
`;

export const TableContainer = styled.div`
	height: calc(100% - 70px);
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #404d57;
	border-top: 1px solid #1e272e;
	justify-content: space-between;
`;
export const WrapperButtons = styled.div`
	display: flex;
	margin-bottom: 35px;
`;

export const ColorSpan = styled.span`
	color: ${props => props.color};
`;

export const WrapperCreatePeriod = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CheckButton = styled(Icon)`
	width: 18px;
	fill: ${props => props.iconColorFill};
	transition: 0.2s all ease-in-out;
	cursor: pointer;
	&:hover {
		fill: white;
		transform: scale(1.1);
	}
`;

export const WrapperModifyActions = styled.div`
	display: flex;
	flex-flow: column;
	height: 61px;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
`;

export const CPNameInputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const TopTitleInput = styled.div`
	color: #a5b9c8;
	font: normal normal normal 12px/15px Lato;
	margin-bottom: 5px;
`;

export const WrapperCellInputText = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	display: ${props => (props.invisible ? "none" : "flex")};
	flex-direction: column;
`;
export const WrapperCenterArrowDate = styled.div`
	display: flex;
	align-items: center;
`;

export const CPRealTotalCostInputWrapper = styled.div`
	margin-right: 15px;
	margin-left: 15px;
	border: 2px solid rgb(119, 122, 126);
	border-radius: 3px;
	display: ${props => (props.visible ? "block" : "none")};
`;

export const CPCommentInputWrapper = styled.div`
	margin-right: 15px;
`;

export const InputName = styled(Input)`
	transition: border-color 0.3s ease;
`;

export const StyledInputNumber = styled(InputNumber)`
	transition: border-color 0.3s ease;
`;

export const InputRealRevenue = styled(InputNumber)`
	transition: border-color 0.3s ease;
	display: ${props => (props.visible ? "block" : "none")};
`;

export const InputRealCost = styled(InputNumber)`
	transition: border-color 0.3s ease;
`;

export const WrapperCustomHeader = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px 40px 42px;
`;

export const WrapperCustomHeaderFirstLine = styled.div`
	display: flex;
	${customGap("10px")};
	width: 100%;
	padding-bottom: 10px;
`;

export const CustomHeaderTitle = styled.div`
	color: #e65757;
`;

export const CustomHeaderSubTitle = styled.div`
	color: white;
	width: 100%;
`;

export const WrapperLoader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
`;

export const WrapperErrorIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ErrorIcon = styled(Icon)`
	fill: #ff6060;
	width: 12px;
`;

export const TooltipContent = styled.div`
	display: flex;
	flex-flow: wrap;
	font-style: normal;
	max-width: 250px;
`;

export const WrapperDuration = styled.div`
	display: flex;
`;

export const ErrorDuration = styled.div`
	color: black;
`;
