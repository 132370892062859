export const Permission = {
	full: "full",
	assigned: "assigned",
	none: "none",
};

export const defaultPermissionsTypesIndex = {
	full: 2,
	assigned: 1,
	none: 0,
};

export const defaultPermissionsTypesList = [
	{
		key: Permission.full,
		textKey: "Full permission",
		iconType: "checkMark",
		color: "#78ce5e",
		index: defaultPermissionsTypesIndex.full,
	},
	{
		key: Permission.none,
		textKey: "No permission",
		iconType: "cross",
		color: "#ff6060",
		index: defaultPermissionsTypesIndex.none,
	},
	{
		key: Permission.assigned,
		textKey: "Permission only if created (or duplicated) by the user",
		iconType: "user",
		color: "#6ea8ff",
		index: defaultPermissionsTypesIndex.assigned,
	},
];

// prettier-ignore
export const actionPermissions = {
	// –––––– Settings –––––– Workspace
	// Read the general infos about the workspace (name, image, location,...)
	"workspace_read":                     [Permission.full, Permission.none],
	// Modify the general infos about the workspace (name, image, location,...)
	"workspace_edit":                     [Permission.full, Permission.none],
	// Delete the workspace and all its data
	"workspace_delete":                   [Permission.full, Permission.none],

	// –––––– Settings –––––– Company
	// Read the company infos for the workspace (company name, logo, coordinates, design, CGU)
	"company_read":                       [Permission.full, Permission.none],
	// Edit the company's general infos (company name, logo, coordinates,...)
	"company_editGeneral":                [Permission.full, Permission.none],
	// Edit the company's Design elements
	"company_editDesign":                 [Permission.full, Permission.none],
	// Edit the company's CGUs
	"company_editCGU":                    [Permission.full, Permission.none],
	// Edit the company's VAT rates
	"company_editVAT":                    [Permission.full, Permission.none],

	// –––––– Settings –––––– Billing
	// Read the billing infos
	"billing_read":                       [Permission.full, Permission.none],
	// Edit the billing infos
	"billing_edit":                       [Permission.full, Permission.none],
	// Edit the Givemefive plan for the workspace
	"plan_edit":                          [Permission.full, Permission.none],

	// –––––– Settings –––––– Users
	// View the workspace's users
	"user_read":                          [Permission.full, Permission.none],
	// Create a new user and give him a role
	"user_create":                        [Permission.full, Permission.none],
	// Edit a user's role
	"user_editRole":                      [Permission.full, Permission.none],
	// Delete a user
	"user_delete":                        [Permission.full, Permission.none],

	// –––––– Settings –––––– Roles
	// View the workspace's roles
	"role_read":                          [Permission.full, Permission.none],
	// Create a role
	"role_create":                        [Permission.full, Permission.none],
	// Edit a role
	"role_edit":                          [Permission.full, Permission.none],
	// Delete a role
	"role_delete":                        [Permission.full, Permission.none],

	// –––––– Settings –––––– Integrations
	// View the various CRM/Signature/Mail integrations
	"integration_read":                   [Permission.full, Permission.assigned, Permission.none],
	// Add a new integration
	"integration_create":                 [Permission.full, Permission.none],
	// Edit the settings of an integration
	"integration_edit":                   [Permission.full, Permission.assigned, Permission.none],
	// Remove an integration
	"integration_delete":                 [Permission.full, Permission.assigned, Permission.none],

	// –––––– Features library
	// Read the features library
	"feature_read":                       [Permission.full, Permission.assigned, Permission.none],
	// Create a feature and its associated models
	"feature_create":                     [Permission.full, Permission.none],
	// Edit a feature and its associated models
	"feature_edit":                       [Permission.full, Permission.assigned, Permission.none],
	// Duplicate a feature and its associated models
	"feature_duplicate":                  [Permission.full, Permission.none],
	// Delete a feature and its associated models
	"feature_delete":                     [Permission.full, Permission.assigned, Permission.none],

	// –––––– Price Models library
	// View a priceModel and its associated models
	"priceModel_read":                    [Permission.full, Permission.assigned, Permission.none],
	// Create a priceModel and its associated models
	"priceModel_create":                  [Permission.full, Permission.none],
	// Edit a priceModel and its associated models
	"priceModel_edit":                    [Permission.full, Permission.assigned, Permission.none],
	// Duplicate a priceModel and its associated models
	"priceModel_duplicate":               [Permission.full, Permission.none],
	// Delete a priceModel and its associated models
	"priceModel_delete":                  [Permission.full, Permission.assigned, Permission.none],

	// –––––– Price Model Combinations library
	// View a price model combination and associated models
	"priceModelCombination_read":         [Permission.full, Permission.assigned, Permission.none],
	// Create a price model combination and associated models
	"priceModelCombination_create":       [Permission.full, Permission.none],
	// Edit a price model combination and associated models
	"priceModelCombination_edit":         [Permission.full, Permission.assigned, Permission.none],
	// Duplicate a price model combination and associated models
	"priceModelCombination_duplicate":    [Permission.full, Permission.none],
	// Delete a price model combination and associated models
	"priceModelCombination_delete":       [Permission.full, Permission.assigned, Permission.none],

	// –––––– Offers pipeline
	// View an offer and associated models
	"offer_read":                         [Permission.full, Permission.assigned, Permission.none],
	// Create an offer and associated models
	"offer_create":                       [Permission.full, Permission.none],
	// View a template and associated models for offer creation
	"offer_priceModelCombination_read":   [Permission.full, Permission.assigned, Permission.none],
	// Personalize an offer (e.g. select default options)
	"offer_personalize": 				  [Permission.full, Permission.assigned, Permission.none],
	// Edit an offer and associated models
	"offer_edit":                         [Permission.full, Permission.assigned, Permission.none],
	// Delete an offer and associated models
	"offer_delete":                       [Permission.full, Permission.assigned, Permission.none],

	// –––––– Price Sensitivity Analyser
	// Access the Price Sensitivity Analyser module
	"psa":                                [Permission.full, Permission.none],

	// –––––– Financial Models
	// Access the financial models dashboard (periods)
	"period":                             [Permission.full, Permission.none],
	// Access the product information
	"product":                            [Permission.full, Permission.none],
	// Access variable costs
	"variableCost":                       [Permission.full, Permission.none],
	// Access fixed costs
	"fixedCost":                          [Permission.full, Permission.none],
	// Access profitability infos
	"profitability":                      [Permission.full, Permission.none],

	// –––––– Business Casing
	// Access potential infos
	"potential":                          [Permission.full, Permission.none],
	// Access simulation infos
	"simulation":                         [Permission.full, Permission.none],
	// Access cost based infos
	"costBased":                          [Permission.full, Permission.none],

	// –––––– Specific analysis
	// Access distribution infos
	"distribution":                       [Permission.full, Permission.none],

	// –––––– Pricing Roadmap
	// Access the roadmap module
	"roadmap":                            [Permission.full, Permission.none],

	// –––––– Learning Modules
	// Access perceived value infos
	"perceivedValue":                     [Permission.full, Permission.none],
	// Access the value map
	"valueMap":                           [Permission.full, Permission.none],
	// Access the tips
	"tips":                               [Permission.full, Permission.none],

	// –––––– Givemefive AI
	// Use of AI
	"ai":     [Permission.full, Permission.assigned, Permission.none],
};

export const permissionsActionsKey = Object.keys(actionPermissions).reduce((acc, key) => {
	acc[key] = key;
	return acc;
}, {});

export const permissionsActionsList = [
	{
		name: "Settings - Workspace",
		key: "settingsWorkspacePermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.workspace_read,
				name: "See the general data of the workspace",
				infoText:
					"The general data of the workspace is the name, the image and the geographical area.",
			},
			{
				key: permissionsActionsKey.workspace_edit,
				name: "Modify workspace data",
				infoText:
					"Gives the user permission to modify workspace data such as name, image or geographic area.",
			},
			{
				key: permissionsActionsKey.workspace_delete,
				name: "Delete workspace",
				infoText:
					"Gives the user permission to delete the workspace and all the data it contains.",
			},
		],
	},
	{
		name: "Settings - Company",
		key: "settingsCompanyPermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.company_read,
				name: "View company data",
				infoText:
					"The company data is the name, logo and contact information of the company, as well as the graphic design and legal data.",
			},
			{
				key: permissionsActionsKey.company_editGeneral,
				name: "Modify the general data of the company",
				infoText:
					"Gives the user permission to change general company information such as name, logo and contact information that will appear on pricing pages and order forms.",
			},
			{
				key: permissionsActionsKey.company_editDesign,
				name: "Modify the design and the graphic charter",
				infoText:
					"Gives the user permission to change the design and corporate identity that will appear on pricing pages and order forms.",
			},
			{
				key: permissionsActionsKey.company_editCGU,
				name: "Modify the legal data (TOS)",
				infoText:
					"Gives the user permission to edit the company's terms and conditions files that will appear on pricing pages and purchase orders.",
			},
			{
				key: permissionsActionsKey.company_editVAT,
				name: "Modify the taxing data of the company",
				infoText: "Gives the user permission to edit the company's VAT information.",
			},
		],
	},
	{
		name: "Settings - Users",
		key: "settingsUsersPermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.user_read,
				name: "See the users of the workspace",
				infoText: "Gives the user permission to see other users in the workspace.",
			},
			{
				key: permissionsActionsKey.user_create,
				name: "Create a user",
				infoText:
					"Gives the user permission to create another user and grant him a role less than or equal to his own role.",
			},
			{
				key: permissionsActionsKey.user_editRole,
				name: "Change the role of a user",
				infoText:
					"Gives the user permission to modify the role of a user with a role strictly inferior to his own role and that he can see. He can grant him a role inferior or equal to his own role.",
			},
			{
				key: permissionsActionsKey.user_delete,
				name: "Delete a user",
				infoText:
					"Gives the user permission to delete another user with a role strictly inferior to his own role.",
			},
		],
	},
	{
		name: "Settings - User roles",
		key: "settingsUserRolesPermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.role_read,
				name: "View user roles",
				infoText:
					"Gives the user permission to view the user roles defined in the workspace.",
			},
			{
				key: permissionsActionsKey.role_create,
				name: "Create a user role",
				infoText:
					"Gives the user permission to create a role at least strictly inferior to his own role.",
			},
			{
				key: permissionsActionsKey.role_edit,
				name: "Modify user roles",
				infoText:
					"Gives the user permission to modify the definition of a role he created himself or strictly inferior to his own role.",
			},
			{
				key: permissionsActionsKey.role_delete,
				name: "Delete a user role",
				infoText:
					"Gives the user permission to delete a role that he has created himself or strictly inferior to his own role.",
			},
		],
	},
	{
		name: "Settings - Integrations",
		key: "settingsIntegrationsPermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.integration_read,
				name: "See the integrations",
				infoText: "Gives the user permission to view integrations.",
			},
			{
				key: permissionsActionsKey.integration_create,
				name: "Create an integration",
				infoText: "Gives permission to create an integration.",
			},
			{
				key: permissionsActionsKey.integration_edit,
				name: "Modify an integration",
				infoText: "Gives the user permission to modify an integration.",
			},
			{
				key: permissionsActionsKey.integration_delete,
				name: "Delete an integration",
				infoText: "Gives the user permission to delete an integration.",
			},
		],
	},
	{
		name: "Settings - Billing",
		key: "settingsBillingPermissions",
		moduleWorkspacePermissionsKey: "always",
		columns: [
			{
				key: permissionsActionsKey.billing_read,
				name: "View billing data",
				infoText: "Gives the user permission to view Givemefive's billing data.",
			},
			{
				key: permissionsActionsKey.billing_edit,
				name: "Modify billing data",
				infoText:
					"Gives the user permission to change the billing information. This corresponds to the payment method and the billing address.",
			},
			{
				key: permissionsActionsKey.plan_edit,
				name: "Modify the Givemefive plan",
				infoText:
					"Gives the user permission to make a request to change the selected Givemefive plan",
			},
		],
	},
	{
		name: "Value attributes",
		key: "attributesLibraryPermissions",
		moduleWorkspacePermissionsKey: "attributesLibrary",
		columns: [
			{
				key: permissionsActionsKey.feature_read,
				name: "See the attributes",
				infoText:
					"Gives the user permission to view the list of attributes in the library.",
			},
			{
				key: permissionsActionsKey.feature_create,
				name: "Create an attribute",
				infoText: "Gives the user permission to create an attribute in the library.",
			},
			{
				key: permissionsActionsKey.feature_edit,
				name: "Modify an attribute",
				infoText: "Gives the user permission to modify an attribute of the library.",
			},
			{
				key: permissionsActionsKey.feature_duplicate,
				name: "Duplicate an attribute",
				infoText:
					"Gives the user permission to duplicate an attribute of the library. The user becomes the creator of the duplicated attribute.",
			},
			{
				key: permissionsActionsKey.feature_delete,
				name: "Delete an attribute",
				infoText:
					"Gives the user permission to delete an attribute from the library. The attribute will then be removed from the plans where it is used.",
			},
		],
	},
	{
		name: "Plans library",
		key: "plansLibraryPermissions",
		moduleWorkspacePermissionsKey: "pricingPlansLibrary",
		columns: [
			{
				key: permissionsActionsKey.priceModel_read,
				name: "See the plans",
				infoText: "Gives the user permission to view the list of plans.",
			},
			{
				key: permissionsActionsKey.priceModel_create,
				name: "Create a plan",
				infoText: "Gives the user permission to create a plan.",
			},
			{
				key: permissionsActionsKey.priceModel_edit,
				name: "Modify a plan",
				infoText:
					"Gives the user permission to modify plans and their pricing and attributes, general plan metrics and plan price differentiation rules (volume rules, commitment rules, payment terms, specific discounts and surcharges, and recurring or one-time charges).",
			},
			{
				key: permissionsActionsKey.priceModel_duplicate,
				name: "Duplicate a plan",
				infoText:
					"Gives the user permission to duplicate a plan. The user becomes the creator of the duplicated plan.",
			},
			{
				key: permissionsActionsKey.priceModel_delete,
				name: "Delete a plan",
				infoText: "Gives the user permission to delete a plan.",
			},
		],
	},
	{
		name: "Offer templates library",
		key: "offerTemplatesLibraryPermissions",
		moduleWorkspacePermissionsKey: "offerTemplatesLibrary",
		columns: [
			{
				key: permissionsActionsKey.priceModelCombination_read,
				name: "See the offer templates",
				infoText: "Gives the user permission to view the list of offer templates.",
			},
			{
				key: permissionsActionsKey.priceModelCombination_create,
				name: "Create an offer template",
				infoText: "Gives the user permission to create an offer template.",
			},
			{
				key: permissionsActionsKey.priceModelCombination_edit,
				name: "Modify an offer template",
				infoText: "Gives the user permission to modify the offer templates.",
			},
			{
				key: permissionsActionsKey.priceModelCombination_duplicate,
				name: "Duplicate an offer template",
				infoText:
					"Gives the user permission to duplicate an offer template. The user becomes the creator of the duplicated offer template.",
			},
			{
				key: permissionsActionsKey.priceModelCombination_delete,
				name: "Delete an offer template",
				infoText: "Gives the user permission to delete an offer template.",
			},
		],
	},
	{
		name: "Offers pipeline",
		key: "offersPipelinePermissions",
		moduleWorkspacePermissionsKey: "offersPipeline",
		columns: [
			{
				key: permissionsActionsKey.offer_read,
				name: "See the offers",
				infoText: "Gives the user permission to view the list of offers.",
			},
			{
				key: permissionsActionsKey.offer_create,
				name: "Create an offer",
				infoText: "Gives the user permission to create an offer.",
			},
			{
				key: permissionsActionsKey.offer_priceModelCombination_read,
				name: "See the offer templates",
				infoText:
					"Gives the user permission to view the list of offer templates when creating an offer.",
			},
			{
				key: permissionsActionsKey.offer_personalize,
				name: "Personalize the offer",
				infoText:
					"Gives the user permission to modify the options selected by default in an offer when it is created.",
			},
			{
				key: permissionsActionsKey.offer_edit,
				name: "Modify an offer",
				infoText: "Gives the user permission to modify offers.",
			},
			{
				key: permissionsActionsKey.offer_delete,
				name: "Delete an offer",
				infoText: "Gives the user permission to delete an offer.",
			},
		],
	},
	{
		name: "Price sensitivity analyser",
		key: "psaPermissions",
		moduleWorkspacePermissionsKey: "priceSensitivityAnalyser",
		columns: [
			{
				key: permissionsActionsKey.psa,
				name: "Access to the module",
			},
		],
	},
	{
		name: "Financial model",
		key: "financialModelPermissions",
		moduleWorkspacePermissionsKey: "conditional",
		columns: [
			{
				key: permissionsActionsKey.period,
				moduleWorkspacePermissionsKey: "dashboard",
				name: "Dashboard",
			},
			{
				key: permissionsActionsKey.product,
				moduleWorkspacePermissionsKey: "revenue",
				name: "Revenue",
			},
			{
				key: permissionsActionsKey.variableCost,
				moduleWorkspacePermissionsKey: "variableCosts",
				name: "Variable costs",
			},
			{
				key: permissionsActionsKey.fixedCost,
				moduleWorkspacePermissionsKey: "fixedCosts",
				name: "Fixed costs",
			},
			{
				key: permissionsActionsKey.profitability,
				moduleWorkspacePermissionsKey: "profitability",
				name: "Profitability",
			},
		],
	},
	{
		name: "Business casing",
		key: "businessCasingPermissions",
		moduleWorkspacePermissionsKey: "conditional",
		columns: [
			{
				key: permissionsActionsKey.potential,
				moduleWorkspacePermissionsKey: "potential",
				name: "Potential",
			},
			{
				key: permissionsActionsKey.simulation,
				moduleWorkspacePermissionsKey: "projection",
				name: "Projections",
			},
			{
				key: permissionsActionsKey.costBased,
				moduleWorkspacePermissionsKey: "costBased",
				name: "Cost-based pricing",
			},
		],
	},
	{
		name: "Specific analyses",
		key: "specificAnalysesPermissions",
		moduleWorkspacePermissionsKey: "distribution",
		columns: [
			{
				key: permissionsActionsKey.distribution,
				name: "Distributions",
			},
		],
	},
	{
		name: "Pricing roadmap",
		key: "pricingRoadmapPermissions",
		moduleWorkspacePermissionsKey: "pricingScore",
		columns: [
			{
				key: permissionsActionsKey.roadmap,
				name: "Access to the module",
			},
		],
	},
	{
		name: "Learning tools",
		key: "learningToolsPermissions",
		moduleWorkspacePermissionsKey: "conditional",
		columns: [
			{
				key: permissionsActionsKey.perceivedValue,
				moduleWorkspacePermissionsKey: "valuePerceptionModeling",
				name: "Perceived value modeling",
			},
			{
				key: permissionsActionsKey.valueMap,
				moduleWorkspacePermissionsKey: "valueMap",
				name: "Value map",
			},
			{
				key: permissionsActionsKey.tips,
				moduleWorkspacePermissionsKey: "psychologicalTricks",
				name: "Psychological tricks",
			},
		],
	},
	{
		name: "Givemefive AI",
		key: "givemefiveAIPermissions",
		moduleWorkspacePermissionsKey: "givemefiveAI",
		columns: [
			{
				key: permissionsActionsKey.ai,
				name: "Use AI",
				infoText: "Gives the user permission to use AI.",
			},
		],
	},
];
