import React, { useContext, useEffect, useMemo, useRef } from "react";
import { computeDifferenceInMonths } from "@services/time";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
	Duration,
	PeriodName,
	RightPart,
	SelectedCursorIcon,
	StyledArrow,
	StyledDate,
	WrapperArrow,
	WrapperContent,
	WrapperDates,
	WrapperFirstLine,
	WrapperPeriod,
	WrapperPeriodType,
	WrapperSecondLine,
	WrapperTags,
	WrapperTempoSituation,
} from "@components/headerCustom/periods/periodComponent/PeriodComponent.style";
import { Icon, Tooltipv3 } from "priceit-ui";
import { timeMetricsObj } from "@services/i18n";

export const SimpleCurrentPeriod = ({ period, isPeriodDrawerOpen }) => {
	const { t } = useTranslation(["shared/header/currentPeriod"]);
	const timeMetrics = timeMetricsObj();
	const startDateMoment = moment(period?.startDate);
	const endDateMoment = moment(period?.endDate);

	const startDate = new Date(period?.startDate);
	const endDate = new Date(period?.endDate);

	const monthDifference = isNaN(computeDifferenceInMonths(startDate, endDate))
		? "-"
		: endDateMoment.diff(startDateMoment, "months") + 1;

	const startDateFormatted =
		startDate !== "" && !isNaN(startDate.getTime())
			? ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + startDate.getFullYear()
			: "__-____";

	const endDateFormatted =
		endDate !== "" && !isNaN(endDate.getTime())
			? ("0" + (endDate.getMonth() + 1)).slice(-2) + "-" + endDate.getFullYear()
			: "__-____";

	const temporalSituation = useMemo(() => {
		const now = new Date();
		if (endDate < now) {
			return { color: "#FFA264", text: t("Past") };
		}
		if (startDate > now) {
			return { color: "#B1D8A6", text: t("Future") };
		}
		return { color: "#5790E6", text: t("Present") };
	}, [startDate, endDate]);

	return (
		<>
			<Tooltipv3
				content={<div style={{ padding: "10px" }}>{period?.comment}</div>}
				disabled={!period?.comment}
				placement={"left"}
				mode="dark"
				type="primary"
				interactive={false}
			>
				<WrapperPeriod isSelected={true} currentPeriod={true}>
					<WrapperContent isPeriodDrawerOpen={isPeriodDrawerOpen}>
						<WrapperFirstLine>
							<PeriodName>{period?.name}</PeriodName>
							<Duration>
								{monthDifference} {timeMetrics["month"](monthDifference || 1)}
							</Duration>
						</WrapperFirstLine>
						<WrapperSecondLine>
							<WrapperDates style={{ padding: "0 19px 0px 0px" }}>
								<StyledDate>{startDateFormatted}</StyledDate>
								<StyledArrow type={"arrowRight"} />
								<StyledDate>{endDateFormatted}</StyledDate>
							</WrapperDates>
							<WrapperTags>
								<WrapperTempoSituation color={temporalSituation.color}>
									{temporalSituation.text}
								</WrapperTempoSituation>
								<WrapperPeriodType
									color={period?.type === "R" ? "#FFDD67" : "#a5b9c8"}
								>
									{period?.type === "R" ? t("Real") : t("Fictive")}
								</WrapperPeriodType>
							</WrapperTags>
						</WrapperSecondLine>
					</WrapperContent>
				</WrapperPeriod>
			</Tooltipv3>
		</>
	);
};
