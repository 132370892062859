import React from "react";
import { createRoot } from "react-dom/client";

import "normalize.css";
import jwt from "jsonwebtoken";

import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";
import i18n from "@services/i18n";
import { ApolloProvider } from "@apollo/client";
import "@services/wdyr";
import "@services/objectsUtils";
import setTokenFromUrl from "@services/setTokenFromUrl";
import Highcharts from "highcharts";
import { client } from "@config/apollo";
import { Main } from "@light/main";
import GlobalContext from "@globalContext";

const scope = Sentry.getCurrentScope();
window.Highcharts = Highcharts;
setTokenFromUrl();
if (process.env.ENVIRONMENT === "production" || process.env.ENVIRONMENT === "preprod") {
	Sentry.init({
		dsn: "https://773b2610ff4d5645eb661a3bb8c5963e@o460574.ingest.us.sentry.io/4507254228123648",
		ignoreErrors: [
			// Error generated by a bug in autofill library from browser
			// https://github.com/getsentry/sentry/issues/5267
			/Blocked a frame with origin/,
		],
	});
	scope.setUser(jwt.decode(localStorage.jwtToken));
}
document.title =
	process.env.ENVIRONMENT === "development"
		? "Givemefive Dev"
		: process.env.ENVIRONMENT === "preprod"
			? "Givemefive Preprod"
			: "Givemefive";

const rootElement = document.getElementById("mount");
const root = createRoot(rootElement);
root.render(
	<I18nextProvider i18n={i18n}>
		<ApolloProvider connectToDevTools={true} client={client()}>
			<GlobalContext.Provider
				value={{
					light: true,
				}}
			>
				<Main />
			</GlobalContext.Provider>
		</ApolloProvider>
	</I18nextProvider>
);
