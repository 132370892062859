import { atom } from "recoil";
import { sectionName } from "@recoil/clientOffers/actions";

export const clientsOffersEnableCrmDeal = atom({
	key: "clientsOffersEnableCrmDealAtom",
	default: false,
});

export const clientsOffersSelectedIdAtom = atom({
	key: "clientsOffersSelectedIdAtom",
	default: null,
});

export const clientsOffersNextButtonDisabledAtom = atom({
	key: "clientsOffersNextButtonDisabledAtom",
	default: null,
});

export const clientsOffersCrossButtonDisabledAtom = atom({
	key: "clientsOffersCrossButtonDisabledAtom",
	default: null,
});

export const clientsOffersTemplateSearchValueAtom = atom({
	key: "clientsOffersTemplateSearchValueAtom",
	default: "",
});

export const clientsOffersCreationStepAtom = atom({
	key: "clientsOffersCreationStepAtom",
	default: sectionName.offerCreationStep.templateSelection,
});

export const clientsOffersBackToModelSelectionStepAtom = atom({
	key: "clientsOffersBackToModelSelectionStepAtom",
	default: false,
});

export const clientsOffersARRTimeMetricAtom = atom({
	key: "clientsOffersARRTimeMetricAtom",
	default: "year",
});

export const clientsOffersIsNewOfferAtom = atom({
	key: "clientsOffersIsNewOfferAtom",
	default: false,
});

export const clientsOffersIsNewOfferVersionAtom = atom({
	key: "clientsOffersIsNewOfferVersionAtom",
	default: false,
});

export const clientsOffersIsRefreshTemplateAtom = atom({
	key: "clientsOffersIsRefreshTemplateAtom",
	default: false,
});

export const clientsOffersShowCreationModalAtom = atom({
	key: "clientsOffersShowCreationModalAtom",
	default: false,
});

export const clientsOffersIsNameEditingAtom = atom({
	key: "clientsOffersIsNameEditingAtom",
	default: false,
});

export const clientsOffersIsCollapsedSectionAtom = atom({
	key: "clientsOffersIsCollapsedSectionAtom",
	default: {},
});

export const clientOffersGroupsStateAtom = atom({
	key: "clientOffersGroupsStateAtom",
	default: {},
});

export const clientOffersIsValidDateAtom = atom({
	key: "clientOffersIsValidDateAtom",
	default: true,
});

export const clientsOffersTemplateFiltersAtom = atom({
	key: "clientsOffersTemplateFiltersAtom",
	default: {},
});

export const clientsOffersSelectedTemplateAtom = atom({
	key: "clientsOffersSelectedTemplateAtom",
	default: null,
});

export const clientsOffersNewPriceModelCombinationAtom = atom({
	key: "clientsOffersNewPriceModelCombinationAtom",
	default: null,
});

export const clientsOfferNewVersionIdAtom = atom({
	key: "clientsOfferNewVersionIdAtom",
	default: null,
});

export const clientOfferVisibleEmailAtom = atom({
	key: "clientOfferVisibleEmailAtom",
	default: false,
});

export const clientsOffersEmailSelectedAtom = atom({
	key: "clientsOffersEmailSelectedAtom",
	default: "",
});

export const clientsOffersSubjectAtom = atom({
	key: "clientsOffersSubjectAtom",
	default: "",
});

export const clientsOffersBodyAtom = atom({
	key: "clientsOffersBodyAtom",
	default: "",
});

export const clientsOffersMailOfferLinkAtom = atom({
	key: "clientsOffersMailOfferLinkAtom",
	default: "",
});

export const clientsOffersMailQuoteGeneratorLinkAtom = atom({
	key: "clientsOffersMailQuoteGeneratorLinkAtom",
	default: "",
});

export const clientsOffersContactAtom = atom({
	key: "clientsOffersContactAtom",
	default: [],
});

export const clientsOffersContactCcAtom = atom({
	key: "clientsOffersContactCcAtom",
	default: [],
});

export const clientsOffersContactCciAtom = atom({
	key: "clientsOffersContactCciAtom",
	default: [],
});

export const clientsOffersErrorMessageDiscountsAndGiftsAtom = atom({
	key: "clientsOffersErrorMessageDiscountsAndGiftsAtom",
	default: null,
});

export const clientsOffersCreationFormDiscountsAndGiftsAtom = atom({
	key: "clientsOffersCreationFormDiscountsAndGiftsAtom",
	default: {},
});

export const clientsOffersIsCreatingGlobalDiscountAtom = atom({
	key: "clientsOffersIsCreatingGlobalDiscountAtom",
	default: false,
});

export const clientsOffersIsCreatingGlobalGiftAtom = atom({
	key: "clientsOffersIsCreatingGlobalGiftAtom",
	default: false,
});

export const clientOffersIsEditingPreselectionAtom = atom({
	key: "clientOffersIsEditingPreselectionAtom",
	default: false,
});

export const clientOfferLoadingCreationVersionAtom = atom({
	key: "clientOfferLoadingCreationVersionAtom",
	default: false,
});

export const clientOfferSessionIdAtom = atom({
	key: "clientOfferSessionIdAtom",
	default: null,
});

export const clientOfferProspectPassword = atom({
	key: "clientOfferProspectPasswordAtom",
	default: "",
});

export const clientOfferAdminPassword = atom({
	key: "clientOfferAdminPasswordAtom",
	default: null,
});

export const lastOfferPropertiesDraftIdAtom = atom({
	key: "lastOfferPropertiesDraftIdAtom",
	default: null,
});

export const lastQuoteConfigurationDraftIdAtom = atom({
	key: "lastQuoteConfigurationDraftIdAtom",
	default: null,
});

export const offerComputedNameAtom = atom({
	key: "offerComputedNameAtom",
	default: null,
});

export const offerNameEditedAtom = atom({
	key: "offerNameEditedAtom",
	default: true,
});
