import styled, { css } from "styled-components";
import { Input, TextArea } from "priceit-ui";

export const Label = styled.div`
	font-size: 12px;
	display: flex;
	white-space: nowrap;
	fill: ${props => props.theme.textColor.subtitle.primary};
`;

export const Counter = styled.div`
	font-size: 12px;
`;

export const StyledInput = styled(Input)`
	${props =>
		props.disabled &&
		css`
			cursor: not-allowed;
		`};
`;

export const WrapperLabelAndInput = styled.div`
	overflow: hidden;
	width: ${props => props.width ?? "100%"};
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const WrapperBody = styled.div`
	position: relative;
	height: 275px;
`;

export const WrapperLabelAndCounter = styled.div`
	display: flex;
	justify-content: space-between;
	color: ${({ disabled, theme }) =>
		disabled ? theme.textColor.subtitle.quaternary : theme.textColor.subtitle.primary};
	${props =>
		props.hidden &&
		css`
			visibility: hidden;
		`}
`;

export const StyledTextArea = styled(TextArea)``;
