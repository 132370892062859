import React from "react";
import { DurationOption, PeriodSelect } from "./PeriodDurationSelector.style";
import { periodTypesEnum } from "@services/periodMultiplier";
import { useTranslation } from "react-i18next";
import { Selector } from "priceit-ui";

export const PeriodDurationSelector = ({
	periodType,
	onChange,
	overPeriod,
	monthDifference,
	NoMarginLeft,
}) => {
	const { t } = useTranslation("components/periodDurationSelector");

	const optionBase = [
		{
			value: "year",
			name: `${t("Over a year")}`,
		},
		{
			value: "semester",
			name: `${t("Over a semester")}`,
		},
		{
			value: "quarter",
			name: `${t("Over a quarter")}`,
		},
		{
			value: "month",
			name: `${t("Over a month")}`,
		},
	];
	const optionWithPeriod = [
		{
			value: "period",
			name: `${t("Over the period")}`,
		},
		{
			value: "year",
			name: `${t("Over a year")}`,
		},
		{
			value: "semester",
			name: `${t("Over a semester")}`,
		},
		{
			value: "quarter",
			name: `${t("Over a quarter")}`,
		},
		{
			value: "month",
			name: `${t("Over a month")}`,
		},
	];
	return (
		<Selector
			widthOption={"75px"}
			height={"50px"}
			multiline
			mode="dark"
			value={periodType}
			style={{ minWidth: "fit-content" }}
			onChange={value =>
				value === "period"
					? onChange(periodTypesEnum.PERIOD)
					: value === "year"
					? onChange(periodTypesEnum.YEAR)
					: value === "semester"
					? onChange(periodTypesEnum.SEMESTER)
					: value === "quarter"
					? onChange(periodTypesEnum.QUARTER)
					: value === "month"
					? onChange(periodTypesEnum.MONTH)
					: null
			}
			options={overPeriod ? optionWithPeriod : optionBase}
		/>
	);
};
