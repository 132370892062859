import { selectorFamily } from "recoil";
import {
	packDisabledDueToPaymentTermsDiscountDependencyAtom,
	paymentTermsDiscountStateAtom,
} from "./atom";
import { priceModelTypeByPriceModelIdSelector } from "./publicLinkSelector";

export const paymentTermsDiscountStateByPriceModelIdSelector = selectorFamily({
	key: "paymentTermsDiscountStateByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(paymentTermsDiscountStateAtom)[priceModelId],
	set:
		priceModelId =>
		({ get, set }, { newValue, discountRule, timeMetric }) => {
			const isOneShot = ["onlyOnce", "oneShot", null, undefined].includes(timeMetric);
			set(paymentTermsDiscountStateAtom, {
				...get(paymentTermsDiscountStateAtom),
				[priceModelId]: !isOneShot ? newValue : null,
			});

			const newPaymentTerm = discountRule
				?.find(rule => !!rule?.paymentTermDiscount)
				?.paymentTermDiscount?.paymentTermRange?.find(range => range.id === newValue)?.term;

			set(priceModelTypeByPriceModelIdSelector(priceModelId), {
				timeMetric,
				newPaymentTerm,
				discountRule,
			});
		},
});

export const paymentTermsDiscountValueByPriceModelIdSelector = selectorFamily({
	key: "paymentTermsDiscountValueByPriceModelIdSelector",
	get:
		({ priceModelId, discountRule }) =>
		({ get }) =>
			discountRule
				?.find(rule => !!rule?.paymentTermDiscount)
				?.paymentTermDiscount?.paymentTermRange?.find(
					range =>
						range.id ===
						get(paymentTermsDiscountStateByPriceModelIdSelector(priceModelId))
				)?.term,
});

export const packDisabledDueToPaymentTermsDiscountDependencyByPriceModelIdSelector = selectorFamily(
	{
		key: "packDisabledDueToPaymentTermsDiscountDependencyByPriceModelIdSelector",
		get:
			priceModelId =>
			({ get }) =>
				get(packDisabledDueToPaymentTermsDiscountDependencyAtom)[priceModelId],
		set:
			priceModelId =>
			({ get, set }, newValue) => {
				set(packDisabledDueToPaymentTermsDiscountDependencyAtom, {
					...get(packDisabledDueToPaymentTermsDiscountDependencyAtom),
					[priceModelId]: newValue,
				});
			},
	}
);
