import React, { useContext, useEffect, useMemo, useRef } from "react";
import { computeDifferenceInMonths } from "@services/time";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
	Duration,
	PeriodName,
	SidePart,
	SelectedCursorIcon,
	StyledArrow,
	StyledDate,
	WrapperArrow,
	WrapperContent,
	WrapperDates,
	WrapperFirstLine,
	WrapperPeriod,
	WrapperPeriodType,
	WrapperSecondLine,
	WrapperTags,
	WrapperTempoSituation,
	SelectedRowCursorIcon,
	WrapperSelectedArrow,
} from "@components/headerCustom/periods/periodComponent/PeriodComponent.style";
import { Icon, Tooltipv3 } from "priceit-ui";
import { useHover } from "@hooks/UseHover";

export const PeriodComponent = ({
	period,
	isSelected,
	onHover,
	onClick,
	currentPeriod = false,
	openDrawer,
	listVisible,
}) => {
	const { t } = useTranslation(["shared/header/currentPeriod"]);

	const ref = useRef();
	const hover = useHover(ref);

	useEffect(() => {
		if (onHover && hover) {
			onHover(period);
		}
	}, [hover]);

	const startDateMoment = moment(period?.startDate);
	const endDateMoment = moment(period?.endDate);

	const startDate = new Date(period?.startDate);
	const endDate = new Date(period?.endDate);

	const monthDifference = isNaN(computeDifferenceInMonths(startDate, endDate))
		? "-"
		: endDateMoment.diff(startDateMoment, "months") + 1;

	const startDateFormatted =
		startDate !== "" && !isNaN(startDate.getTime())
			? ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + startDate.getFullYear()
			: "__-____";

	const endDateFormatted =
		endDate !== "" && !isNaN(endDate.getTime())
			? ("0" + (endDate.getMonth() + 1)).slice(-2) + "-" + endDate.getFullYear()
			: "__-____";

	const temporalSituation = useMemo(() => {
		const now = new Date();
		if (endDate < now) {
			return { color: "#FFA264", text: t("Past") };
		}
		if (startDate > now) {
			return { color: "#B1D8A6", text: t("Future") };
		}
		return { color: "#5790E6", text: t("Present") };
	}, [startDate, endDate]);

	return (
		<>
			<Tooltipv3
				content={<div style={{ padding: "10px" }}>{period?.comment}</div>}
				disabled={!period?.comment}
				placement={"left"}
				mode="dark"
				type="primary"
				interactive={false}
			>
				<WrapperPeriod
					ref={ref}
					isSelected={isSelected}
					onClick={currentPeriod ? null : onClick}
					currentPeriod={currentPeriod && !listVisible}
				>
					<SidePart>
						{isSelected ? (
							<WrapperSelectedArrow>
								<SelectedRowCursorIcon type="upTriangleSharp" />
							</WrapperSelectedArrow>
						) : null}
					</SidePart>
					<WrapperContent>
						<WrapperFirstLine>
							<PeriodName>{period?.name}</PeriodName>
							<Duration>
								{monthDifference} {t("month", { count: monthDifference || 1 })}
							</Duration>
						</WrapperFirstLine>
						<WrapperSecondLine>
							<WrapperDates>
								<StyledDate>{startDateFormatted}</StyledDate>
								<StyledArrow type={"arrowRight"} />
								<StyledDate>{endDateFormatted}</StyledDate>
							</WrapperDates>
							<WrapperTags>
								<WrapperTempoSituation color={temporalSituation.color}>
									{temporalSituation.text}
								</WrapperTempoSituation>
								<WrapperPeriodType
									color={period?.type === "R" ? "#FFDD67" : "#a5b9c8"}
								>
									{period?.type === "R" ? t("Real") : t("Fictive")}
								</WrapperPeriodType>
							</WrapperTags>
						</WrapperSecondLine>
					</WrapperContent>
					<SidePart></SidePart>
				</WrapperPeriod>
			</Tooltipv3>
		</>
	);
};
