import React from "react";
import { StyledIcon, Wrapper } from "./Shortcut.style";
import { Button, Tooltipv3 } from "priceit-ui";
import { useThemeContext } from "@theme/Theme";

export const Shortcut = ({
	showPointer,
	tooltipContent,
	tooltipInteractive = true,
	tooltipDelay = [0, 0],
	iconFill,
	iconHoverFill,
	disabled,
	icon,
	iconWidth,
	iconHeight,
	onClick,
	type,
	title,
}) => {
	const { mode } = useThemeContext();
	return (
		<Wrapper onClick={() => (disabled ? null : onClick())}>
			<Tooltipv3
				placement={"bottom"}
				mode={mode}
				appendTo={document.body}
				interactive={tooltipInteractive}
				content={tooltipContent}
				disabled={disabled}
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				maxWidth={"250px"}
				delay={tooltipDelay}
			>
				{type === "button" ? (
					<Button type="primary" icon={icon} height={"30px"}>
						{title}
					</Button>
				) : (
					<StyledIcon
						type={icon}
						iconWidth={iconWidth}
						iconHeight={iconHeight}
						colorFill={iconFill}
						hoverFill={iconHoverFill}
						isCursor={!!showPointer}
						disabled={disabled}
						height={"30px"}
						width={"30px"}
					/>
				)}
			</Tooltipv3>
		</Wrapper>
	);
};
