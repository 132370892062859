import { gql } from "@apollo/client";
import {
	CATEGORIZATION_CATEGORIES_COLOR_AND_NAME_FRAGMENT,
	CATEGORIZATION_CATEGORIES_NAME_FRAGMENT,
	CATEGORIZATION_DESCRIPTION_FRAGMENT,
	CATEGORIZATION_FRAGMENT,
	CATEGORIZATION_INDEX_FRAGMENT,
	CATEGORIZATION_NAME_FRAGMENT,
} from "@graphQl/fragments/categorizationNewFragment";

export const GET_CATEGORIZATION_BY_PERIOD_ID = gql`
	query GetCategorizationByPeriodId($id: ID!) {
		getCategorizationByPeriodId(id: $id) {
			id
			name
			description
			createdAt
			createdBy
			updatedAt
			updatedBy
			category {
				id
				categorizationId
				name
				color
				createdAt
				createdBy
				updatedAt
				updatedBy
				index
			}
		}
	}
`;

export const GET_CATEGORIZATION_NEW_NAME_AND_DESCRIPTION_BY_ID = gql`
	${CATEGORIZATION_NAME_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	${CATEGORIZATION_INDEX_FRAGMENT}
	query GetCategorizationNewById($id: ID!) {
		getCategorizationNewById(id: $id) {
			...CategorizationNameFragment
			...CategorizationDescriptionFragment
			...CategorizationIndexFragment
		}
	}
`;

export const GET_CATEGORIZATION_DESCRIPTION_BY_ID = gql`
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	query GetCategorizationNewById($id: ID!) {
		getCategorizationNewById(id: $id) {
			...CategorizationDescriptionFragment
		}
	}
`;

export const GET_SANDBOX_CATEGORIZATIONS = gql`
	${CATEGORIZATION_FRAGMENT}
	query GetSandboxCategorizations {
		getSandboxCategorizations {
			...CategorizationFragment
		}
	}
`;

export const GET_SANDBOX_CATEGORIZATIONS_NAME_DESCRIPTION_AND_CATEGORIES_NAME = gql`
	${CATEGORIZATION_NAME_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	${CATEGORIZATION_INDEX_FRAGMENT}
	${CATEGORIZATION_CATEGORIES_NAME_FRAGMENT}
	query GetSandboxCategorizationsNameDescriptionAndCategories {
		getSandboxCategorizations {
			...CategorizationNameFragment
			...CategorizationDescriptionFragment
			...CategorizationIndexFragment
			...CategorizationCategoriesNameFragment
		}
	}
`;

export const GET_CATEGORIZATION_CATEGORIES_COLOR_AND_NAME_BY_COLUMN_ID = gql`
	${CATEGORIZATION_CATEGORIES_COLOR_AND_NAME_FRAGMENT}
	query GetCategorizationCategoriesColorAndNameByColumnId($id: ID!) {
		getCategorizationByCategorizationColumnId(id: $id) {
			...CategorizationCategoriesColorAndNameFragment
		}
	}
`;

export const GET_LINKS_CATEGORIZATIONS = gql`
	${CATEGORIZATION_FRAGMENT}
	query GetLinksCategorizations {
		getLinksCategorizations {
			...CategorizationFragment
		}
	}
`;

export const GET_LINKS_CATEGORIZATIONS_INDEX = gql`
	${CATEGORIZATION_INDEX_FRAGMENT}
	query GetLinksCategorizationsIndex {
		getLinksCategorizations {
			...CategorizationIndexFragment
		}
	}
`;

export const GET_LINKS_CATEGORIZATIONS_NAME_DESCRIPTION_AND_CATEGORIES_NAME = gql`
	${CATEGORIZATION_NAME_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	${CATEGORIZATION_INDEX_FRAGMENT}
	${CATEGORIZATION_CATEGORIES_NAME_FRAGMENT}
	query GetLinksCategorizationsNameDescriptionAndCategoriesName {
		getLinksCategorizations {
			...CategorizationNameFragment
			...CategorizationDescriptionFragment
			...CategorizationIndexFragment
			...CategorizationCategoriesNameFragment
		}
	}
`;

export const GET_LINKS_CATEGORIZATIONS_DATA_FOR_CLIENT_OFFER = gql`
	${CATEGORIZATION_NAME_FRAGMENT}
	${CATEGORIZATION_DESCRIPTION_FRAGMENT}
	${CATEGORIZATION_INDEX_FRAGMENT}
	${CATEGORIZATION_CATEGORIES_COLOR_AND_NAME_FRAGMENT}
	query GetLinksCategorizationsDataForClientOffer {
		getLinksCategorizations {
			...CategorizationNameFragment
			...CategorizationDescriptionFragment
			...CategorizationIndexFragment
			...CategorizationCategoriesColorAndNameFragment
		}
	}
`;

export const GET_LINKS_CATEGORIZATIONS_CATEGORIES = gql`
	${CATEGORIZATION_CATEGORIES_NAME_FRAGMENT}
	query GetLinksCategorizationsCategories {
		getLinksCategorizations {
			...CategorizationCategoriesNameFragment
		}
	}
`;

export const GET_ATTRIBUTE_CATEGORIZATIONS = gql`
	${CATEGORIZATION_FRAGMENT}
	query GetAttributeCategorizations {
		getAttributeCategorizations {
			...CategorizationFragment
		}
	}
`;
