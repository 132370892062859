import { selectorFamily } from "recoil";
import { additionalValuesStateAtom } from "./atom";

export const additionalValuesStatesByPriceModelIdSelector = selectorFamily({
	key: "additionalValuesStatesByPriceModelIdSelector",
	get:
		priceModelId =>
		({ get }) =>
			get(additionalValuesStateAtom)[priceModelId] || [],
	set:
		priceModelId =>
		({ get, set }, newValue) => {
			const filteredNewValue = {};

			Object?.keys(newValue)?.map(element => {
				filteredNewValue[element] = newValue[element]?.filter(state => state.rangeId);
			});

			set(additionalValuesStateAtom, {
				...get(additionalValuesStateAtom),
				[priceModelId]: filteredNewValue,
			});
		},
});

export const additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector = selectorFamily({
	key: "additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector",
	get:
		({ priceModelId, discountId, packId, ruleId, isAddon }) =>
		({ get }) =>
			get(additionalValuesStateAtom)?.[priceModelId]?.[packId]?.find(
				element => element.ruleId === ruleId && element.discountId === discountId
			),
	set:
		({ priceModelId, discountId, packId, ruleId, isAddon, isBefore }) =>
		({ get, set }, rangeId) => {
			const atom = get(additionalValuesStateAtom);

			if (rangeId) {
				set(additionalValuesStateAtom, {
					...(atom || {}),
					[priceModelId]: {
						...(atom?.[priceModelId] || {}),
						[packId]: [
							...(atom?.[priceModelId]?.[packId]?.filter(
								element => element.ruleId !== ruleId
							) || []),
							{ ruleId, discountId, rangeId, isAddon, isBefore },
						],
					},
				});
			} else {
				set(additionalValuesStateAtom, {
					...(atom || {}),
					[priceModelId]: {
						...(atom?.[priceModelId] || {}),
						[packId]: [
							...(atom?.[priceModelId]?.[packId]?.filter(
								element => element.ruleId !== ruleId
							) || []),
						],
					},
				});
			}
		},
});

export const resetAdditionalValuesStatesByPriceModelIdAndRuleType = selectorFamily({
	key: "resetAdditionalValuesStatesByPriceModelIdAndRuleType",
	set:
		({ priceModelId, ruleType, list, isPublic }) =>
		({ get, set }) => {
			const atomById = get(additionalValuesStateAtom)[priceModelId] || {};
			const additionalValuesState = {};
			Object?.keys(atomById)?.map(element => {
				atomById[element]?.map(state => {
					const currentSpecialDiscount = list?.find(
						x => x.id === state.ruleId
					)?.specialDiscount;

					const currentDefaultPosition = currentSpecialDiscount?.defaultPositions?.find(
						x => x.packId === element
					);

					const isLineAfter = ruleType.includes("After");
					const isSpeDiscAfter = !state.after;
					const isLineExtra = !ruleType.includes("addOn");
					const isExtra = !state.isAddon;

					if (
						(isLineAfter && !isSpeDiscAfter) ||
						(!isLineAfter && isSpeDiscAfter) ||
						(isLineExtra && !isExtra) ||
						(!isLineExtra && isExtra) ||
						(currentDefaultPosition?.isAddonExtraLocked && isPublic)
					) {
						const newValue = get(
							additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector({
								priceModelId,
								discountId: state.discountId,
								packId: element,
								ruleId: state.ruleId,
								isAddon: ruleType.includes("addOn"),
								isBefore: ruleType.includes("Before"),
							})
						);
						additionalValuesState[element] = [
							...(additionalValuesState?.[element] || []),
							newValue,
						];
					}
				});
				if (!additionalValuesState[element]) {
					additionalValuesState[element] = [];
				}
			});

			set(additionalValuesStatesByPriceModelIdSelector(priceModelId), additionalValuesState);
		},
});
