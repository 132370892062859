import styled from "styled-components";
import { Tablev4, Icon } from "priceit-ui";
import { customGap } from "@services/Utils.style";

export const EllipsisDiv = styled.div`
	display: block;
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${({ theme }) => theme.page.textColor};
`;

export const Container = styled.div`
	width: ${props => props.width};
`;

export const TextDiv = styled.div`
	display: block;
	max-width: 100%;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const WrapperActions = styled.div`
	display: flex;
	${customGap("20px")};
`;

export const WrapperHoverActionTableIcon = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid transparent;
	border-radius: 3px;
	padding: 1.5px;
`;

export const HoverActionTableIcon = styled(Icon)`
	width: ${props => props.width ?? "12px"};
	height: ${props => props.height ?? "12px"};
	fill: #1d1d1d;
	stroke: #1d1d1d;
	${WrapperHoverActionTableIcon}:hover & {
		fill: #fff;
		stroke: #fff;
	} ;
`;

export const ActionTableText = styled.div`
	align-items: center;
	margin-left: 5px;
	${WrapperHoverActionTableIcon}:hover & {
		color: white;
	} ;
`;

export const OutlierDetailText = styled.div`
	line-height: 16px;
`;

export const RespondentsTablev4 = styled(Tablev4)`
	width: 100%;
`;
