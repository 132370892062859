import { useTranslation } from "react-i18next";

export const metrics = () => {
	const { t } = useTranslation("priceModel/page");
	const textTimeMetricBasic = {
		onlyOnce: t("Non-recurring"),
		oneShot: t("one shot"),
		second: t("second"),
		minute: t("minute"),
		hour: t("hour"),
		day: t("day"),
		week: t("week"),
		month: t("month"),
		quarter: t("quarter"),
		semester: t("semester"),
		year: t("year"),
	};
	const textTimeMetricPlurial = {
		onlyOnce: t("Non-recurring"),
		oneShot: t("one shot"),
		second: t("seconds"),
		minute: t("minutes"),
		hour: t("hours"),
		day: t("days"),
		week: t("weeks"),
		month: t("months"),
		quarter: t("quarters"),
		semester: t("semesters"),
		year: t("years"),
	};
	const textTimeMetricTotalPriceOption = {
		onlyOnce: t("Total price"),
		second: t("Every second, total price "),
		minute: t("Every minute, total price "),
		hour: t("Hourly, total price "),
		day: t("Daily, total price "),
		week: t("Weekly, total price "),
		month: t("Monthly, total price "),
		quarter: t("Quarterly, total price "),
		semester: t("Semesterly, total price "),
		year: t("Yearly, total price "),
	};

	const textTimeMetricWithPer = {
		onlyOnce: t("Non-recurring"),
		oneShot: t("One shot"),
		second: t("Per second"),
		minute: t("Per minute"),
		hour: t("Per hour"),
		day: t("Per day"),
		week: t("Per week"),
		month: t("Per month"),
		quarter: t("Per quarter"),
		semester: t("Per semester"),
		year: t("Per year"),
	};

	const valueTimeMetricWInMonths = {
		hour: 0.00136986,
		day: 0.0328767,
		week: 0.230137,
		month: 1,
		quarter: 3,
		semester: 6,
		year: 12,
		oneShot: 9999,
	};

	return {
		textTimeMetricBasic,
		textTimeMetricPlurial,
		textTimeMetricTotalPriceOption,
		textTimeMetricWithPer,
		valueTimeMetricWInMonths,
	};
};
