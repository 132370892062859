import styled, { css } from "styled-components";
import { Icon } from "priceit-ui";

export const InfoIcon = styled(Icon)`
	fill: ${props => (props.checked ? "#FFFFFF" : "#A5B9C8")};
	width: 12px;
	height: ${props => props.height ?? "12px"};
	margin: ${props => props.margin ?? 0};
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const StyledIconButton = styled(Icon)`
	fill: #a5b9c8;
	height: 10px;
	width: 10px;
	margin-right: 5px;
	cursor: pointer;

	&:hover {
		fill: #fff;
	}
`;

export const WrapperInputButtons = styled.div`
	position: absolute;
	right: 28px;
	display: flex;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
`;

export const Strong = styled.div`
	font-weight: bold;
`;

export const TooltipContent = styled.div`
	padding: 10px;
	max-width: 250px;
	text-align: center;
	${props =>
		props.color &&
		css`
			color: ${props.color};
			font-weight: bold;
		`};
`;
