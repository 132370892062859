import React from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { useRecoilValue } from "recoil";
import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";
import {
	InputTitle,
	WrapperLineComponentNeedRowDirection,
	WrapperTextAndIcon,
	WrapperTitleIconAndSwitchForParameters,
} from "@pages/clientsOffers/components/Components.style";
import {
	InfoIcon,
	NoTermsAndConditionsLink,
	PDFOrLinkIcon,
	StyledLink,
	StyledTooltip,
	SubSectionTitle,
	TermsAndConditionsLink,
	WrapperInfoIcon,
	WrapperInputLabel,
	WrapperLabelAndTextArea,
	WrapperTextArea,
	WrapperTitleIconAndSwitchForTextArea,
	WrapperUploadedTC,
} from "./Conditions.style";
import { useQuery } from "@apollo/client";
import { TextArea, ToggleSwitch } from "priceit-ui";
import { MAX_LENGTH_TC_FOOTER, tcTypes } from "@services/constants";
import { validateEmail } from "@src/services/validateEmail/validateEmail";
import { useParams } from "react-router-dom";
import {
	GET_OFFER_PROPERTIES_BY_ID,
	GET_OFFER_PROPERTIES_BY_OFFER_ID,
} from "@src/graphQl/queries/offerProperties";
import {
	updateOfferPropertiesEnableFooterMutation,
	updateOfferPropertiesEnableTcMutation,
	updateOfferPropertiesFooterContentMutation,
} from "@src/graphQl/mutations/offerProperties";
import { lastOfferPropertiesDraftIdAtom } from "@src/recoil/clientOffers/atom";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { Permission } from "@services/defaultPermissions";

export const Conditions = React.memo(({ isDrawer }) => {
	const { t } = useTranslation("specific/clientsOffers");
	const { mode } = useThemeContext();
	const { workspaceId } = useParams();

	const { auth } = useAuth();
	const { company_editCGU } = auth?.role;
	const isRoot = auth?.isRoot;
	const canEdit = isRoot || company_editCGU === Permission.full;

	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);
	const lastOfferPropertiesDraftId = useRecoilValue(lastOfferPropertiesDraftIdAtom);

	const { data: dataOfferProperties } = useQuery(GET_OFFER_PROPERTIES_BY_OFFER_ID, {
		variables: {
			offerId,
		},
		skip: !offerId || !isDrawer,
	});

	const { data: dataDraftOfferProperties } = useQuery(GET_OFFER_PROPERTIES_BY_ID, {
		variables: {
			id: lastOfferPropertiesDraftId,
		},
		skip: !lastOfferPropertiesDraftId || isDrawer,
	});

	const {
		id: offerPropertiesId,
		enableTc,
		enableFooter,
		footerContent,
		tcType,
		tcPDFName,
		tcUrlLink,
		tcPDFLink,
	} = (isDrawer
		? dataOfferProperties?.getOfferPropertiesByOfferId
		: dataDraftOfferProperties?.getOfferPropertiesById) || {};

	const tcLink = tcType === tcTypes.LINK ? tcUrlLink : tcType === tcTypes.PDF ? tcPDFLink : null;

	const [updateEnableTcOfferProperties] = updateOfferPropertiesEnableTcMutation();
	const [updateEnableFooterOfferProperties] = updateOfferPropertiesEnableFooterMutation();
	const [updateFooterContentOfferProperties] = updateOfferPropertiesFooterContentMutation();

	const onChangeFooterContent = value => {
		updateFooterContentOfferProperties({
			variables: {
				offerProperties: {
					id: offerPropertiesId,
					footerContent: value,
				},
			},
			optimisticResponse: {
				updateOfferProperties: {
					__typename: "OfferProperties",
					id: offerPropertiesId,
					footerContent: value,
				},
			},
		});
	};

	const onChangeEnableTc = value => {
		updateEnableTcOfferProperties({
			variables: {
				offerProperties: {
					id: offerPropertiesId,
					enableTc: value,
				},
			},
			optimisticResponse: {
				updateOfferProperties: {
					__typename: "OfferProperties",
					id: offerPropertiesId,
					enableTc: value,
				},
			},
		});
	};

	const onChangeEnableFooter = value => {
		updateEnableFooterOfferProperties({
			variables: {
				offerProperties: {
					id: offerPropertiesId,
					enableFooter: value,
				},
			},
			optimisticResponse: {
				updateOfferProperties: {
					__typename: "OfferProperties",
					id: offerPropertiesId,
					enableFooter: value,
				},
			},
		});
	};

	return (
		<>
			<WrapperLineComponentNeedRowDirection isDrawer={isDrawer}>
				<WrapperTitleIconAndSwitchForParameters isDrawer={isDrawer}>
					<WrapperTextAndIcon isMinWidth={isDrawer}>
						<InputTitle>{t("Terms and conditions")}</InputTitle>
					</WrapperTextAndIcon>
					<ToggleSwitch
						type={"outer"}
						mode={mode}
						checked={!tcLink && !canEdit ? false : enableTc}
						disabled={!canEdit}
						onClick={() => onChangeEnableTc(!enableTc)}
					/>
				</WrapperTitleIconAndSwitchForParameters>
				{enableTc && (
					<WrapperUploadedTC isDrawer={isDrawer}>
						{tcLink ? (
							<>
								<PDFOrLinkIcon type={tcType === tcTypes.PDF ? "pdf" : "link3"} />
								<TermsAndConditionsLink
									href={validateEmail(tcLink) ? `mailto:${tcLink}` : tcLink}
									target={"_blank"}
									rel="noreferrer"
								>
									{tcType === tcTypes.PDF ? tcPDFName : tcLink}
								</TermsAndConditionsLink>
							</>
						) : canEdit ? (
							<NoTermsAndConditionsLink>
								{tcType === tcTypes.LINK
									? t("Terms and conditions link is undefined")
									: t("Terms and conditions file is undefined")}
							</NoTermsAndConditionsLink>
						) : null}
						{isDrawer && tcLink ? null : canEdit ? (
							<StyledTooltip
								placement={"top"}
								maxWidth={"300px"}
								content={
									tcLink ? (
										t(
											"If you want to modify this document, go to your workspace settings (only for admins)."
										)
									) : (
										<StyledLink to={`/workspaces/${workspaceId}/settings`}>
											{t(
												"Upload your T&Cs here if you want to integrate them into your dynamic offers."
											)}
										</StyledLink>
									)
								}
								mode="dark"
								type="primary"
								interactive
							>
								<WrapperInfoIcon>
									<InfoIcon type="info" />
								</WrapperInfoIcon>
							</StyledTooltip>
						) : null}
						{!tcLink && !canEdit && (
							<StyledTooltip
								placement={"top"}
								maxWidth={"300px"}
								content={t(
									"No files are defined in the workspace settings. Please contact a workspace administrator to add a file."
								)}
								mode={mode}
								type="warning"
								interactive
							>
								<WrapperInfoIcon>
									<InfoIcon type="info" />
								</WrapperInfoIcon>
							</StyledTooltip>
						)}
					</WrapperUploadedTC>
				)}
			</WrapperLineComponentNeedRowDirection>
			<WrapperLineComponentNeedRowDirection isDrawer={isDrawer}>
				<WrapperTitleIconAndSwitchForTextArea isDrawer={isDrawer}>
					<WrapperTextAndIcon isMinWidth={isDrawer}>
						<InputTitle>{t("Special payment terms")}</InputTitle>
					</WrapperTextAndIcon>
					<ToggleSwitch
						type={"outer"}
						mode={mode}
						checked={enableFooter}
						onClick={() => onChangeEnableFooter(!enableFooter)}
					/>
				</WrapperTitleIconAndSwitchForTextArea>
				{enableFooter && (
					<WrapperLabelAndTextArea>
						<WrapperInputLabel isDrawer={isDrawer}>
							<SubSectionTitle>{t("Footer")}</SubSectionTitle>
							<SubSectionTitle>
								{footerContent?.length || 0}/{MAX_LENGTH_TC_FOOTER}
							</SubSectionTitle>
						</WrapperInputLabel>
						<WrapperTextArea>
							<TextArea
								mode={mode}
								noCenter
								maxLength={MAX_LENGTH_TC_FOOTER}
								width={"100%"}
								height={"80px"}
								maxHeight={"80px"}
								onClick={e => e.stopPropagation()}
								value={footerContent}
								onChange={e => onChangeFooterContent(e.target.value)}
								appendTo={document.body}
								enableDelay
								delay={1000}
								errorMaxLengthTooltipContent={maxLength =>
									t(
										"You have exceeded the maximum number of characters ({{maxLength}} characters)",
										{
											maxLength: maxLength,
										}
									)
								}
							/>
						</WrapperTextArea>
					</WrapperLabelAndTextArea>
				)}
			</WrapperLineComponentNeedRowDirection>
		</>
	);
});
Conditions.whyDidYouRender = true;
Conditions.displayName = "Conditions";
