import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useThemeContext } from "@theme/Theme";

import { useWorkspace } from "@src/hooks/useWorkspace/UseWorkspace";

import { clientsOffersSelectedIdSelector } from "@recoil/clientOffers";

import { GET_OFFER_TOKEN_BY_OFFER_ID } from "@graphQl/queries/offer";

import { LabelAndTextAreaComponent } from "@components/labelAndTextAreaComponent/LabelAndTextAreaComponent";
import { getPublicLink } from "@pages/myPricingTools/buildingTools/priceModels/services/PriceModels.service";
import { useAuth } from "@hooks/useAuth/UseAuth";
import { clientsOffersBodyAtom, clientsOffersMailOfferLinkAtom } from "@recoil/clientOffers/atom";

export const Body = React.memo(({ offerVersionId }) => {
	const { t, i18n } = useTranslation("clientsOffers/modalSendEmail");
	const { mode } = useThemeContext();
	const { workspace } = useWorkspace();
	const { auth } = useAuth();

	const [clientsOffersBody, setClientsOffersBody] = useRecoilState(clientsOffersBodyAtom);
	const setMailOfferLink = useSetRecoilState(clientsOffersMailOfferLinkAtom);
	const offerId = useRecoilValue(clientsOffersSelectedIdSelector);

	const { data: dataOffer } = useQuery(GET_OFFER_TOKEN_BY_OFFER_ID, {
		variables: { id: offerId },
		skip: !offerId,
	});
	const { token } = dataOffer?.getOfferById?.tokenObj || {};

	const { data: dataPriceModelCombination } = useQuery(
		GET_OFFER_VERSION_PRICE_MODEL_COMBINATION,
		{
			variables: { id: offerVersionId },
			skip: !offerVersionId,
		}
	);
	const language =
		dataPriceModelCombination?.getOfferVersionById?.priceModelCombination?.language;

	const offerLink = getPublicLink({
		token,
		isGroup: false,
		isOffer: true,
		workspaceName: workspace.name,
	});

	const valueWithLink = useMemo(() => {
		const tOffer = i18n.getFixedT(language, ["clientsOffers/modalSendEmail"]);
		return (
			tOffer("Hello") +
			",\n\n" +
			tOffer("Please find our offer via the link below:") +
			"\n" +
			offerLink +
			"\n\n" +
			auth.surname +
			" " +
			auth.lastname
		);
	}, [language, offerLink, auth.surname, auth.lastname]);

	useEffect(() => {
		setClientsOffersBody(currentVal => (!!currentVal ? currentVal : valueWithLink));
		setMailOfferLink(currentVal => (!!currentVal ? currentVal : offerLink));
	}, []);

	return (
		<LabelAndTextAreaComponent
			mode={mode}
			width={"100%"}
			label={t("Body")}
			isMandatory={true}
			value={clientsOffersBody}
			setValue={value => setClientsOffersBody(value)}
			errorTooltipContent={t("Body is required")}
		/>
	);
});
Body.whyDidYouRender = true;
Body.displayName = "Body";
