import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WrapperPercentCharts, WrapperTitle } from "../../ProductsDrawer.style";
import {
	WrapperFixedCostViewHeader,
	Titles,
	Title,
	SubTitle,
	BigRightArrow,
	SmallRightArrow,
	WrapperDataExtension,
	StyledInputNumber,
	InfoIconWrapper,
	WrapperTitleAndPeriodSelector,
	WrapperCharts,
} from "./SecondLineHeader.style";
import { percentChartsRender } from "../../services/ProductsDrawer.service";
import { PeriodDurationSelector } from "@components/periodDurationSelector/PeriodDurationSelector";
import { Icon, Tooltipv3 } from "priceit-ui";
import DataBox from "@components/dataBox/DataBox";
import { computeVariableCostPart } from "@pages/myBusinessModel/fixedCosts/service/FixedCosts.service";
import { periodMultiplier, periodTypesEnum } from "@services/periodMultiplier";
import { gql, useApolloClient } from "@apollo/client";
import { useFixedCosts } from "@hooks/useFixedCosts/UseFixedCosts";
import moment from "moment";

const SecondLineHeader = ({
	view,
	period,
	averageData,
	meanFixedCost,
	periodType,
	dispatch,
	actions,
	data,
	productsSelected,
}) => {
	const { t } = useTranslation("drawer/secondLineHeader");
	const { cache } = useApolloClient();
	const { updateFixedCost } = useFixedCosts();

	const endDate = moment(period?.endDate);
	const startDate = moment(period?.startDate);
	const monthDifference = endDate.diff(startDate, "months") + 1;

	const [amount, setAmount] = useState(
		(computeVariableCostPart(
			data?.currentFixedCostRow?.row?.state?.variableCostPart,
			data?.currentFixedCostRow?.row?.state?.variableCostPartType,
			data?.currentFixedCostRow?.row?.state?.cost
		).amount *
			(periodMultiplier(periodType) || monthDifference)) /
			periodMultiplier(data?.fixedCost?.timePeriod)
	);

	const [percentage, setPercentage] = useState(
		computeVariableCostPart(
			data?.currentFixedCostRow?.row?.state?.variableCostPart,
			data?.currentFixedCostRow?.row?.state?.variableCostPartType,
			(data?.currentFixedCostRow?.row?.state?.cost *
				(periodMultiplier(periodType) || monthDifference)) /
				periodMultiplier(data?.fixedCost?.timePeriod)
		).percent || 0
	);

	useEffect(() => {
		setAmount(
			(computeVariableCostPart(
				percentage,
				"percentage",
				data?.currentFixedCostRow?.row?.state?.cost
			).amount *
				(periodMultiplier(periodType) || monthDifference)) /
				periodMultiplier(data?.fixedCost?.timePeriod)
		);
	}, [percentage, periodType]);

	return (
		<>
			{view === "productsListView" || view === "productsCardView" ? (
				<>
					<WrapperTitleAndPeriodSelector>
						<WrapperTitle>{t("Show results into table ")}:</WrapperTitle>
						<PeriodDurationSelector
							periodType={periodType}
							onChange={value =>
								dispatch({
									type: actions.UPDATE_PERIOD_TYPE,
									value,
								})
							}
							overPeriod
							NoMarginLeft
						/>
					</WrapperTitleAndPeriodSelector>
					<WrapperCharts>
						<WrapperTitle>
							{productsSelected.length === 0
								? t("No line are selected")
								: t("The selected line represents:", {
										productsSelected: productsSelected.length,
										count: productsSelected.length || 1,
								  })}
						</WrapperTitle>
						<WrapperPercentCharts>
							{period
								? percentChartsRender({
										t: t,
										data: Object.assign(averageData, {
											totalFixedCost: meanFixedCost.meanFixedCosts,
										}),
										period: period,
										coeff:
											periodType === periodTypesEnum.PERIOD
												? 1
												: periodMultiplier(periodType) / monthDifference,
								  })
								: null}
						</WrapperPercentCharts>
					</WrapperCharts>
					{/* <div style={{ paddingRight: "24px" }}>
						<Tooltipv3
							placement="left"
							interactive={false}
							content={t("This feature will be available soon")}
						>
							<Button mode="medium" type="primary" disabled>
								{t("Export this tab")}
							</Button>
						</Tooltipv3>
					</div> */}
				</>
			) : view === "fixedCostView" ? (
				<WrapperFixedCostViewHeader>
					<Titles>
						<Title>{data?.currentFixedCostRow?.row?.state?.name}</Title>
						<SubTitle>
							{t("Total costs")}
							{": "}
							{(
								(data?.currentFixedCostRow?.row?.state?.cost *
									(periodMultiplier(periodType) || monthDifference)) /
									periodMultiplier(
										data?.currentFixedCostRow?.row.state?.timePeriod
									) || 0
							).fnb({
								stringText: true,
								zeroIfNan: true,
								decimal: 2,
								withCurrency: true,
							})}
						</SubTitle>
					</Titles>
					<BigRightArrow type="upTriangleSharp" />
					<PeriodDurationSelector
						periodType={periodType}
						onChange={value =>
							dispatch({
								type: actions.UPDATE_PERIOD_TYPE,
								value,
							})
						}
						overPeriod
					/>
					<SmallRightArrow type="upTriangleSharp" />
					<DataBox
						title={t("Semi-variable costs")}
						value={amount.fnb({
							stringText: true,
							zeroIfNan: true,
							decimal: 2,
							withCurrency: true,
						})}
						valueUnits={""}
						color={"#00CBFF"}
						width={"270px"}
						dataExtension={
							<WrapperDataExtension>
								<StyledInputNumber
									mode="dark"
									theme={{
										textAlign: "center",
										inputtextcolor: "white",
										focusBackgroundColor: "#1E272E",
									}}
									width="100px"
									height="30px"
									precision={2}
									suffix={"%"}
									value={percentage}
									isAllowed={e => {
										return (
											(e.floatValue >= 0 && e.floatValue <= 100) ||
											e.formattedValue === ""
										);
									}}
									onChange={e => {
										setPercentage(e.floatValue);
										cache.writeFragment({
											id: `FixedCost:${data?.currentFixedCostRow?.row?.state?.id}`,
											fragment: gql`
												fragment MyFixedCost on FixedCost {
													variableCostPart
													variableCostPartType
												}
											`,
											data: {
												variableCostPart: e.floatValue,
												variableCostPartType: "percentage",
											},
										});
									}}
									onFocus={() => {
										updateFixedCost({
											variables: {
												updateFixedCostInput: {
													id: data?.currentFixedCostRow?.row?.state?.id,
													variableCostPart: percentage,
													variableCostPartType: "percentage",
												},
											},
										});
										cache.writeFragment({
											id: `FixedCost:${data?.currentFixedCostRow?.row?.state?.id}`,
											fragment: gql`
												fragment MyFixedCost on FixedCost {
													variableCostPart
													variableCostPartType
												}
											`,
											data: {
												variableCostPart: percentage,
												variableCostPartType: "percentage",
											},
										});
									}}
									onBlur={() => {
										data?.currentFixedCostRow?.row?.setState(oldState => {
											return {
												...oldState,
												variableCostPart: percentage,
												variableCostPartType: "percentage",
											};
										});
										updateFixedCost({
											variables: {
												updateFixedCostInput: {
													id: data?.currentFixedCostRow?.row?.state?.id,
													variableCostPart: percentage,
												},
											},
										});
									}}
								/>
								<InfoIconWrapper>
									<Tooltipv3
										placement="right"
										content={t(
											"The proportion of fixed costs to be reallocated. They evolve with the volume and/or revenue of the selected revenue elements."
										)}
										interactive={false}
										mode="dark"
										type="primary"
										maxWidth="350px"
									>
										<Icon
											type="info"
											width="12px"
											height="12px"
											fill="#A5B9C8"
										/>
									</Tooltipv3>
								</InfoIconWrapper>
							</WrapperDataExtension>
						}
					/>
				</WrapperFixedCostViewHeader>
			) : null}
		</>
	);
};

export default SecondLineHeader;
