import recoilActions, { priceModelType } from "./actions";
// Volume discount
import {
	initNumericalDiscountSelector,
	volumeDiscountStateByPriceModelIdSelector,
	volumeDiscountVolumeLinkedStateByPriceModelIdSelector,
} from "./volumeDiscountSelector";

// Commitment discount
import {
	commitmentDiscountConditionShowSelectByPriceModelIdSelector,
	commitmentDiscountStateByPriceModelIdSelector,
	commitmentDiscountValueByPriceModelIdSelector,
} from "./commitmentDiscountSelector";

// Payment terms discount
import { paymentTermsDiscountStateByPriceModelIdSelector } from "./paymentTermsDiscountSelector";

// Additional Values
import {
	additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector,
	additionalValuesStatesByPriceModelIdSelector,
	resetAdditionalValuesStatesByPriceModelIdAndRuleType,
} from "./additionalValuesSelector";

// Special discount
import {
	specialDiscountStateByPriceModelIdAndDiscountIdSelector,
	specialDiscountStatesByPriceModelIdSelector,
} from "./specialDiscountSelector";

// Discount Rule
import { fetchDiscountRulesSelector } from "./discountRuleSelector";

// Booster
import {
	boosterStateByPMIdAndFeatureIdAndPackIdAndInputTypeSelector,
	boosterStatesByPriceModelIdSelector,
	removeBoosterByPMIdAndFeatureIdAndPackIdAndInputTypeSelector,
} from "./boosterSelector";

// Public link
import {
	contactEmailsSelector,
	discountPricePointByPackIdAndTypeSelector,
	discountPricePointByPriceModelIdSelector,
	fetchPriceModelsSelector,
	highlightDifferencesByPriceModelIdSelector,
	hidePricesByPriceModelIdSelector,
	isSideBarExpandedByPriceModelIdSelector,
	openedAllSectionSelector,
	openedSectionSelector,
	priceModelTypeByPriceModelIdSelector,
	selectedPackIdByPriceModelIdSelector,
	showPackInfoSelectionByPriceModelIdSelector,
	showSavingsByPriceModelIdSelector,
	tvaDisplayByPriceModelIdSelector,
} from "./publicLinkSelector";

// Atoms
import {
	additionalValuesStateAtom,
	boosterStateAtom,
	commentAtom,
	commitmentDiscountStateAtom,
	companyNameAtom,
	contactEmailsAtom,
	numericalDiscountRangeStateAtom,
	numericalDiscountStateAtom,
	paymentTermsDiscountStateAtom,
	postalAddressAtom,
	prospectEmailAtom,
	publicLinkCurrentPriceModelCombinationIdAtom,
	publicLinkOpenedSectionAtom,
	publicLinkPriceModelTypeAtom,
	publicLinkProfitabilityPeriodAtom,
	publicLinkSelectedPackIdAtom,
	publicLinkSelectedTabAtom,
	publicLinkShowOfferConfirmationAtom,
	publicLinkTypeAtom,
	publicShowSignatureActionAtom,
	publicSignatureStatusAtom,
	specialDiscountStateAtom,
	volumeDiscountIdStateAtom,
	volumeDiscountStateAtom,
} from "./atom";

export default recoilActions;

export {
	priceModelType,
	publicLinkTypeAtom,
	volumeDiscountStateByPriceModelIdSelector,
	volumeDiscountVolumeLinkedStateByPriceModelIdSelector,
	commitmentDiscountStateByPriceModelIdSelector,
	commitmentDiscountConditionShowSelectByPriceModelIdSelector,
	commitmentDiscountValueByPriceModelIdSelector,
	paymentTermsDiscountStateByPriceModelIdSelector,
	additionalValuesStateByPMIdAndDiscountIdAndPackIdAndRuleIdSelector,
	resetAdditionalValuesStatesByPriceModelIdAndRuleType,
	additionalValuesStatesByPriceModelIdSelector,
	specialDiscountStateByPriceModelIdAndDiscountIdSelector,
	specialDiscountStatesByPriceModelIdSelector,
	fetchDiscountRulesSelector,
	boosterStatesByPriceModelIdSelector,
	boosterStateByPMIdAndFeatureIdAndPackIdAndInputTypeSelector,
	removeBoosterByPMIdAndFeatureIdAndPackIdAndInputTypeSelector,
	fetchPriceModelsSelector,
	isSideBarExpandedByPriceModelIdSelector,
	highlightDifferencesByPriceModelIdSelector,
	hidePricesByPriceModelIdSelector,
	selectedPackIdByPriceModelIdSelector,
	priceModelTypeByPriceModelIdSelector,
	showSavingsByPriceModelIdSelector,
	tvaDisplayByPriceModelIdSelector,
	volumeDiscountStateAtom,
	volumeDiscountIdStateAtom,
	commitmentDiscountStateAtom,
	paymentTermsDiscountStateAtom,
	publicLinkSelectedPackIdAtom,
	publicLinkProfitabilityPeriodAtom,
	publicLinkPriceModelTypeAtom,
	specialDiscountStateAtom,
	numericalDiscountStateAtom,
	numericalDiscountRangeStateAtom,
	initNumericalDiscountSelector,
	additionalValuesStateAtom,
	boosterStateAtom,
	publicSignatureStatusAtom,
	publicShowSignatureActionAtom,
	publicLinkShowOfferConfirmationAtom,
	publicLinkCurrentPriceModelCombinationIdAtom,
	discountPricePointByPackIdAndTypeSelector,
	discountPricePointByPriceModelIdSelector,
	openedSectionSelector,
	openedAllSectionSelector,
	publicLinkOpenedSectionAtom,
	publicLinkSelectedTabAtom,
	showPackInfoSelectionByPriceModelIdSelector,
	companyNameAtom,
	commentAtom,
	contactEmailsAtom,
	contactEmailsSelector,
	postalAddressAtom,
	prospectEmailAtom,
};
