import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";

import { GET_PERIODS_BY_WORKSPACE_ID } from "../../graphQl/query";
import { useAuth } from "@hooks/useAuth/UseAuth";

import { listOfFixedCostObj } from "@services/defaultValues";
import {
	createBulkFixedCostMutation,
	createDistributionMutation,
	createMyPotentialMutation,
	createProductMutation,
	createProfitabilityMutation,
	createProjectionMutation,
} from "@components/headerCustom/periods/graphQl/mutation";

import {
	CPDatesCreate,
	CPDuration,
	ErrorDuration,
	ErrorIcon,
	RightArrowIcon,
	TopTitleInput,
	WrapperCellInputText,
	WrapperCenterArrowDate,
	WrapperCreatePeriod,
	WrapperDuration,
	WrapperErrorIcon,
} from "@components/headerCustom/periods/periodsDrawer/PeriodsDrawer.style";
import { actions } from "@components/headerCustom/periods/periodsDrawer/reducer";
import moment from "moment";
import { Dialog, Icon, Input, InputNumber, Loader, SingleSelect, Tooltipv3 } from "priceit-ui";
import { useTranslation } from "react-i18next";
import { basedOnEnum } from "@pages/specificAnalyses/distributions/reducer/reducer";
import { priceMetricTrad, timeMetricsObj } from "@services/i18n";
import {
	createCategorizationMutation,
	createFixedCostPeriodCategorizationMutation,
	createPeriodCategorizationMutation,
} from "@src/graphQl/mutations/categorizationNewMutation";
import { createUsersMutation } from "@src/graphQl/mutations/user";
import { createPeriodMutation } from "@src/graphQl/mutations/period";

const { Modal } = Dialog;

export const ModalePeriodCreation = ({ visible, dispatchPeriodDrawer }) => {
	const { workspaceId } = useParams();
	const { t } = useTranslation(["shared/periodsDrawer"]);

	const timeMetrics = timeMetricsObj();
	const timeMetric = "month";

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState("");
	const [comment, setComment] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [type, setType] = useState("F");
	const [realRevenue, setRealRevenue] = useState(null);
	const [realCost, setRealCost] = useState(null);
	const [errorName, setErrorName] = useState(false);
	const [errorDates, setErrorDates] = useState(false);

	const listOfFixedCost = listOfFixedCostObj();

	const resetInputs = () => {
		setName("");
		setComment("");
		setStartDate("");
		setEndDate("");
		setType("F");
		setRealRevenue(null);
		setRealCost(null);
	};

	const {
		loading: loadingPeriod,
		error: errorPeriod,
		data: dataPeriod,
	} = useQuery(GET_PERIODS_BY_WORKSPACE_ID, {
		variables: { workspaceId },
	});

	const startDateTimeStamp = moment(startDate, "MM-YYYY", true);
	const endDateTimeStamp = moment(endDate, "MM-YYYY", true);
	const duration = endDateTimeStamp.diff(startDateTimeStamp, "months") + 1;

	useEffect(() => {
		setErrorDates(false);
	}, [startDate, endDate]);

	const errorNameCondition =
		dataPeriod?.getPeriodsByWorkspaceId &&
		dataPeriod.getPeriodsByWorkspaceId.map(element => element.name).includes(name);

	useEffect(() => {
		setErrorName(false);
	}, [name]);

	const checkPeriodErrors = () => {
		let validStatus = true;
		if (!startDateTimeStamp.isValid() || !endDateTimeStamp.isValid() || duration < 1) {
			setErrorDates(true);
			validStatus = false;
		}
		if (errorNameCondition || !name) {
			setErrorName(true);
			validStatus = false;
		}
		return validStatus;
	};

	const { auth } = useAuth();
	const createPeriod = createPeriodMutation();
	const createUsers = createUsersMutation();
	const createBulkFixedCost = createBulkFixedCostMutation();
	const createProduct = createProductMutation();
	const createProfitability = createProfitabilityMutation();
	const createMyPotential = createMyPotentialMutation();
	const createProjection = createProjectionMutation();
	const createDistribution = createDistributionMutation();
	const createCategorization = createCategorizationMutation();
	const createPeriodCategorization = createPeriodCategorizationMutation();
	const createFixedCostPeriodCategorization = createFixedCostPeriodCategorizationMutation();

	const currency = "€";

	const onCreatePeriod = async period => {
		let priceitUser = undefined;
		if (auth?.email === "priceit@pricingpact.com") {
			priceitUser = auth;
		} else {
			priceitUser = (
				await createUsers({
					variables: {
						createUsersInput: {
							workspaceId: parseInt(workspaceId),
							emails: ["priceit@pricingpact.com"],
							activeFromDate: null,
							activeToDate: null,
							unlimitedAccess: true,
							role: "admin",
						},
					},
				})
			).data.createUsers[0];
		}

		if (priceitUser) {
			const newPeriod = (
				await createPeriod({
					variables: {
						createPeriodInput: period,
					},
				})
			).data.createPeriod;

			const categorization1 = await createCategorization({
				variables: {
					createCategorizationInput: {
						name: "Categorization 1",
					},
				},
			});
			await createPeriodCategorization({
				variables: {
					createPeriodCategorizationInput: {
						categorizationId: categorization1.data.createCategorization.id,
						periodId: newPeriod.id,
					},
				},
			});
			const categorization2 = await createCategorization({
				variables: {
					createCategorizationInput: {
						name: "Categorization 2",
					},
				},
			});
			await createPeriodCategorization({
				variables: {
					createPeriodCategorizationInput: {
						categorizationId: categorization2.data.createCategorization.id,
						periodId: newPeriod.id,
					},
				},
			});
			const categorization3 = await createCategorization({
				variables: {
					createCategorizationInput: {
						name: "Categorization 3",
					},
				},
			});
			await createPeriodCategorization({
				variables: {
					createPeriodCategorizationInput: {
						categorizationId: categorization3.data.createCategorization.id,
						periodId: newPeriod.id,
					},
				},
			});

			for (let i = 0; i < 3; i++) {
				const newCategorization = await createCategorization({
					variables: {
						createCategorizationInput: {
							name: "Categorization " + (i + 1),
						},
					},
				});
				await createFixedCostPeriodCategorization({
					variables: {
						createFixedCostPeriodCategorizationInput: {
							categorizationId: newCategorization.data.createCategorization.id,
							periodId: newPeriod.id,
						},
					},
				});
			}

			await createBulkFixedCost({
				variables: {
					periodId: newPeriod.id,
					userId: priceitUser.id,
					fixedCosts: listOfFixedCost,
				},
			});

			const newProduct = (
				await createProduct({
					variables: {
						createProductInput: {
							periodId: newPeriod.id,
							userId: priceitUser.id,
							name: t("Demo product"),
							description: "by Pricing Pact",
							price: 10,
							tva: 21,
							deductibleTva: null,
							volume: 1000,
							volumeMetric: "unit",
							frequency: null,
							frequencyMetric: null,
							discount: null,
							variableCosts: 1,
							timeMetric: "year",
							paymentFrequency: "notApplicable",
							priceIsKnowingIn: "HTVA",
						},
					},
				})
			).data.createProduct;

			await createProfitability({
				variables: {
					createProfitabilityInput: {
						periodId: newPeriod.id,
						userId: priceitUser.id,
						name: "Demo profitability",
						description: "by Pricing Pact",
						isFavorite: false,
						periodType: "year",
						chartTypeOn: "amount",
						price: 0,
						volume: 0,
						variableCosts: 0,
						fixedCosts: 0,
						productIds: [newProduct.id],
					},
				},
			});

			await createMyPotential({
				variables: {
					createMyPotentialInput: {
						periodId: newPeriod.id,
						userId: priceitUser.id,
						periodType: "year",
						name: "Demo potential",
						description: "by Pricing Pact",
						isFavorite: false,
						isTva: false,
						volumeVariation1: 0,
						volumeVariation2: 5,
						volumeVariation3: 10,
						volumeVariation4: 15,
						priceVariation1: 0,
						priceVariation2: 5,
						priceVariation3: 10,
						priceVariation4: 15,
						productIds: [newProduct.id],
					},
				},
			});

			await createProjection({
				variables: {
					createProjectionInput: {
						periodId: newPeriod.id,
						userId: priceitUser.id,
						periodType: "year",
						name: "Demo projection",
						description: "by Pricing Pact",
						isFavorite: false,

						chartPerformanceEvolution: "growthInValue",
						chartProfitDriverEvolution: "priceChange",
						chartInitProfit: "amount",
						chartFinalProfit: "amount",
						priceEvolution: 1,
						volumeEvolution: 5,
						variableCostsEvolution: 1,
						fixedCostsEvolution: 2,
						numberOfPeriod: 3,
						driversSetting: "percentageIncrease",
						periodTypeGoal: "year",
						productIds: [newProduct.id],
					},
				},
			});

			await createDistribution({
				variables: {
					createDistributionInput: {
						name: "Demo distribution",
						isFavorite: false,
						description: "by Pricing Pact",
						timeframe: "year",
						basedOn: basedOnEnum.listPrices,
						range: "100",
						isRecurring: false,
						timeMetric: "",
						periodId: newPeriod.id,
						metrics: [],
					},
				},
			});
			return newPeriod;
		}
		return { id: null };
	};
	return (
		<Modal
			visible={visible}
			onCancel={e =>
				dispatchPeriodDrawer({
					type: actions.UPDATE_IS_CREATING,
					value: false,
				})
			}
			onOk={async () => {
				if (checkPeriodErrors()) {
					setIsLoading(true);
					await onCreatePeriod({
						workspaceId,
						name,
						comment,
						startDate: startDateTimeStamp.valueOf(),
						endDate: endDateTimeStamp.valueOf(),
						type,
						estimationFixedCost: realCost || 0,
						estimationProducts: realRevenue || 0,
					});
					resetInputs();
					setIsLoading(false);
					dispatchPeriodDrawer({
						type: actions.UPDATE_IS_CREATING,
						value: false,
					});
					setIsLoading(false);
				}
			}}
			okText={isLoading ? <Loader /> : t("Create")}
			title={t("Create a new period")}
			width="500px"
			onOkDisabled={isLoading}
		>
			<WrapperCreatePeriod>
				<WrapperCellInputText>
					<TopTitleInput>{t("Period Name*")}</TopTitleInput>
					<Input
						disabled={isLoading}
						placeholder={t("Name")}
						mode={"dark"}
						error={errorName}
						errorTooltipContent={t("This name already exists")}
						centerPlaceHolder={false}
						value={name}
						width="100%"
						height="30px"
						onChange={e => {
							setName(e.target.value);
						}}
					/>
				</WrapperCellInputText>
				<WrapperCellInputText>
					<TopTitleInput>{t("Description")}</TopTitleInput>
					<Input
						disabled={isLoading}
						mode={"dark"}
						placeholder={t("Description")}
						theme={{
							backgroundColor: "#354048",
							inputtextcolor: "#A5B9C8",
							borderColor: "#A5B9C8",
						}}
						value={comment}
						width="100%"
						height="30px"
						onChange={e => setComment(e.target.value)}
					/>
				</WrapperCellInputText>
				<div style={{ display: "flex", width: "100%" }}>
					<WrapperCellInputText>
						<TopTitleInput>{t("Period*")}</TopTitleInput>
						<WrapperCenterArrowDate>
							<CPDatesCreate>
								<InputNumber
									disabled={isLoading}
									mode={"dark"}
									placeholder="MM-YYYY"
									error={errorDates}
									widthErrorIcon="0px"
									width="125px"
									height="30px"
									format="##-####"
									mask={[
										t("maskDateMonth"),
										t("maskDateMonth"),
										t("maskDateYear"),
										t("maskDateYear"),
										t("maskDateYear"),
										t("maskDateYear"),
									]}
									onChange={e => setStartDate(e.formattedValue)}
									value={startDate}
								/>

								<Icon
									type="arrowRight"
									fill="#FDB432"
									style={{ margin: "0px 5px", width: "24px" }}
								/>
								<InputNumber
									disabled={isLoading}
									mode={"dark"}
									placeholder="MM-YYYY"
									error={errorDates}
									widthErrorIcon="0px"
									errorTooltipContent={t("Invalid dates")}
									width="125px"
									height="30px"
									format="##-####"
									mask={[
										t("maskDateMonth"),
										t("maskDateMonth"),
										t("maskDateYear"),
										t("maskDateYear"),
										t("maskDateYear"),
										t("maskDateYear"),
									]}
									onChange={e => setEndDate(e.formattedValue)}
									value={endDate}
								/>
							</CPDatesCreate>

							{errorDates ? (
								<WrapperDuration>
									<RightArrowIcon type="upTriangleSharp" />
									<Tooltipv3
										placement="top"
										type="error"
										maxWidth="250px"
										content={
											<ErrorDuration>
												{t(
													"Please make sure you have entered valid dates in the time scope."
												)}
											</ErrorDuration>
										}
									>
										<WrapperErrorIcon>
											<ErrorIcon type="warning" />
										</WrapperErrorIcon>
									</Tooltipv3>
								</WrapperDuration>
							) : isNaN(duration) ? null : (
								<WrapperDuration>
									<RightArrowIcon type="upTriangleSharp" />
									<CPDuration>
										{duration}{" "}
										{priceMetricTrad({
											priceMetricList: timeMetrics,
											priceMetric: timeMetric,
											count: duration || 1,
										})}
									</CPDuration>
								</WrapperDuration>
							)}
						</WrapperCenterArrowDate>
					</WrapperCellInputText>
				</div>
				<WrapperCellInputText>
					<TopTitleInput>{t("Real/Fictive*")}</TopTitleInput>
					<SingleSelect.Group
						disabled={isLoading}
						type="secondary"
						mode="dark"
						center
						appendTo={document.body}
						height="30px"
						width="125px"
						onChange={value => setType(value)}
						value={type}
						data={[
							{
								value: "R",
								color: "#ffdd67",
								option: (
									<SingleSelect.Option center>{t("Real")}</SingleSelect.Option>
								),
							},
							{
								value: "F",
								color: "#a5b9c8",
								option: (
									<SingleSelect.Option center>{t("Fictive")}</SingleSelect.Option>
								),
							},
						]}
					/>
				</WrapperCellInputText>
				<WrapperCellInputText>
					<TopTitleInput>
						{type === "R"
							? t(
									"shared/periodsDrawer:What was the achieved revenue during this period?"
								)
							: t(
									"shared/periodsDrawer:What is your targeted revenue for this period?"
								)}
					</TopTitleInput>
					<InputNumber
						disabled={isLoading}
						placeholder={Number(0).fnb({ stringText: true, withCurrency: true })}
						theme={{
							backgroundColor: "#354048",
							inputtextcolor: "#A5B9C8",
							borderColor: "#A5B9C8",
						}}
						mode={"dark"}
						width="125px"
						height="30px"
						currency={currency}
						value={realRevenue}
						onChange={e => setRealRevenue(e.floatValue)}
					/>
				</WrapperCellInputText>
				<WrapperCellInputText>
					<TopTitleInput>
						{type === "R"
							? t(
									"shared/periodsDrawer:What were the total costs during this period?"
								)
							: t(
									"shared/periodsDrawer:What are your projected costs during this period"
								)}
					</TopTitleInput>
					<InputNumber
						disabled={isLoading}
						placeholder={Number(0).fnb({ stringText: true, withCurrency: true })}
						mode={"dark"}
						theme={{
							backgroundColor: "#354048",
							inputtextcolor: "#A5B9C8",
							borderColor: "#A5B9C8",
						}}
						width="125px"
						height="30px"
						currency={currency}
						value={realCost}
						onChange={e => setRealCost(e.floatValue)}
					/>
				</WrapperCellInputText>
			</WrapperCreatePeriod>
		</Modal>
	);
};
